<div class="modal fade" bsModal #createTicketModal="bs-modal" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-sm">
    <div class="modal-content">
      <div class="modal-header clearfix">
        <h4 class="modal-title pull-left">Create a new ticket</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="close()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form [formGroup]="formGroup" class="ND--elements--main-form-group">
            <div class="form-group col-sm-6">
              <label>Title<sup class='required-star'></sup></label>
              <input name="title" type="text" formControlName="title" class="form-control" />
              <div
                class="text-danger"
                *ngIf="!formGroup.controls.title?.valid && (formGroup.controls.title?.dirty || formGroup.controls.title?.touched)"
              >
                <div [hidden]="!formGroup.controls.title.errors.required">Title is required</div>
                <div [hidden]="!formGroup.controls.title.errors.maxlength">Max Length is 30 characters</div>
              </div>
            </div>

            <div class="form-group col-sm-6">
              <label>Status<sup class='required-star'></sup></label>
              <app-dropdown name="status" [data]="ticketStatuses" formControlName="statusId" [textField]="'Status'" [valueField]="'Id'">
              </app-dropdown>
              <div
                class="text-danger"
                *ngIf="!formGroup.controls.statusId?.valid && (formGroup.controls.statusId?.dirty || formGroup.controls.statusId?.touched)"
              >
                <div [hidden]="!formGroup.controls.statusId.errors.required">Status is required</div>
              </div>
            </div>

            <div class="form-group col-sm-12">
              <label>Description</label>
                <textarea name="description" type="text" formControlName="description" class="ticket-form-textarea form-control"></textarea>
                <div
                  class="text-danger"
                  *ngIf="
                  !formGroup.controls.description?.valid &&
                  (formGroup.controls.description?.dirty || formGroup.controls.description?.touched)
                "
                >
                  <div [hidden]="!formGroup.controls.description.errors.maxlength">Max Length is 220 characters</div>
                </div>
            </div>
        </form>
      </div>

      <div class="modal-footer">
        <button class="button button--primary" (click)='save()' [disabled]="!this.formGroup.valid">Save</button>
        <button class="button" type="reset" (click)="close()">Cancel</button>
      </div>

    </div>
  </div>
</div>
