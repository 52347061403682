import { isPlatformBrowser } from '@angular/common';
import { BehaviorSubject, Observable, Subject, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import {
  IAuthResponse,
  IServiceResponse,
  Presence,
  PresenceAndNote,
  ServerNotification,
  PresenceAndNoteSet,
  CallSession,
  CallSessionStateChanged,
  Endpoint,
} from '../model';
import { io } from 'socket.io-client';

export interface InvokeServiceArgs {
  UniqueServiceCallId?: number;
  Target?: string;
  TokenId?: string;
  Operation: string;
  RequestData: string;
}

const CC4ALL_TOKEN = 'cc4all-jwt';
const CC4ALL_USERID = 'cc4all-userid';

export class CC4AllSocket {
  private _socket: any;

  private _token: string;
  private _userId: number;
  private _sip: string;
  private _authenticated$ = new BehaviorSubject(false);
  private _initialized$ = new BehaviorSubject(false);

  private _handlersCounter = 0;
  private _handlers = new Map<string, Subject<any>>();

  private _callSessions$ = new Subject<CallSession>();
  private _presenceAndNote$ = new Subject<PresenceAndNote>();
  private _serverNotifications$ = new Subject<any>();

  public get callSessions$(): Observable<CallSession> {
    return this._callSessions$.asObservable();
  }
  public get presenceAndNote$(): Observable<PresenceAndNote> {
    return this._presenceAndNote$.asObservable();
  }
  public get serverNotifications$(): Observable<any> {
    return this._serverNotifications$.asObservable();
  }

  public get authenticated$(): Observable<boolean> {
    return this._authenticated$.asObservable();
  }

  public get initialized$(): Observable<boolean> {
    return this._initialized$.asObservable();
  }

  public get sip(): string {
    return this._sip;
  }

  constructor(
    private endpoint: string,
    private target: string,
    private platformId: Object,
    private _debug: boolean = false,
  ) {
    if (isPlatformBrowser(platformId)) {
      this._token = localStorage.getItem(CC4ALL_TOKEN) || null;
      this._userId = parseInt(localStorage.getItem(CC4ALL_USERID), 10) || null;

      if (_debug) {
        localStorage.debug = 'socket.io-client:socket';
      }
    }

    this._socket = io(endpoint, {
      secure: true,
      reconnection: true,
      rejectUnauthorized: false,
    });

    // this is emmited from the server when the socket connects, useful for example to join the server to receive notifications
    this._socket.on('mySocketConnected', (value) => {
      this.join();
    });

    if (!!this._token && !!this._userId) {
      this.signInToken(this._token).subscribe((res) => {
        this._initialized$.next(true);
      });
    } else {
      this._initialized$.next(true);
    }

    // response from server
    this._socket.on('invokeServiceResult', (value) => {
      let responseHandler: Subject<any> = this._handlers[value.UniqueServiceCallId];
      delete this._handlers[value.UniqueServiceCallId];

      if (responseHandler) {
        if (value.Error && value.Error !== '') {
          responseHandler.error(value.Error);
        } else {
          try {
            let resp = value.ResponseData !== null ? JSON.parse(value.ResponseData) : null;
            responseHandler.next(resp);
            responseHandler.complete();
          } catch (error) {
            responseHandler.error(error);
          }
        }
      }
    });

    // notification from server
    this._socket.on('serverNotification', (value: ServerNotification) => {
      switch (value.Operation) {
        case PresenceAndNoteSet:
          if (value.Target !== this._sip) {
            return;
          }

          let presence: PresenceAndNote = JSON.parse(value.RequestData);
          this._presenceAndNote$.next(presence);
          break;

        case CallSessionStateChanged:
          let callSession: CallSession = JSON.parse(value.RequestData);
          this._callSessions$.next(callSession);
          break;

        default:
          this._serverNotifications$.next(value);
          break;
      }
    });
  }

  signOut() {
    this._token = null;
    localStorage.removeItem(CC4ALL_TOKEN);
    this._authenticated$.next(false);
  }

  close() {
    this._serverNotifications$.complete();
    this._socket.disconnect();
    this._socket.close();
  }

  // sign in with username and password and get the token
  public signIn(username: string, password: string): Observable<IAuthResponse> {
    let args = {
      Operation: 'SignIn',
      RequestData: JSON.stringify({ Username: username, Password: password }),
    };

    let result = this.invokeService<InvokeServiceArgs, IServiceResponse<IAuthResponse>>(args);
    return this.handleAuthResponsePipe(result);
  }

  public aadSignIn(accessToken: string): Observable<IAuthResponse> {
    let args = {
      Operation: 'SignInAzureAD',
      RequestData: JSON.stringify({ Token: accessToken, Target: this.target }),
    };

    let result = this.invokeService<InvokeServiceArgs, IServiceResponse<IAuthResponse>>(args);
    return this.handleAuthResponsePipe(result);
  }

  private signInToken(accessToken: string): Observable<IAuthResponse> {
    let args = {
      Operation: 'SignInToken',
      RequestData: JSON.stringify({ Token: accessToken, Target: this.target }),
    };

    let result = this.invokeService<InvokeServiceArgs, IServiceResponse<IAuthResponse>>(args);
    return this.handleAuthResponsePipe(result);
  }

  public changePresence(presence: Presence, note: string): Observable<any> {
    let args = {
      Operation: 'ChangePresence',
      RequestData: JSON.stringify({ Sip: this._sip, Presence: presence, Note: note }),
    };

    return this.invokeService<InvokeServiceArgs, any>(args).pipe(map((res) => true));
  }

  public ejectFromMeeting(conversationKey: string, sessionId: string): Observable<any> {
    let args = {
      Operation: 'EjectFromMeeting',
      RequestData: JSON.stringify({ SourceUri: this._sip, ConversationKey: conversationKey, SessionId: sessionId }),
    };

    return this.invokeService<InvokeServiceArgs, any>(args).pipe(map((res) => true));
  }

  public sendDTMF(conversationKey: string, tone: string): Observable<any> {
    let args = {
      Operation: 'SendTone',
      RequestData: JSON.stringify({ SourceUri: this._sip, ConversationKey: conversationKey, Tone: tone }),
    };

    return this.invokeService<InvokeServiceArgs, any>(args);
  }

  public getAgentCallSession(): Observable<CallSession> {
    let args = {
      Operation: 'AgentCallSession',
      RequestData: JSON.stringify({ sip: this._sip, UserAzureId: null, Ref: this._userId }),
    };

    return this.invokeService<InvokeServiceArgs, CallSession>(args);
  }

  public getEndPointListForAgent(): Observable<Array<Endpoint>> {
    let args = {
      Operation: 'GetEndPointListForAgent',
      RequestData: this._userId + '',
    };

    return this.invokeService<InvokeServiceArgs, Array<Endpoint>>(args);
  }

  public pauseCall(conversationKey: string, sessionId: string): Observable<boolean> {
    let args = {
      Operation: 'AgentPutOnHold',
      RequestData: JSON.stringify({
        SourceUri: this._sip,
        ConversationKey: conversationKey,
        SessionId: sessionId,
      }),
    };

    return this.invokeService<InvokeServiceArgs, any>(args).pipe(map((res) => true));
  }

  public resumeCall(conversationKey: string, sessionId: string): Observable<boolean> {
    let args = {
      Operation: 'AgentResumed',
      RequestData: JSON.stringify({
        SourceUri: this._sip,
        ConversationKey: conversationKey,
        SessionId: sessionId,
      }),
    };

    return this.invokeService<InvokeServiceArgs, any>(args).pipe(map((res) => false));
  }

  public createOutboundCall(uniqueCallId: string, endpointId: number, fromUri: string, toUri: string): Observable<any> {
    let args = {
      Operation: 'CreateOutboundCall',
      RequestData: JSON.stringify({
        CaallerAgentSip: this._sip,
        UniqueCallId: uniqueCallId,
        IsTeamsOutboundDialing: true,
        CallingNumber: fromUri,
        TargetCustomerPhoneNumber: toUri,
        EndpointID: endpointId,
      }),
    };

    return this.invokeService<InvokeServiceArgs, any>(args);
  }

  public warmTransferStart(conversationKey: string, sessionId: string, targetUri: string): Observable<any> {
    let callSessionRequest = {
      SourceUri: this._sip,
      ConversationKey: conversationKey,
      SessionId: sessionId,
    };

    let args = {
      Operation: 'WarmTransferStart',
      RequestData: JSON.stringify({
        CallSessionRequest: callSessionRequest,
        TargetUri: targetUri,
        ContactType: 0, // Agent
      }),
    };

    return this.invokeService<InvokeServiceArgs, any>(args).pipe(map((res) => true));
  }

  public warmTransferCancel(conversationKey: string, sessionId: string, targetUri: string): Observable<any> {
    let callSessionRequest = {
      SourceUri: this._sip,
      ConversationKey: conversationKey,
      SessionId: sessionId,
    };

    let args = {
      Operation: 'WarmTransferCancel',
      RequestData: JSON.stringify({
        CallSessionRequest: callSessionRequest,
        TargetUri: targetUri,
        ContactType: 0, // Agent
      }),
    };

    return this.invokeService<InvokeServiceArgs, any>(args).pipe(map((res) => true));
  }

  public switchToCallerDuringWarmTransfer(conversationKey: string, sessionId: string, targetUri: string): Observable<any> {
    let args = {
      Operation: 'SwitchToCallerDuringWarmTransfer',
      RequestData: JSON.stringify({
        SourceUri: this._sip,
        ConversationKey: conversationKey,
        SessionId: sessionId,
      }),
    };

    return this.invokeService<InvokeServiceArgs, any>(args);
  }

  public switchToAgentDuringWarmTransfer(conversationKey: string, sessionId: string, targetUri: string): Observable<any> {
    let args = {
      Operation: 'SwitchToAgentDuringWarmTransfer',
      RequestData: JSON.stringify({
        SourceUri: this._sip,
        ConversationKey: conversationKey,
        SessionId: sessionId,
      }),
    };

    return this.invokeService<InvokeServiceArgs, any>(args);
  }

  public toggleRecording(conversationKey: string, sessionId: string, targetUri: string): Observable<any> {
    let args = {
      Operation: 'ToggleRecording',
      RequestData: JSON.stringify({
        SourceUri: this._sip,
        ConversationKey: conversationKey,
        SessionId: sessionId,
      }),
    };

    return this.invokeService<InvokeServiceArgs, any>(args);
  }

  public invokeService<TArgs extends InvokeServiceArgs, TRes>(args: TArgs): Observable<TRes> {
    let counter = this._handlersCounter++;
    let result = new Observable<TRes>((observer) => {
      this._handlers[counter.toString()] = observer;
      return () => {
        delete this._handlers[counter];
      };
    });

    args.UniqueServiceCallId = counter;
    args.Target = this.target;
    args.TokenId = this._token;
    if (!args.TokenId) {
      delete args.TokenId;
    }

    this._socket.emit('invokeService', args);
    return result;
  }

  private join() {
    if (this._token) {
      let args = {
        token: this._token,
        companyKey: this.target,
      };

      this._socket.emit('joinToken', args);
    }
  }

  private handleAuthResponsePipe(response: Observable<IServiceResponse<IAuthResponse>>): Observable<IAuthResponse> {
    return response.pipe(
      tap((x) => this.handleAuthResponse(x)),
      map((x) => x.Data),
      catchError((err) => {
        this.signOut();
        return throwError(err);
      }),
    );
  }

  private handleAuthResponse(response: IServiceResponse<IAuthResponse>) {
    if (!!response.ValidationFailures ?? response.ValidationFailures.length > 0) {
      throw Error(response.ValidationFailures[0].Message);
    }

    if (response.Exception) {
      throw Error(response.Exception);
    }

    this._token = response.Data.JWT;
    this._sip = response.Data.SIP;
    localStorage.setItem(CC4ALL_TOKEN, this._token);

    if (!!response.Data.UserId) {
      this._userId = response.Data.UserId;
      localStorage.setItem(CC4ALL_USERID, this._userId + '');
    }

    this.join();

    this._authenticated$.next(true);
  }
}
