<h3 class="info-header">Pictures</h3>

<div class="row">
  <div class="col-xs-12" *ngFor="let image of images; let $index = index">
    <h4>{{ image.description }}</h4>
    <img class="img-responsive" [src]="image.thumbUrl" (error)="onThumbError(image)" (click)="openDetails(image.imageUrl)" />
  </div>
</div>

<app-picture-details #pictureDetailsModal></app-picture-details>
