<div class="modal fade" bsModal #modal="bs-modal" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-sm">
    <div class="modal-content">
      <div class="modal-header clearfix">
        <h4 class="modal-title pull-left">Add note</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="cancel()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body">
        <form #form="ngForm" *ngIf="initialized" class="ND--elements--main-form-group">
          <div class="form-group col-sm-12" [class.has-error]="noteField | hasErrors: validate">
            <label class="control-label">Note<sup class='required-star'></sup></label>
            <textarea type="text" [(ngModel)]="note" name="note" #noteField="ngModel" class="form-control" required></textarea>
            <app-validation-errors [model]="noteField" [validate]="validate"></app-validation-errors>
          </div>

          <div class="form-group col-sm-12 ND--elements--common-checkbox">
            <input type="checkbox" class="inspinia-checkbox" name="sendAlertName" id="sendAlert" [(ngModel)]="sendAlert" />
            <label for="sendAlert">Send alert</label>
          </div>
        </form>
      </div>

      <div class="modal-footer">
        <button class="button ND--buttons--second-button" (click)="cancel()">Cancel</button>
        <button class="button ND--buttons--primary" type="submit" (click)="save()">Save</button>
      </div>
    </div>
  </div>
</div>
