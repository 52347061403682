import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

import { Ticket } from '@libs/portal-common/services';
import { IEditableComponent } from '@libs/portal-common/system';

@Component({
  selector: 'app-incident-ticket',
  templateUrl: './incident-ticket.component.html',
  styleUrls: ['./incident-ticket.component.scss'],
})
export class IncidentTicketComponent implements OnInit, IEditableComponent {
  ticket: Ticket = null;
  subscription: Subscription;

  constructor(private route: ActivatedRoute) {}

  ngOnInit() {
    this.subscription = this.route.parent.data.subscribe((data) => {
      this.ticket = data.ticket;
    });
  }

  hasChanges(): boolean {
    return false;
  }
}
