import { Injectable } from '@angular/core';

import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { BaseResponse, IRestBaseListResponse } from '../models/rest/baseResponse';
import { AppNotificationsService } from '@libs/portal-common/system';

export interface IDefaultRuleSet {
  Id?: number;
  fkRegion: number;
  fkRuleSet: number;
}

export interface ISetDefaultRuleSetResponse extends BaseResponse {
  DefaultRuleSet: IDefaultRuleSet;
}

@Injectable({ providedIn: 'root' })
export class DefaultRuleSetService {
  private url = 'DefaultRuleSet';

  constructor(
    private _httpClient: HttpClient,
    private notifications: AppNotificationsService,
  ) {}

  get(regionId?: number): Observable<IRestBaseListResponse<IDefaultRuleSet>> {
    let params = new HttpParams();
    if (!!regionId) {
      params = params.set('fkRegion', regionId.toString());
    }

    return this._httpClient.get<IRestBaseListResponse<IDefaultRuleSet>>(this.url, { params: params });
  }

  setAsDefault(model: IDefaultRuleSet): Observable<ISetDefaultRuleSetResponse> {
    return this._httpClient.post<ISetDefaultRuleSetResponse>(this.url, { DefaultRuleSet: model });
  }

  delete(id: number): Observable<any> {
    return this._httpClient.delete<any>(`${this.url}/${id}`);
  }
}
