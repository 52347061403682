import { Component, OnInit, HostBinding, Inject } from '@angular/core';
import { ActivityReportName, ANALYTICS, AnalyticsEvent, IAnalyticsService } from '@libs/portal-common/services';
import { routerTransition } from '@libs/portal-common/system';

import { ActivityFilter } from '../services/call-history.service';

@Component({
  selector: 'app-lane-activity',
  templateUrl: './lane-activity.component.html',
  animations: [routerTransition],
})
export class LaneActivityComponent implements OnInit {
  @HostBinding('@routerTransition') routerTransition = '';

  filter = new ActivityFilter();

  constructor(@Inject(ANALYTICS) private analytcis: IAnalyticsService) {}

  ngOnInit() {
    this.analytcis.track(AnalyticsEvent.DailyActivityOpen, { ReportName: ActivityReportName.LaneActivity });
  }

  search(filter: ActivityFilter) {
    this.filter = Object.assign(new ActivityFilter(), filter);
  }
}
