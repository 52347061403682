import { Injectable } from '@angular/core';
import { tap, map, filter, flatMap } from 'rxjs/operators';

import { HttpClient } from '@angular/common/http';
import { AppNotificationsService } from '@libs/portal-common/system';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class FieldGatewaysService {
  constructor(
    private _httpClient: HttpClient,
    private notifications: AppNotificationsService,
  ) {}

  getAllGateways(): Observable<any> {
    return this._httpClient.get<any>('FieldGateways/Gateways').pipe(
      map((gatewaysResponse) => {
        if (!gatewaysResponse.Success) {
          this.notifications.error(gatewaysResponse.Message);
          return [];
        }

        return gatewaysResponse.Data;
      }),
    );
  }

  getIsAvailable(): Observable<boolean> {
    return this._httpClient.get<any>('FieldGateways/Available').pipe(
      map((gatewaysResponse) => {
        if (!gatewaysResponse.Success) {
          this.notifications.error(gatewaysResponse.Message);
          return false;
        }

        return gatewaysResponse.Available;
      }),
    );
  }
}
