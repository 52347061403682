<div class="info-block" *ngIf="reasonsToCloseGate.length > 0 || reasonsToOpenGate.length > 0">
  <h3 class="info-header">Vend List</h3>
  <div class="panel panel-vend" *ngIf="reasonsToOpenGate.length > 0">
    <div class="panel-heading">
      <h5>Approved vends</h5>
    </div>
    <div class="panel-body">
      <ol>
        <li *ngFor="let item of reasonsToOpenGate">
          {{ item.title }}
        </li>
      </ol>
    </div>
  </div>
  <div class="panel panel-default" *ngIf="reasonsToCloseGate.length > 0">
    <div class="panel-heading">
      <h5>Do NOT Vend</h5>
    </div>
    <div class="panel-body">
      <ol>
        <li *ngFor="let item of reasonsToCloseGate">
          {{ item.title }}
        </li>
      </ol>
    </div>
  </div>
</div>
