import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
export interface SchedulerEvent {
  Created: string;
  EndTime: string;
  Id: number;
  IsActive: boolean;
  IsAllDay: boolean;
  IsGlobal: boolean;
  LocationId: number;
  Modified: string;
  RecurrenceException: string;
  RecurrenceId: number;
  RecurrenceRule: string;
  StartTime: string;
  TextToDisplay: string;
  Title: string;
  fkRegion: number;
  locationDisplay?: string;
  eventInfo?: { info: string; start?: string; end?: string; finished?: string };
}
export interface SchedulerEventResponse {
  Data: SchedulerEvent[];
}

@Injectable()
export class KendoScheduleNotificationsService {
  constructor(private _httpClient: HttpClient) {}

  getAll(): Observable<SchedulerEventResponse> {
    return this._httpClient.get<SchedulerEventResponse>('notification');
  }
}
