import { Observable } from 'rxjs';
import { IOutgoingConversation } from '.';
import { SubmissionParameters } from '.';

export type ConversationState = 'Incoming' | 'Accepted' | 'HangedUp' | 'Finished';
export type OutgoingConversationState = 'Created' | 'Connecting' | 'Connected' | 'Disconnected';

export type CallState = 'Incoming' | 'Accepted' | 'Finished';
export interface ICall {
  id: string;
  state: CallState;

  caller: string;
  sessionId: string;
  agentSip: string;

  data?: any;
}

export type CallsMapType = { [key: string]: ICall };

export interface IConversationParameters {
  lotName: string;
  terminateCallTone: string;
}

export interface IConversation {
  agentSip: string;
  agentName: string;

  callId: string;
  sessionId: string;
  callerUri: string;
  callSource: string;

  callDuration: number;
  wrapupDuration: number;
  queueDuration: number;

  canAccept: boolean;
  state$: Observable<ConversationState>;

  isPaused$: Observable<boolean>;

  resolving: boolean;
  cancelled: boolean;

  skipSavingIncident: boolean;

  submissionParameters: SubmissionParameters;

  accept(): Observable<boolean>;
  reject(): Observable<boolean>;

  hangUp(): Observable<boolean>;
  wrapUp(): Observable<boolean>;
  submitWrapUp(parameters: SubmissionParameters): Observable<boolean>;

  sendDtmf(dtmf: string): Observable<boolean>;

  setParameters(parameters: IConversationParameters);

  pause(): Observable<boolean>;
  resume(): Observable<boolean>;

  outboundCall(uri: string): Observable<IOutgoingConversation>;

  cancelProcessing(): Observable<boolean>;
  markCancelled();
  stringifyAndReset(): string;
}

export class ConversationBase {
  public submissionParameters: SubmissionParameters = {
    fkProblem: 0,
    ProblemName: null,
    fkIssue: 0,
    IssueName: null,
    GateVend: false,
    MgrNotified: false,
  };
}

export interface IIntegrationAppService {
  isAuthenticated$: Observable<boolean>;
  initialized$: Observable<boolean>;
  outgoingCall$: Observable<IOutgoingConversation>;

  calls$: Observable<CallsMapType>;

  name: string;

  signIn(username: string, password: string): Observable<boolean>;
  signInAAD(): Observable<boolean>;

  signOut(): Observable<any>;

  createConversation(caller: string, callId: string, sessionId: string, agentSip: string): Observable<IConversation>;
}
