<div class="modal fade" bsModal #modal="bs-modal" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content" *ngIf="!!manufacturer">
      <div class="modal-header clearfix">
        <h4 class="modal-title pull-left">Revenue Integrator: {{ manufacturer.Name }}</h4>

        <button type="button" class="close pull-right" aria-label="Close" (click)="close()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body options-pad">
        <!-- <button class="btn btn-default btn-sm btn-block" (click)="openGate()"
          [disabled]="!canDo('CanOpenGate')">Vend</button> -->
        <button class="btn btn-default btn-sm btn-block" (click)="closeGate()" [disabled]="!canDo('CanCloseGate')">Close Gate</button>
        <button class="btn btn-default btn-sm btn-block" (click)="setPrice()" [disabled]="!canDo('CanSetPrice')">Set Price</button>

        <button class="btn btn-default btn-sm btn-block" (click)="swallowTicket()" [disabled]="!canDo('CanSwallowTicket')">
          Swallow Ticket
        </button>
        <button class="btn btn-default btn-sm btn-block" (click)="ejectTicket()" [disabled]="!canDo('CanEjectTicket')">Eject Ticket</button>
        <button class="btn btn-default btn-sm btn-block" (click)="showTicketInfo()" [disabled]="!canDo('CanShowTicketInfo')">
          Show Ticket Info
        </button>

        <button class="btn btn-default btn-sm btn-block" (click)="setLostTicket()" [disabled]="!canDo('CanSetLostTicket')">
          Set Lost Ticket
        </button>
        <button class="btn btn-default btn-sm btn-block" (click)="validateTicket()" [disabled]="!canDo('CanValidateTicket')">
          Validate Ticket
        </button>
        <button class="btn btn-default btn-sm btn-block" (click)="executeAction()" [disabled]="!canExecuteAction()">Execute Action</button>
      </div>
    </div>
  </div>
</div>

<app-revenue-control-form #revenueControlForm></app-revenue-control-form>
<app-revenue-control-confirmation #revenueControlConfirmation></app-revenue-control-confirmation>

<app-ticket-info #ticketInfoModal></app-ticket-info>
