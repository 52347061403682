import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';

import { Observable } from 'rxjs';

import { ANALYTICS, BaseResponse, IAnalyticsService, RevenueControlService } from '@libs/portal-common/services';
import { AppNotificationsService } from '@libs/portal-common/system';

import { IncomingCall } from '../../../../../../abstractions';
import { IRevenueForm } from '../../revenue-form-factory.service';
import { AgentAnalyticsEvent } from '../../../../../../services';

type SendStatus = 'Success' | 'Error';

@Component({
  selector: 'app-set-price-base',
  templateUrl: './set-price-base.component.html',
  styleUrls: ['./set-price-base.component.scss'],
})
export class SetPriceBaseComponent implements IRevenueForm, OnInit {
  @ViewChild('form', { static: false }) public form: NgForm;

  locationId: number = null;
  laneId: number = null;
  provider: string;

  validating = false;
  initialized = false;

  price = null;
  ticketNum = null;

  constructor(
    private revenueControl: RevenueControlService,
    private notifications: AppNotificationsService,
    @Inject(ANALYTICS) private analytics: IAnalyticsService,
  ) {}

  ngOnInit() {
    this.analytics.track(AgentAnalyticsEvent.RevenueFormOpen, { Provider: this.provider, RCForm: 'SetPriceForm' });
  }

  init(provider: string, incomingCall: IncomingCall, formParams: any): void {
    this.validating = false;
    this.initialized = false;

    this.price = null;
    this.ticketNum = null;

    this.locationId = incomingCall.location.Id;
    this.laneId = incomingCall.lane.Id;
    this.provider = provider;

    setTimeout(() => (this.initialized = true), 30);
  }

  validate(): boolean {
    this.validating = true;
    return this.form.valid;
  }

  canSubmit(): boolean {
    return true;
  }

  submit(): Observable<BaseResponse> {
    this.analytics.track(AgentAnalyticsEvent.RevenueFormExecute, {
      Provider: this.provider,
      RCActionType: 'rc_set_price',
      SetPriceProperties: this.price * 1,
    });

    return this.revenueControl.setPrice({
      LotId: this.locationId,
      LaneId: this.laneId,
      CommandParams: {
        __type: 'grm.service.model.RevenueControl.PriceCommandParams, GRM.Service.Model',
        Price: this.price * 1,
        Ticket: this.ticketNum * 1,
      },
    });
  }
}
