import { Component, OnInit, Renderer2, Input, Inject } from '@angular/core';

import { IRouteDescription, INavbarItemsService, NAVBAR_ITEMS } from '@libs/portal-common/services';
import { animate, style, transition, trigger } from '@angular/animations';

@Component({
  templateUrl: 'navbar.component.html',
  styleUrls: ['navbar.component.scss'],
  selector: 'app-nav-bar',
  animations: [
    trigger('insertRemoveNavList', [
      transition(':enter', [style({ opacity: 0 }), animate('0.1s 0.2s', style({ opacity: 1 }))]),
      transition(':leave', [animate('100ms', style({ opacity: 0 }))]),
    ]),
  ],
})
export class NavBarComponent implements OnInit {
  _isMenuCollapsed: boolean;
  @Input() set isMenuCollapsed(flag: boolean) {
    this._isMenuCollapsed = flag;
    if (!this._isMenuCollapsed) {
      this.navigationsCollapsed = this.setDefaultCollapseState();
    }
  }

  hasImage = true;
  logoImage = '/img/splash.png';
  companyNameShortcut = '';
  companyName = '';
  currentYear = new Date().getFullYear();
  isCollapsed = false;

  navigationsCollapsed: { [id: string]: boolean } = this.setDefaultCollapseState();

  routes: IRouteDescription[] = [];

  constructor(
    private renderer: Renderer2,
    @Inject(NAVBAR_ITEMS) private navbarItemsService: INavbarItemsService,
  ) {}

  ngOnInit() {
    this.navbarItemsService.routes$.subscribe((routes) => (this.routes = routes));
  }

  /**
   * Temporary workaround since ngx-bootstrap has not collapse animation
   * @param el
   * @param height
   */
  setCollapsedHeight(el, height) {
    this.renderer.setStyle(el, 'height', height + 'px');
  }

  setDefaultCollapseState() {
    return { Activity: true, Reports: true, PBIReports: true };
  }
}
