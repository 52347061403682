import { ContactPhone, Shift } from '@libs/portal-common';

export class SupportData {
  audioVideoQuality = 0;
  support: string = null;
}

export class LocationInfo {
  Lot: Lot;
  Lanes: Array<any>;
  Fields: Array<any>;
  Problems: Array<any>;
  Contacts: Array<Contact>;
}

export class Lot {
  Id: number;
  LotNumber: number;

  IsStaticHtmlExist: boolean;

  GeneralInfo: string;
  Contacts: any;
  Rates: string;

  fkManager: number;
  fkOwner: number;

  GateInstructions: any;
  Equipment: string;

  FacilityCode: string;

  RevenueControlManufacturer: RevenueControlManufacturer;
  RevenueControlEnabled: boolean;
}

export class RevenueControlManufacturer {
  Id: number;
  IsIntegrated: boolean;
  Name: string;

  CanOpenGate: boolean;
  CanCloseGate: boolean;
  CanSetPrice: boolean;
  CanEjectTicket: boolean;
  CanSwallowTicket: boolean;
  CanShowTicketInfo: boolean;
  CanSetLostTicket: boolean;
  CanValidateTicket: boolean;
  CanExecuteAction: boolean;
  fkRegion: number;
}

export class Contact {
  Id: number;

  fkUserAuth?: number;

  Name: string;
  Email: string;
  IsActive: boolean;
  IsPerson: boolean;
  NotificationEmail: string;
  Occupation: string;
  Address: string;
  City: string;
  Zip: string;
  Country: string;
  TimeZone: string;
  Phones: ContactPhone[];
  Shifts: Shift[];
  Notes: string;
  Modified: any;
  IsAway: boolean;
  IsTesting: boolean;
  UserName: string;
  fkResourcesHierarchy: number;
  IsAgent: boolean;
  SipAddress: string;
  fkRegion: number;
}
