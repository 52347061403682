import { Component, OnInit, OnDestroy, NgZone } from '@angular/core';

import { Subscription, interval } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

import { CallCenterStatisticService, CallCenterStatisticsData } from '@libs/portal-common/services';

@Component({
  selector: 'app-statistics',
  templateUrl: './statistics.component.html',
  styleUrls: ['./statistics.component.scss'],
})
export class StatisticsComponent implements OnInit, OnDestroy {
  private _subscription: Subscription;

  stats = new CallCenterStatisticsData();
  progress = {
    callsQueue: '0%',
    waitTime: '0%',
    callTime: '0%',
    wrapupTime: '0%',
  };

  constructor(
    private _statsService: CallCenterStatisticService,
    private ngZone: NgZone,
  ) {}

  ngOnInit() {
    this.ngZone.runOutsideAngular(() => {
      this._subscription = interval(1000) // miliseconds
        .pipe(mergeMap((tic) => this._statsService.getStatistics()))
        .subscribe((resp) => {
          this.ngZone.run(() => {
            this.stats = resp;
            this.calculateProgressWidth();
          });
        });
    });
  }

  ngOnDestroy() {
    if (this._subscription) {
      this._subscription.unsubscribe();
      this._subscription = null;
    }
  }

  private calculateProgressWidth() {
    if (this.stats) {
      this.progress.callsQueue = Math.min(this.stats.CdrSummary.CallsWaitingInQueue * 10, 100) + '%';

      let avgQueue = parseFloat(this.stats.CdrSummary.AvgQueueLh.replace('s', ''));
      this.progress.waitTime = Math.min(avgQueue, 100) + '%';

      let callTime = parseFloat(this.stats.CdrSummary.AvgCallDurationLh.replace('s', ''));
      this.progress.callTime = Math.min(callTime, 100) + '%';

      let wrapupTime = parseFloat(this.stats.CdrSummary.AvgWrapUpLh.replace('s', ''));
      this.progress.wrapupTime = Math.min(wrapupTime, 100) + '%';
    }
  }
}
