<div name="Header" #accordion class="accordion" (click)="togglePanel()">
  <div class="d-flex">
    <img *ngIf="isOpen" src="/img/vectorUp.svg" alt="opened" />
    <img *ngIf="!isOpen" src="/img/vectorDown.svg" alt="closed" />
    <span class="accordion-title">{{ title }}</span>
    <p [innerHTML]="badges | safeHtml" *ngIf="badges"></p>
  </div>

  <div class='flex__box' *ngIf='!_editingMode'>
    <button
      *ngIf="mainButtonShow"
      (click)="mainBtnClick($event)"
      [disabled]="busy"
      class="button ND--buttons--primary-outline-button d-flex align-items-center"
    >
      <ng-container *ngIf='!mainButtonCustomIcon'>
        <i *ngIf="!mainButtonText" class="fa fa-plus" style="margin-right: 5px"></i>
        <i *ngIf="mainButtonText === 'Edit'" class="fa fa-pencil" style="margin-right: 5px"></i>
      </ng-container>
      <ng-container *ngIf='mainButtonCustomIcon'>
        <i [class]='mainButtonCustomIcon' style="margin-right: 5px"></i>
      </ng-container>
      {{ mainButtonText ? mainButtonText : 'Add' }}
    </button>
    <button
      *ngIf="secondButtonShow"
      (click)="secondBtnClick($event)"
      [disabled]="busy"
      class="button ND--buttons--primary-outline-button d-flex align-items-center"
    >
      <ng-container *ngIf='!secondButtonCustomIcon'>
        <i *ngIf="!mainButtonText" class="fa fa-plus" style="margin-right: 5px"></i>
        <i *ngIf="mainButtonText === 'Edit'" class="fa fa-pencil" style="margin-right: 5px"></i>
      </ng-container>
      <ng-container *ngIf='secondButtonCustomIcon'>
        <i [class]='secondButtonCustomIcon' style="margin-right: 5px"></i>
      </ng-container>
      {{ secondButtonText ? secondButtonText : 'Add' }}
    </button>
  </div>

  <div *ngIf="_editingMode">
    <button [disabled]="busy" class="button ND--buttons--second-button" (click)="cancelBtnClick($event)">
      <span> Cancel</span>
    </button>
    <button [disabled]="busy" class="button ND--buttons--primary-outline-button" (click)="saveBtnClick($event)">
      <i class="fa fa-save"></i>
      <span> Save</span>
    </button>
  </div>
</div>

<div #panel *ngIf="isOpen" class="panel" @fadeIn>
  <ng-container *ngIf="!busy">
    <ng-content></ng-content>
  </ng-container>

  <ng-container *ngIf="busy">
    <div class="loader text-center">
      <i class="fa fa-spinner fa-spin fa-2x"></i>
    </div>
  </ng-container>
</div>
