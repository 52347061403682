import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'typeFieldExecutor', pure: true })
export class TypeFieldExecutorPipe implements PipeTransform {
  transform(value: any): any {
    if (typeof value === 'undefined') {
      return;
    }

    if (value === null) {
      return '';
    }

    if (typeof value === 'string') {
      return value;
    }

    if (typeof value === 'number') {
      return value;
    }

    if (typeof value === 'boolean') {
      return value ? `<img src="/img/call-center-icons/check_green.svg" alt="check" />` : '';
    }

    if (typeof value === 'object') {
      return 'type error';
    }

    return '';
  }
}
