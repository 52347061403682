import { Observable, Observer, BehaviorSubject, of } from 'rxjs';

import { ISkypePerson, ISkypeGroup, IPerson, ISkypeDisposable, ISearchQuery } from '../../abstractions';
import { SkypePerson } from '../services/skype-person';

export class ContactsSearchResult implements ISkypeGroup {
  private _persons = new BehaviorSubject<Array<ISkypePerson>>([]);

  private _listeners = new Array<{ listener: ISkypeDisposable; property: Observer<any> }>();

  public get id(): string {
    return null;
  }
  public get name$(): Observable<string> {
    return null;
  }
  public get uri$(): Observable<string> {
    return null;
  }
  public get groups$(): Observable<Array<ISkypeGroup>> {
    return of([]);
  }
  public get persons$(): Observable<Array<ISkypePerson>> {
    return this._persons.asObservable();
  }

  constructor(private _query: ISearchQuery<IPerson>) {
    let self = this;

    self._listeners.push({
      listener: self._query.results.changed(() => {
        self._query.results
          .get()
          .then((persons) => {
            let skypePersons = persons.map((person) => new SkypePerson(person.result));
            self._persons.next(skypePersons);
          })
          .catch((err) => {
            self._persons.next([]);
            console.log(err);
          });
      }),
      property: self._persons,
    });
  }

  dispose() {
    this._listeners.forEach((item) => {
      item.listener.dispose();
      if (item.property) {
        item.property.complete();
      }
    });
    this._listeners = [];
  }
}
