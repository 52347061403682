import { Component, OnInit, HostBinding, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';

import { Observable, Observer } from 'rxjs';

import { ModalDirective } from 'ngx-bootstrap/modal';

import { IncomingCall } from '../../../../abstractions';

import { RevenueControlService, TicketInfo, Payment, ValidationApplied } from '@libs/portal-common/services';
import { AppNotificationsService } from '@libs/portal-common/system';

@Component({
  selector: 'app-ticket-info',
  templateUrl: './ticket-info.component.html',
  styleUrls: ['./ticket-info.component.scss'],
})
export class TicketInfoComponent implements OnInit {
  @ViewChild('modal', { static: true }) public modal: ModalDirective;
  @ViewChild('form', { static: false }) public form: NgForm;

  incomingCall: IncomingCall;
  ticketNum: number = null;
  ticketInfo: TicketInfo;

  busy = false;
  validate = false;
  initialized = false;

  resultObserver: Observer<boolean>;

  constructor(
    private revenueControl: RevenueControlService,
    private notifications: AppNotificationsService,
  ) {}

  ngOnInit() {}

  open(incomingCall: IncomingCall): Observable<boolean> {
    this.incomingCall = incomingCall;
    this.ticketNum = null;
    this.ticketInfo = null;

    this.busy = false;
    this.modal.show();

    this.validate = false;
    this.initialized = false;

    setTimeout(() => (this.initialized = true), 30);

    return new Observable((observer) => (this.resultObserver = observer));
  }

  showTicketInfo() {
    this.validate = true;
    if (!this.form.valid) {
      return;
    }

    let request = {
      LotId: this.incomingCall.location.Id,
      LaneId: this.incomingCall.lane.Id,
      CommandParams: {
        Ticket: this.ticketNum * 1,
      },
    };

    this.busy = true;
    this.ticketInfo = null;
    this.revenueControl.showTicketInfo(request).subscribe(
      (response) => {
        this.busy = false;

        if (!response.Success) {
          this.notifications.error(response.Message || 'Failed fetching ticket info.');
          return;
        }

        // response.TicketInfo.PaymentsMade = this.fakePayments();
        this.ticketInfo = response.TicketInfo;
      },
      (err) => {
        console.log(err);
        this.notifications.error(err.message || 'Failed fetching ticket info.');
        this.busy = false;
      },
    );
  }

  cancel() {
    this.modal.hide();
    this.resultObserver.next(null);
    this.resultObserver.complete();
  }

  isExpanded(entity: any): boolean {
    return !!entity && !!entity.isExpanded;
  }
  toggle(entity: any) {
    if (!entity) {
      return;
    }

    entity.isExpanded = !entity.isExpanded;
  }

  private fakePayments(): Array<Payment> {
    return [this.fakePayment(), this.fakePayment(), this.fakePayment()];
  }
  private fakePayment(): Payment {
    return {
      PaymentId: 1,
      PaymentAmount: 10,
      PaymentDateTime: new Date(),
      PayMethod: 'some method',
      ValidationsApplied: this.fakeValidations(),
      LastFourDigits: 'last four',
    };
  }
  private fakeValidations(): Array<ValidationApplied> {
    return [this.fakeValidation(3), this.fakeValidation(2), this.fakeValidation(4)];
  }
  private fakeValidation(order: number): ValidationApplied {
    return {
      AppliedValidation: {
        ValidationId: order,
        ValidationName: '11111',
        TypeOfValidation: 'validation type',
        Amount: 10,
        PercentageOff: 20,
        MinutesOff: 30,
        RateTableId: 15,
        RateTableName: 'Table name',
      },
      ValidationAmount: 10,
      ValidationOrder: order,
    };
  }
}
