import { Component, HostBinding, Inject, OnInit } from '@angular/core';
import { ActivityReportName, ANALYTICS, AnalyticsEvent, IAnalyticsService } from '@libs/portal-common/services';
import { routerTransition } from '@libs/portal-common/system';

import { ActivityFilter } from '../services/call-history.service';

@Component({
  selector: 'app-agent-activity',
  templateUrl: './agent-activity.component.html',
  animations: [routerTransition],
})
export class AgentActivityComponent implements OnInit {
  @HostBinding('@routerTransition') routerTransition = '';

  filter: ActivityFilter;

  constructor(@Inject(ANALYTICS) private analytcis: IAnalyticsService) {}

  ngOnInit() {
    let filter = new ActivityFilter();
    this.filter = filter;
    this.analytcis.track(AnalyticsEvent.DailyActivityOpen, { ReportName: ActivityReportName.AgentActivity });
  }

  search(filter: ActivityFilter) {
    this.filter = Object.assign(new ActivityFilter(), filter);
  }
}
