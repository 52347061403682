import { Component, OnInit, ViewChild, EventEmitter, Output } from '@angular/core';

import { Observable, Observer } from 'rxjs';

import { ModalDirective } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss'],
})
export class ConfirmationComponent implements OnInit {
  @ViewChild('modal', { static: true }) public modal: ModalDirective;
  @Output() modalComponent = new EventEmitter<ConfirmationComponent>();

  message = '';
  title = 'Confirm Action';

  resultObserver: Observer<boolean>;

  constructor() {}

  ngOnInit() {
    this.modalComponent.emit(this);
  }

  open(message: string): Observable<boolean> {
    this.message = message;
    this.modal.show();

    return new Observable((observer) => {
      this.resultObserver = observer;
    });
  }

  ok() {
    this.modal.hide();
    this.resultObserver.next(true);
    this.resultObserver.complete();
  }

  cancel() {
    this.modal.hide();
    this.resultObserver.next(false);
    this.resultObserver.complete();
  }
}
