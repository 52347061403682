import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthenticatedGuard } from '@libs/portal-common/services';

import { DailyActivityComponent } from './daily-activity/daily-activity.component';
import { LaneActivityComponent } from './lane-activity/lane-activity.component';
import { AgentActivityComponent } from './agent-activity/agent-activity.component';
import { HeatmapContainerComponent } from './heatmap/heatmap-container/heatmap-container.component';
import { ActivityItemComponent } from './activity-item/activity-item.component';
import { QualityAnalysisComponent } from './quality-analysis/quality-analysis.component';

import { ACTIVITY_ROUTES } from './activity-routes';
import { TicketResolverService } from './services/ticket-resolver.service';

const routes: Routes = [
  { path: 'daily', component: DailyActivityComponent,
    data: { header: 'Daily Activity', pageIcon: '/img/page-icons/ActivityPageIcon.svg' },
    canActivate: [AuthenticatedGuard] },
  {
    path: 'daily/:id',
    component: ActivityItemComponent,
    canActivate: [AuthenticatedGuard],
    children: ACTIVITY_ROUTES,
    resolve: { ticket: TicketResolverService },
    runGuardsAndResolvers: 'always',
  },

  { path: 'lane', component: LaneActivityComponent,
    data: { header: 'Lane Activity', pageIcon: '/img/page-icons/ActivityPageIcon.svg' },
    canActivate: [AuthenticatedGuard] },
  {
    path: 'lane/:id',
    component: ActivityItemComponent,
    canActivate: [AuthenticatedGuard],
    children: ACTIVITY_ROUTES,
    resolve: { ticket: TicketResolverService },
    runGuardsAndResolvers: 'always',
  },

  { path: 'agent', component: AgentActivityComponent,
    data: { header: 'Agent Activity', pageIcon: '/img/page-icons/ActivityPageIcon.svg' },
    canActivate: [AuthenticatedGuard] },
  {
    path: 'agent/:id',
    component: ActivityItemComponent,
    canActivate: [AuthenticatedGuard],
    children: ACTIVITY_ROUTES,
    resolve: { ticket: TicketResolverService },
    runGuardsAndResolvers: 'always',
  },

  { path: 'qa', component: QualityAnalysisComponent,
    data: { header: 'Quality Analysis', pageIcon: '/img/page-icons/ActivityPageIcon.svg' },
    canActivate: [AuthenticatedGuard] },
  {
    path: 'qa/:id',
    component: ActivityItemComponent,
    canActivate: [AuthenticatedGuard],
    children: ACTIVITY_ROUTES,
    resolve: { ticket: TicketResolverService },
    runGuardsAndResolvers: 'always',
  },

  { path: 'heatmap', component: HeatmapContainerComponent,
    data: { header: 'Heatmap', pageIcon: '/img/page-icons/ActivityPageIcon.svg' },
    canActivate: [AuthenticatedGuard] },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ActivityRoutingModule {}
