import { Inject, Injectable, PLATFORM_ID, ViewChild } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { isPlatformServer } from '@angular/common';
import { TransferState, makeStateKey } from '@angular/platform-browser';

import { Observable, of, zip } from 'rxjs';
import { tap, map, filter, flatMap, first, mergeMap, catchError } from 'rxjs/operators';

import { AppNotificationsService, APP_CONFIGURATION, LocationsService } from '@libs/portal-common';

import { NavigationService } from './navigation.service';

import { IncomingCallsService } from './incoming-calls.service';
import { IssueSubmissionService } from './issue-submission.service';

import { IncomingCall, ISkypeAppServiceToken, ISkypeAppService, IConversation } from '../../abstractions';
import { AppConfigurationService } from '../../services';

import { IntegrationAppFactoryService } from './integration-app-factory.service';

@Injectable()
export class SkypeSignedInGuard implements CanActivate {
  constructor(
    private router: Router,
    @Inject(ISkypeAppServiceToken) private skypeApp: ISkypeAppService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.skypeApp.isSignedIn$.pipe(
      tap((isSignedIn) => {
        // not logged in so redirect to login page with the return url
        if (!isSignedIn) {
          this.router.navigate(['/auth/login'], {
            queryParams: { returnUrl: state.url },
          });
        }
      }),
    );
  }
}

@Injectable()
export class IddleGuard implements CanActivate {
  constructor(
    private router: Router,
    private navService: NavigationService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.navService.incomingCall$.pipe(map((incomingCall) => !incomingCall));
  }
}

@Injectable()
export class IncomingCallGuard implements CanActivate {
  constructor(
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: Object,
    private transferState: TransferState,
    private locations: LocationsService,
    @Inject(ISkypeAppServiceToken) private skypeApp: ISkypeAppService,
    private issueSubmissionService: IssueSubmissionService,
    private navService: NavigationService,
    private incomingCallsService: IncomingCallsService,
    private integrationAppFactory: IntegrationAppFactoryService,
    @Inject(APP_CONFIGURATION) private configuration: AppConfigurationService,
    private notifications: AppNotificationsService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.navService.incomingCall$.pipe(
      mergeMap((incomingCall) => {
        if (!!incomingCall) {
          return of(true);
        }

        let caller: string = route.queryParams['caller'];
        let callId: string = route.queryParams['callId'];
        let sessionId: string = route.queryParams['session_id'];
        let agentSip: string = route.queryParams['agentSip'];
        let test: string = (route.queryParams['test'] || 'false').toLowerCase();
        let isTest = test === 'true';

        if (!!caller && (!!callId || !!sessionId)) {
          if (isPlatformServer(this.platformId)) {
            return of(true);
          } else {
            return this.startIncomingCall(caller, callId, sessionId, agentSip, isTest).pipe(map((call) => !!call));
          }
        }

        this.router.navigate(['/']);
        return of(false);
      }),
      catchError((err) => {
        this.notifications.error(err);
        return of(false);
      }),
    );
  }

  private startIncomingCall(caller: string, callId: string, sessionId: string, agentSip: string, test: boolean): Observable<IncomingCall> {
    let self = this;
    return this.createConversation(caller, callId, sessionId, agentSip, test).pipe(
      map((conversation) => {
        conversation.accept().subscribe((res) => {});
        return conversation;
      }),
      mergeMap((conversation) => this.incomingCallsService.resolveIncomingCall(conversation)),
      first(),
      flatMap((incomingCall) => {
        incomingCall.conversation.resolving = false;

        if (incomingCall.isResolved()) {
          return of(incomingCall);
        } else {
          return self.navService.locationSelector.open(incomingCall).pipe(flatMap((res) => self.navService.laneSelector.open(res)));
        }
      }),

      flatMap((incomingCall) =>
        this.locations.getBy({ Id: incomingCall.location.Id }).pipe(
          map((locationInfo) => {
            incomingCall.locationInfo = <any>locationInfo;

            return incomingCall;
          }),
        ),
      ),

      flatMap((incomingCall) =>
        this.issueSubmissionService.getProblems(incomingCall.location).pipe(
          map((problems) => {
            incomingCall.problems = problems;
            return incomingCall;
          }),
        ),
      ),

      tap((incomingCall) => {
        this.navService.init(incomingCall);
      }),
    );
  }

  private createConversation(
    caller: string,
    callId: string,
    sessionId: string,
    agentSip: string,
    test: boolean,
  ): Observable<IConversation> {
    const app = this.integrationAppFactory.getApp(test);
    return app.createConversation(caller, callId, sessionId, agentSip);
  }
}
