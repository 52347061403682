import { Directive, forwardRef } from '@angular/core';
import { NG_VALIDATORS } from '@angular/forms';
import { Validator, AbstractControl } from '@angular/forms';

@Directive({
  selector: '[appValidateNumber][formControlName], [appValidateNumber][formControl],[appValidateNumber][ngModel]',
  providers: [{ provide: NG_VALIDATORS, useExisting: forwardRef(() => NumberValidatorDirective), multi: true }],
})
export class NumberValidatorDirective implements Validator {
  constructor() {}

  validate(c: AbstractControl) {
    if (c.value === null) {
      return null;
    }

    let isValid = !isNaN(c.value) && c.value > 0;

    return isValid
      ? null
      : {
          appValidateNumber: {
            valid: false,
          },
        };
  }
}
