import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { BsModalService, ModalDirective, ModalOptions } from 'ngx-bootstrap/modal';
import {
  ANALYTICS,
  AppNotificationsService,
  CreateTicketModel,
  IAnalyticsService,
  IncidentService,
  TicketsService,
  TicketStatus,
} from '@libs/portal-common';
import { Location } from '@angular/common';
import { NavbarItemsService } from '../../../../services/access-management/navbar-items.service';
import { IncomingCall } from '../../../../abstractions';
import { filter } from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { AgentAnalyticsEvent } from '../../../../services';
import { Receipt } from '../../../../abstractions/receipt';

@Component({
  templateUrl: 'receipt-modal.component.html',
  styleUrls: ['receipt-modal.component.scss'],
})
export class ReceiptModalComponent implements OnInit {
  @ViewChild('receiptModal', { static: true }) public receiptModal: ModalDirective;

  private subscriptions = new Subscription();
  formGroup: FormGroup;
  ticket = new CreateTicketModel();
  incomingCall: IncomingCall;
  receipt: Receipt;
  ticketStatuses = new Array<TicketStatus>();
  modalConfig: ModalOptions = { backdrop: 'static', keyboard: false, show: true };
  validationMessage = '';

  constructor(
    private bsModalService: BsModalService,
    private ticketsService: TicketsService,
    private incidentService: IncidentService,
    private notifications: AppNotificationsService,
    private location: Location,
    private navbarItemsService: NavbarItemsService,
    private formBuilder: FormBuilder,
    @Inject(ANALYTICS) private analytics: IAnalyticsService,
  ) {}

  ngOnInit() {
    this.formGroup = this.formBuilder.group({
      CustomerName: ['', [Validators.required, Validators.maxLength(220)]],
      Address: ['', [Validators.maxLength(220)]],
      City: ['', [Validators.maxLength(220)]],
      StateProvince: ['', [Validators.maxLength(220)]],
      ZipCode: ['', [Validators.maxLength(220)]],
      Phone: ['', [Validators.maxLength(20)]],
      CustomerEmail: ['', [Validators.required, Validators.maxLength(50)]],
      LicensePlate: ['', [Validators.maxLength(50)]],
      ParkingLocation: ['', [Validators.required]],
      CashCreditAmount: [0, [Validators.min(1)]],
      DateOfTransaction: [''],
    });
    this.receiptModal.config = this.modalConfig;
    this.subscriptions.add(
      this.navbarItemsService.incomingCall$
        .pipe(filter((incomingCall) => !!incomingCall))
        .subscribe((incomingCall: IncomingCall) => {
          this.incomingCall = incomingCall;
          if (this.incomingCall?.location?.LotName) {
            this.formGroup.controls.ParkingLocation.setValue(this.incomingCall.location.LotName);
          }
          this.formGroup.controls.DateOfTransaction.setValue(new Date(Date.now()).toLocaleString());
        }),
    );

    this.analytics.track(AgentAnalyticsEvent.ReceiptOpen, null);
  }

  save() {
    this.validationMessage = '';
    if (this.formGroup.valid) {
      this.receipt = this.formGroup.getRawValue();
      this.receipt.CashCreditAmount = `${this.receipt.CashCreditAmount}:C2`;

      this.incidentService.sendReceipt(this.receipt).subscribe(
        (response) => {
          if (!response.Success) {
            this.notifications.error(response.Message);
            return;
          }

          this.notifications.success('Receipt sent');
        },
        (err) => {
          this.notifications.error(err.Message || 'Error when sending Receipt');
        },
      );

      this.close();
      this.analytics.track(AgentAnalyticsEvent.ReceiptSaved, null);
    } else {
      this.checkValidationMessage();
    }
  }

  close() {
    this.receiptModal.hide();
    this.location.back();
    this.subscriptions.unsubscribe();
  }

  private checkValidationMessage() {
    if (this.formGroup.controls.CustomerName.errors &&
      this.formGroup.controls.CustomerName.errors.required) {
      this.validationMessage = 'Customer Name is required.';
      return;
    }
    if (this.formGroup.controls.CustomerEmail.errors &&
      this.formGroup.controls.CustomerEmail.errors.required) {
      this.validationMessage = 'Email Address is required.';
      return;
    }

    if (this.formGroup.controls.CashCreditAmount.errors &&
      this.formGroup.controls.CashCreditAmount.errors.min) {
      this.validationMessage = 'Amount should be greater than 0.';
      return;
    }
  }
}
