import { Issue } from './issue';
import { ProblemTypesEnum } from '../../services';

export class Problem {
  Id: number;
  ProblemName: string;
  Issues: Array<Issue> = [];
  IsActive = false;
  SortOrder: number;
  fkCategory: ProblemTypesEnum;
}
