import { Injectable, Inject } from '@angular/core';
import { TransferState, makeStateKey } from '@angular/platform-browser';

import { of, throwError, zip, Observable } from 'rxjs';
import { tap, map, catchError } from 'rxjs/operators';

import {
  IncidentService,
  ProblemsService,
  LotIssuesService,
  FieldSetService,
  AppStateService,
  ILocation,
  WrapupAndSaveModel,
} from '@libs/portal-common/services';
import { FieldSetConverter } from '@libs/portal-common/shared';
import { AppNotificationsService, PubSubService } from '@libs/portal-common/system';

import {
  IncomingCall,
  FieldValue,
  SubmissionParameters,
  Problem,
  LotIssue,
  ISortable,
  Issue,
  IssueField,
  ISkypeAppService,
  ISkypeAppServiceToken,
} from '../../abstractions';
import { FakeConversation } from '../../integrations/no-integration';

@Injectable()
export class IssueSubmissionService {
  private initializing = false;

  constructor(
    private _pubSubService: PubSubService,
    private incidentService: IncidentService,
    private problemsService: ProblemsService,
    private lotIssuesService: LotIssuesService,
    private fieldSetService: FieldSetService,
    private notifications: AppNotificationsService,
    @Inject(ISkypeAppServiceToken) private skypeApp: ISkypeAppService,
    private appStateService: AppStateService,
    private transferState: TransferState,
  ) {
    // invalidating cache
    this._pubSubService.$sub('globalProblemsChanged').subscribe((lotId) => {
      this.removeCachedProblems();
    });

    this._pubSubService.$sub('globalIssuesChanged').subscribe((lotId) => {
      this.removeCachedProblems();
    });

    this._pubSubService.$sub('lotIssuesChanged').subscribe((lotId) => {
      let issues = this.getIssues();
      issues.delete(lotId);
      this.setIssues(issues);
    });
    // end invalidating

    this.skypeApp.isSignedIn$.subscribe((res) => {
      if (res) {
        this.init().subscribe(() => {});
      }
    });
  }

  wrapupAndSave(incomingCall: IncomingCall, fields: Array<FieldValue>, parameters: SubmissionParameters): Observable<any> {
    if (incomingCall.conversation instanceof FakeConversation) {
      return of({});
    }

    let model = new WrapupAndSaveModel();

    if (incomingCall.location) {
      model.fkLot = incomingCall.location.Id;
    }

    if (incomingCall.lane) {
      model.fkLane = incomingCall.lane.Id;
    }

    model.fkProblem = parameters.fkProblem;
    model.fkIssue = parameters.fkIssue;

    model.GateVend = parameters.GateVend;
    model.MgrNotified = parameters.MgrNotified;

    model.AgentName = incomingCall.conversation.agentName;
    model.AgentSIP = incomingCall.conversation.agentSip;
    model.Caller = incomingCall.conversation.callerUri;
    model.CallSource = incomingCall.conversation.callSource;

    model.CallDuration = incomingCall.conversation.callDuration;
    model.WrapupDuration = incomingCall.conversation.wrapupDuration;
    model.InQueue = incomingCall.conversation.queueDuration;

    model.Fields = fields;

    model.CallId = incomingCall.conversation.callId;
    model.SessionId = incomingCall.conversation.sessionId;

    return this.incidentService.wrapupAndSave(model).pipe(
      map((response) => {
        if (response.Success) {
          this.notifications.success('Saved');
        } else {
          this.notifications.error(response.Message || 'Saving incident failed.');
        }

        return response;
      }),
    );
  }

  getProblems(location: ILocation): Observable<Array<Problem>> {
    return zip(this.getAllProblems(location.fkRuleSet), this.getLocationIssues(location.Id), this.getFieldSet(location.fkRuleSet)).pipe(
      map((result) => {
        let problems = result[0];
        let issues = result[1];
        let fieldSet = result[2];

        problems.forEach((problem) => {
          problem.Issues.forEach((issue) => {
            let lotIssue = issues.find((li) => li.fkIssue === issue.Id);
            issue.Fields = FieldSetConverter.convertFieldsJsonToFieldSet(issue.FieldsJson, fieldSet);
            if (lotIssue) {
              lotIssue.Fields = FieldSetConverter.convertFieldsJsonToFieldSet(lotIssue.FieldsJson, fieldSet);
              this.applyLotIssue(issue, lotIssue);
            }

            if (!issue.Fields.find((f) => f.FieldName === 'cNotes')) {
              issue.Fields.push(IssueField.default());
            }

            issue.Fields = issue.Fields.sort(this.fieldsComparer);
          });

          problem.Issues = problem.Issues.filter((i) => i.IsActive).sort(this.compare);
        });

        problems = problems.filter((p) => p.IsActive).sort(this.compare);
        return problems;
      }),
      catchError((err) => {
        if (err.status === 404) {
          return of(new Array<Problem>());
        }

        return throwError(err);
      }),
    );
  }

  init(): Observable<boolean> {
    if (this.initializing) {
      return of(false);
    }

    this.initializing = true;
    return (
      this.getAllProblems(null)
        // .flatMap(problems => this.locationsService.getAll())
        // .filter(result => result.Success)
        // .map(result => result.LotDirectory)
        // .flatMap(locations => Observable.from(locations))

        // .zip(Observable.interval(1000))
        // .map(interval => interval[0])

        // .flatMap(location => {
        //     let locationId = (<any>location).Id;
        //     return this.getLocationIssues(locationId);
        // })

        .pipe(
          tap((result) => (this.initializing = false)),
          map((result) => true),
        )
    );
  }

  private getAllProblems(ruleSetId: number): Observable<Array<Problem>> {
    let cachedProblems = this.getCachedProblems();
    if (cachedProblems) {
      let result = JSON.parse(JSON.stringify(cachedProblems.filter((x) => x.fkRuleSet === ruleSetId)));

      return of(result);
    }

    return this.problemsService.getAll().pipe(
      map((response) => {
        if (response.Success) {
          let problems = <Array<Problem>>response.Data;
          problems = problems.filter((r) => r.IsActive);
          this.setCachedProblems(problems);

          let result = JSON.parse(JSON.stringify(problems.filter((x) => x.fkRuleSet === ruleSetId)));

          return result;
        } else {
          this.notifications.error(response.Message);
          return [];
        }
      }),
    );
  }

  getLocationIssues(locationId: number): Observable<Array<LotIssue>> {
    let issues = this.getIssues();
    if (issues[locationId]) {
      let result = JSON.parse(JSON.stringify(issues[locationId]));
      return of(result);
    }

    return this.lotIssuesService.getAllForLot(locationId).pipe(
      map((response) => {
        if (response.Success) {
          issues[locationId] = <Array<LotIssue>>response.Data;
          this.setIssues(issues);
          let result = JSON.parse(JSON.stringify(issues[locationId]));
          return result;
        } else {
          this.notifications.error(response.Message);
          return [];
        }
      }),
      catchError((err) => {
        return of([]);
      }),
    );
  }

  private getFieldSet(ruleSetId: number) {
    return this.fieldSetService.getAll().pipe(
      map((response) => {
        if (response.Success) {
          return JSON.parse(JSON.stringify(response.Data.filter((x) => x.fkRuleSet === ruleSetId)));
        } else {
          this.notifications.error(response.Message);
          return [];
        }
      }),
    );
  }

  applyLotIssue(issue: Issue, lotIssue: LotIssue) {
    if (!issue.Actions) {
      issue.Actions = [];
    }
    Object.assign(issue.Actions, lotIssue.Actions);

    let notes =
      lotIssue.Fields.find((field) => field.FieldName === 'cNotes') ||
      issue.Fields.find((field) => field.FieldName === 'cNotes') ||
      IssueField.default();

    issue.Fields = lotIssue.Fields.filter((field) => field.FieldName !== 'cNotes');
    issue.Fields.push(notes);

    issue.RuleDescription = lotIssue.RuleDescription;

    issue.IsActive = lotIssue.IsActive;
    issue.IsNotify = lotIssue.IsNotify;

    issue.GateVend = lotIssue.GateVend;
    issue.ShowInPage = lotIssue.ShowInPage;
    issue.SortOrder = lotIssue.SortOrder;
  }

  private compare(a: ISortable, b: ISortable) {
    if (a.SortOrder < b.SortOrder) {
      return -1;
    }
    if (a.SortOrder > b.SortOrder) {
      return 1;
    }

    return 0;
  }

  private fieldsComparer(a: IssueField, b: IssueField) {
    if (a.FieldName === 'cNotes' && b.FieldName !== 'cNotes') {
      return 1;
    }
    if (a.FieldName !== 'cNotes' && b.FieldName === 'cNotes') {
      return -1;
    }

    return 0;
  }

  private getCachedProblems(): Array<Problem> {
    return this.get<Array<Problem>>('cache-problems');
  }
  private setCachedProblems(value: Array<Problem>) {
    this.set<Array<Problem>>('cache-problems', value);
  }
  private removeCachedProblems() {
    this.remove('cache-problems');
  }

  private getIssues(): Map<number, Array<Issue>> {
    let result = this.get<Map<number, Array<Issue>>>('cache-issues', new Map<number, Array<Issue>>());
    return result || new Map<number, Array<Issue>>();
  }
  private setIssues(value: Map<number, Array<Issue>>) {
    this.set<Map<number, Array<Issue>>>('cache-issues', value);
  }
  private removeIssues() {
    this.remove('cache-issues');
  }

  private get<T>(key: string, nullVal: T = null): T {
    return this.transferState.get(makeStateKey<T>(key), null);
  }
  private set<T>(key: string, value: T) {
    return this.transferState.set(makeStateKey<T>(key), value);
  }
  private remove<T>(key: string) {
    this.transferState.remove(makeStateKey<T>(key));
  }
}
