import { NgModule, ModuleWithProviders, PLATFORM_ID } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ModalModule } from 'ngx-bootstrap/modal';
import { ToastrModule, ToastrService } from 'ngx-toastr';

import { ConfirmationComponent } from './confirmation/confirmation.component';
import { ConfirmationService } from './confirmation/confirmation.service';
import { ConfirmDeactivateGuard } from './guards/confirm-deactivate.guard';

import { AppNotificationsService, appNotificationsServiceFactory } from './services/app-notifications.service';
import { PubSubService } from './services/pub-sub.service';
import { DomService } from './services/dom.service';

import { NotificationsToast } from './notifications/notifications-toast.component';

@NgModule({
  imports: [
    CommonModule,
    ToastrModule.forRoot({
      toastComponent: NotificationsToast,
      timeOut: 2000,
      positionClass: 'toast-bottom-right',
      newestOnTop: false,
    }),
    ModalModule,
  ],
  declarations: [ConfirmationComponent, NotificationsToast],
  exports: [ConfirmationComponent],
  entryComponents: [ConfirmationComponent],
})
export class SystemModule {
  static forRoot(): ModuleWithProviders<SystemModule> {
    return {
      ngModule: SystemModule,
      providers: [
        {
          provide: AppNotificationsService,
          deps: [ToastrService, PLATFORM_ID],
          useFactory: appNotificationsServiceFactory,
        },
        ConfirmationService,
        ConfirmDeactivateGuard,
        PubSubService,
        DomService,
      ],
    };
  }
}
