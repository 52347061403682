import { Component, OnInit, HostBinding, Input } from '@angular/core';

import { routerTransition } from '@libs/portal-common/system';

import { LocationsService, AccessManagementService } from '@libs/portal-common/services';
import { daysOfWeek } from '@libs/portal-common/shared';

import { HeatmapFilter } from '../heatmap.component';

@Component({
  selector: 'app-heatmap-container',
  templateUrl: './heatmap-container.component.html',
  styleUrls: ['./heatmap-container.component.scss'],
  animations: [routerTransition],
})
export class HeatmapContainerComponent implements OnInit {
  @HostBinding('@routerTransition') routerTransition = '';

  @Input() disabled = false;

  loading = false;

  days = [];

  locations = [];
  filteredLocations = [];

  filter = new HeatmapFilter();
  displayLocations = false;

  intervals = [
    { minutes: 30, name: '30 minutes' },
    { minutes: 60, name: '60 minutes' },
  ];

  constructor(
    private locationsService: LocationsService,
    private accessManagementService: AccessManagementService,
  ) {
    this.days = daysOfWeek;
  }

  handleDaysFilter(value) {
    this.days = daysOfWeek.filter((s) => s.Name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
  }
  handleLocationsFilter(value) {
    this.filteredLocations = this.locations.filter((s) => s.LotName.toLowerCase().indexOf(value.toLowerCase()) !== -1);
  }

  ngOnInit() {
    this.init();
  }

  private init() {
    if (this.accessManagementService.hasAccessPermissions({ entities: ['Locations'], access: 'CanRead' })) {
      this.displayLocations = true;

      this.locationsService.getAll().subscribe((response) => {
        this.locations = (response.Data || []).map(function (lot) {
          return {
            Id: lot.Id,
            LotName: `${lot.LotNumber} ${lot.LotName}`,
          };
        });

        this.filteredLocations = this.locations;
      });
    }
  }

  search() {
    this.filter = Object.assign({}, this.filter);
  }
}
