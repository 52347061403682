import { InjectionToken } from '@angular/core';

export enum AnalyticsEvent {
  Login = 'login',
  Logout = 'logout',
  MonitoringOpen = 'monitor_open',
  MonitoringSettingsOpen = 'monitoring_settings_open',
  MonitoringSettingsSave = 'monitoring_settings_save',

  DailyActivityOpen = 'activity_report_open',
  DailyActivityExport = 'activity_export',
  DailyActivityFieldsSubmit = 'activity_grid_fields_submit',
}

export enum ActivityReportName {
  DailyActivity = 'DailyActivity',
  LaneActivity = 'LaneActivity ',
  AgentActivity = 'AgentActivity',
  QualityAnalysis = 'QualityAnalysis',
  Heatmap = 'Heatmap ',
}

export interface IAnalyticsService {
  track(event: string, data: any): void;
  trackLogin(userId: string): void;
  setUserId(userId: string): void;
  initialize(): void;
}

export const ANALYTICS = new InjectionToken<IAnalyticsService>('ANALYTICS');
