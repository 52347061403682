import { Component, Inject, OnInit } from '@angular/core';

import { Observable } from 'rxjs';

import { ANALYTICS, BaseResponse, IAnalyticsService, RevenueControlService } from '@libs/portal-common/services';
import { AppNotificationsService } from '@libs/portal-common/system';

import { IncomingCall } from '../../../../../../abstractions';
import { IRevenueForm } from '../../revenue-form-factory.service';
import { AgentAnalyticsEvent } from '../../../../../../services';

type SendStatus = 'Success' | 'Error';

@Component({
  selector: 'app-eject-ticket-base',
  templateUrl: './eject-ticket-base.component.html',
  styleUrls: ['./eject-ticket-base.component.scss'],
})
export class EjectTicketBaseComponent implements IRevenueForm, OnInit {
  locationId: number = null;
  laneId: number = null;
  provider: string;

  constructor(
    private revenueControl: RevenueControlService,
    private notifications: AppNotificationsService,
    @Inject(ANALYTICS) private analytics: IAnalyticsService,
  ) {}

  ngOnInit() {
    this.analytics.track(AgentAnalyticsEvent.RevenueFormOpen, { Provider: this.provider, RCForm: 'EjectTicketForm' });
  }

  init(provider: string, incomingCall: IncomingCall, formParams: any): void {
    this.locationId = incomingCall.location.Id;
    this.laneId = incomingCall.lane.Id;
    this.provider = provider;
  }

  validate(): boolean {
    return true;
  }

  canSubmit(): boolean {
    return true;
  }

  submit(): Observable<BaseResponse> {
    this.analytics.track(AgentAnalyticsEvent.RevenueFormExecute, { Provider: this.provider, RCActionType: 'rc_eject_ticket' });
    return this.revenueControl.ejectTicket({ LotId: this.locationId, LaneId: this.laneId });
  }
}
