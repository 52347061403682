import { Observable } from 'rxjs';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class AzureService {
  private _providerTokens = [];

  constructor(private _httpClient: HttpClient) {}

  getAzureGroups(token: string): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', `Bearer ${token}`);
    return this._httpClient.get('https://graph.microsoft.com/v1.0/groups?$top=999', {
      headers: headers,
    });
  }

  saveToken(providerName: string, token: string) {
    let provider = this._providerTokens.findBy('Name', providerName);
    if (provider) {
      provider.AccessToken = token;
    } else {
      this._providerTokens.push({ Name: providerName, AccessToken: token });
    }
  }

  getToken(providerName: string) {
    let existingProvider = this._providerTokens.findBy('Name', providerName);
    if (existingProvider) {
      return existingProvider.AccessToken;
    }

    return '';
  }
}
