<div class="modal fade" bsModal #createTicketModal="bs-modal" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-sm">
    <div class="modal-content">
      <div class="modal-header clearfix">
        <h4 class="modal-title pull-left">Create a new ticket</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="cancel()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <form #form="ngForm" *ngIf="initialized" novalidate>
        <div class="modal-body">
          <div class="form-group row">
            <label for="title" class="col-sm-3 col-form-label">Title</label>
            <div class="col-sm-8">
              <input name="title" type="text" [(ngModel)]="ticket.title" class="form-control" #title="ngModel" />
            </div>
          </div>

          <div class="form-group row">
            <label for="description" class="col-sm-3 col-form-label">Description</label>
            <div class="col-sm-8">
              <input name="description" type="text" [(ngModel)]="ticket.description" class="form-control" #description="ngModel" />
            </div>
          </div>

          <div class="form-group row">
            <label for="status" class="col-sm-3 col-form-label">Status</label>
            <div class="col-sm-8">
              <app-dropdown
                name="status"
                [data]="ticketStatuses"
                [(ngModel)]="ticket.statusId"
                [textField]="'Status'"
                [valueField]="'Id'"
                #statusId="ngModel"
              >
              </app-dropdown>
            </div>
          </div>

          <div class="form-group row" *ngIf="locationSelectorEnabled">
            <label for="location" class="col-sm-3 col-form-label">Location</label>
            <div class="col-sm-8">
              <app-dropdown
                name="location"
                [data]="locations"
                [(ngModel)]="ticket.locationId"
                [textField]="'LotName'"
                [valueField]="'Id'"
                #locationId="ngModel"
              >
              </app-dropdown>
            </div>
          </div>
        </div>

        <div class="modal-footer">
          <button class="button button--primary" type="submit" (click)="save()">Create</button>
          <button class="button" (click)="cancel()">Cancel</button>
        </div>
      </form>
    </div>
  </div>
</div>
