import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { IncomingCallGuard } from './services/';

import { CallProcessingComponent } from './call-processing/call-processing.component';
import { GeneralInformationComponent } from './call-processing/call-processing-info/general-information/general-information.component';
import { RateInformationComponent } from './call-processing/call-processing-info/rate-information/rate-information.component';
import { EquipmentComponent } from './call-processing/call-processing-info/equipment/equipment.component';
import { CommonIssuesComponent } from './call-processing/call-processing-info/common-issues/common-issues.component';
import { ReservationsListComponent } from './call-processing/call-processing-info/reservations-list/reservations-list.component';

import { StartIncomingCallComponent } from './start-incoming-call/start-incoming-call.component';
import { CreateTicketModalComponent } from './call-processing/call-processing-info/create-ticket-modal/create-ticket-modal.component';
import { ReceiptModalComponent } from './call-processing/call-processing-info/receipt-modal/receipt-modal.component';

const routes: Routes = [
  {
    path: 'start-incoming-call',
    component: StartIncomingCallComponent,
    data: { title: 'Calls', header: 'Start incoming call' },
  },
  {
    path: 'call-processing',
    component: CallProcessingComponent,
    canActivate: [IncomingCallGuard],
    children: [
      {
        path: 'general-information',
        component: GeneralInformationComponent,
        data: { title: 'General Information', header: 'Call processing' },
      },
      {
        path: 'rate-information',
        component: RateInformationComponent,
        data: { title: 'Rate Information', header: 'Call processing' },
      },
      {
        path: 'equipment',
        component: EquipmentComponent,
        data: { title: 'Pictures', header: 'Call processing' },
      },
      {
        path: 'common-issues',
        component: CommonIssuesComponent,
        data: { title: 'Common Issues', header: 'Call processing' },
      },
      {
        path: 'reservations-list',
        component: ReservationsListComponent,
        data: { title: 'List of Reservations', header: 'Call processing' },
      },
      {
        path: 'create-ticket-modal',
        component: CreateTicketModalComponent,
        data: { title: 'Create Ticket', header: '' },
      },
      {
        path: 'receipt-modal',
        component: ReceiptModalComponent,
        data: { title: 'Receipt', header: '' },
      },

      { path: '', redirectTo: 'general-information', pathMatch: 'full' },
      { path: '**', redirectTo: 'general-information' },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SkypeClientRoutingModule {}
