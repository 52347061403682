import { Component, OnInit, HostBinding, OnDestroy, Inject } from '@angular/core';
import { Location } from '@angular/common';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';

import { Subscription, zip } from 'rxjs';

import { routerTransition } from '@libs/portal-common/system';
import { NavList } from '../activity-routes';
import { AppConfigurationService, APP_CONFIGURATION, IAppConfigurationBase, Ticket } from '@libs/portal-common/services';

@Component({
  selector: 'app-activity-item',
  templateUrl: './activity-item.component.html',
  styleUrls: ['./activity-item.component.scss'],
  animations: [routerTransition],
})
export class ActivityItemComponent implements OnInit, OnDestroy {
  @HostBinding('@routerTransition') routerTransition = '';
  @HostBinding('class') class = 'flex__box flex__box--column flex__fluid';

  routes = NavList;
  subscription: Subscription;
  routerSubscription: Subscription;

  incidentId = 0;

  ticketsEnabled = false;
  ticket: Ticket;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private location: Location,
    @Inject(APP_CONFIGURATION) private configuration: AppConfigurationService<IAppConfigurationBase>,
  ) {}

  ngOnInit() {
    this.ticketsEnabled = this.configuration.data.uiCustomization.ticketsEnabled;

    this.subscription = zip(this.activatedRoute.params, this.activatedRoute.data).subscribe((res) => {
      this.incidentId = +res[0]['id'];
      this.ticket = res[1].ticket;
      this.routes = NavList.filter((x) => x.path !== 'ticket' || (this.ticketsEnabled && !!this.ticket));
    });

    this.routerSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.ticket = this.activatedRoute.snapshot.data.ticket;
        this.routes = NavList.filter((x) => x.path !== 'ticket' || (this.ticketsEnabled && !!this.ticket));
      }
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.routerSubscription.unsubscribe();
  }

  back() {
    this.router.navigate(['../'], { relativeTo: this.activatedRoute });
  }
}
