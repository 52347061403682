<div class="modal fade" bsModal #modal="bs-modal" [config]="{ backdrop: 'static' }" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header clearfix">
        <h4 class="modal-title pull-left">Select lane</h4>

        <button type="button" class="close pull-right" aria-label="Close" (click)="cancel()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body">
        <h5 class="lane-name" *ngIf="model.location">{{ model.location.LotName }}</h5>

        <ul class="list-unstyled">
          <li *ngFor="let lane of model.lanes" class="lane" (click)="selectLane(lane)">{{ lane.Lane_Type }}</li>
        </ul>
      </div>
    </div>
  </div>
</div>
