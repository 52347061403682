import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { ModalModule } from 'ngx-bootstrap/modal';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { TabsModule } from 'ngx-bootstrap/tabs';

import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { SortableModule } from '@progress/kendo-angular-sortable';
import { IntlModule } from '@progress/kendo-angular-intl';

import { VgCoreModule } from '@videogular/ngx-videogular/core';
import { VgControlsModule } from '@videogular/ngx-videogular/controls';
import { VgOverlayPlayModule } from '@videogular/ngx-videogular/overlay-play';
import { VgBufferingModule } from '@videogular/ngx-videogular/buffering';

import { ValidationModule } from '@libs/portal-common/validation';
import { ServicesModule } from '@libs/portal-common/services';
import { SystemModule } from '@libs/portal-common/system';

import { VideoplayerComponent } from './components/videoplayer/videoplayer.component';
import { SwitchFilterComponent } from './components/switch-filter/switch-filter.component';

import { DropDownComponent } from './components/drop-down/drop-down.component';
import { AudioPlayerComponent } from './components/audio-player/audio-player.component';

import { Map2ArrayPipe } from './pipes/map2array.pipe';
import { DateFormatPipe } from './pipes/date-format.pipe';
import { SafePipe } from './pipes/safe.pipe';
import { SafeUrlPipe } from './pipes/safe-url.pipe';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';

import { DurationPipe } from './pipes/duration.pipe';
import { FindFirstPipe } from './pipes/find-first.pipe';
import { FilterPipe } from './pipes/filter.pipe';
import { ExcludePipe } from './pipes/exclude.pipe';
import { FieldTypePipe } from './pipes/field-type.pipe';
import { OrderByPipe } from './pipes/order-by.pipe';
import { HighlightPipe } from './pipes/highlight.pipe';

import { MatchHeightDirective } from './directives/match-height.directive';
import { DynComponentHostDirective } from './directives/dyn-component-host.directive';

import { SortableTableComponent } from './components/sortable-table/sortable-table.component';

import { WizardComponent } from './components/wizard/wizard.component';
import { WizardStepComponent } from './components/wizard/wizard-step/wizard-step.component';
import { MultiCheckFilterComponent } from './grid-filters/multicheck-filter/multicheck-filter.component';
import { DropDownFilterComponent } from './grid-filters/dropdown-filter/drop-down-filter.component';
import { MultiselectFilterComponent } from './grid-filters/multiselect-filter/multiselect-filter.component';
import { CreateTicketComponent } from './components/tickets/create-ticket/create-ticket.component';
import { TicketEditorComponent } from './components/tickets/ticket-editor/ticket-editor.component';
import { LabelModule } from '@progress/kendo-angular-label';
import { TimePickerModule } from '@progress/kendo-angular-dateinputs';
import { ShiftViewCorrectionPipe } from './pipes/shift-view-correction.pipe';
import { NoContentComponent } from './components/no-content/no-content.component';
import { DndImageUploaderDirective } from './directives/dnd-image-uploader.directive';
import { AccordionComponent } from './components/accordion/accordion.component';
import { TableInfoComponent } from './components/table-info/table-info.component';
import { TypeFieldExecutorPipe } from './pipes/type-field-executor.pipe';
import { PrimaryButtonComponent } from './components/buttons/primary-button/primary-button.component';
import { FieldViewComponent } from './components/field-view/field-view.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,

    DropDownsModule,
    ModalModule,
    TabsModule,
    CollapseModule,
    InputsModule,
    SortableModule,
    IntlModule,

    VgCoreModule,
    VgControlsModule,
    VgOverlayPlayModule,
    VgBufferingModule,

    ValidationModule,
    ServicesModule,
    SystemModule,
    LabelModule,
    TimePickerModule,
  ],
  declarations: [
    DropDownComponent,
    NoContentComponent,
    Map2ArrayPipe,
    DateFormatPipe,
    SafePipe,
    SafeUrlPipe,
    SafeHtmlPipe,
    DurationPipe,
    FindFirstPipe,
    FilterPipe,
    ExcludePipe,
    FieldTypePipe,
    OrderByPipe,
    HighlightPipe,
    ShiftViewCorrectionPipe,
    VideoplayerComponent,
    SortableTableComponent,
    DropDownFilterComponent,
    SwitchFilterComponent,
    MatchHeightDirective,
    AudioPlayerComponent,
    WizardComponent,
    WizardStepComponent,
    MultiCheckFilterComponent,
    DropDownFilterComponent,
    MultiselectFilterComponent,
    CreateTicketComponent,
    TicketEditorComponent,
    DynComponentHostDirective,
    DndImageUploaderDirective,
    AccordionComponent,
    TableInfoComponent,
    TypeFieldExecutorPipe,
    PrimaryButtonComponent,
    FieldViewComponent,
  ],
  exports: [
    DropDownComponent,
    NoContentComponent,
    Map2ArrayPipe,
    DateFormatPipe,
    SafePipe,
    SafeUrlPipe,
    SafeHtmlPipe,
    DurationPipe,
    FindFirstPipe,
    FilterPipe,
    ExcludePipe,
    FieldTypePipe,
    OrderByPipe,
    HighlightPipe,
    ShiftViewCorrectionPipe,
    VideoplayerComponent,
    SortableTableComponent,
    DropDownFilterComponent,
    SwitchFilterComponent,
    MatchHeightDirective,
    AudioPlayerComponent,
    WizardComponent,
    WizardStepComponent,
    MultiCheckFilterComponent,
    DropDownFilterComponent,
    MultiselectFilterComponent,
    CreateTicketComponent,
    TicketEditorComponent,
    DynComponentHostDirective,
    DndImageUploaderDirective,
    AccordionComponent,
    TableInfoComponent,
    TypeFieldExecutorPipe,
    PrimaryButtonComponent,
    FieldViewComponent,
  ],
})
export class SharedModule {}

declare global {
  interface Array<T> {
    findById(id: any): T;
    findBy(field: string, value: any): T;
    removeById(id: any): void;
    removeBy(field: string, value: any): void;
    existsId(id: any): boolean;
    findAndRemove(search_term: any): void;
    findAndRemoveById(id: any): void;
    selectMany<T>(select: (arg: T) => Array<T>);
  }
}
