import { Observable, BehaviorSubject } from 'rxjs';
import { first } from 'rxjs/operators';

import {
  OutgoingConversationState,
  IOutgoingConversation,
  ISkypeAppService,
  ISkypeMePerson,
  ISkypePerson,
  IMePerson,
  ISkypeConversation,
  ISkypeDisposable,
  IParticipant,
} from '../../abstractions';

import { SkypeMePerson } from './skype-me-person';
import { SkypePerson } from './skype-person';

export class SkypeOutgoingConversation implements IOutgoingConversation {
  private _listeners = new Array<ISkypeDisposable>();

  private _state = new BehaviorSubject<OutgoingConversationState>('Created');
  private _selfParticipant = new BehaviorSubject<ISkypeMePerson>(null);
  private _participants = new BehaviorSubject<Array<ISkypePerson>>([]);

  private _isPaused = new BehaviorSubject<boolean>(false);

  public get state$(): Observable<OutgoingConversationState> {
    return this._state.asObservable();
  }
  public get selfParticipant$(): Observable<ISkypeMePerson> {
    return this._selfParticipant.asObservable();
  }
  public get participants$(): Observable<ISkypePerson[]> {
    return this._participants.asObservable();
  }

  constructor(
    private _conversation: ISkypeConversation,
    private _skypeApp: ISkypeAppService,
    private _mePerson: IMePerson,
  ) {
    this._listeners.push(this.conversationStateHandling());
    this._listeners.push(this.participantsHandling());
    this.startAudioService();
  }

  private conversationStateHandling(): ISkypeDisposable {
    let self = this;
    this._state.next(<OutgoingConversationState>this._conversation.state());

    this._selfParticipant.next(new SkypeMePerson(this._mePerson, this._skypeApp));

    return this._conversation.state.changed((newValue, reason, oldValue) => {
      console.log('Outgoing conversation state changed from', oldValue, 'to', newValue);
      self._state.next(<OutgoingConversationState>newValue);

      if (newValue === 'Disconnected') {
        this.dispose();
      }
    });
  }

  private startAudioService() {
    this._conversation.audioService
      .start()
      .then((res) => {})
      .catch((err) => {
        this.dispose();
        this._state.next('Disconnected');
      });
  }

  private participantsHandling(): ISkypeDisposable {
    return this._conversation.participants.changed(() => this.getParticipants());
  }
  private getParticipants() {
    this._conversation.participants
      .get()
      .then((participants) => this.mapParticipants(participants))
      .catch((err) => this._participants.next([]));
  }
  private mapParticipants(participants: Array<IParticipant>) {
    let persons = participants.map((participant) => new SkypePerson(participant.person));
    this._participants.next(persons);
  }

  public leave(): Observable<boolean> {
    return new Observable<boolean>((observer) => {
      this._conversation
        .leave()
        .then((result) => {
          observer.next(true);
          observer.complete();
        })
        .catch((err) => {
          console.log(err);
          observer.error(err);
        });
    });
  }

  pause(): Observable<boolean> {
    return this.handlePause(true);
  }
  resume(): Observable<boolean> {
    return this.handlePause(false);
  }

  private handlePause(pause: boolean): Observable<boolean> {
    let selfParticipant = this._conversation.selfParticipant;
    let audio = selfParticipant.audio;
    let onHold = audio.isOnHold();

    let self = this;

    return new Observable<boolean>((observer) => {
      if (onHold !== pause) {
        audio.isOnHold.set(pause).then(
          function () {
            self._isPaused.next(pause);
            observer.next(pause);
            observer.complete();
          },
          function (error) {
            observer.error(error);
          },
        );
      } else {
        observer.next(pause);
        observer.complete();
      }
    }).pipe(first());
  }

  private dispose() {
    this._listeners.forEach((listener) => listener.dispose());
    this._listeners = [];
  }
}
