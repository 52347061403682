import { IMePerson, SkypeOnlineStatus } from '../../abstractions';

export class StatusManagementQueue {
  private _status: SkypeOnlineStatus;
  private _busy = false;

  constructor(private _mePerson: IMePerson) {}

  public setStatus(status: SkypeOnlineStatus) {
    this._status = status;
    this.setStatusTask();
  }

  public setText(text: string): Promise<string> {
    return this._mePerson.note.text.set(text);
  }

  private setStatusTask() {
    if (this._busy || !this._status) {
      return;
    }
    this._busy = true;
    let status = this._status;

    this._mePerson.status
      .set(status)
      .then(() => this._mePerson.status.get())
      .then((resultStatus) => {
        if (status === resultStatus && this._status === status) {
          this._status = null;
        }

        this._busy = false;
        setTimeout(() => {
          this.setStatusTask();
        }, 1);
      });
  }
}
