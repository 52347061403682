import { Observable, BehaviorSubject, of, Subscription } from 'rxjs';
import { delay, filter, first, map, mergeMap, tap } from 'rxjs/operators';

import {
  IConversation,
  IConversationParameters,
  ConversationState,
  IOutgoingConversation,
  ConversationBase,
  SubmissionParameters,
} from '../../abstractions';
import { Five9AppService } from './services/five9-app.service';

export class Five9Conversation extends ConversationBase implements IConversation {
  private _state = new BehaviorSubject<ConversationState>('Incoming');
  private _isPaused = new BehaviorSubject<boolean>(false);

  private _acceptTime: Date;
  private _hangupTime: Date;
  private _wrapupTime: Date;

  private _parameters: IConversationParameters;
  private stateSubscription: Subscription;

  private campaignId: string = null;

  public callId: string;
  public sessionId: string;
  public interactionId: string;

  public test = true;

  public resolving = false;
  public _isCancelled = false;
  public skipSavingIncident = false;

  public get agentSip(): string {
    return this._agentSip;
  }

  public get agentName(): string {
    return this._agentSip;
  }

  public get callSource(): string {
    return 'five9';
  }

  public get callDuration(): number {
    if (this._acceptTime && this._hangupTime) {
      let res = (+this._hangupTime - +this._acceptTime) / 1000;
      return Math.round(res);
    }

    return 0;
  }

  public get wrapupDuration(): number {
    if (this._hangupTime && this._wrapupTime) {
      let res = (+this._wrapupTime - +this._hangupTime) / 1000;
      return Math.round(res);
    }

    return 0;
  }

  public get queueDuration(): number {
    return 0;
  }

  get cancelled(): boolean {
    return this._isCancelled;
  }

  public get canAccept(): boolean {
    return this._state.value === 'Incoming';
  }

  public get state$(): Observable<ConversationState> {
    return this._state.asObservable();
  }

  public get isPaused$(): Observable<boolean> {
    return this._isPaused.asObservable();
  }

  public get callerUri(): string {
    return this._uri;
  }

  constructor(
    private _uri: string,
    private _agentSip: string,
    private five9: Five9AppService,
  ) {
    super();

    this.stateSubscription = five9.calls$
      .pipe(
        filter((calls) => {
          const call = calls[this.sessionId];
          return call?.state === 'Finished';
        }),
        first(),
      )
      .subscribe((calls) => {
        const call = calls[this.sessionId];
        this.campaignId = call.data.campaignId;

        if (this._state.value === 'Accepted') {
          this.five9
            .disposeCampaignCall(this.interactionId, this.campaignId)
            // NXPD-60 no need to set busy status, five9 can do it itself
            // .pipe(
            //   mergeMap(x => this.five9.setBusyStatus())
            // )
            .subscribe((x) => {
              this._state.next('HangedUp');
              this._hangupTime = new Date();
            });
        }

        this.stateSubscription.unsubscribe();
      });
  }

  public static Parse(content: string, five9: Five9AppService): IConversation {
    let parsed = JSON.parse(content);
    const conversation = new Five9Conversation(parsed.uri, parsed.agentSip, five9);
    conversation._state = new BehaviorSubject<ConversationState>(parsed.state);
    return conversation;
  }

  public setParameters(parameters: IConversationParameters) {
    this._parameters = parameters;
  }

  public accept(): Observable<boolean> {
    this._state.next('Accepted');

    this._acceptTime = new Date();

    return of(true);
  }

  public reject(): Observable<boolean> {
    this._state.next('Finished');
    return of(true);
  }

  public hangUp(): Observable<boolean> {
    let leave = () => {
      this.stateSubscription.unsubscribe();

      this.five9
        .finishCall(this.interactionId)
        .pipe(
          tap((res) => (this.campaignId = res.campaignId)),
          mergeMap((res) =>
            this.five9.setCallVariables(this.interactionId, {
              umojo_category: this.submissionParameters.ProblemName,
              umojo_issue_type: this.submissionParameters.IssueName,
              vend: this.submissionParameters.GateVend ? 'Yes' : 'No',
            }),
          ),
          mergeMap((res) => this.five9.disposeCampaignCall(this.interactionId, this.campaignId)),
        )
        .subscribe(
          (res) => {
            this._state.next('HangedUp');
            this._hangupTime = new Date();
          },
          (err) => {
            console.log(err);
          },
        );
    };

    if (!this._parameters || !this._parameters.terminateCallTone) {
      leave();
    } else {
      this.sendDtmf(this._parameters.terminateCallTone).subscribe((res) => {
        leave();
      });
    }

    return of(true);
  }

  public wrapUp(): Observable<boolean> {
    if (this._state.getValue() === 'Accepted') {
      this.hangUp();
    }

    return this.five9.setReadyStatus().pipe(
      tap((res) => {
        this._wrapupTime = new Date();
        this._state.next('Finished');
      }),
      map((res) => true),
    );
  }

  public submitWrapUp(parameters: SubmissionParameters): Observable<boolean> {
    return of(true);
  }

  public cancelProcessing(): Observable<boolean> {
    this._isCancelled = true;

    return this.wrapUp();
  }
  public markCancelled() {
    this._isCancelled = true;
  }

  sendDtmf(dtmf: string): Observable<boolean> {
    if (dtmf) {
      let char = dtmf.slice(0, 1);
      return this.five9.sendDtmf(char, this.interactionId).pipe(
        delay(300),
        mergeMap((x) => this.five9.sendDtmf(dtmf.slice(1), this.interactionId)),
      );
    }

    return of(true);
  }

  pause(): Observable<boolean> {
    this._isPaused.next(true);
    return of(true);
  }
  resume(): Observable<boolean> {
    this._isPaused.next(false);
    return of(true);
  }

  outboundCall(uri: string): Observable<IOutgoingConversation> {
    return of(null);
  }

  public stringifyAndReset(): string {
    let state = this._state.value;
    this._state = null;
    return JSON.stringify({ state: state, uri: this._uri, skipSavingIncident: this.skipSavingIncident });
  }

  private getActivity() {
    let lotName = this._parameters ? this._parameters.lotName : 'test lot';
    return `Completing - ${lotName}`;
  }
}
