import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { tap, map, filter, flatMap } from 'rxjs/operators';

import { HttpClient } from '@angular/common/http';
import { AppConfigurationService, APP_CONFIGURATION, IAppConfigurationBase } from './app-configuration.service';

@Injectable({ providedIn: 'root' })
export class UpdatesService {
  private uploadBaseUrl = this.configuration.apiBaseUrl + '/Update/Download/';

  constructor(
    private _httpClient: HttpClient,
    @Inject(APP_CONFIGURATION)
    private configuration: AppConfigurationService<IAppConfigurationBase>,
  ) {}

  getLastVersions(): Observable<any> {
    return this._httpClient.get<any>('AgentInstallers').pipe(
      map((response) => {
        var result = [];
        if (response.Version) {
          result.push({
            url: response.CurrentUserInstallerLink,
            installer: 'Agent',
            description: 'Agent, current user installer, without admin account priveleges',
            version: response.Version,
          });
          result.push({
            url: response.AllUsersInstallerLink,
            installer: 'Agent',
            description: 'Agent, All users installer, requires admin account priveleges',
            version: response.Version,
          });
        }
        if (response.Cc4allVersion) {
          result.push({
            url: response.Cc4allCurrentUserInstallerLink,
            installer: 'CC4ALL Agent',
            description: 'CC4ALL Agent, current user installer, without admin account priveleges',
            version: response.Cc4allVersion,
          });
          result.push({
            url: response.Cc4allAllUsersInstallerLink,
            installer: 'CC4ALL Agent',
            description: 'CC4ALL Agent, All users installer, requires admin account priveleges',
            version: response.Cc4allVersion,
          });
        }

        return result;
      }),
    );
  }

  getInstallerInfo(installer): Observable<any> {
    return this._httpClient.get<any>('Update/InstallerInfo/' + installer + '?BypassCache=true');
  }
}
