import { BehaviorSubject, Observable, Observer } from 'rxjs';

import { SkypeOnlineStatus, ISkypePerson, IPerson, ISkypeDisposable } from '../../abstractions';

export class SkypePerson implements ISkypePerson {
  private _id = '';
  private _displayName = new BehaviorSubject<string>(null);
  private _avatarUrl = new BehaviorSubject<string>(null);
  private _status = new BehaviorSubject<SkypeOnlineStatus>(null);
  private _activity = new BehaviorSubject<string>(null);

  private _listeners = new Array<{ listener: ISkypeDisposable; property: Observer<any> }>();

  public get id(): string {
    return this._id;
  }
  public get displayName$(): Observable<string> {
    return this._displayName.asObservable();
  }
  public get avatarUrl$(): Observable<string> {
    return this._avatarUrl.asObservable();
  }
  public get status$(): Observable<SkypeOnlineStatus> {
    return this._status.asObservable();
  }
  public get activity$(): Observable<string> {
    return this._activity.asObservable();
  }

  constructor(private _person: IPerson) {
    let self = this;

    self._listeners.push({
      listener: self._person.id.changed((id) => {
        self._id = id;
      }),
      property: null,
    });

    self._listeners.push({
      listener: self._person.displayName.changed((displayName) => {
        self._displayName.next(displayName);
      }),
      property: self._displayName,
    });

    self._listeners.push({
      listener: self._person.avatarUrl.changed((avatarUrl) => {
        self._avatarUrl.next(avatarUrl);
      }),
      property: self._avatarUrl,
    });

    self._listeners.push({
      listener: self._person.status.changed((status) => {
        self._status.next(status);
      }),
      property: self._status,
    });

    self._listeners.push({
      listener: self._person.activity.changed((activity) => {
        self._activity.next(activity);
      }),
      property: self._activity,
    });
  }

  dispose() {
    this._listeners.forEach((item) => {
      item.listener.dispose();

      if (item.property) {
        item.property.complete();
      }
    });
    this._listeners = [];
  }
}
