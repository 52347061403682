import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';

import { Observable } from 'rxjs';
import * as moment from 'moment';

import { BaseResponse, RevenueControlService, IValidationDiscount, ANALYTICS, IAnalyticsService } from '@libs/portal-common/services';
import { AppNotificationsService } from '@libs/portal-common/system';

import { IncomingCall } from '../../../../../../abstractions';
import { IRevenueForm } from '../../revenue-form-factory.service';
import { AgentAnalyticsEvent } from '../../../../../../services';

@Component({
  selector: 'app-lost-ticket-flash-parcs',
  templateUrl: './lost-ticket-flash-parcs.component.html',
  styleUrls: ['./lost-ticket-flash-parcs.component.scss'],
})
export class LostTicketFlashParcsComponent implements IRevenueForm, OnInit {
  @ViewChild('form', { static: false }) public form: NgForm;

  locationId: number = null;
  laneId: number = null;

  prices = new Array<IValidationDiscount>();
  priceId: string = null;
  ticketDate: Date = null;

  validating = false;
  initialized = false;
  provider: string;

  constructor(
    private revenueControl: RevenueControlService,
    private notifications: AppNotificationsService,
    @Inject(ANALYTICS) private analytics: IAnalyticsService,
  ) {}

  ngOnInit() {
    this.analytics.track(AgentAnalyticsEvent.RevenueFormOpen, { Provider: this.provider, RCForm: 'LostTicketForm' });
  }

  init(provider: string, incomingCall: IncomingCall, formParams: any): void {
    this.validating = false;
    this.initialized = false;

    this.locationId = incomingCall.location.Id;
    this.laneId = incomingCall.lane.Id;

    this.prices = [];
    this.priceId = null;
    this.ticketDate = new Date();
    this.provider = provider;

    setTimeout(() => (this.initialized = true), 30);

    this.revenueControl.getValidationDiscounts({ LotId: this.locationId, LaneId: this.laneId }).subscribe(
      (res) => {
        if (!res.Success) {
          this.notifications.error(res.Message);
          return;
        }

        this.prices = res.ValidationDiscounts;

        if (this.prices.length > 0) {
          this.priceId = this.prices[0].ValidationId;
        }
      },
      (err) => {
        this.notifications.error(err.Message || 'Error');
      },
    );
  }

  validate(): boolean {
    this.validating = true;
    return this.form.valid;
  }

  canSubmit(): boolean {
    return true;
  }

  submit(): Observable<BaseResponse> {
    this.analytics.track(AgentAnalyticsEvent.RevenueFormExecute, { Provider: this.provider, RCActionType: 'rc_lost_ticket_set_price' });
    return this.revenueControl.lostTicket({
      LotId: this.locationId,
      LaneId: this.laneId,
      CommandParams: {
        __type: 'grm.service.model.RevenueControl.FlashParcsSetLostTicketCommandParams, GRM.Service.Model',
        PriceId: this.priceId,
        EntryDateTime: this.dateToString(this.ticketDate),
        SupportCallId: null,
      },
    });
  }

  private dateToString = function (date: Date) {
    return moment(date).format('YYYY-MM-DD HH:mm:ss');
  };
}
