import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'shiftViewCorrectionPipe',
  pure: true,
})
export class ShiftViewCorrectionPipe implements PipeTransform {
  transform(shift: string): any {
    if (!shift) {
      return shift;
    }

    let resShiftString = shift.replace(/,/g, ', ');
    let lastIndex = resShiftString.lastIndexOf('-');

    if (lastIndex > 0) {
      resShiftString = resShiftString.slice(0, lastIndex) + ' - ' + resShiftString.slice(lastIndex + 1);
    }

    return resShiftString;
  }

  deTransform(shift: string): any {
    return shift.replace(/, /g, ',').replace(' - ', '-');
  }
}
