import { Injectable } from '@angular/core';

import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap, map } from 'rxjs/operators';

import { BaseResponse, IRestBaseListResponse } from '../models/rest/baseResponse';
import { AppNotificationsService } from '@libs/portal-common/system';

export interface ITag {
  Value: string;
  fkRegion: number;
}

interface IInsertTagResponse extends BaseResponse {
  Tag: ITag;
}

@Injectable({ providedIn: 'root' })
export class TagsService {
  constructor(
    private _httpClient: HttpClient,
    private notifications: AppNotificationsService,
  ) {}

  getAll(fkRegion: number): Observable<Array<ITag>> {
    let params = new HttpParams().set('fkRegion', fkRegion.toString());

    return this._httpClient.get<IRestBaseListResponse<ITag>>('Tags', { params: params }).pipe(map((x) => x.Data));
  }

  insert(tag: ITag): Observable<ITag> {
    return this._httpClient.put<IInsertTagResponse>('Tags', { tag: tag }).pipe(map((x) => x.Tag));
  }

  delete(tag: ITag): Observable<any> {
    let params = new HttpParams().set('fkRegion', tag.fkRegion.toString()).set('value', tag.Value);

    return this._httpClient.delete<any>('Tags', { params: params });
  }
}
