<ng-container>
  <app-sidebar-nav
    class="flex__box left-nav hidden-print"
    [class.left-nav--closed]="isMenuCollapsed"
    (mouseleave)="mouseLeaveFromNavBar($event)"
    (mouseover)="isShowCollapseIcon = true"
    [isMenuCollapsed]="isMenuCollapsed"
    [class.dark-nav]="isAuthenticated"
    *ngIf="isAuthenticated"
  >
  </app-sidebar-nav>
  <div class="open-nav-icon" (click)="toggleMenu()" @insertRemoveOpenNavBtn *ngIf="isMenuCollapsed"></div>

  <div class="page-host main-view flex__box flex__box--column flex__fluid">
    <div
      class="top-bar page__heading flex__box flex__box__row flex__box--align-center"
      *ngIf="isAuthenticated"
      [class.hasNotifications]="hasNotifications"
    >
      <ng-container *ngIf="isShowCollapseIcon">
        <div
          (click)="toggleMenu()"
          class="collapse-nav-icon"
          id="collapse-nav-icon"
          @insertRemoveCollapseNavBtn
          *ngIf="!isMenuCollapsed"
        ></div>
      </ng-container>

      <div class="d-flex">
        <img class='top-bar-page-icon' *ngIf="pageIcon" [src]="pageIcon" alt="pageIcon" />
        <h3 class="page__heading__title">{{ pageHeader }}</h3>
      </div>
      <div class="top-bar__tools">
        <a [routerLink]="['/auth/logout']" class="d-flex"
        >Logout <img src="/img/general-icons/logout.svg" alt="logout" style="margin-left: 5px"
        /></a>
      </div>
    </div>

    <div id="rootRouterOutlet" class="flex__box flex__box--column flex__fluid spinner__container main-router-outlet">
      <router-outlet></router-outlet>
    </div>

    <div
      class="genesys-softphone"
      *ngIf="genesysIframeUrl"
      kendoDragTarget
      (release)="handleRelease($event)"
      (dragStart)="handleDragStart($event)"
    >
      <div class="drag-target" kendoDragHandle></div>
      <div [style.pointer-events]="iframePinterEvents">
        <iframe id="genesysSoftphone" allow="camera *; microphone *" [src]="genesysIframeUrl | safeUrl"></iframe>
      </div>
    </div>
  </div>

  <app-incoming-call></app-incoming-call>
  <app-skype-conversation></app-skype-conversation>
</ng-container>

