import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { HttpClient } from '@angular/common/http';

import { BaseResponse, IRestBaseListResponse } from '../models/rest/baseResponse';

export interface ICallMatchingGroup {
  Id: number;
  Name: string;
  Template: string;
  fkCallCenter: number;
  Tags?: Array<string>;
}

interface IInsertOrUpdateCallMatchingGroupResponse extends BaseResponse {
  CallMatchingGroup: ICallMatchingGroup;
}

interface IGetCallMatchingGroupTagsResponse extends BaseResponse {
  Tags: Array<string>;
}

@Injectable({ providedIn: 'root' })
export class CallMatchingGroupsService {
  constructor(private _httpClient: HttpClient) {}

  getAll(callCenterId: number): Observable<Array<ICallMatchingGroup>> {
    return this._httpClient
      .get<IRestBaseListResponse<ICallMatchingGroup>>(`CallCenter/${callCenterId}/CallMatchingGroups/`)
      .pipe(map((response) => response.Data));
  }

  insertOrUpdate(callMatchingGroup: ICallMatchingGroup): Observable<ICallMatchingGroup> {
    return this._httpClient
      .post<IInsertOrUpdateCallMatchingGroupResponse>('CallMatchingGroups', { callMatchingGroup })
      .pipe(map((response) => response.CallMatchingGroup));
  }

  delete(callMatchingGroupId: number): Observable<BaseResponse> {
    return this._httpClient.delete<BaseResponse>(`CallMatchingGroups/${callMatchingGroupId}`);
  }

  getTags(callMatchingGroupId: number): Observable<Array<string>> {
    return this._httpClient
      .get<IGetCallMatchingGroupTagsResponse>(`CallMatchingGroups/${callMatchingGroupId}/Tags/`)
      .pipe(map((response) => response.Tags));
  }

  getTagsByCallCenter(CallCenterId: number): Observable<Array<string>> {
    return this._httpClient
      .get<IGetCallMatchingGroupTagsResponse>(`/CallCenter/${CallCenterId}/CallMatchingGroups/Tags`)
      .pipe(map((response) => response.Tags));
  }

  saveTags(callMatchingGroupId: number, tags: Array<string>): Observable<BaseResponse> {
    return this._httpClient.post<BaseResponse>(`CallMatchingGroups/${callMatchingGroupId}/Tags`, { tags: tags });
  }
}
