import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { LoaderStatusService } from '../services/loader-status.service';

const API_REQUEST_EXCEPTIONS = ['CallCenterStatistics', 'maps-api', 'PowerBiDashboard', 'noodoe', 'Ocra'];

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {

  private totalApiRequests: Array<HttpRequest<any>> = [];

  constructor(
    private loaderStatusService: LoaderStatusService
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (request.url.includes('/api/') && request.method !== 'OPTIONS') {
      if (!this.requestExistException(request.url)) {
        this.totalApiRequests = [...this.totalApiRequests, request];
        this.loaderStatusService.setLoadingStatus(true);
      }
    }

    return next.handle(request).pipe(
      finalize(() => {
        this.totalApiRequests.shift();

        if (this.totalApiRequests.length === 0) {
          this.loaderStatusService.setLoadingStatus(false);
        }
      })
    );
  }

  private requestExistException(url: string) {
    let isExistException = false;
    API_REQUEST_EXCEPTIONS.forEach((exception: string) => {
      if (url.includes(exception)) {
        isExistException = true;
      }
    });
    return isExistException;
  }
}
