import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { SafeUrl } from '@angular/platform-browser';

export interface ICallProcessingTab {
  url?: SafeUrl;
  name?: string;
}
export interface IViewTabsData {
  activeTab: number;
  tabs: ICallProcessingTab[];
}

@Injectable()
export class CallProcessingService {
  private _viewTabsData = new BehaviorSubject<IViewTabsData>(null);

  constructor() {
    this.setViewTabsData();
  }

  get viewTabsData$(): Observable<IViewTabsData> {
    return this._viewTabsData;
  }

  setViewTabsData() {
    this._viewTabsData.next({ activeTab: 0, tabs: [{ name: 'Main' }] });
  }

  updateViewTabsData(name: string, url?: SafeUrl) {
    let tabs = this._viewTabsData.value.tabs;
    let viewData = tabs.findBy('name', name) || null;

    if (!viewData) {
      viewData = { name, url };
      tabs.push(viewData);
    }

    this._viewTabsData.next(null);
    this._viewTabsData.next({ activeTab: tabs.indexOf(viewData), tabs: [...tabs] });
  }
}
