import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CallCenterParkonectCredentials } from '../models/callCenterParkonectCredentials';
import { Observable } from 'rxjs';
import { CallCenterParkonectCredentialsResponse } from '../models/rest/callCenter/callCenterParkonectCredentialsResponse';

@Injectable({ providedIn: 'root' })
export class CallCenterParkonectCredentialsService {
  constructor(private _httpClient: HttpClient) {}

  getByCallCenterId(callCenterId: number): Observable<CallCenterParkonectCredentialsResponse> {
    return this._httpClient.get<CallCenterParkonectCredentialsResponse>('CallCenter/' + callCenterId + '/ParkonectCredentials');
  }

  insertOrUpdate(parkonectCredentials: CallCenterParkonectCredentials): Observable<CallCenterParkonectCredentialsResponse> {
    return this._httpClient.put<CallCenterParkonectCredentialsResponse>('CallCenter/ParkonectCredentials', {
      ParkonectCredentials: parkonectCredentials,
    });
  }
}
