import { Observable, BehaviorSubject, of, Subscription, from } from 'rxjs';
import { delay, filter, first, map, mergeMap, tap } from 'rxjs/operators';

import {
  IConversation,
  IConversationParameters,
  ConversationState,
  IOutgoingConversation,
  ConversationBase,
  SubmissionParameters,
} from '../../abstractions';
import { GenesysAppService } from './services/genesys-app.service';
import { AgentAnalyticsEvent } from '../../services';

export class GenesysConversation extends ConversationBase implements IConversation {
  private _state = new BehaviorSubject<ConversationState>('Incoming');
  private _isPaused = new BehaviorSubject<boolean>(false);

  private _acceptTime: Date;
  private _hangupTime: Date;
  private _wrapupTime: Date;

  private _parameters: IConversationParameters;

  public callId: string;
  public sessionId: string;

  public resolving = false;
  public isCancelled = false;
  public skipSavingIncident = false;

  private stateSubscription: Subscription;

  public get agentSip(): string {
    return this._agentSip;
  }

  public get agentName(): string {
    return this._agentSip;
  }

  public get callSource(): string {
    return 'genesys';
  }

  public get callDuration(): number {
    if (this._acceptTime && this._hangupTime) {
      let res = (+this._hangupTime - +this._acceptTime) / 1000;
      return Math.round(res);
    }

    return 0;
  }

  public get wrapupDuration(): number {
    if (this._hangupTime && this._wrapupTime) {
      let res = (+this._wrapupTime - +this._hangupTime) / 1000;
      return Math.round(res);
    }

    return 0;
  }

  public get queueDuration(): number {
    return this._queueDuration;
  }

  get cancelled(): boolean {
    return this.isCancelled;
  }

  public get canAccept(): boolean {
    return this._state.value === 'Incoming';
  }

  public get state$(): Observable<ConversationState> {
    return this._state.asObservable();
  }

  public get isPaused$(): Observable<boolean> {
    return this._isPaused.asObservable();
  }

  public get callerUri(): string {
    return this._uri;
  }

  constructor(
    private _uri: string,
    private _agentSip: string,
    private genesys: GenesysAppService,
    public selfParticipantId: string,
    public callerParticipantId: string,
    public selfSessionId: string,
    private _queueDuration: number,
  ) {
    super();
    this.stateSubscription = genesys.calls$
      .pipe(
        map((calls) => calls[this.callId]),
        filter((call) => !call || call.state === 'Finished'),
        filter((call) => this._state.value === 'Accepted'),
        first(),
      )
      .subscribe((state) => {
        this._state.next('HangedUp');
        this._hangupTime = new Date();
      });

    this.state$
      .pipe(
        filter((state) => state === 'Finished'),
        first(),
        mergeMap((state) => {
          return this.genesys.disposeCall(this.callId, this.selfParticipantId);
        }),
      )
      .subscribe((state) => {});
  }

  public static Parse(content: string, genesys: GenesysAppService): IConversation {
    let parsed = JSON.parse(content);
    const conversation = new GenesysConversation(
      parsed.uri,
      parsed.agentSip,
      genesys,
      parsed.callerParticipantId,
      parsed.selfParticipantId,
      parsed.selfSessionId,
      parsed.queueDuration,
    );
    conversation._state = new BehaviorSubject<ConversationState>(parsed.state);
    return conversation;
  }

  public setParameters(parameters: IConversationParameters) {
    this._parameters = parameters;
  }

  public accept(): Observable<boolean> {
    this._state.next('Accepted');

    this._acceptTime = new Date();
    this.genesys.analytcis.track(AgentAnalyticsEvent.GenesysCallAccepted, null);

    return of(true);
  }

  public reject(): Observable<boolean> {
    this._state.next('Finished');
    this.genesys.analytcis.track(AgentAnalyticsEvent.GenesysCallFinished, null);

    return of(true);
  }

  public hangUp(): Observable<boolean> {
    let leave = () => {
      this.stateSubscription.unsubscribe();

      this.genesys.finishCall(this.callId, this.selfParticipantId, this.selfSessionId).subscribe(
        (res) => {
          this._state.next('HangedUp');
          this.genesys.analytcis.track(AgentAnalyticsEvent.GenesysCallHangUp, null);
          this._hangupTime = new Date();
        },
        (err) => {
          console.log(err);
        },
      );
    };

    if (!this._parameters || !this._parameters.terminateCallTone) {
      leave();
    } else {
      this.sendDtmf(this._parameters.terminateCallTone).subscribe((res) => {
        leave();
      });
    }

    return of(true);
  }

  public wrapUp(): Observable<boolean> {
    this.genesys.analytcis.track(AgentAnalyticsEvent.GenesysCallWrapUp, null);
    if (this._state.getValue() === 'Accepted') {
      this.hangUp();
    }

    return this.genesys.setReadyStatus().pipe(
      tap((res) => {
        this._wrapupTime = new Date();
        this._state.next('Finished');
      }),
      map((res) => true),
    );
  }

  public submitWrapUp(parameters: SubmissionParameters): Observable<boolean> {
    return from(this.submitWrapUpImpl(parameters));
  }

  private async submitWrapUpImpl(parameters: SubmissionParameters): Promise<boolean> {
    let codeId = await this.genesys.findWrapUpCode(parameters.ProblemName, parameters.IssueName);
    if (!codeId) {
      return false;
    }

    return this.genesys.submitWrapUpCode(this.callId, this.selfParticipantId, codeId);
  }

  public cancelProcessing(): Observable<boolean> {
    this.isCancelled = true;

    return this.wrapUp();
  }
  public markCancelled() {
    this.isCancelled = true;
  }

  sendDtmf(dtmf: string): Observable<boolean> {
    // Split DTMF sequesnce into tones and play each tone with 200ms payse before playing.
    if (dtmf) {
      let char = dtmf.slice(0, 1);
      return this.genesys.sendDtmf(char, this.callId, this.selfParticipantId).pipe(
        delay(200),
        mergeMap((x) => this.sendDtmf(dtmf.slice(1))),
      );
    }

    return of(true);
  }

  pause(): Observable<boolean> {
    this._isPaused.next(true);
    return of(true);
  }
  resume(): Observable<boolean> {
    this._isPaused.next(false);
    return of(true);
  }

  outboundCall(uri: string): Observable<IOutgoingConversation> {
    return of(null);
  }

  public stringifyAndReset(): string {
    let state = this._state.value;
    this._state = null;
    return JSON.stringify({ state: state, uri: this._uri, skipSavingIncident: this.skipSavingIncident });
  }
}
