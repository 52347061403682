<kendo-dropdownlist
  [data]="data"
  (valueChange)="onChange($event)"
  [defaultItem]="defaultItem"
  [value]="selectedValue"
  [valuePrimitive]="true"
  [textField]="textField"
  [valueField]="valueField"
>
</kendo-dropdownlist>
