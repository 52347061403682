<div>

  <div class="ibox col-sm-12">
    <div class="ND--panels--full-width-panel">
      <div class='flex__box align-items-center flex__box--row'>
        <button
          type="button"
          class='primary-switch-button button'
          [ngClass]="isRegionFilter ? 'ND--buttons--primary' : 'ND--buttons--primary-outline-button'"
          [disabled]="!regions.length"
          (click)="switchFilter(true)"
        >
          Regions
        </button>
        <button
          type="button"
          class='primary-switch-button button'
          [ngClass]="!isRegionFilter ? 'ND--buttons--primary' : 'ND--buttons--primary-outline-button'"
          (click)="switchFilter(false)"
        >
          Queues
        </button>
      </div>
      <button (click)="openMonitoringSettingsEditor()" class="btn"><i class="k-icon k-i-gear"></i></button>
    </div>
  </div>
</div>

<div class="monitoring-page">
  <div class="wrapper wrapper-content animated fadeInRight row">
    <div class="col-lg-7">
      <div class='ND--panels--full-width-panel' style='margin-bottom: 10px' *ngIf="isRegionFilter">
        <div>
          <span>Regions: </span>
          <button
            *ngFor="let region of regions"
            type="button"
            (click)="selectRegion(region.Id)"
            style="margin: 5px"
            [ngClass]="regionButtons[region.Id] ? 'btn btn-primary' : 'btn secondary-color'"
          >
            {{ region.Name }}
          </button>
        </div>

      </div>

      <div class='ND--panels--full-width-panel' style='margin-bottom: 10px' *ngIf="!isRegionFilter">
        <div>
          <span>
            Queues:
          </span>
          <button
            *ngFor="let queue of settingsQueues"
            type="button"
            (click)="selectQueue(queue)"
            style="margin: 5px"
            [ngClass]="queuesButtons[queue.Id] ? 'btn btn-primary' : 'btn secondary-color'"
          >
            {{ queue.Name }}
          </button>
        </div>
      </div>
      <div style='margin-bottom: 10px'>
        <div class="ND--panels--full-width-panel">
          <div class='ND--titles--panel-title'>Calls</div>
        </div>
        <div class="ibox__content">
          <table class="ND--tables--table-list" id="monitorcallstable">
            <thead style='border-top: none'>
              <tr>
                <th>Caller</th>
                <th>Location</th>
                <th style="text-align: right">In Queue</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let call of callsInQueue">
                <td>{{ call.Caller }}</td>
                <td>{{ call.lotLane }}</td>
                <td style="text-align: right">{{ call.WaitSeconds }}</td>
              </tr>
            </tbody>
          </table>
          <app-no-content *ngIf='callsInQueue?.length === 0' [isWhiteBackground]='true'></app-no-content>
        </div>
      </div>
      <div>
        <div class="ND--panels--full-width-panel">
          <div class='ND--titles--panel-title'>Agents</div>
        </div>
        <div class="ibox__content">
          <table class="ND--tables--table-list" id="agentsTable">
            <thead style='border-top: none'>
              <tr>
                <th>Agent</th>
                <th>24h</th>
                <th>Availability</th>
                <th>Activity</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let agent of stats.Agents">
                <td>{{ agent.DisplayName }}</td>
                <td>{{ agent.Calls24H }}</td>
                <td [ngClass]="classToUse(agent.Availability)">{{ agent.Availability }}</td>
                <td>{{ agent.Activity }}</td>
              </tr>
            </tbody>
          </table>
          <app-no-content *ngIf='stats?.Agents?.length === 0' [isWhiteBackground]='true'></app-no-content>
        </div>
      </div>
    </div>
    <div class="col-lg-5">
      <div class="infoboards-lg">
        <div class="infoboard-widget">
          <div class="widget navy-bg">
            <div class="row">
              <div class="col-2">
                <i class="fa fa-group fa-3x"></i>
              </div>
              <div class="col-10 text-right">
                <span> Available Agents </span>
                <h1 class="font-bold">
                  <span>{{ stats.CdrSummary.AvailableAgents }}</span>
                </h1>
              </div>
            </div>
          </div>
        </div>
        <div class="infoboard-widget">
          <div class="widget style1 navy-bg">
            <div class="row">
              <div class="col-2">
                <i class="fa fa-phone fa-3x"></i>
              </div>
              <div class="col-10 text-right">
                <span> Active Calls </span>
                <h1 class="font-bold">
                  <span id="ActiveCalls">{{ stats.CdrSummary.ActiveCalls }}</span>
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="infoboards">
        <ng-template ngFor let-infoboard [ngForOf]="infoboards" let-idx="index">
          <div [ngClass]="infoboard.infoClass" class="infoboard-widget">
            <div class="m-b-md">
              <i [class]="infoboard.iconClass"></i>
              <h2 class="m-xs">{{ infoboard.getValue() }}</h2>
              <div class="font-bold no-margins" style='font-size: 11px'>
                {{ infoboard.title }}
              </div>
              <small>{{ infoboard.period }}</small>
            </div>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</div>

<app-edit-monitoring-settings #editMonitoringSettings></app-edit-monitoring-settings>
