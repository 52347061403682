import { Component, Input, OnChanges, ChangeDetectorRef, HostBinding } from '@angular/core';

import { ILocation } from '@libs/portal-common/services';

declare var google: any;

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
})
export class MapComponent implements OnChanges {
  @HostBinding('class') class = 'info-tab-content';

  @Input() location: ILocation;

  zoom = 8;
  center: google.maps.LatLngLiteral;
  options: google.maps.MapOptions = {
    mapTypeId: 'roadmap',
    scrollwheel: false,
    disableDoubleClickZoom: true,
    streetViewControl: false,
    maxZoom: 15,
    minZoom: 8,
  };

  constructor(private _cd: ChangeDetectorRef) {}

  ngOnChanges(changes: any): void {
    if (changes.location && changes.location.currentValue) {
      let location = changes.location.currentValue;

      this.decode(location);
    }
  }

  private decode(location: ILocation) {
    let address = location.Address;
    let geocoder = new google.maps.Geocoder();

    geocoder.geocode({ address: address }, (results, status) => {
      if (status === google.maps.GeocoderStatus.OK) {
        let coordinates = results[0].geometry.location;

        this.center = {
          lat: coordinates.lat(),
          lng: coordinates.lng(),
        };

        console.log('center', this.center);
        this._cd.detectChanges();
      } else {
        console.log('Error - ', results, ' & Status - ', status);
      }
    });
  }
}
