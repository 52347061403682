<h3 class="info-header">Common Issues</h3>
<div class="issue" *ngFor="let issue of issues">
  <h5 class="issue-title">Issue: {{ issue.IssueName }}</h5>

  <div class="issue-body info-block">
    <ul>
      <li>
        <p [innerHTML]="issue.RuleDescription | safeHtml"></p>
      </li>
    </ul>
  </div>
</div>
