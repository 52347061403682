import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap, map, filter, flatMap } from 'rxjs/operators';

import { PubSubService } from '@libs/portal-common/system';

import { HttpClient } from '@angular/common/http';
import { BaseResponse, Contact, ContactIssue } from '../models';

export interface IIssueContactsResponse extends BaseResponse {
  Contacts: Array<Contact>;
}
export interface IIssueContactResponse extends BaseResponse {
  ContactIssue: ContactIssue;
}

@Injectable({ providedIn: 'root' })
export class IssuesService {
  constructor(
    private _httpClient: HttpClient,
    private _pubSubService: PubSubService,
  ) {}

  // Get all Issues
  getAll(problemId: number): Observable<any> {
    return this._httpClient.post<any>('Issues/List', { ProblemId: problemId });
  }

  // Get an Issue
  get(issueId): Observable<any> {
    return this._httpClient.get<any>('Issues/' + issueId);
  }

  // Create an Issue
  create(issue): Observable<any> {
    return this._httpClient.put<any>('Issues/', { issue }).pipe(tap((response) => this._pubSubService.$pub('globalIssuesChanged')));
  }

  // Update an Issue
  update(issue): Observable<any> {
    return this._httpClient.put<any>('Issues/', { issue }).pipe(tap((response) => this._pubSubService.$pub('globalIssuesChanged')));
  }

  // Delete an Issue
  delete(issueId): Observable<any> {
    return this._httpClient.delete<any>('Issues/' + issueId).pipe(tap((response) => this._pubSubService.$pub('globalIssuesChanged')));
  }

  // Reorder Issues for a Problem
  reorder(problemId, ids): Observable<any> {
    return this._httpClient
      .post<any>('Issues/Reorder', {
        ProblemId: problemId,
        Ids: ids,
      })
      .pipe(tap((response) => this._pubSubService.$pub('globalIssuesChanged')));
  }

  getIssueContacts(issueId: number): Observable<IIssueContactsResponse> {
    return this._httpClient.get<IIssueContactsResponse>(`Issues/${issueId}/Contacts`);
  }

  createOrUpdateIssueContact(contactIssue: ContactIssue): Observable<IIssueContactResponse> {
    return this._httpClient.post<IIssueContactResponse>('Issues/Contacts', { ContactIssue: contactIssue });
  }

  deleteIssueContact(contactIssue: ContactIssue): Observable<BaseResponse> {
    return this._httpClient.delete<BaseResponse>(`Issues/${contactIssue.fkIssue}/Contacts/${contactIssue.fkContact}`);
  }
}
