import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IdentityProvider } from '../models/identityProvider';
import { IRestBaseListResponse } from '../models';

@Injectable({ providedIn: 'root' })
export class IdentityProvidersService {
  constructor(private _httpClient: HttpClient) {}

  getAll(): Observable<IRestBaseListResponse<IdentityProvider>> {
    return this._httpClient.get<IRestBaseListResponse<IdentityProvider>>('IdentityProvider');
  }

  getAllInfo(): Observable<IRestBaseListResponse<IdentityProvider>> {
    return this._httpClient.get<IRestBaseListResponse<IdentityProvider>>('IdentityProvider/Info');
  }
}
