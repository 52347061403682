import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ConfirmationComponent } from './confirmation.component';

@Injectable({ providedIn: 'root' })
export class ConfirmationService {
  private modal: ConfirmationComponent;
  private defaultMessage = 'Are you sure you want to delete this item? This action cannot be undone.';

  constructor() {}

  public init(modal: ConfirmationComponent) {
    this.modal = modal;
  }

  public show(message?: string): Observable<boolean> {
    return this.modal.open(message ? message : this.defaultMessage);
  }

  public customConfirmationShow(modal: any, data: any) {
    return modal.open(data);
  }
}
