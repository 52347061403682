import { Observable, BehaviorSubject, of, Subscription } from 'rxjs';
import { delay, filter, first, flatMap, map, mergeMap, tap } from 'rxjs/operators';

import {
  IConversation,
  IConversationParameters,
  ConversationState,
  IOutgoingConversation,
  SubmissionParameters,
  ConversationBase,
} from '../../abstractions';

import { CC4AllAppService } from './services/cc4all-app.service';

export class CC4AllConversation extends ConversationBase implements IConversation {
  private _state = new BehaviorSubject<ConversationState>('Incoming');
  private _isPaused = new BehaviorSubject<boolean>(false);

  private _acceptTime: Date;
  private _hangupTime: Date;
  private _wrapupTime: Date;

  private _parameters: IConversationParameters;
  private presenceSubscription: Subscription;
  private stateSubscription: Subscription;

  public callId: string;
  public sessionId: string;
  public conversationKey: string;

  public test = true;

  public resolving = false;
  public _isCancelled = false;
  public skipSavingIncident = false;

  public get agentSip(): string {
    return this._agentSip;
  }

  public get agentName(): string {
    return this._agentSip;
  }

  public get callSource(): string {
    return 'cc4all';
  }

  public get callDuration(): number {
    if (this._acceptTime && this._hangupTime) {
      let res = (+this._hangupTime - +this._acceptTime) / 1000;
      return Math.round(res);
    }

    return 0;
  }

  public get wrapupDuration(): number {
    if (this._hangupTime && this._wrapupTime) {
      let res = (+this._wrapupTime - +this._hangupTime) / 1000;
      return Math.round(res);
    }

    return 0;
  }

  public get queueDuration(): number {
    return 0;
  }

  get cancelled(): boolean {
    return this._isCancelled;
  }

  public get canAccept(): boolean {
    return this._state.value === 'Incoming';
  }

  public get state$(): Observable<ConversationState> {
    return this._state.asObservable();
  }

  public get isPaused$(): Observable<boolean> {
    return this._isPaused.asObservable();
  }

  public get callerUri(): string {
    return this._uri;
  }

  constructor(
    private _uri: string,
    private _agentSip: string,
    private cc4all: CC4AllAppService,
  ) {
    super();

    this.presenceSubscription = cc4all.presenceAndNote$.subscribe((res) => {
      if (this._state.value === 'HangedUp' && res.Availability === 'Online') {
        this.cc4all.setBusyStatus();
      }
    });

    this.stateSubscription = this.cc4all.callSessions$.pipe(filter((x) => x.ConversationKey === this.conversationKey)).subscribe((res) => {
      switch (res.StatusAsString) {
        case 'Terminated':
          if (this._state.value === 'Accepted') {
            this.cc4all.setBusyStatus().subscribe((x) => {
              this._state.next('HangedUp');
              this._hangupTime = new Date();
            });
          }

          this.stateSubscription.unsubscribe();
          break;

        case 'OnHold':
          this._isPaused.next(true);
          break;

        case 'Resumed':
          this._isPaused.next(false);
          break;

        default:
          break;
      }
    });
  }

  public static Parse(content: string, cc4all: CC4AllAppService): IConversation {
    let parsed = JSON.parse(content);
    const conversation = new CC4AllConversation(parsed.uri, parsed.agentSip, cc4all);
    conversation._state = new BehaviorSubject<ConversationState>(parsed.state);
    return conversation;
  }

  public setParameters(parameters: IConversationParameters) {
    this._parameters = parameters;
  }

  public accept(): Observable<boolean> {
    this._state.next('Accepted');

    this._acceptTime = new Date();

    return of(true);
  }

  public reject(): Observable<boolean> {
    this._state.next('Finished');
    return of(true);
  }

  public hangUp(): Observable<boolean> {
    let leave = () => {
      this.cc4all
        .finishCall(this.conversationKey, this.sessionId)
        .pipe(mergeMap((res) => this.cc4all.setBusyStatus()))
        .subscribe(
          (res) => {
            this._state.next('HangedUp');
            this._hangupTime = new Date();
          },
          (err) => {
            console.log(err);
          },
        );
    };

    if (!this._parameters || !this._parameters.terminateCallTone) {
      leave();
    } else {
      this.sendDtmf(this._parameters.terminateCallTone).subscribe((res) => {
        leave();
      });
    }

    return of(true);
  }

  public wrapUp(): Observable<boolean> {
    if (this._state.getValue() === 'Accepted') {
      this.hangUp();
    }

    this.presenceSubscription.unsubscribe();
    return this.cc4all.setReadyStatus().pipe(
      tap((res) => {
        this._wrapupTime = new Date();
        this._state.next('Finished');
      }),
      map((res) => true),
    );
  }

  public submitWrapUp(parameters: SubmissionParameters): Observable<boolean> {
    return of(true);
  }

  public cancelProcessing(): Observable<boolean> {
    this._isCancelled = true;

    return this.wrapUp();
  }
  public markCancelled() {
    this._isCancelled = true;
  }

  sendDtmf(dtmf: string): Observable<boolean> {
    if (dtmf) {
      let char = dtmf.slice(0, 1);
      return this.cc4all.sendDtmf(char, this.conversationKey).pipe(
        delay(200),
        mergeMap((x) => this.cc4all.sendDtmf(dtmf.slice(1), this.conversationKey)),
      );
    }

    return of(true);
  }

  pause(): Observable<boolean> {
    return this.handlePause(true);
  }
  resume(): Observable<boolean> {
    return this.handlePause(false);
  }

  outboundCall(uri: string): Observable<IOutgoingConversation> {
    if (this._state.getValue() === 'Accepted') {
      return this.warmTransfer(uri);
    }

    return this.createOutboundCall(uri);
  }

  public stringifyAndReset(): string {
    let state = this._state.value;
    this._state = null;
    return JSON.stringify({ state: state, uri: this._uri, skipSavingIncident: this.skipSavingIncident });
  }

  private warmTransfer(uri: string): Observable<IOutgoingConversation> {
    return this.cc4all.warmTransferStart(this.conversationKey, this.sessionId, uri).pipe(
      mergeMap((conversation) => conversation.state$.pipe(map((state) => ({ state: state, conversation: conversation })))),
      filter((res) => res.state === 'Disconnected'),
      first(),
      mergeMap((res) => this.resume().pipe(map((resumeRes) => res.conversation))),
      tap((res) => {
        if (this._state.value === 'HangedUp') {
          this.cc4all.setBusyStatus();
        }
      }),
    );
  }

  private createOutboundCall(uri: string): Observable<IOutgoingConversation> {
    return this.cc4all.createOutboundCall(uri);
  }

  private handlePause(pause: boolean): Observable<boolean> {
    if (pause) {
      return this.cc4all.pauseCall(this.conversationKey, this.sessionId);
    }

    return this.cc4all.resumeCall(this.conversationKey, this.sessionId);
  }
}
