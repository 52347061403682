import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { TooltipModule } from 'ngx-bootstrap/tooltip';

import { ValidationModule } from '@libs/portal-common/validation';
import { ServicesModule } from '@libs/portal-common/services';
import { SystemModule } from '@libs/portal-common/system';

import { AuthRoutingModule } from './auth-routing.module';

import { LoginComponent } from './login.component';
import { LogoutComponent } from './logout.component';

import { RegistrationComponent } from './registration/registration.component';
import { AuthErrorComponent } from './auth-error/auth-error.component';

@NgModule({
  imports: [CommonModule, FormsModule, TooltipModule, ValidationModule, ServicesModule, SystemModule, AuthRoutingModule],
  declarations: [LoginComponent, LogoutComponent, RegistrationComponent, AuthErrorComponent],
  exports: [LoginComponent, LogoutComponent, RegistrationComponent, AuthErrorComponent],
})
export class AuthenticationModule {}
