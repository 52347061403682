<h5>Incoming call form</h5>

<form novalidate>
  <div class="form-group">
    <label>Caller</label>
    <input type="text" class="form-control" name="caller" [(ngModel)]="model.caller" placeholder="Caller" />
  </div>
  <div class="form-group">
    <label>Call ID</label>
    <input type="text" class="form-control" name="callId" [(ngModel)]="model.callId" placeholder="Call ID" />
  </div>
  <div class="form-group">
    <label>Sesion ID</label>
    <input type="text" class="form-control" name="sessionId" [(ngModel)]="model.sessionId" placeholder="Session ID" />
  </div>
  <div class="form-group">
    <label>Agent SIP</label>
    <input type="text" class="form-control" name="agentSip" [(ngModel)]="model.agentSip" placeholder="Agent SIP" />
  </div>
  <button class="btn btn-primary" (click)="submitForm()">Submit</button>
</form>
