import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ReportsService } from './reports.service';

export enum FilterStateEnum {
  yesterday = 'yesterday',
  week = 'week',
  last2weeks = 'last2weeks',
  selectedMonth = 'selectedMonth',
  lastMonth = 'lastMonth',
  year = 'year',
}

export interface IStatisticsFilter {
  Start: Date;
  End: Date;
  State?: FilterStateEnum | null;
  MonthId?: number | null;
}

export class StatisticsData {
  AvgCallsPerHour30DaysBefore: Array<any>;
  AvgCallsPerHour90DaysBefore: Array<any>;
  AvgCallsPerHourCurrentYear: Array<any>;
  AvgCallsPerHourPreviousYear: Array<any>;
  CallsPerWeekDayFourWeeks: Array<any>;
  AvgCallsPerWeekDayCurrentYear: Array<any>;
  AvgCallsPerWeekPreviousYear: Array<any>;
  CallsPerWeekCurrentYear: Array<any>;
  CallsPerWeekPreviousYear: Array<any>;
  CallsPerMonthCurrentYear: Array<any>;
  CallsPerMonthPreviousYear: Array<any>;
  VendsPerMonthCurrentYear: Array<any>;
  AlertsPerMonthCurrentYear: Array<any>;
  CallsPerMonthSegmentsList: Array<any>;
  Top20AgentsCallsList: Array<any>;
  Top20LocationsCallsList: Array<any>;
  ProblemsCurrentYearList: Array<any>;
  ProblemsPreviousYear: Array<any>;
}

export class DashboardChartsData {
  ProblemsCurrentYearList: Array<any>;
  AverageHoursPerIncidentList: Array<any>;
  AverageIncidentsPerWeekDayList: Array<any>;
  MonthsVendsCurrentYearList: Array<any>;
}

@Injectable({ providedIn: 'root' })
export class StatisticsService {
  constructor(private _reports: ReportsService) {}

  callStats(filter: IStatisticsFilter): Observable<StatisticsData> {
    return this._reports.getCallStats(filter);
  }

  topLanesIssues(filter: any): Observable<any> {
    return this._reports.getTopLanesIssues(filter);
  }

  topIssuesByType(filter: any): Observable<any> {
    return this._reports.getTopIssuesByType(filter);
  }

  dashboardStats(filter: any): Observable<DashboardChartsData> {
    return this._reports.getDashboard(filter);
  }
}
