import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-accordion',
  templateUrl: 'accordion.component.html',
  styleUrls: ['accordion.component.scss'],
  animations: [trigger('fadeIn', [state('in', style({ opacity: 1 })), transition('void => *', [style({ opacity: 0 }), animate(500)])])],
})
export class AccordionComponent {
  _editingMode = false;
  @Input() isOpen?: boolean;
  @Input() mainButtonShow?: boolean;
  @Input() mainButtonText?: string;
  @Input() mainButtonCustomIcon?: string;
  @Input() secondButtonShow?: boolean;
  @Input() secondButtonText?: string;
  @Input() secondButtonCustomIcon?: string;
  @Input() saveWithOutCancelEdit?: boolean;
  @Input() editModeShow?: boolean;
  @Input() busy?: boolean;
  @Input() title: string;
  @Input() badges: string;
  @Input() set editingMode(flag: boolean) {
    this._editingMode = flag;
  }
  @Output() mainButtonClicked: EventEmitter<void> = new EventEmitter<void>();
  @Output() secondButtonClicked: EventEmitter<void> = new EventEmitter<void>();
  @Output() cancelBtnClicked: EventEmitter<void> = new EventEmitter<void>();
  @Output() saveBtnClicked: EventEmitter<void> = new EventEmitter<void>();
  @ViewChild('accordion') accordion?: ElementRef;
  @ViewChild('panel') panel?: ElementRef;
  constructor() {}

  togglePanel() {
    if (this.busy) {
      return;
    }
    this.accordion.nativeElement.classList.toggle('active');
    if (this.editModeShow && this.isOpen) {
      this.cancelBtnClick(null);
    }
    this.isOpen = !this.isOpen;
  }

  mainBtnClick(event: Event) {
    if (!this.checkButtonAvailability(event)) {
      return;
    }
    if (this.editModeShow) {
      this._editingMode = true;
      this.isOpen = true;
    }
    this.mainButtonClicked.emit();
  }

  secondBtnClick(event: Event) {
    if (!this.checkButtonAvailability(event)) {
      return;
    }
    this.secondButtonClicked.emit();
  }

  cancelBtnClick(event: Event) {
    if (!this.checkButtonAvailability(event)) {
      return;
    }
    if (this.editModeShow) {
      this._editingMode = false;
    }
    this.cancelBtnClicked.emit();
  }

  saveBtnClick(event: Event) {
    if (this.saveWithOutCancelEdit) {
      event.stopPropagation();
      event.preventDefault();
    }
    if (!this.saveWithOutCancelEdit && this.editModeShow) {
      this._editingMode = false;
    }
    this.saveBtnClicked.emit();
  }

  private checkButtonAvailability(event: Event): boolean {
    if (event) {
      event.stopPropagation();
    }

    return !this.busy;
  }
}
