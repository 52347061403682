import { Observable, BehaviorSubject, of } from 'rxjs';
import { tap, map, filter, flatMap, first } from 'rxjs/operators';

import {
  IConversation,
  IConversationParameters,
  ConversationState,
  IOutgoingConversation,
  ConversationBase,
  SubmissionParameters,
} from '../../../abstractions';

export class FakeConversation extends ConversationBase implements IConversation {
  private _state = new BehaviorSubject<ConversationState>('Incoming');
  private _isPaused = new BehaviorSubject<boolean>(false);

  private _acceptTime: Date;
  private _hangupTime: Date;
  private _wrapupTime: Date;

  private _parameters: IConversationParameters;

  public callId: string;
  public sessionId: string;

  public test = true;

  public resolving = false;
  public _isCancelled = false;
  public skipSavingIncident = false;

  public get agentSip(): string {
    return this._agentSip;
  }

  public get agentName(): string {
    return this._agentSip;
  }

  public get callSource(): string {
    return 'none';
  }

  public get callDuration(): number {
    if (this._acceptTime && this._hangupTime) {
      let res = (+this._hangupTime - +this._acceptTime) / 1000;
      return Math.round(res);
    }

    return 0;
  }

  public get wrapupDuration(): number {
    if (this._hangupTime && this._wrapupTime) {
      let res = (+this._wrapupTime - +this._hangupTime) / 1000;
      return Math.round(res);
    }

    return 0;
  }

  public get queueDuration(): number {
    return 0;
  }

  get cancelled(): boolean {
    return this._isCancelled;
  }

  public get canAccept(): boolean {
    return this._state.value === 'Incoming';
  }

  public get state$(): Observable<ConversationState> {
    return this._state.asObservable();
  }

  public get isPaused$(): Observable<boolean> {
    return this._isPaused.asObservable();
  }

  public get callerUri(): string {
    return this._uri;
  }

  constructor(
    private _uri: string,
    private _agentSip: string,
    skipSavingincIdent: boolean,
  ) {
    super();
    this.skipSavingIncident = skipSavingincIdent;
  }

  public static Parse(content: string): IConversation {
    let parsed = JSON.parse(content);
    const conversation = new FakeConversation(parsed.uri, parsed.agentSip, parsed.skipSavingIncident);
    conversation._state = new BehaviorSubject<ConversationState>(parsed.state);
    return conversation;
  }

  public setParameters(parameters: IConversationParameters) {
    this._parameters = parameters;
  }

  public accept(): Observable<boolean> {
    this._state.next('Accepted');

    this._acceptTime = new Date();

    return of(true);
  }

  public reject(): Observable<boolean> {
    this._state.next('Finished');
    return of(true);
  }

  public hangUp(): Observable<boolean> {
    this._state.next('HangedUp');
    this._hangupTime = new Date();

    if (this._isCancelled) {
      return this.wrapUp();
    }

    return of(true);
  }

  public wrapUp(): Observable<boolean> {
    if (this._state.getValue() === 'Accepted') {
      this.hangUp();
    }

    this._state.next('Finished');

    this._wrapupTime = new Date();

    return of(true);
  }

  public submitWrapUp(parameters: SubmissionParameters): Observable<boolean> {
    return of(true);
  }

  public cancelProcessing(): Observable<boolean> {
    this._isCancelled = true;

    return this.wrapUp();
  }
  public markCancelled() {
    this._isCancelled = true;
  }

  sendDtmf(dtmf: string): Observable<boolean> {
    console.log('Dtmf tone sent: ', dtmf);
    return of(true);
  }

  pause(): Observable<boolean> {
    this._isPaused.next(true);
    return of(true);
  }
  resume(): Observable<boolean> {
    this._isPaused.next(false);
    return of(true);
  }

  outboundCall(uri: string): Observable<IOutgoingConversation> {
    return of(null);
  }

  public stringifyAndReset(): string {
    let state = this._state.value;
    this._state = null;
    return JSON.stringify({ state: state, uri: this._uri, skipSavingIncident: this.skipSavingIncident });
  }

  private getActivity() {
    let lotName = this._parameters ? this._parameters.lotName : 'test lot';
    return `Completing - ${lotName}`;
  }
}
