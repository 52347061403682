import { Injectable } from '@angular/core';

import { Observable, BehaviorSubject } from 'rxjs';

import { State, CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { tap, map } from 'rxjs/operators';

import { GridDataResult } from '@progress/kendo-angular-grid';
import { Params } from '@angular/router';

import * as moment from 'moment';

import { IncidentsQualityAnalysisService } from '@libs/portal-common/services';
import { AppNotificationsService } from '@libs/portal-common/system';

const EMPTY_RESULT: GridDataResult = {
  data: [],
  total: 0,
};

export class QualityAnalysisFilter {
  start: Date = new Date(new Date().setHours(0, 0, 0, 0));
  end: Date = new Date(new Date().setHours(23, 59, 0, 0));

  contactId?: number;
  incidentId?: number;

  static FromParams(params: Params): QualityAnalysisFilter {
    let result = new QualityAnalysisFilter();

    let start = params['start'],
      end = params['end'],
      contactId = params['contactId'],
      incidentId = params['incidentId'];

    if (!!start) {
      result.start = moment(start, 'YYYYMMDDHHmm').toDate();
    }

    if (!!end) {
      result.end = moment(end, 'YYYYMMDDHHmm').toDate();
    }

    result.contactId = !!contactId ? +contactId : null;

    result.incidentId = !!incidentId ? +incidentId : null;

    return result;
  }

  toParams(): Params {
    let result = {};

    if (this.start) {
      result['start'] = moment(this.start).format('YYYYMMDDHHmm');
    }

    if (this.end) {
      result['end'] = moment(this.end).format('YYYYMMDDHHmm');
    }

    result['contactId'] = this.contactId;

    result['incidentId'] = this.incidentId;

    return result;
  }
}

@Injectable()
export class QualityAnalysisService extends BehaviorSubject<GridDataResult> {
  public busy$ = new BehaviorSubject(false);

  constructor(
    private incidentsQAService: IncidentsQualityAnalysisService,
    private notifications: AppNotificationsService,
  ) {
    super(null);
  }

  public query(filter: QualityAnalysisFilter, state: State) {
    state.sort = state.sort.filter((sortDescriptor) => sortDescriptor.dir === 'asc' || sortDescriptor.dir === 'desc');

    this.busy$.next(true);

    this.getQA(filter, state).subscribe(
      (x) => {
        this.busy$.next(false);
        super.next(x);
      },
      (err) => {
        this.busy$.next(false);
        super.next(EMPTY_RESULT);
        this.notifications.error(err);
      },
    );
  }

  public clear(): void {
    super.next(EMPTY_RESULT);
  }

  getQA(filter: QualityAnalysisFilter, state: State): Observable<GridDataResult> {
    let qaFilter = this.constructFilter(filter);

    let params = {
      group: state.group,
      filter: <CompositeFilterDescriptor>{
        logic: 'and',
        filters: [qaFilter],
      },
      skip: state.skip,
      limit: state.take,
      sort: state.sort,
    };

    if (state.filter.filters.length > 0) {
      params.filter.filters.push(state.filter);
    }

    return this.incidentsQAService.getAllPost(null, params).pipe(
      tap((response) => {
        response.Data.forEach((element) => {
          element.IncidentQualityAnalysisAnswersDict = element.IncidentQualityAnalysisAnswers.reduce(
            (a, x) => ({ ...a, [x.fkQualityAnalysisQuestion]: x.Answer }),
            {},
          );
        });
        this.notifications.checkResponse(response);
      }),
      map(
        (response) =>
          <GridDataResult>{
            data: response.Groups || response.Data || [],
            total: response.Total,
          },
      ),
    );
  }

  private constructFilter(filter: QualityAnalysisFilter): CompositeFilterDescriptor {
    let qaFilter: CompositeFilterDescriptor = {
      logic: 'and',
      filters: [],
    };

    if (filter.incidentId) {
      qaFilter.filters.push({ field: 'fkIncident', operator: 'eq', value: filter.incidentId });
      return qaFilter;
    }

    if (filter.start) {
      qaFilter.filters.push({ field: 'Created', operator: 'gt', value: filter.start });
    }

    if (filter.end) {
      qaFilter.filters.push({ field: 'Created', operator: 'lt', value: filter.end });
    }

    if (filter.contactId) {
      qaFilter.filters.push({ field: 'fkAgent', operator: 'eq', value: filter.contactId });
    }

    return qaFilter;
  }
}
