import { Injectable, Inject, Injector } from '@angular/core';

import { GlobalErrorHandler } from '@libs/portal-common/services';
import { AgentAnalyticsEvent } from './analytics.service';

@Injectable()
export class AgentErrorHandler extends GlobalErrorHandler {
  constructor(@Inject(Injector) injector: Injector) {
    super(injector);
  }

  handleError(error: Error) {
    this.analytics.track(AgentAnalyticsEvent.Exception, null);
    super.handleError(error);
  }
}
