<div
  class="modal fade"
  bsModal
  #modal="bs-modal"
  tabindex="-1"
  role="dialog"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header clearfix">
        <h4 class="modal-title pull-left">
          Transcription of a conversation with a voice bot
        </h4>
        <button
          type="button"
          class="close pull-right"
          aria-label="Close"
          (click)="cancel()"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body">
        <div class="row justify-content-center">
          <div class="input-icons">
            <i class="fa fa-search icon"> </i>
            <input class="input-field" type="text" placeholder="Search" [(ngModel)]="searchString" />
          </div>
        </div>
        <div
          *ngFor="let callSegment of callSegmentList | filter:'Message':searchString"
          class="row justify-content-center"
        >
          <div class="col-2">
            <div class="timeline"></div>
            <div>
              <div class="timelinecircle"></div>
              <div class="timelinetime">{{ callSegment.Time }}</div>
            </div>
          </div>
          <div class="col-10">
            <div
              [ngClass]="{
                'row bot-message-container': callSegment.IsBot,
                'row human-message-container': !callSegment.IsBot
              }"
            >
              <span
                [ngClass]="{
                  'bot-message': callSegment.IsBot,
                  'human-message': !callSegment.IsBot
                }"
                >{{ callSegment.Message }}</span
              >
              <div *ngIf="callSegment.IsBot" class="avatar-base-box"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
