import { Component, OnInit, ViewChild } from '@angular/core';

import { Observable, Observer } from 'rxjs';

import { ModalDirective } from 'ngx-bootstrap/modal';

import { RevenueControlService } from '@libs/portal-common/services';
import { AppNotificationsService } from '@libs/portal-common/system';

import { IncomingCall, RevenueControlManufacturer } from '../../../../abstractions';
import { RevenueControlConfirmationComponent } from '../revenue-control-confirmation/revenue-control-confirmation.component';

import { RevenueFormFactoryService } from '../revenue-forms/revenue-form-factory.service';
import { RevenueControlFormComponent } from '../revenue-control-form/revenue-control-form.component';

@Component({
  selector: 'app-revenue-control-selector',
  templateUrl: './revenue-control-selector.component.html',
  styleUrls: ['./revenue-control-selector.component.scss'],
})
export class RevenueControlSelectorComponent implements OnInit {
  @ViewChild('modal', { static: true }) public modal: ModalDirective;

  @ViewChild('revenueControlConfirmation', { static: true }) revenueControlConfirmation: RevenueControlConfirmationComponent;
  @ViewChild('revenueControlForm', { static: true }) revenueControlForm: RevenueControlFormComponent;

  manufacturer: RevenueControlManufacturer = null;
  incomingCall: IncomingCall;
  locationId: number = null;
  laneId: number = null;

  resultObserver: Observer<any>;

  constructor(
    private revenueControl: RevenueControlService,
    private notifications: AppNotificationsService,
    private revenueFactory: RevenueFormFactoryService,
  ) {}

  ngOnInit() {}

  open(manufacturer: RevenueControlManufacturer, incomingCall: IncomingCall): Observable<any> {
    this.manufacturer = manufacturer;
    this.locationId = incomingCall.location.Id;
    this.laneId = incomingCall.lane.Id;
    this.incomingCall = incomingCall;

    this.modal.show();

    return new Observable((observer) => (this.resultObserver = observer));
  }

  close() {
    this.modal.hide();
    this.resultObserver.next(null);
    this.resultObserver.complete();
  }

  openGate() {
    const revenueForm = this.revenueFactory.openGateForm(this.manufacturer.Name);
    this.revenueControlForm.open(revenueForm, this.incomingCall);
  }

  closeGate() {
    const revenueForm = this.revenueFactory.closeGateForm(this.manufacturer.Name);
    this.revenueControlForm.open(revenueForm, this.incomingCall);
  }

  setPrice() {
    const revenueForm = this.revenueFactory.setPriceTicketForm(this.manufacturer.Name);
    this.revenueControlForm.open(revenueForm, this.incomingCall);
  }

  ejectTicket() {
    const revenueForm = this.revenueFactory.ejectTicketForm(this.manufacturer.Name);
    this.revenueControlForm.open(revenueForm, this.incomingCall);
  }

  swallowTicket() {
    const revenueForm = this.revenueFactory.swallowTicketForm(this.manufacturer.Name);
    this.revenueControlForm.open(revenueForm, this.incomingCall);
  }

  showTicketInfo() {
    const revenueForm = this.revenueFactory.ticketInfoForm(this.manufacturer.Name);
    this.revenueControlForm.open(revenueForm, this.incomingCall);
  }

  setLostTicket() {
    const revenueForm = this.revenueFactory.lostTicketForm(this.manufacturer.Name);
    this.revenueControlForm.open(revenueForm, this.incomingCall);
  }

  validateTicket() {
    const revenueForm = this.revenueFactory.validateTicketForm(this.manufacturer.Name);
    this.revenueControlForm.open(revenueForm, this.incomingCall);
  }

  executeAction() {
    const revenueForm = this.revenueFactory.executeActionForm(this.manufacturer.Name);
    this.revenueControlForm.open(revenueForm, this.incomingCall);
  }

  canDo(actionName: string) {
    return this.manufacturer && this.manufacturer.IsIntegrated && this.manufacturer[actionName];
  }

  canExecuteAction(): boolean {
    if (!this.manufacturer) {
      return false;
    }

    if (!this.manufacturer.CanExecuteAction) {
      return false;
    }

    const lane: number = (<any>this.manufacturer).Lane;
    return lane > 0;
  }
}
