import { IPowerAutomateIntegration } from '../services';

export class PowerAutomateIntegrationsSelector {
  private _selectedJson = '[]';

  public get selected(): Array<number> {
    return JSON.parse(this._selectedJson || '[]').map((x) => x.Id);
  }

  public set selected(value: Array<number>) {
    this._selectedJson = JSON.stringify(value.map((item) => ({ Id: item })));
  }

  public get available(): Array<IPowerAutomateIntegration> {
    return this._integrations.filter((x) => !this.selected.find((s) => s === x.Id));
  }

  public itemDisabled(itemArgs: { dataItem: any; index: number }): boolean {
    return !itemArgs.dataItem;
  }

  public get selectedIntegrations(): Array<IPowerAutomateIntegration> {
    return this._integrations.filter((x) => this.selected.find((s) => s === x.Id));
  }

  constructor(
    selectedJson: string,
    private _integrations: Array<IPowerAutomateIntegration>,
  ) {
    this._selectedJson = `${selectedJson || '[]'}`;
  }

  public remove(id: number) {
    this.selected = this.selected.filter((x) => x !== id);
  }

  public add(id: number) {
    this.selected = this.selected.concat([id]);
  }

  public getSelectedJson(): string {
    return this._selectedJson;
  }
}
