<div class="grid-container grid-page">
  <div class="filter-container filter row">
    <div class="filter-group form-group">
      <label>From</label>
      <kendo-datetimepicker class="umj-datepicker-sm" [readOnlyInput]='true' [readonly]="disabled" [(value)]="filter.Start"> </kendo-datetimepicker>
    </div>
    <div class="filter-group form-group">
      <label>To</label>
      <kendo-datetimepicker class="umj-datepicker-sm" [readOnlyInput]='true' [readonly]="disabled" [(value)]="filter.End" style="width: 100%">
      </kendo-datetimepicker>
    </div>
    <div class="filter-group form-group">
      <label>Day of week</label>
      <kendo-dropdownlist
        [data]="days"
        [(value)]="filter.DayOfWeek"
        [filterable]="true"
        (filterChange)="handleDaysFilter($event)"
        [textField]="'Name'"
        [valueField]="'Id'"
        [defaultItem]="{ Id: null, Name: 'Select day...' }"
        style="width: 100%"
        [disabled]="disabled"
        [valuePrimitive]="true"
        class="form-control-sm"
      >
      </kendo-dropdownlist>
    </div>
    <div class="filter-group form-group" *ngIf="displayLocations">
      <label>Location</label>
      <kendo-dropdownlist
        [data]="filteredLocations"
        [(value)]="filter.LotId"
        [filterable]="true"
        (filterChange)="handleLocationsFilter($event)"
        [textField]="'LotName'"
        [valueField]="'Id'"
        [defaultItem]="{ Id: null, LotName: 'Select location...' }"
        style="width: 100%"
        [disabled]="disabled"
        [valuePrimitive]="true"
        class="form-control-sm"
      >
      </kendo-dropdownlist>
    </div>

    <fieldset>
      <legend class="duration-legend"><label>Minimum duration (sec)</label></legend>
      <div class="row">
        <div class="col-sm-3 form-group">
          <input
            placeholder="Queue"
            type="number"
            class="form-control form-control-sm"
            id="MinQueueTime"
            name="MinQueueTime"
            [(ngModel)]="filter.MinQueueTime"
          />
        </div>
        <div class="col-sm-3 form-group">
          <input
            placeholder="Call"
            type="number"
            class="form-control form-control-sm"
            id="MinCallTime"
            name="MinCallTime"
            [(ngModel)]="filter.MinCallTime"
          />
        </div>
        <div class="col-sm-3 form-group">
          <input
            placeholder="Wrapup"
            type="number"
            class="form-control form-control-sm"
            id="MinWrapUpTime"
            name="MinWrapUpTime"
            [(ngModel)]="filter.MinWrapUpTime"
          />
        </div>
        <div class="col-sm-3 form-group">
          <input
            placeholder="Total"
            type="number"
            class="form-control form-control-sm"
            id="MinTotalDuration"
            name="MinTotalDuration"
            [(ngModel)]="filter.MinTotalDuration"
          />
        </div>
      </div>
    </fieldset>

    <div class="filter-group form-group">
      <label>Interval</label>
      <kendo-dropdownlist
        [data]="intervals"
        [(value)]="filter.Interval"
        [textField]="'name'"
        [valueField]="'minutes'"
        [disabled]="disabled"
        [valuePrimitive]="true"
        class="form-control-sm"
      >
      </kendo-dropdownlist>
    </div>

    <div class="form-group col-sm-1">
      <button class="button ND--buttons--primary btn-search" [disabled]="disabled" (click)="search()">Select</button>
    </div>
  </div>

  <div class="heatmap-toolbar">
    <button k-button (click)="heatmap.csvExport()" class="k-button k-button-icontext hidden-on-narrow" [disabled]="disabled" tabindex="0">
      <span class="k-icon k-i-excel"></span>Export to CSV
    </button>
    <button k-button (click)="heatmap.excelExport()" class="k-button k-button-icontext hidden-on-narrow" [disabled]="disabled" tabindex="0">
      <span class="k-icon k-i-excel"></span>Export to Excel
    </button>
    <button k-button (click)="heatmap.saveAsPdf()" class="k-button k-button-icontext hidden-on-narrow" [disabled]="disabled" tabindex="0">
      <span class="k-icon k-i-pdf"></span>Export to PDF
    </button>
  </div>

  <app-heatmap #heatmap [filter]="filter" (busy)="disabled = $event"></app-heatmap>
</div>
