import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter',
})
export class FilterPipe implements PipeTransform {
  transform(items: any[], fieldName: string | Array<string>, value: string): any[] {
    if (!items) {
      return [];
    }

    if (!value) {
      return items;
    }

    let fields = fieldName instanceof Array ? <Array<string>>fieldName : [fieldName];
    let lowerCaseVal = value.toLowerCase();

    return items.filter((it) => {
      return !!fields.find((f) => {
        let field = it[f];
        return field && field.toLowerCase().indexOf(lowerCaseVal) > -1;
      });
    });
  }
}
