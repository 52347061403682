import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { GetVoiceBotRequest, AddOrUpdateVoiceBotRequest, DeleteVoiceBotRequest } from '../models/rest/voice-bots/voiceBotsRequests';
import { BotEventByConferenceIdResponse, BotEventDetailsResponse, VoiceBotResponse } from '../models/rest/voice-bots/voiceBotResponses';
import { BaseResponse, IRestBaseListResponse } from '../models/rest/baseResponse';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { VoiceBot } from '../models/voiceBot';

@Injectable({ providedIn: 'root' })
export class VoiceBotsService {
  constructor(private _httpClient: HttpClient) {}

  getAll(): Observable<IRestBaseListResponse<VoiceBot>> {
    return this._httpClient.get<IRestBaseListResponse<VoiceBot>>('VoiceBot').pipe(map((response) => response));
  }

  getVoiceBot(request: GetVoiceBotRequest): Observable<VoiceBotResponse> {
    return this._httpClient.get<VoiceBotResponse>('VoiceBot/' + request.Id);
  }

  addOrUpdateVoiceBot(request: AddOrUpdateVoiceBotRequest): Observable<VoiceBotResponse> {
    return this._httpClient.put<VoiceBotResponse>('VoiceBot', request);
  }

  deleteVoiceBot(request: DeleteVoiceBotRequest): Observable<BaseResponse> {
    return this._httpClient.delete<BaseResponse>('VoiceBot/' + request.Id);
  }

  getBotEvent(botEventConferenceId: string): Observable<BotEventByConferenceIdResponse> {
    let headers = new HttpHeaders();
    headers.append('Accept', 'application/octet-stream');

    return this._httpClient.get<BotEventByConferenceIdResponse>('BotEvent/' + botEventConferenceId);
  }

  getBotEventDetails(incidentId: number, botEventConferenceId: string): Observable<BotEventDetailsResponse> {
    let headers = new HttpHeaders();
    headers.append('Accept', 'application/octet-stream');

    return this._httpClient.get<BotEventDetailsResponse>(`BotEvent/${botEventConferenceId}/Details?incidentId=${incidentId}`);
  }
}
