<form #form="ngForm" *ngIf="initialized" class="modal-form" novalidate>
  <!-- div class="form-group" [class.has-error]="ticketField | hasErrors: validating">
        <div class="validation-container">
            <input class="form-control" type="text" [(ngModel)]="ticketNum" name="ticketNum" #ticketField="ngModel"
                required placeholder="Ticket number">

            <app-validation-errors [model]="ticketField" [validate]="validating"></app-validation-errors>
        </div>
    </div-->

  <div class="form-group" [class.has-error]="priceField | hasErrors: validating">
    <div class="validation-container">
      <!-- <kendo-numerictextbox class="price" [format]="'c2'" [min]="0" [step]="1" [(ngModel)]="price" name="price" #priceField="ngModel"
          required>
        </kendo-numerictextbox> -->
      <input class="form-control" type="text" [(ngModel)]="price" name="price" #priceField="ngModel" required placeholder="Ticket cost" />

      <app-validation-errors [model]="priceField" [validate]="validating"></app-validation-errors>
    </div>
  </div>
</form>
