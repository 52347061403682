<div class="modal fade" bsModal #modal="bs-modal" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header clearfix">
        <h4 class="modal-title pull-left">Monitoring page settings</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="cancel()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body">
        <app-monitoring-settings-editor
          #editor
          *ngIf="!!model"
          zone="portal"
          maxSelected="12"
          columns="3"
          [selected]="model"
          contentClass=""
        >
        </app-monitoring-settings-editor>
      </div>

      <div class="modal-footer" style="padding: 0.5rem">
        <button class="button ND--buttons--second-button" (click)="restoreDefaults()">Restore defaults</button>
        <button class="button ND--buttons--second-button" (click)="cancel()"><i class="fa fa-ban"></i> Cancel</button>
        <button class="button ND--buttons--primary" (click)="save()"><i class="fa fa-check"></i> Save</button>
      </div>
    </div>
  </div>
</div>
