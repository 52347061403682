<div class="card1">
  <div class="card-header">
    <ul class="nav nav-justified">
      <li
        class="nav-item"
        *ngFor="let step of steps"
        [ngClass]="{ active: step.isActive, enabled: !step.isDisabled, disabled: step.isDisabled, completed: completed }"
      >
        <a (click)="goToStep(step)"
          >{{ step.title }}
          <i class="fa fa-exclamation invalid" [hidden]="step.isValid"></i>
          <i class="fa fa-question incompleted" [hidden]="!step.showWarning"></i>
          <i class="fa fa-check" [hidden]="!step.isValid || !step.completed"></i>
        </a>
      </li>
    </ul>
  </div>
  <div class="card-block">
    <ng-content></ng-content>
  </div>
  <div class="card-footer" [hidden]="isCompleted">
    <button
      type="button"
      class="button btn-secondary float-left"
      (click)="previous()"
      [hidden]="!hasPrevStep || !activeStep.showPrev"
      [disabled]="busy"
    >
      <span class="icon-container">
        <i class="fa fa-angle-left"></i>
      </span>
      Previous
    </button>
    <button
      type="button"
      class="button btn-secondary float-right"
      (click)="next()"
      [disabled]="!activeStep.isValid || busy"
      [hidden]="!hasNextStep || !activeStep.showNext"
    >
      {{ nextButtonText }}
      <span class="icon-container">
        <i *ngIf="!busy" class="fa fa-angle-right"></i>
        <i *ngIf="busy" class="fa fa-spinner fa-spin"></i>
      </span>
    </button>
    <button
      type="button"
      class="button btn-secondary float-right"
      (click)="complete()"
      [disabled]="!activeStep.isValid || busy"
      [hidden]="hasNextStep"
    >
      <span class="icon-container">
        <i *ngIf="!busy" class="fa fa-check"></i>
        <i *ngIf="busy" class="fa fa-spinner fa-spin"></i>
      </span>
      Complete
    </button>
  </div>
</div>
