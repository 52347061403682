import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import {
  CreateVideoRecordsSettingsRequest,
  UpdateVideoRecordsSettingsRequest,
  DeleteVideoRecordsSettingsRequest,
  GetVideoRecordsSettingsRequest,
} from '../models/rest/video-records/videoRecordsRequests';
import { VideoRecordsSettingsResponse } from '../models/rest/video-records/videoRecordsResponses';
import { BaseResponse } from '../models/rest/baseResponse';
import { HttpClient, HttpParams } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class VideoRecordsService {
  constructor(private _httpClient: HttpClient) {}

  // Get incident's video record
  getByIncident(incidentId): Observable<any> {
    return this._httpClient.get(`/VideoRecordSettings/List?IncidentId=${incidentId}`);
  }

  getVideoRecordsSettingsList(regionId?: number): Observable<any> {
    let params = new HttpParams();
    if (!!regionId) {
      params = params.set('RegionId', regionId.toString());
    }

    return this._httpClient.get('/VideoRecordSettings/List', {
      params: params,
    });
  }

  getVideoRecordsSettings(request: GetVideoRecordsSettingsRequest): Observable<VideoRecordsSettingsResponse> {
    return this._httpClient.get<VideoRecordsSettingsResponse>('/VideoRecordSettings/' + request.Id);
  }

  createVideoRecordsSettings(request: CreateVideoRecordsSettingsRequest): Observable<VideoRecordsSettingsResponse> {
    return this._httpClient.post<VideoRecordsSettingsResponse>('/VideoRecordSettings', request);
  }

  updateVideoRecordsSettings(request: UpdateVideoRecordsSettingsRequest): Observable<VideoRecordsSettingsResponse> {
    return this._httpClient.put<VideoRecordsSettingsResponse>('/VideoRecordSettings', request);
  }

  deleteVideoRecordsSettings(request: DeleteVideoRecordsSettingsRequest): Observable<BaseResponse> {
    return this._httpClient.delete<BaseResponse>('/VideoRecordSettings/' + request.Id);
  }
}
