import { Component, OnInit, Input, OnDestroy, HostBinding, ViewChild, ElementRef, Inject } from '@angular/core';

import { ILocation, ILane, LocationsService, LotModel, IStreamSourceType } from '@libs/portal-common/services';

import { AppConfigurationService, APP_CONFIGURATION } from '../../../../services/app-configuration.service';

@Component({
  selector: 'app-dvr-video',
  templateUrl: './dvr-video.component.html',
  styleUrls: ['./dvr-video.component.scss'],
})
export class DvrVideoComponent implements OnInit, OnDestroy {
  @HostBinding('class') class = 'info-tab-content';
  @ViewChild('image', { static: false }) image: ElementRef;

  private _location: ILocation = null;
  private _lane: ILane = null;
  private dvrProxyUrl: string;

  source: string = null;

  @Input('location')
  set location(location: ILocation) {
    this._location = location;
    this.initPlayer();
  }

  @Input('lane')
  set lane(lane: ILane) {
    this._lane = lane;
    this.initPlayer();
  }

  constructor(
    @Inject(APP_CONFIGURATION) private configuration: AppConfigurationService,
    private locations: LocationsService,
  ) {
    let dvrProxy = configuration.data.paths.dvrProxy;
    dvrProxy = dvrProxy.trimRight();
    dvrProxy = dvrProxy.endsWith('/') ? dvrProxy.slice(0, -1) : dvrProxy;
    this.dvrProxyUrl = `${dvrProxy}/camera`;
  }

  ngOnInit() {}

  ngOnDestroy() {
    this.destroyImage();
  }

  private initPlayer() {
    if (!this._location || !this._lane) {
      this.destroyImage();
      return;
    }

    if (!!this.source) {
      this.source = this.dvrProxyUrl;
    }

    this.locations.getBy({ Id: this._location.Id }).subscribe((res) => {
      this.buildUrl(res.Lot, this._lane);
    });
  }

  private buildUrl(lot: LotModel, lane: ILane) {
    this.destroyImage();

    if (lane.StreamSourceType == IStreamSourceType.None) {
      return;
    }

    let cameraUrl: string = null;
    if (lane.StreamSourceType == IStreamSourceType.DVR) {
      if (!lot || !lane || !lot.DVRWebIP || !lot.DVRUserName || !lot.DVRPwd) return;

      let port = lot.DVRWebPort || 37777;
      let channel = !!lane.DVR_Channel ? lane.DVR_Channel : lane.Lane_Number;

      cameraUrl = `dahua://${lot.DVRUserName}:${lot.DVRPwd}@${lot.DVRWebIP}:${port}/cam/realmonitor?channel=${channel}&subtype=1`;
    } else if (lane.StreamSourceType == IStreamSourceType.RTSP) {
      let address = lane.RtspAddress.replace('rtsp://', '');
      cameraUrl = `rtsp://${lane.RtspUsername}:${lane.RtspPassword}@${address}`;
    } else {
      throw Error('Unknown protocol');
    }

    this.source = `${this.dvrProxyUrl}?url=${cameraUrl}`;
  }

  destroyImage() {
    if (!!this.image) {
      this.image.nativeElement.src = this.dvrProxyUrl;
    }

    this.source = null;
  }
}
