import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IRestBaseListResponse, BaseResponse, GeometryLatLon } from '../models';

export interface NoodoeLocationViewModel {
  LocationId: string;
  LocationName: string;
  LocationDisplayName: string;
}

export interface NoodoeSitesInfo extends BaseResponse {
  SiteId: string;
  Cpo: string;
  Name: string;
}

export interface NoodoeStation {
  StationID: string;
}

export interface NoodoeImagen {
  Url: string;
}

export interface NoodoeSite extends BaseResponse {
  SiteID: string;

  Name: string;

  Country: string;

  State: string;

  Address: string;

  Description: string;

  Geometry: GeometryLatLon;

  TimeZone: string;

  Images: Array<NoodoeImagen>;

  Stations: Array<NoodoeStation>;
}

export interface NoodoeSiteResponse extends BaseResponse {
  Site: NoodoeSite;
}

@Injectable({ providedIn: 'root' })
export class NoodoeService {
  constructor(private _httpClient: HttpClient) {}

  // Get all sites
  getSites(): Observable<IRestBaseListResponse<NoodoeSitesInfo>> {
    return this._httpClient.get<any>('noodoe/sites');
  }
  // Get site by Id
  getSiteById(siteId: string): Observable<NoodoeSiteResponse> {
    return this._httpClient.get<any>(`noodoe/sites/${siteId}`);
  }
}
