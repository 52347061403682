import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { TransferState, makeStateKey } from '@angular/platform-browser';

import { Observable, BehaviorSubject } from 'rxjs';

export class AuthHeader {
  constructor(
    public name: string,
    public value: string,
  ) {}
}

const AUTH_TOKEN = 'auth_token';
const USERNAME_TOKEN = 'username';

const keys = {};
keys[AUTH_TOKEN] = makeStateKey(AUTH_TOKEN);
keys[USERNAME_TOKEN] = makeStateKey(USERNAME_TOKEN);

@Injectable({ providedIn: 'root' })
export class AppStateService {
  private _authToken: string = null;
  private _username: string = null;

  private _isAuthenticated = new BehaviorSubject(false);

  get isAuthenticated$(): Observable<boolean> {
    return this._isAuthenticated;
  }

  get isAuthenticated(): boolean {
    return this._isAuthenticated.value;
  }
  get username(): string {
    return this._username;
  }

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private transferState: TransferState,
  ) {
    let authToken: string = this.getItem(AUTH_TOKEN);
    let username: string = this.getItem(USERNAME_TOKEN);

    if (!!username && !!authToken) {
      this._username = username;
      this._authToken = authToken;
      this._isAuthenticated.next(true);
    }
  }

  setAuthenticated(username: string, authToken: string) {
    this._authToken = authToken;
    this.setItem(USERNAME_TOKEN, username);
    this.setItem(AUTH_TOKEN, authToken);
    this._isAuthenticated.next(!!authToken);
  }

  getAuthHeader(): AuthHeader {
    if (this.isAuthenticated && !!this._authToken) {
      return new AuthHeader('X-ss-id', this._authToken);
    }

    return null;
  }

  getAuthToken(): string {
    if (this.isAuthenticated && !!this._authToken) {
      return this._authToken;
    }

    return null;
  }

  getAuthTokenParameter(): string {
    if (!!this._authToken) {
      return 'ss-id=' + this._authToken;
    }

    return null;
  }

  public clearAuthToken() {
    this._authToken = null;
    this._username = null;
    this.removeItem(USERNAME_TOKEN);
    this.removeItem(AUTH_TOKEN);
    this._isAuthenticated.next(false);
  }

  private getItem(name: string): string {
    let item: string = null;

    if (isPlatformBrowser(this.platformId)) {
      item = localStorage.getItem(name);
    }

    if (!item) {
      item = this.transferState.get(keys[name], null);
    }

    return item;
  }

  private setItem(name: string, value: string) {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.setItem(name, value);
    }
    this.transferState.set(keys[name], value);
  }

  private removeItem(name: string) {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.removeItem(name);
    }
    this.transferState.remove(keys[name]);
  }
}
