import { Component, OnInit, HostBinding, ViewChild, EventEmitter, Output } from '@angular/core';

import { Observable, Observer } from 'rxjs';

import { ModalDirective } from 'ngx-bootstrap/modal';

import { IActivityGridState, GridStateService, IDynamicField, DynamicFieldComparer } from '../services/grid-state.service';

@Component({
  selector: 'app-activity-grid-fields-picker',
  templateUrl: './activity-grid-fields-picker.component.html',
  styleUrls: ['./activity-grid-fields-picker.component.scss'],
})
export class ActivityGridFieldsPickerComponent implements OnInit {
  @ViewChild('fieldsPickerModal', { static: true }) public fieldsPickerModal: ModalDirective;

  resultObserver: Observer<IActivityGridState>;

  busy = false;

  model = {
    selectedFields: new Array<IDynamicField>(),
    availableFields: new Array<IDynamicField>(),
  };

  constructor(private gridStateService: GridStateService) {}

  ngOnInit() {}

  open(): Observable<IActivityGridState> {
    this.gridStateService.getState().subscribe((state) => {
      this.model = this.createModel(state);
    });

    this.fieldsPickerModal.show();
    return new Observable((observer) => (this.resultObserver = observer));
  }

  toggleField(field: IDynamicField) {
    if (field.isSelected) {
      field.isSelected = false;
      this.model.selectedFields = this.model.selectedFields.filter((x) => x.fieldName !== field.fieldName);
      this.model.availableFields = [...this.model.availableFields, field];
    } else {
      field.isSelected = true;
      this.model.selectedFields = [...this.model.selectedFields, field];
      this.model.availableFields = this.model.availableFields.filter((x) => x.fieldName !== field.fieldName);
    }
  }

  submit() {
    this.model.selectedFields.forEach((field, idx) => {
      field.order = idx;
    });

    let result = { dynamicFields: this.model.selectedFields };

    this.gridStateService.saveState(result);
    this.resultObserver.next(result);
    this.resultObserver.complete();
    this.fieldsPickerModal.hide();
  }

  reset() {
    this.gridStateService.resetState();

    this.gridStateService.getState().subscribe((state) => {
      this.model = this.createModel(state);
    });
  }

  cancel() {
    this.resultObserver.next(null);
    this.resultObserver.complete();
    this.fieldsPickerModal.hide();
  }

  private createModel(state: IActivityGridState): { selectedFields: Array<IDynamicField>; availableFields: Array<IDynamicField> } {
    return {
      selectedFields: state.dynamicFields.filter((x) => x.isSelected).sort(DynamicFieldComparer),
      availableFields: state.dynamicFields.filter((x) => !x.isSelected),
    };
  }
}
