import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { HttpClient } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class SignalRRestClientService {
  agentsUrl = 'grmhub/agents/';
  servicesUrl = 'grmhub/services/';
  portalsUrl = 'grmhub/portals/';

  constructor(private _httpClient: HttpClient) {}

  // Get connected agents
  getAgents(): Observable<any> {
    return this._httpClient.get<any>(this.agentsUrl);
  }

  // Get connected services
  getServices(): Observable<any> {
    return this._httpClient.get<any>(this.servicesUrl);
  }

  // Get connected portals
  getPortals(): Observable<any> {
    return this._httpClient.get<any>(this.portalsUrl);
  }
}
