import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthenticatedGuard, PermissionsGuard } from '@libs/portal-common/services';

import { HomeComponent } from './home/home.component';
import { RedirectGuard } from './services';

const appRoutes: Routes = [
  { path: 'home', component: HomeComponent },

  {
    path: 'activity',
    loadChildren: () => import('@libs/portal-common/activity').then((m) => m.ActivityModule),
    canActivate: [AuthenticatedGuard, PermissionsGuard],
    data: { requiredPermissions: { entities: ['Incidents'], access: 'CanRead' } },
  },
  {
    path: 'call',
    loadChildren: () => import('./skype-client/skype-client.module').then((m) => m.SkypeClientModule),
    canActivate: [AuthenticatedGuard],
  },

  {
    path: 'auth',
    loadChildren: () => import('@libs/portal-common/authentication').then((m) => m.AuthenticationModule),
  },

  { path: '', children: [], canActivate: [RedirectGuard], pathMatch: 'full' },
  { path: '**', children: [], canActivate: [RedirectGuard] },
];

@NgModule({
  imports: [RouterModule, RouterModule.forRoot(appRoutes, { useHash: false, relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
