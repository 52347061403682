import { Injectable, Inject } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';

import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { AuthService } from '../services/access-management/auth.service';
import { AppConfigurationService, APP_CONFIGURATION, IAppConfigurationBase } from '../services/app-configuration.service';

import { AppNotificationsService } from '@libs/portal-common/system';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  defaultMessageForCustomers = 'Oops! We encountered an internal server error. Please try again later.';
  defaultConsoleLogStyles = 'font-size: 12px; color: black; background-color: yellow';

  constructor(
    private notifications: AppNotificationsService,
    @Inject(APP_CONFIGURATION) private configuration: AppConfigurationService<IAppConfigurationBase>,
    private authService: AuthService,
    private router: Router,
  ) {}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err) => this.catchAuthError(err, next)),
      map(this.checkAuth),
    );
  }

  private catchAuthError = (response: HttpResponse<any>, next: HttpHandler) => {
    let error: HttpErrorResponse = null;

    if (response instanceof HttpErrorResponse) {
      error = <HttpErrorResponse>response;
    } else {
      return throwError(response);
    }

    let urlString = error.url;
    urlString = urlString.replace(this.configuration.apiBaseUrl, '');

    if (error.status === 0) {
      this.notifications.apiError(`Service unavailable (Status 0). Resource: '${urlString}'`, null);
    }

    if (error.status === 503) {
      this.notifications.apiError(`Service unavailable. Resource: '${urlString}'`, null);
    }

    if (error.status === 401) {
      // if not authenticated
      this.authService.logout().then(() => {
        this.router.navigate(['/auth/login'], { queryParams: { returnUrl: this.router.url } });
      });
    }

    if (error.status === 404) {
      this.notifications.error(`Resource '${urlString}' not found.`);
    }

    if (error.status >= 400 && error.status < 500 && error.status !== 404 && error.status !== 401) {
      this.extractErrorMessage(error);
    }

    let traceId: string = response.error?.TraceId;
    const traceIdSections = traceId?.split('-') || [];
    traceId = traceIdSections[1] || traceIdSections[0] || null;

    if (error.status >= 500 && error.status !== 503) {
      this.notifications.apiError(this.defaultMessageForCustomers, traceId);
    }

    this.postErrorInConsoleLog(error, traceId);
    return throwError(response);
  };

  private extractErrorMessage(error: any): void {
    if (!error) {
      return;
    }

    if (typeof error.error === 'string' && !!error.error) {
      this.notifications.error(error);
      return;
    }

    if (error.error.Message) {
      this.notifications.error(error.error.Message);
      return;
    }

    if (error.statusText) {
      this.notifications.error(error.statusText);
      return;
    }

    if (error.error.Errors && error.error.Errors.length > 0) {
      let messages: string = error.error.Errors.map((err, index) => {
        return `${index + 1}. ${err.PropertyName ?? err.PropertyName}: ${err.ErrorMessage ?? err.ErrorMessage} \n`;
      })
        .filter((x) => !!x)
        .join('');
      this.notifications.error(messages);
    }
  }

  private checkAuth = (res: HttpResponse<any>): HttpResponse<any> => {
    if (!!res && !!res.body && !res.body.Success && res.body.ErrorCode * 1 === 401) {
      console.log(res);

      this.authService.logout().then(() => {
        this.router.navigate(['/login']);
      });

      throw new Error(res.body.Message || 'Unauthorized');
    }

    return res;
  };

  private postErrorInConsoleLog(errorResponse: HttpErrorResponse, traceId: string = null): void {
    console.log('%cAttention! HttpError Information: ', this.defaultConsoleLogStyles);
    if (traceId) {
      console.log(errorResponse, 'Trace ID', traceId);
    } else {
      console.log(errorResponse);
    }
    console.log('%cError Message: ' + errorResponse.message, this.defaultConsoleLogStyles);
  }
}
