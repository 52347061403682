import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { HttpClient } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class FilesService {
  constructor(private _httpClient: HttpClient) {}

  getDirectory(path): Observable<any> {
    return this._httpClient.request<any>('get', 'files', {
      body: { Path: '/Locations/2220', ForDownload: false },
    });
  }

  uploadFile(path): Observable<any> {
    return this._httpClient.post<any>('files', { Path: '/2220', ForDownload: false });
  }
}
