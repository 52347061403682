import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { BehaviorSubject, Observable, of, Subject, zip } from 'rxjs';

import { AppNotificationsService } from '@libs/portal-common';

import { CallsMapType, IConversation, IIntegrationAppService, IOutgoingConversation } from '../../../abstractions';

import { AppConfigurationService, APP_CONFIGURATION } from '../../../services/app-configuration.service';
import { Five9Conversation } from '../five9-conversation';

import { AppWebSocket } from '../../app-web-socket';

import { first, mergeMap, tap, map, shareReplay, catchError, flatMap } from 'rxjs/operators';
import { Five9StateService } from './five9-state.service';
import { Five9HttpClient } from './five9-httpclient';
import { AgentPresence, Interaction, Prompt, Message, IReasonCodeInfo, Disposition, CallVariableInfo, CallInfo, Metadata } from '../model';

export interface FinishedConversation {
  sessionId: string;
  campaignId: string;
}

@Injectable()
export class Five9AppService implements IIntegrationAppService {
  private five9WebSocket: AppWebSocket;
  private prompts: Observable<Array<Prompt>>;
  private _calls$ = new BehaviorSubject<CallsMapType>({});
  private metadata: Metadata = null;
  private notReadyReasons = new Array<IReasonCodeInfo>();
  private umojoCallVariables = new Array<CallVariableInfo>();

  public get name(): string {
    return 'Five9';
  }

  public get calls$(): Observable<CallsMapType> {
    return this._calls$.asObservable();
  }

  constructor(
    @Inject(PLATFORM_ID) platformId: Object,
    private five9State: Five9StateService,
    private five9HttpClient: Five9HttpClient,
    @Inject(APP_CONFIGURATION) private configuration: AppConfigurationService,
    private notifications: AppNotificationsService,
  ) {
    if (isPlatformBrowser(platformId)) {
      this.initialize();
    }
  }

  public get isAuthenticated$(): Observable<boolean> {
    return of(true);
  }
  public get initialized$(): Observable<boolean> {
    return of(true);
  }

  public get outgoingCall$(): Observable<IOutgoingConversation> {
    return of(null);
  }

  signIn(username: string, password: string): Observable<boolean> {
    throw new Error('Method not implemented.');
  }
  signInAAD(): Observable<boolean> {
    throw new Error('Method not implemented.');
  }
  signOut(): Observable<any> {
    return of(true);
  }

  private initialize() {
    if (this.configuration.data.integration.type === 'five9') {
      this.five9State
        .getMetadata()
        .pipe(
          first(),
          tap((m) => {
            let ws_key = Math.floor(Math.random() * 100);
            let apiUrlHost = Five9StateService.GetApiUrlHost(m);
            this.five9WebSocket = new AppWebSocket(`wss://${apiUrlHost}/appsvcs/ws/${ws_key}`);
            this.five9WebSocket.connect();
          }),
          mergeMap((m) => this.five9WebSocket.messages$),
        )
        .subscribe((m: Message) => {
          if (m.context.eventReason === 'DISCONNECTED_BY_AGENT' || m.context.eventReason === 'DISCONNECTED_BY_CALLER') {
            let calls: CallsMapType = {};
            const sessionId = m.payLoad.variables[41];
            calls[sessionId] = {
              id: sessionId,
              state: 'Finished',

              caller: null,
              agentSip: null,
              sessionId: sessionId,
              data: { campaignId: m.payLoad.campaignId },
            };
            this._calls$.next(calls);
          }
        });
    }
  }

  finishCall(interactionId: string): Observable<CallInfo> {
    return this.five9HttpClient.put<CallInfo>(`agents/${this.five9State.userId}/interactions/calls/${interactionId}/disconnect`, {});
  }
  private disposeCall(interactionId: string, dispositionId: string): Observable<any> {
    let model = {};
    if (!!dispositionId) {
      model['dispositionId'] = dispositionId;
    }

    return this.five9HttpClient
      .put<AgentPresence>(`agents/${this.five9State.userId}/interactions/calls/${interactionId}/dispose`, model)
      .pipe(
        catchError((err) => {
          console.log(err);
          return of(null);
        }),
      );
  }

  disposeCampaignCall(interactionId: string, campaignId: string): Observable<any> {
    return this.getCampaignDispositions(campaignId).pipe(
      mergeMap((dispositions) => {
        let dispositionId = !!dispositions && dispositions.length > 0 ? dispositions[0].id : null;

        return this.disposeCall(interactionId, dispositionId);
      }),
    );
  }

  setCallVariables(interactionId: string, variables: { [key: string]: string }): Observable<any> {
    let model = {};
    Object.keys(variables).forEach((key) => {
      let umojoVariable = this.umojoCallVariables.find((v) => v.name === key);
      if (!!umojoVariable) {
        model[umojoVariable.id] = variables[key];
      }
    });

    return this.five9HttpClient.put<AgentPresence>(
      `agents/${this.five9State.userId}/interactions/calls/${interactionId}/variables_2`,
      model,
    );
  }

  sendDtmf(dtmf: string, interactionId: string): Observable<any> {
    if (!this.prompts) {
      this.prompts = this.getActivePrompts().pipe(shareReplay(1));
    }

    return this.prompts.pipe(
      mergeMap((prompts) => {
        if (dtmf === '*') {
          dtmf = 'Star';
        } else if (dtmf === '#') {
          dtmf = 'Pound';
        }

        const name = `DTMF${dtmf}`;
        const prompt = (prompts || []).find((x) => x.name === name);

        if (!prompt) {
          console.log('Prompt not found', dtmf);
          return of(true);
        }
        const request = {
          value: prompt.id,
        };

        return this.five9HttpClient.put<AgentPresence>(
          `agents/${this.five9State.userId}/interactions/calls/${interactionId}/audio/player/play_prompt`,
          request,
        );
      }),
    );
  }

  setReadyStatus(): Observable<AgentPresence> {
    const request: AgentPresence = {
      notReadyReasonCodeId: '0',
      readyChannels: ['CALL', 'VOICE_MAIL'],
    };
    return this.five9HttpClient.put<AgentPresence>(`agents/${this.five9State.userId}/presence`, request);
  }
  setBusyStatus(): Observable<AgentPresence> {
    let notReady = this.getFirstNotReadyReason();
    if (!notReady) {
      return of(null);
    }

    const request: AgentPresence = {
      notReadyReasonCodeId: notReady.id,
      readyChannels: [],
    };
    return this.five9HttpClient.put<AgentPresence>(`agents/${this.five9State.userId}/presence`, request);
  }

  getActiveInteractions(): Observable<Array<Interaction>> {
    return this.five9HttpClient.get<Array<Interaction>>(`agents/${this.five9State.userId}/interactions`);
  }
  getActivePrompts(): Observable<Array<Prompt>> {
    return this.five9HttpClient.get<Array<Prompt>>(`agents/${this.five9State.userId}/prompts`);
  }
  getNotReadyReasonCodes(orgId: string): Observable<Array<IReasonCodeInfo>> {
    return this.five9HttpClient.get<Array<IReasonCodeInfo>>(`orgs/${orgId}/not_ready_reason_codes`);
  }

  private getDispositions(): Observable<Array<Disposition>> {
    return this.five9HttpClient
      .get<{ dispositions: Array<Disposition> }>(`orgs/${this.metadata.orgId}/no_campaign_dispositions`)
      .pipe(map((x) => x.dispositions));
  }
  private getCampaignDispositions(campaignId: string): Observable<Array<Disposition>> {
    if (!campaignId) {
      return this.getDispositions();
    }

    return this.five9HttpClient
      .get<{ dispositions: Array<Disposition> }>(`orgs/${this.metadata.orgId}/campaigns/${campaignId}/dispositions`)
      .pipe(map((x) => x.dispositions));
  }

  getCallVariables(orgId: string): Observable<Array<CallVariableInfo>> {
    return this.five9HttpClient.get<Array<CallVariableInfo>>(`orgs/${orgId}/call_variables`);
  }

  createConversation(caller: string, callId: string, sessionId: string, agentSip: string): Observable<IConversation> {
    return this.five9State.getMetadata().pipe(
      first(),
      tap((m) => (this.metadata = m)),
      mergeMap((m) => zip(this.getNotReadyReasonCodes(m.orgId), this.getActiveInteractions(), this.getCallVariables(m.orgId))),
      map((res) => {
        this.notReadyReasons = res[0] || [];
        this.umojoCallVariables = (res[2] || []).filter((x) => x.group === 'Umojo');

        let interactions = res[1];

        let calls = interactions.filter((x) => x.channelType === 'CALL');
        if (calls.length === 0) {
          return null;
        }

        let conversation = new Five9Conversation(caller, agentSip, this);
        conversation.callId = callId;
        conversation.sessionId = sessionId;
        conversation.interactionId = calls[0].interactionId;

        return conversation;
      }),
    );
  }

  private getFirstNotReadyReason(): IReasonCodeInfo {
    return this.notReadyReasons.find((x) => x.selectable);
  }
}
