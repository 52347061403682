import { SkypeMePerson } from './skype-me-person';
import { Injectable, NgZone } from '@angular/core';

import { Observable, BehaviorSubject, Subject, from as fromPromise, throwError } from 'rxjs';
import { tap, map, filter, flatMap, timeout, catchError, first } from 'rxjs/operators';

import { CredentialsStorageService } from '../../services';
import { AuthService, AppStateService } from '@libs/portal-common/services';

import {
  IConversation,
  ISkypeAppService,
  ISkypeMePerson,
  ISkypeCredentials,
  SkypeSignInState,
  SkypeMediaCapabilities,
  SkypeOnlineStatus,
  AuthStatus,
  IOutgoingConversation,
  ISkypeWebApplication,
  ISkypeDisposable,
  IPersonsAndGroupsManager,
  ISkypeConversation,
} from '../../abstractions';

import { SkypeConversation } from './skype-conversation';
import { FakeConversation } from '../../integrations/no-integration';

import { AppConfigurationService } from '../../services/app-configuration.service';
import { StatusManagementQueue } from './status-management-queue';
import { SkypeOutgoingConversation } from './skype-outgoing-conversation';

declare var Skype;

@Injectable()
export class SkypeAppService implements ISkypeAppService {
  private _app: ISkypeWebApplication;

  private _isSignedIn = new BehaviorSubject<boolean>(false);

  private _mePerson = new BehaviorSubject<ISkypeMePerson>(null);

  private _incomingCall = new Subject<IConversation>();
  private _outgoingCall = new Subject<IOutgoingConversation>();

  private _authStatus = new BehaviorSubject<AuthStatus>(null);

  private _statusQueue: StatusManagementQueue;

  private _mediaCapabilities = new BehaviorSubject<SkypeMediaCapabilities>(null);

  get isSignedIn$() {
    return this._isSignedIn.asObservable();
  }

  get mePerson$() {
    return this._mePerson.asObservable();
  }

  public isSignedIn(): boolean {
    return this._isSignedIn.value;
  }

  get incomingCall$(): Observable<IConversation> {
    return this._incomingCall.asObservable();
  }
  get outgoingCall$(): Observable<IOutgoingConversation> {
    return this._outgoingCall.asObservable();
  }

  get authStatus$(): Observable<AuthStatus> {
    return this._authStatus;
  }

  get mediaCapabilities$(): Observable<SkypeMediaCapabilities> {
    return this._mediaCapabilities;
  }
  get personsAndGroupsManager(): IPersonsAndGroupsManager {
    return this._app.personsAndGroupsManager;
  }

  constructor(
    private auth: AuthService,
    private credentialsStorage: CredentialsStorageService,
    private appStateService: AppStateService,
    private configuration: AppConfigurationService,
    private ngZone: NgZone,
  ) {}

  simulateSkypeCall(uri: string, skipSavingIncident: boolean) {
    this._incomingCall.next(new FakeConversation(uri, '', skipSavingIncident));
  }

  outboundCall(uris: Array<string>): Observable<IOutgoingConversation> {
    return fromPromise(this.ensureClient()).pipe(
      map((res) => {
        let conversation = this.createConversation(uris);
        let skypeConversation = new SkypeOutgoingConversation(conversation, this, this._app.personsAndGroupsManager.mePerson);
        this._outgoingCall.next(skypeConversation);
        return skypeConversation;
      }),
    );
  }
  private createConversation(uris: Array<string>): ISkypeConversation {
    if (!uris || uris.length === 0) {
      throw new Error('You must provide at least one participant to create a conversation');
    }
    if (uris.length === 1) {
      return this._app.conversationsManager.getConversation(uris[0]);
    } else {
      let conversation = this._app.conversationsManager.createConversation();
      uris.forEach((uri) => {
        let participant = conversation.createParticipant(uri);
        conversation.participants.add(participant);
      });

      return conversation;
    }
  }

  async login(credentials: ISkypeCredentials): Promise<boolean> {
    let app: ISkypeWebApplication;

    try {
      this._authStatus.next('WaitingSkype');
      app = await this.signIn(credentials).toPromise();
    } catch (err) {
      this.handleFailedLogin(err);
      throw new Error('Invalid credentials');
    }

    try {
      let state = await app.signInManager.state.get();
      console.log('lync state: ' + state);

      this._statusQueue = new StatusManagementQueue(this._app.personsAndGroupsManager.mePerson);
      this.setStatus('Online', 'Waiting for new calls');

      this._authStatus.next('WaitingCode');
      let sip = app.personsAndGroupsManager.mePerson.id();
      let displayName = app.personsAndGroupsManager.mePerson.displayName();
      let creds = await this.getCredentialsForApi(app, sip, displayName).toPromise();

      this._authStatus.next('WaitingToken');
      // let token = await this.auth.skypeAuthenticate(creds.login, creds.password).toPromise();
      // this.appStateService.setSkypeAuthenticated(token);
      this.credentialsStorage.saveSkypeCredentials(credentials);

      this._authStatus.next('Finished');

      let self = this;

      app.conversationsManager.conversations.added((conversation) => {
        console.log('added', conversation);

        conversation.state.changed((newValue, reason, oldValue) => {
          console.log('CONVERSATION ID:', conversation.id(), 'State changed from', oldValue, 'to', newValue);
          let listeners = new Array<ISkypeDisposable>();

          if (newValue === 'Incoming') {
            listeners.push(
              conversation.audioService.accept.enabled.when(true, () => {
                self._incomingCall.next(new SkypeConversation(conversation, this, this._app.personsAndGroupsManager.mePerson));
              }),
            );
          } else {
            listeners.forEach((listener) => listener.dispose());
            listeners = [];
          }

          if (newValue === 'Disconnected') {
            app.conversationsManager.conversations.remove(conversation);
          }
        });
      });

      this._mePerson.next(new SkypeMePerson(this._app.personsAndGroupsManager.mePerson, this));
      self._incomingCall.next(null);

      this._isSignedIn.next(state === 'SignedIn');

      return state === 'SignedIn';
    } catch (err) {
      this.handleFailedLogin(err);
      throw err.message;
    }
  }

  private handleFailedLogin(err: any) {
    console.log(err);
    this._app = null;
    this._authStatus.next(null);
  }

  async checkLogin(): Promise<boolean> {
    let credentials = this.credentialsStorage.readSkypeCredentials();
    if (credentials) {
      let app = await this.ensureClient();

      try {
        await this.login(credentials);
        return true;
      } catch (exception) {
        this.credentialsStorage.removeSkypeCredentials();
        return false;
      }
    } else {
      return false;
    }
  }

  private signIn(credentials: ISkypeCredentials): Observable<ISkypeWebApplication> {
    let signInInfo = {
      username: credentials.login,
      password: credentials.password,
      origins: this.configuration.data.skype.origins,
    };

    return fromPromise(this.ensureClient()).pipe(
      flatMap((app) => {
        let signInPromise: Promise<void>;

        this.ngZone.runOutsideAngular(() => {
          signInPromise = app.signInManager.signIn(signInInfo);
        });

        return fromPromise(signInPromise).pipe(map((res) => app));
      }),
      timeout(this.configuration.data.skype.authTimeout || 10000),
      catchError((err) => {
        this.disposeApp();

        return throwError(err);
      }),
    );
  }

  private async logout(): Promise<void> {
    this.credentialsStorage.removeSkypeCredentials();

    this.clearMePerson();

    await this._app.signInManager.signOut();

    this._isSignedIn.next(false);

    this._app = null;
  }

  public clearCredentials() {
    this.credentialsStorage.removeSkypeCredentials();
  }

  async getSignInState(): Promise<SkypeSignInState> {
    if (this._app === undefined || this._app.signInManager === undefined) {
      return new Promise<SkypeSignInState>(() => 'SignedOut');
    }

    return await this._app.signInManager.state.get();
  }

  async getStatus(): Promise<SkypeOnlineStatus> {
    return this._app.personsAndGroupsManager.mePerson.status();
  }

  get status$(): Observable<SkypeOnlineStatus> {
    let self = this;

    return new Observable((observer) => {
      let evt = this._app.personsAndGroupsManager.mePerson.status.changed((status) => {
        observer.next(status);
      });

      return () => {
        evt.dispose();
      };
    });
  }

  setStatus(status: SkypeOnlineStatus, message: string) {
    if (!!this._statusQueue) {
      this._statusQueue.setText(message);
      this._statusQueue.setStatus(status);
    }
  }

  getSip(): string {
    if (!this._isSignedIn.value) {
      return this.appStateService.username;
    }

    return this._app.personsAndGroupsManager.mePerson.id();
  }
  getName(): string {
    if (!this._isSignedIn.value) {
      return null;
    }

    return this._app.personsAndGroupsManager.mePerson.displayName();
  }

  // ensures the skype client object is initialized
  private ensureClient(): Promise<ISkypeWebApplication> {
    return new Promise((resolve, reject) => {
      let self = this;

      if (this._app != null) {
        resolve(this._app);
        return;
      }

      this.ngZone.runOutsideAngular(() => {
        Skype.initialize(
          {
            apiKey: 'a42fcebd-5b43-4b89-a065-74450fb91255',
          },
          (api) => {
            self._app = api.application();
            resolve(self._app);
          },
          function (er) {
            reject(er);
          },
        );
      });
    });
  }

  private getCredentialsForApi(app: ISkypeWebApplication, sip: string, displayName: string): Observable<ISkypeCredentials> {
    return new Observable<ISkypeCredentials>((observer) => {
      let conversationsManager = app.conversationsManager;
      let listeners = new Array<ISkypeDisposable>();

      let authAgentSip = this.configuration.data.skype.authAgentSip;
      if (!authAgentSip.startsWith('sip:')) {
        authAgentSip = 'sip:' + authAgentSip;
      }

      let conversations = conversationsManager.conversations.filter((con) => con.creator.id() === authAgentSip);
      listeners.push(
        conversations.added((conversation) => {
          listeners.push(
            conversation.chatService.accept.enabled.when(true, () => {
              console.log('Accepting IM invitation ...');

              let messages = conversation.chatService.messages.filter(
                (message) => message.direction() === 'Incoming' && message.sender.id() === authAgentSip,
              );

              listeners.push(
                messages.added((message) => {
                  let code = this.parseAuthMessage(message.text());
                  if (code) {
                    observer.next({ login: sip, password: code });
                    observer.complete();
                  }
                }),
              );

              conversation.chatService.accept();
            }),
          );
        }),
      );

      // this.auth.sendCodeToSkype(sip, displayName).subscribe(res => { }, err => { observer.error(err); });

      return () => {
        console.log('unsubscribed');
        listeners.forEach((listener) => listener.dispose());
        listeners = [];
      };
    }).pipe(first(), timeout(this.configuration.data.skype.authTimeout || 10000));
  }

  private parseAuthMessage(message: string): string {
    console.log(message);
    let pattern = /\((.*)\)/;
    let result = message.match(pattern)[1];

    return result;
  }

  private clearMePerson() {
    let mePerson = this._mePerson.getValue();

    if (mePerson) {
      mePerson.dispose();
      this._mePerson.next(null);
    }
  }

  private disposeApp() {
    this._app = null;

    if (this._statusQueue) {
      this._statusQueue = null;
    }
  }
}
