import { Injectable } from '@angular/core';
import { TransferState, makeStateKey } from '@angular/platform-browser';

import { Observable, throwError, of } from 'rxjs';
import { tap, map } from 'rxjs/operators';

import { HttpClient } from '@angular/common/http';

import { LocationContactResponse, LocationContactsResponse } from '..//models/rest/contact/lotContactResponses';

import {
  GetLocationContactsRequest,
  GetOrDeleteLocationContactRequest,
  InsertOrUpdateLocationContactRequest,
  ReorderLocationContactsRequest,
} from '../models/rest/contact/lotContactRequests';
import { BaseResponse } from '../models/rest/baseResponse';
import { AppStateService } from './app-state.service';

export class LotModel {
  Id?: number;
  IsActive?: boolean;
  LotNumber?: number;
  LotName?: string;

  IsStaticHtmlExist: boolean;

  Street?: string;
  City?: string;
  UState?: string;
  Zip?: string;
  Lat?: number;
  Lng?: number;

  GeneralInfo?: string;
  Contacts: any;
  Rates?: string;

  fkManager?: number;
  fkOwner?: number;
  fkLocationType: number;

  GateInstructions: any;
  Equipment: string;

  FacilityCode: string;
  RevenueClientSettings: any;
  RevenueControlEnabled: boolean;
  fkRevenueControlManufacturer: number;
  TimeZone: string;
  RevenueControlBehindGateway: boolean;
  RevenueControlGatewayId: string;
  RevenueControlReserveGatewayId: string;
  ParkonectId: number;
  ParkonectUsername: string;
  ParkonectPassword: string;
  fkSalesRegion?: number;
  fkRuleSet: number;
  ConfluenceId: string;
  DefaultView: string;
  IntercomManufacturer: any = null;
  integrationProvider: any = null;
  DVRManufacturer: string;
  DVRWebIP: string;
  DVRWebPort: number;
  DVRUserName: string;
  DVRPwd: string;
  ImmediatelyContact?: string;
  ThinxId?: string;
  VoicePlatformAccountId?: string;
  OcraLocationId?: string;
  InexLPRLocationId?: string;
  ParkchirpLocationId?: string;
  NoodoeLocationId?: string;
  OcraEnabled: boolean;
  InexLPREnabled: boolean;
  ParkchirpEnabled: boolean;
  NoodoeEnabled: boolean;
  OcraDefaultTimeFrame?: string;
  ParkchirpDefaultTimeFrame?: string;
  InexLPRDefaultTimeFrame?: string;

  MitteLocationId?: string;
  MitteDefaultTimeFrame?: string;
  MitteEnabled: boolean;

  Intercom_PhoneNumber?: string;
  IsVoiceBotEnabled: boolean;
  fkVoiceBot?: number;
  DvrSerialNumber?: string;
  DvrConnectionType?: number;
}

export class LocationIFrameTab {
  Id: number;
  Order: number;
  Enabled: boolean;
  Title: string;
  Url: string;
  BasicAuthEnabled: boolean;
  Username: string;
  Password: string;
  fkLot: number;
}

export interface ILocationType {
  Id: number;
  Name: string;
}

interface IGetLocationTagsResponse extends BaseResponse {
  Tags: Array<string>;
}

interface IGetLocationIFrameTabsResponse extends BaseResponse {
  IFrameTabs: Array<LocationIFrameTab>;
}

interface IGetLocationIFrameTabResponse extends BaseResponse {
  IFrameTab: LocationIFrameTab;
}

interface IInsertOrUpdateLocationIFrameTabResponse extends BaseResponse {
  IFrameTab: LocationIFrameTab;
}

interface IGetLocationTypesResponse extends BaseResponse {
  Types: Array<ILocationType>;
}

@Injectable({ providedIn: 'root' })
export class LocationsService {
  constructor(
    private _httpClient: HttpClient,
    private appStateService: AppStateService,
    private transferState: TransferState,
  ) {
    this.appStateService.isAuthenticated$.subscribe((res) => {
      if (!res) {
        this.resetCache();
      }
    });
  }

  getAll(): Observable<any> {
    let locations = this.getFromCache();
    if (!!locations) {
      return of(locations);
    }

    return this._httpClient.post<any>('Lot/LotDirectory', null).pipe(
      map((response) => {
        if (response.Success) {
          this.saveInCache(response);
        }

        return response;
      }),
    );
  }

  updateLotOwner(request) {
    return this.update('Owner', request);
  }

  updateLotManager(request) {
    return this.update('Manager', request);
  }

  update(field, UpdateLotRequest): Observable<any> {
    if (arguments.length < 2) {
      console.error('missing fields to update');
      return throwError('missing fields to update');
    }

    return this._httpClient
      .post<any>('Lot/UpdateLot' + field, UpdateLotRequest)
      .pipe(tap((response) => this.updateCacheLots(response.Lot)));
  }

  updateLotField(lotId, field, value): Observable<any> {
    if (arguments.length < 3) {
      console.error('missing fields to update');
      return throwError('missing fields to update');
    }

    return this._httpClient
      .post<any>(`Lot/${lotId}/${field}`, { FieldValue: value })
      .pipe(tap((response) => this.updateCacheLots(response.Lot)));
  }

  updateLotConfiguration(UpdateLotRequest): Observable<any> {
    return this._httpClient
      .post<any>('Lot/UpdateLotConfiguration', UpdateLotRequest)
      .pipe(tap((response) => this.updateCacheLots(response.Lot)));
  }

  updateCacheLots(lot) {
    let locations = this.getFromCache();
    if (locations) {
      let location = locations.Data.find((x) => x.Id === lot.Id);

      if (location) {
        Object.assign(location, lot);
        this.saveInCache(locations);
      }
    }
  }

  insert(lotModel): Observable<any> {
    return this._httpClient.post<any>('Lot/InsertLotConfiguration', { LotModel: { Lot: lotModel } }).pipe(
      tap((response) => {
        let locations = this.getFromCache();
        if (locations) {
          locations.Data.push(response.Lot);
          this.saveInCache(locations);
        }
      }),
    );
  }

  delete(LotId): Observable<any> {
    return this._httpClient.post<any>('Lot/Delete', { Id: LotId }).pipe(
      tap(() => {
        let locations = this.getFromCache();
        if (locations) {
          locations.Data = locations.Data.filter((x) => x.Id !== LotId);
          this.saveInCache(locations);
        }
      }),
    );
  }

  getByLot(GetLotRequest): Observable<{ Lot: LotModel }> {
    return this._httpClient.post<any>('Lot/GetLotManagementByLotNumber', GetLotRequest);
  }

  getBy(GetLocationManagementRequest): Observable<{ Lot: LotModel; Lanes: Array<any>; LotIssues: Array<any> }> {
    return this._httpClient.post<any>('Lot/GetLocationManagement', GetLocationManagementRequest);
  }

  getLocationContacts(request: GetLocationContactsRequest): Observable<LocationContactsResponse> {
    return this._httpClient.get<LocationContactsResponse>(`Lot/${request.LotId}/Contacts`);
  }

  getLocationContact(request: GetOrDeleteLocationContactRequest): Observable<LocationContactResponse> {
    return this._httpClient.get<LocationContactResponse>(`Lot/${request.LotId}/Contacts/${request.ContactId}`);
  }

  reorderLocationContacts(request: ReorderLocationContactsRequest): Observable<BaseResponse> {
    return this._httpClient.post<BaseResponse>(`Lot/${request.LotId}/ReorderContacts`, request);
  }

  createOrUpdateLocationContact(request: InsertOrUpdateLocationContactRequest): Observable<LocationContactResponse> {
    return this._httpClient.put<LocationContactResponse>(
      `Lot/${request.LocationContact.fkLot}/Contacts/${request.LocationContact.fkContact}`,
      request,
    );
  }

  deleteLocationContact(request: GetOrDeleteLocationContactRequest): Observable<LocationContactResponse> {
    return this._httpClient.delete<LocationContactResponse>(`Lot/${request.LotId}/Contacts/${request.ContactId}`);
  }

  getLocationTags(lotId: number): Observable<Array<string>> {
    return this._httpClient.get<IGetLocationTagsResponse>(`Lot/${lotId}/Tags`).pipe(map((x) => x.Tags));
  }
  saveLocationTags(lotId: number, tags: Array<string>): Observable<BaseResponse> {
    return this._httpClient.post<BaseResponse>(`Lot/${lotId}/Tags`, {
      tags: tags,
    });
  }

  getLocationIFrameTabs(lotId: number): Observable<IGetLocationIFrameTabsResponse> {
    return this._httpClient.get<IGetLocationIFrameTabsResponse>(`Lot/${lotId}/IFrameTabs`);
  }

  getLocationIFrameTab(id: number): Observable<IGetLocationIFrameTabResponse> {
    return this._httpClient.get<IGetLocationIFrameTabResponse>(`Lot/IFrameTabs/${id}`);
  }

  reorderLocationIFrameTabs(lotId: number, tabs: Array<LocationIFrameTab>): Observable<BaseResponse> {
    return this._httpClient.post<BaseResponse>(`Lot/${lotId}/IFrameTabs/Reorder`, { IFrameTabs: tabs });
  }

  insertOrUpdateLocationIFrameTab(tab: LocationIFrameTab): Observable<IInsertOrUpdateLocationIFrameTabResponse> {
    return this._httpClient.post<IInsertOrUpdateLocationIFrameTabResponse>(`Lot/${tab.fkLot}/IFrameTabs`, { IFrameTab: tab });
  }

  deleteLocationIFrameTab(id: number): Observable<LocationContactResponse> {
    return this._httpClient.delete<LocationContactResponse>(`Lot/IFrameTabs/${id}`);
  }

  getLocationTypes(): Observable<Array<ILocationType>> {
    return this._httpClient.get<IGetLocationTypesResponse>(`Lot/Types`).pipe(map((x) => x.Types));
  }

  private getFromCache(): any {
    return this.transferState.get(makeStateKey<string>('locations-cache'), null);
  }
  private saveInCache(value): any {
    return this.transferState.set(makeStateKey<string>('locations-cache'), value);
  }
  private resetCache() {
    this.transferState.remove(makeStateKey<string>('locations-cache'));
  }
}
