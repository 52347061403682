import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class AgentService {
  constructor(private _httpClient: HttpClient) {}

  getAll() {
    return this._httpClient.get('Agent');
  }
}
