import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { ModalModule } from 'ngx-bootstrap/modal';
import { CollapseModule } from 'ngx-bootstrap/collapse';

import { NavBarComponent } from './components/navbar/navbar.component';
import { StatisticsComponent } from './components/navbar/statistics/statistics.component';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { UmojoSpinnerComponent } from './components/spinner/umojo-spinner.component';

@NgModule({
  imports: [CommonModule, FormsModule, RouterModule, ModalModule, CollapseModule, NgScrollbarModule],
  declarations: [NavBarComponent, StatisticsComponent, UmojoSpinnerComponent],
  exports: [NavBarComponent, StatisticsComponent, UmojoSpinnerComponent],
})
export class NavigationModule {}
