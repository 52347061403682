import { Inject, Injectable } from '@angular/core';

import { Observable, BehaviorSubject } from 'rxjs';

import { tap, map } from 'rxjs/operators';

import { GridDataResult } from '@progress/kendo-angular-grid';

import { AppConfigurationService, APP_CONFIGURATION, FieldSetService, IAppConfigurationBase } from '@libs/portal-common/services';

export const DynamicFieldComparer = (left: IDynamicField, right: IDynamicField) => {
  if (left.order < right.order) {
    return -1;
  }
  if (left.order > right.order) {
    return 1;
  }

  return 0;
};

const DefaultFieldWidth = 95;

// prettier-ignore
export const PredefinedFields: Array<IDynamicField> = [
  { fieldName: 'Id', fieldType: null, displayName: 'Id', isSelected: true, isStatic: true, order: 0, width: 80 },
  { fieldName: 'CallTime', fieldType: null, displayName: 'Date', isSelected: true, isStatic: true, order: 1, width: 120 },
  { fieldName: 'H', fieldType: null, displayName: 'H', isSelected: true, isStatic: true, order: 2, width: 62 },
  { fieldName: 'LotNumber', fieldType: null, displayName: 'Lot', isSelected: true, isStatic: true, order: 3, width: 62 },
  { fieldName: 'LotName', fieldType: null, displayName: 'Location', isSelected: true, isStatic: true, order: 4, width: 120 },
  { fieldName: 'Lane', fieldType: null, displayName: 'Lane', isSelected: true, isStatic: true, order: 5, width: 148 },
  { fieldName: 'Issue', fieldType: null, displayName: 'Issue', isSelected: true, isStatic: true, order: 6, width: 148 },
  { fieldName: 'Problem', fieldType: null, displayName: 'Problem', isSelected: false, isStatic: true, width: 148 },
  { fieldName: 'Vend', fieldType: null, displayName: 'Vend', isSelected: true, isStatic: true, order: 7, width: 85 },
  { fieldName: 'QueueTime', fieldType: null, displayName: 'Queue', isSelected: true, isStatic: true, order: 8, width: 95 },
  { fieldName: 'CallDuration', fieldType: null, displayName: 'Dur', isSelected: true, isStatic: true, order: 9, width: 85 },
  { fieldName: 'Wrapup', fieldType: null, displayName: 'Wrapup', isSelected: true, isStatic: true, order: 10, width: 100 },
  { fieldName: 'TotalTime', fieldType: null, displayName: 'Total', isSelected: true, isStatic: true, order: 11, width: 85 },
  { fieldName: 'IsRecordingFound', fieldType: null, displayName: 'Rec.', isSelected: true, isStatic: true, order: 12, width: 85 },
  { fieldName: 'IsVideoRecordingFound', fieldType: null, displayName: 'Video', isSelected: true, isStatic: true, order: 13, width: 85 },
  { fieldName: 'Agent', fieldType: null, displayName: 'Agent', isSelected: true, isStatic: true, order: 14, width: 148 },
  { fieldName: 'Parker', fieldType: null, displayName: 'Parker', isSelected: true, isStatic: true, order: 15, width: 95 },
  { fieldName: 'CallMatchingGroupName', fieldType: null, displayName: 'Group', isSelected: false, isStatic: true, order: 16, width: 110 },
  { fieldName: 'CallSource', fieldType: null, displayName: 'Source', isSelected: true, isStatic: true, order: 17, width: 100 },
  { fieldName: 'CallId', fieldType: null, displayName: 'Call ID', isSelected: false, isStatic: true, order: 18, width: 100 },
  { fieldName: 'SessionId', fieldType: null, displayName: 'Session ID', isSelected: false, isStatic: true, order: 19, width: 150 },
  { fieldName: 'VBEventId', fieldType: null, displayName: 'Voice Bot', isSelected: false, isStatic: true, order: 20, width: 50 },
  { fieldName: 'VBEventConferenceId', fieldType: null, displayName: 'VB Conf ID', isSelected: false, isStatic: true, order: 21, width: 85 },
  { fieldName: 'VBEventDur', fieldType: null, displayName: 'VB Dur', isSelected: false, isStatic: true, order: 22, width: 95 },
  { fieldName: 'VBEventFileName', fieldType: null, displayName: 'VB Rec', isSelected: false, isStatic: true, order: 24, width: 85 },
  { fieldName: 'VBEventCallSegment', fieldType: null, displayName: 'Transcript', isSelected: false, isStatic: true, order: 25, width: 85 },
  { fieldName: 'Caller', fieldType: null, displayName: 'ANI', isSelected: false, isStatic: true, order: 26, width: 250 },
  { fieldName: 'NoodoeStationId', fieldType: null, displayName: 'Station ID', isSelected: false, isStatic: true, order: 27, width: 100 },
  { fieldName: 'NoodoeStationChargeStarted', fieldType: null, displayName: 'Start Charging', isSelected: false, isStatic: true, order: 28, width: 70 },
  { fieldName: 'NoodoeStationChargeStopped', fieldType: null, displayName: 'Stop Charging', isSelected: false, isStatic: true, order: 29, width: 70 },
  { fieldName: 'NoodoeStationReseted', fieldType: null, displayName: 'Soft Reset', isSelected: false, isStatic: true, order: 30, width: 70 },
  { fieldName: 'NoodoeStationMaintenaceModeLastAction', fieldType: null, displayName: 'Maintenance Mode', isSelected: false, isStatic: true, order: 31, width: 100 },
  { fieldName: 'IsCallClosedByAgent', fieldType: null, displayName: 'Closed by Agent', isSelected: true, isStatic: true, order: 32, width: 100 },
];

const OnlyAdminFields: { [key: string]: any } = {
  QueueTime: true,
  CallDuration: true,
  Wrapup: true,
  TotalTime: true,
  IsRecordingFound: true,
};

export interface IActivityGridState {
  dynamicFields: Array<IDynamicField>;
}

interface IDynamicFieldState {
  isSelected: boolean;
  order?: number;
}

export interface IDynamicField extends IDynamicFieldState {
  fieldName: string;
  fieldType: string;
  displayName: string;
  isStatic: boolean;
  width?: number;
}

@Injectable()
export class GridStateService extends BehaviorSubject<GridDataResult> {
  private STATE_KEY = 'ACTIVITY_GRID_FIELDS_STATE';

  constructor(
    private filedSetService: FieldSetService,
    @Inject(APP_CONFIGURATION) private configuration: AppConfigurationService<IAppConfigurationBase>,
  ) {
    super(null);
  }

  public getState(): Observable<IActivityGridState> {
    let stateStr = localStorage.getItem(this.STATE_KEY);
    let state = !!stateStr ? JSON.parse(stateStr) : null;

    return this.filedSetService.getAll(null, null).pipe(
      map((x) => {
        let set = new Map<string, IDynamicField>();

        x.Data.forEach((field) => {
          set[field.FieldName] = {
            fieldName: field.IsStatic ? field.FieldName.substr(1) : field.FieldName,
            fieldType: field.FieldType,
            displayName: field.DisplayName,
            isSelected: false,
            isStatic: field.IsStatic,
            order: null,
            width: DefaultFieldWidth,
          };
        });

        let fields = Object.keys(set).map((key) => {
          let field = set[key];
          this.appStateToField(field, state);
          return field;
        });

        let predefinedFields = JSON.parse(JSON.stringify(PredefinedFields)).map((field) => {
          this.appStateToField(field, state);
          return field;
        });

        let result: Array<IDynamicField> = [...predefinedFields, ...fields];
        if (this.configuration.mode === 'webagent') {
          result = result.filter((field) => !OnlyAdminFields[field.fieldName]);
        }

        return { dynamicFields: result };
      }),
    );
  }

  private appStateToField(field: IDynamicField, state: Map<string, IDynamicFieldState>) {
    if (!!state) {
      let fieldState: IDynamicFieldState = state[field.fieldName];

      field.isSelected = !!fieldState ? fieldState.isSelected : false;
      field.order = !!fieldState ? fieldState.order : null;
    }
  }

  public saveState(gridState: IActivityGridState): void {
    let state = new Map<string, IDynamicFieldState>();

    gridState.dynamicFields.forEach((field, idx) => {
      state[field.fieldName] = { isSelected: field.isSelected, order: field.isSelected ? field.order : null };
    });

    localStorage.setItem(this.STATE_KEY, JSON.stringify(state));
  }

  public resetState() {
    localStorage.removeItem(this.STATE_KEY);
  }
}
