import { Component, Input, TemplateRef, Output, EventEmitter, ContentChild, ViewEncapsulation } from '@angular/core';

import { DragEndEvent } from '@progress/kendo-angular-sortable/dist/es2015/sortable-events';

@Component({
  selector: 'app-sortable-table',
  templateUrl: './sortable-table.component.html',
  styleUrls: ['./sortable-table.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SortableTableComponent {
  _data: any;
  isDirty = false;

  @Output() dragEnd = new EventEmitter<DragEndEvent>();

  @Input() header: Array<string> = [];

  @ContentChild(TemplateRef) itemTemplateRef: TemplateRef<any>;

  @Input()
  get data() {
    return this._data;
  }

  @Output() dataChange = new EventEmitter();
  set data(val) {
    this._data = val;
    this.dataChange.emit(this._data);
  }

  public onDataMove(e: DragEndEvent): void {
    this.isDirty = true;
  }

  public onDragEnd(e: DragEndEvent): void {
    if (this.isDirty) {
      this.dragEnd.emit(e);
      this.isDirty = false;
    }
  }
}
