import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { NavigationService } from '../../../services';
import { Issue } from '../../../../abstractions';
import { AgentAnalyticsEvent } from '../../../../services';
import { ANALYTICS, IAnalyticsService } from '@libs/portal-common';

@Component({
  selector: 'app-common-issues',
  templateUrl: './common-issues.component.html',
  styleUrls: ['./common-issues.component.scss'],
})
export class CommonIssuesComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();

  public issues = new Array<Issue>();

  constructor(
    private navService: NavigationService,
    @Inject(ANALYTICS) private analytics: IAnalyticsService,
  ) {}

  ngOnInit() {
    this.subscription.add(
      this.navService.incomingCall$.subscribe((incomingCall) => {
        this.issues = [];

        if (incomingCall) {
          let issues = incomingCall.problems.forEach((p) => {
            p.Issues.filter((issue) => issue.ShowInPage).forEach((issue) => this.issues.push(issue));
          });
        }
      }),
    );

    this.analytics.track(AgentAnalyticsEvent.CommonIssuesClicked, null);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
