import { Injectable, Inject } from '@angular/core';

import { Observable } from 'rxjs';
import { tap, map, filter, flatMap } from 'rxjs/operators';

import { LocationsService } from './locations.service';
import { LanesService } from './lanes.service';
import { AppConfigurationService, APP_CONFIGURATION, IAppConfigurationBase } from './app-configuration.service';
import { IRTSPTransportType, IStreamSourceType } from '../models';

export interface ILocationManagementProperties {
  RevenueControlManufacturer: IRevenueControlManufacturer;
  RevenueControlEnabled: boolean;
  AnalogGateVendCode: string;
}
export interface ILocation extends ILocationManagementProperties {
  Id: number;
  LotNumber: number;
  LotName: string;
  Address: string;
  AnalogGateVendCode: string;
  fkRuleSet: number;
  Active: boolean;
}

export interface IRevenueControlManufacturer {
  Id: number;
  Name: string;
  IsIntegrated: boolean;
  CanOpenGate: boolean;
  CanCloseGate: boolean;
  CanSetPrice: boolean;
  CanSetRate: boolean;
  CanEjectTicket: boolean;
  CanSwallowTicket: boolean;
  CanShowTicketInfo: boolean;
}

export interface ILane {
  Id: number;
  Lane_Number: number;
  Lane_Type: string;
  Gate_Vend_Code: string;
  TerminateCallTone: string;
  FacilityLaneCode: string;

  DVR_Channel: string;
  StreamSourceType: IStreamSourceType;
  RtspTransport?: IRTSPTransportType;
  RtspAddress?: string;
  RtspUsername?: string;
  RtspPassword?: string;
}

export interface ILocationLane {
  locations: Array<ILocation>;
  location: ILocation;

  lanes: Array<ILane>;
  lane: ILane;
}

@Injectable({ providedIn: 'root' })
export class LocationLaneResolverService {
  constructor(
    private locationsService: LocationsService,
    private lanesService: LanesService,
    @Inject(APP_CONFIGURATION) private configuration: AppConfigurationService<IAppConfigurationBase>,
  ) {}

  resolveUri(uri: string): Observable<ILocationLane> {
    return this.getLocations().pipe(flatMap((locations) => this.findLocationLane(uri, locations)));
  }

  private getLocations(): Observable<Array<ILocation>> {
    return this.locationsService.getAll().pipe(
      map((response) => {
        if (response.Success) {
          return this.extractLots(response.Data) || [];
        }
        return [];
      }),
    );
  }

  getLotLanes(lotId): Observable<Array<ILane>> {
    return this.lanesService.getLotLanes(lotId).pipe(map((lanes) => lanes));
  }

  private async findLocationLane(uri: string, locations: Array<ILocation>): Promise<ILocationLane> {
    let result: ILocationLane = {
      locations: locations,
      location: null,

      lanes: new Array<ILane>(),
      lane: null,
    };

    if (!uri || uri.length < 7) {
      return result;
    }

    uri = this.extractPhoneNumber(uri);

    let st_loc = uri.substring(0, 6);
    let lot_id = parseInt(st_loc, 10);

    if (!lot_id) {
      return result;
    }

    let lane_number = 0;

    let location = locations.find((l) => l.Id === lot_id);

    if (location) {
      result.lanes = await this.getLotLanes(location.Id).toPromise();

      try {
        result.location = location;

        lane_number = parseInt(uri.substring(6, 7), 10);

        if (lane_number === 9) {
          if (uri.length < 10) {
            return result;
          }

          let ext_lane = uri.substring(6, 10);
          let ext_lane_number = parseInt(ext_lane, 10);

          if (!ext_lane_number) {
            return null;
          }

          if (!(ext_lane_number >= 9000 && ext_lane_number < 9999)) {
            return null;
          }

          lane_number = ext_lane_number;
        }
      } catch (ex) {
        return result;
      }

      try {
        let lnum = lane_number === 0 ? 1 : lane_number;

        if (lnum > 9000) {
          lnum = lnum - 9000;
        }

        let lane = result.lanes.find((a) => a.Lane_Number === lnum);

        if (!lane) {
          if (result.lanes.length === 1) {
            lane = result.lanes[0];
          }
        }

        if (lane) {
          result.lane = lane;
        }
      } catch (ex) {
        return result;
      }
    }
    return result;
  }

  private extractPhoneNumber(uri: string): string {
    let phoneNumber = decodeURIComponent(uri);

    const sipPatterns = this.configuration.data.applicationOptions.sipPatterns || [];
    sipPatterns.forEach((x) => (phoneNumber = phoneNumber.replace(x.pattern, x.replacement)));

    phoneNumber = phoneNumber.replace('tel:', '').replace('sip:', '').replace('+1', '').replace(' ', '');

    if (phoneNumber.length > 10) {
      phoneNumber = phoneNumber.substring(0, 10);
    }

    return phoneNumber;
  }

  private extractLots(lotDirectory: Array<any>): Array<ILocation> {
    return lotDirectory.map((lot) => {
      let address = '';
      if (lot.Street) {
        address += lot.Street;
      }

      if (lot.City) {
        if (address) {
          address += ', ';
        }

        address += lot.City;
      }

      if (lot.State) {
        if (address) {
          address += ', ';
        }

        address += lot.State;
      }

      return {
        Id: lot.Id,
        LotNumber: lot.LotNumber,
        LotName: lot.LotName,
        Address: address,
        RevenueControlManufacturer: null,
        RevenueControlEnabled: false,
        AnalogGateVendCode: null,
        fkRuleSet: lot.fkRuleSet,
        Active: lot.Active,

        DVRManufacturer: lot.DVRManufacturer,
        DVRWebIP: lot.DVRWebIP,
        DVRWebPort: lot.DVRWebPort,
        DVRUserName: lot.DVRUserName,
        DVRPwd: lot.DVRPwd,
      };
    });
  }
}
