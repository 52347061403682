import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';

import { Observable, of } from 'rxjs';

import { ANALYTICS, BaseResponse, IAnalyticsService, RevenueControlService, TicketInfo } from '@libs/portal-common/services';
import { AppNotificationsService } from '@libs/portal-common/system';

import { IncomingCall } from '../../../../../../abstractions';
import { IRevenueForm } from '../../revenue-form-factory.service';
import { AgentAnalyticsEvent } from '../../../../../../services';

type SendStatus = 'Success' | 'Error';

@Component({
  selector: 'app-ticket-info-base',
  templateUrl: './ticket-info-base.component.html',
  styleUrls: ['./ticket-info-base.component.scss'],
})
export class TicketInfoBaseComponent implements IRevenueForm, OnInit {
  @ViewChild('form', { static: false }) public form: NgForm;

  incomingCall: IncomingCall;
  ticketNum: number = null;
  ticketInfo: TicketInfo;

  busy = false;

  validating = false;
  initialized = false;

  provider: string;

  constructor(
    private revenueControl: RevenueControlService,
    private notifications: AppNotificationsService,
    @Inject(ANALYTICS) private analytics: IAnalyticsService,
  ) {}

  ngOnInit() {
    this.analytics.track(AgentAnalyticsEvent.RevenueFormOpen, { Provider: this.provider, RCForm: 'ShowTicketForm' });
  }

  init(provider: string, incomingCall: IncomingCall, formParams: any): void {
    this.validating = false;
    this.initialized = false;

    this.incomingCall = incomingCall;
    this.ticketNum = null;
    this.provider = provider;

    setTimeout(() => (this.initialized = true), 30);
  }

  validate(): boolean {
    return true;
  }

  canSubmit(): boolean {
    return true;
  }

  submit(): Observable<BaseResponse> {
    return of(null);
  }

  showTicketInfo() {
    this.validating = true;
    if (!this.form.valid) {
      return;
    }

    let request = {
      LotId: this.incomingCall.location.Id,
      LaneId: this.incomingCall.lane.Id,
      CommandParams: {
        Ticket: this.ticketNum * 1,
      },
    };

    this.analytics.track(AgentAnalyticsEvent.RevenueFormExecute, { Provider: this.provider, RCActionType: 'rc_show_ticket_search' });

    this.busy = true;
    this.ticketInfo = null;
    this.revenueControl.showTicketInfo(request).subscribe(
      (response) => {
        this.busy = false;

        if (!response.Success) {
          this.notifications.error(response.Message || 'Failed fetching ticket info.');
          return;
        }

        // response.TicketInfo.PaymentsMade = this.fakePayments();
        this.ticketInfo = response.TicketInfo;
      },
      (err) => {
        console.log(err);
        this.notifications.error(err.message || 'Failed fetching ticket info.');
        this.busy = false;
      },
    );
  }
}
