import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { CallsMapType, IConversation, IIntegrationAppService, IOutgoingConversation } from '../../../abstractions';

import { FakeConversation } from './fake-conversation';

export interface FinishedConversation {
  sessionId: string;
  campaignId: string;
}

@Injectable()
export class NoIntegrationAppService implements IIntegrationAppService {
  public get name(): string {
    return 'None';
  }

  constructor() {}

  public get calls$(): Observable<CallsMapType> {
    return of({});
  }

  public get outgoingCall$(): Observable<IOutgoingConversation> {
    return of(null);
  }

  public get isAuthenticated$(): Observable<boolean> {
    return of(true);
  }
  public get initialized$(): Observable<boolean> {
    return of(true);
  }

  signIn(username: string, password: string): Observable<boolean> {
    throw new Error('Method not implemented.');
  }
  signInAAD(): Observable<boolean> {
    throw new Error('Method not implemented.');
  }
  signOut(): Observable<any> {
    return of(true);
  }

  createConversation(caller: string, callId: string, sessionId: string, agentSip: string): Observable<IConversation> {
    let conversation = new FakeConversation(caller, agentSip, false);
    conversation.callId = callId;
    conversation.sessionId = sessionId;
    return of(conversation);
  }
}
