import { Injectable } from '@angular/core';
import { Observable, zip } from 'rxjs';
import { map } from 'rxjs/operators';

import { HttpClient, HttpParams } from '@angular/common/http';
import { BaseResponse, IRestBaseListResponse } from '../models/rest/baseResponse';
import { IGetFlashParcsCompaniesRequest, IGetFlashParcsLocationsRequest } from '../models/rest/revenueControl/revenueControlRequest';
import { IFlashParcsCompaniesResponse, IFlashParcsLocationsResponse } from '../models/rest/revenueControl/revenueControlResponse';

export interface IManufacturer {
  Id: number;
  Name: string;
  IsIntegrated: boolean;
  CanOpenGate: boolean;
  CanCloseGate: boolean;
  CanSetPrice: boolean;
  CanEjectTicket: boolean;
  CanSwallowTicket: boolean;
  CanShowTicketInfo: boolean;
  CanSetLostTicket: boolean;
  CanGetRates: boolean;
  fkRegion: number;
}

export interface AmanoOneFacility {
  FacilityID: string;
  FacilityName: string;
}

export interface IPParkingFacility {
  FacilityCode: string;
  FacilityName: string;
}

export interface AmanoOneFacilityView extends AmanoOneFacility {
  FacilityView: string;
}

export interface IPParkingFacilityView extends IPParkingFacility {
  FacilityView: string;
}

interface IAmanoOneFacilityResponse extends BaseResponse {
  Facilities: AmanoOneFacility[];
}

interface IIPParkingFacilityResponse extends BaseResponse {
  Facilities: IPParkingFacility[];
}

export interface RevenueDevice {
  Id: string;
  Name: string;
}

export interface RevenueRate {
  Id: string;
  Name: string;
}

export interface RevenueDeviceView extends RevenueDevice {
  View: string;
}

export interface RevenueDevicesResponse extends BaseResponse {
  Devices: Array<RevenueDevice>;
}

export interface RevenueRatesResponse extends BaseResponse {
  Rates: Array<RevenueRate>;
}

export interface ISetPriceModel {
  LotId: number;
  LaneId: number;
  CommandParams: {
    __type: string;
    Price: number;
    Ticket: number;
  };
}

export interface IRevenueControlRequestBase {
  LotId: number;
  LaneId: number;
}
export interface IRevenueControlRequest<TParams> extends IRevenueControlRequestBase {
  CommandParams: TParams;
}

export class ShowTicketInfoParams {
  Ticket: number;
}

export class TicketInfo {
  TicketNumber: number;
  PaidThroughDateTime: Date;
  ParkhouseForRateCalculation: number;
  EntryDateTime?: Date;
  ExitedDateTime?: Date;
  PaymentsMade?: Array<Payment>;
  RetractedTicket: boolean;
  UnpaidBalance: number;
}

export class Payment {
  PaymentId: number;
  PaymentAmount: number;
  PaymentDateTime: Date;
  PayMethod: string;
  ValidationsApplied?: Array<ValidationApplied>;
  LastFourDigits: string;
}
export class ValidationApplied {
  AppliedValidation?: {
    ValidationId: number;
    ValidationName?: string;
    TypeOfValidation: string;
    Amount?: number;
    PercentageOff?: number;
    MinutesOff?: number;
    RateTableId?: number;
    RateTableName?: string;
  };
  ValidationAmount: number;
  ValidationOrder: number;
}

export interface TicketInfoResponse extends BaseResponse {
  TicketInfo: TicketInfo;
}

export interface IValidationDiscount {
  ValidationId: string;
  ValidationName: string;
}
export interface IValidationDiscountsResponse extends BaseResponse {
  ValidationDiscounts: Array<IValidationDiscount>;
}

@Injectable({ providedIn: 'root' })
export class RevenueControlService {
  constructor(private _httpClient: HttpClient) {}

  // Get all Manufacturers
  getAllManufacturers(regionId: number): Observable<{ Data: Array<IManufacturer>; Total: number }> {
    let params = new HttpParams().set('fkRegion', regionId.toString());

    return this._httpClient.get<IRestBaseListResponse<IManufacturer>>('RevenueControl/Manufacturers', { params: params });
  }

  // Get the Manufacturer for the Lot
  getManufacturer(lotId): Observable<any> {
    return this._httpClient.get<any>('RevenueControl/Manufacturer/' + lotId);
  }

  // Get the Settings for the Lot
  getSettings(lotId): Observable<any> {
    return this._httpClient.get<any>('RevenueControl/Settings/' + lotId);
  }

  // Create a Manufacturer
  createManufacturer(manufacturer): Observable<any> {
    return this._httpClient.put<any>('RevenueControl/Manufacturer/', { manufacturer });
  }

  // Update a Manufacturer
  updateManufacturer(manufacturer): Observable<any> {
    return this._httpClient.put<any>('RevenueControl/Manufacturer/', { manufacturer });
  }

  // Delete a Manufacturer
  deleteManufacturer(manufacturerId): Observable<any> {
    return this._httpClient.delete<any>('RevenueControl/Manufacturer/' + manufacturerId);
  }

  setPrice(model: ISetPriceModel): Observable<any> {
    return this._httpClient.post<any>('RevenueControl/SetPrice/', model);
  }

  closeGate(model: IRevenueControlRequestBase): Observable<any> {
    return this._httpClient.post<any>('RevenueControl/CloseGate/', model);
  }

  openGate(model: IRevenueControlRequestBase): Observable<BaseResponse> {
    return this._httpClient.post<BaseResponse>('RevenueControl/OpenGate/', model);
  }

  ejectTicket(model: IRevenueControlRequestBase): Observable<any> {
    return this._httpClient.post<any>('RevenueControl/EjectTicket/', model);
  }

  swallowTicket(model: IRevenueControlRequestBase): Observable<any> {
    return this._httpClient.post<any>('RevenueControl/SwallowTicket/', model);
  }

  lostTicket<TParams>(model: IRevenueControlRequest<TParams>): Observable<any> {
    return this._httpClient.post<any>('RevenueControl/SetLostTicket/', model);
  }

  getValidationDiscounts(params: IRevenueControlRequestBase): Observable<IValidationDiscountsResponse> {
    return this._httpClient.get<any>('RevenueControl/ValidationPriceList/', { params: this.getHttpParams(params) });
  }

  showTicketInfo<TParams>(request: IRevenueControlRequest<TParams>): Observable<any> {
    return this._httpClient.post<any>('RevenueControl/ShowTicketInfo', request);
  }

  validateTicket<TParams>(model: IRevenueControlRequest<TParams>): Observable<any> {
    return this._httpClient.post<any>('RevenueControl/ValidateTicket/', model);
  }

  getFlashParcsCompanies(request: IGetFlashParcsCompaniesRequest): Observable<IFlashParcsCompaniesResponse> {
    let url =
      'RevenueControl/FlashParcsCompanies?AuthUser=' +
      request.AuthUser +
      '&AuthKey=' +
      request.AuthKey +
      '&ServiceUrl=' +
      request.ServiceUrl;
    return this._httpClient.get<IFlashParcsCompaniesResponse>(url);
  }

  getFlashParcsLocations(request: IGetFlashParcsLocationsRequest): Observable<IFlashParcsLocationsResponse> {
    let url =
      'RevenueControl/FlashParcsLocations?AuthUser=' +
      request.AuthUser +
      '&AuthKey=' +
      request.AuthKey +
      '&ServiceUrl=' +
      request.ServiceUrl +
      '&CompanyId=' +
      request.CompanyId;
    return this._httpClient.get<IFlashParcsLocationsResponse>(url);
  }

  getDevices(lotId: number): Observable<RevenueDevicesResponse> {
    return this._httpClient.get<RevenueDevicesResponse>('RevenueControl/Devices?lotId=' + lotId);
  }

  getRates(lotId: number): Observable<RevenueRatesResponse> {
    return this._httpClient.get<RevenueRatesResponse>('RevenueControl/Rates?lotId=' + lotId);
  }

  getDataparkLocations(serviceUrl: string, isGateway: boolean, gatewayId: string, reserveGatewayId: string) {
    let params = new HttpParams();
    params = params.append('ServiceUrl', serviceUrl);
    params = params.append('IsBehindGateway', '' + isGateway);
    params = params.append('RevenueControlGatewayId', gatewayId);
    params = params.append('revenueControlReserveGatewayId', reserveGatewayId);
    return this._httpClient.get<RevenueDevicesResponse>('RevenueControl/DataparkLocations', { params: params });
  }

  getAmanoOneFacilities(
    url: string,
    clientId: number,
    clientSecret: string,
    behindGateway: boolean,
    gatewayId: string,
    reserveGatewayId: string,
  ): Observable<IAmanoOneFacilityResponse> {
    return this._httpClient.get<IAmanoOneFacilityResponse>('RevenueControl/AmanoOneFacilities', {
      params: {
        Url: url,
        ClientId: clientId,
        ClientSecret: clientSecret,
        BehindGateway: behindGateway,
        GatewayId: gatewayId,
        ReserveGatewayId: reserveGatewayId,
      },
    });
  }

  getIpParkingFacilities(
    commandServiceUrl: string,
    commandServiceUsername: string,
    commandServicePassword: string,
    configurationServiceUrl: string,
    configurationServiceUsername: string,
    configurationServicePassword: string,
  ): Observable<IIPParkingFacilityResponse> {
    return this._httpClient.get<IIPParkingFacilityResponse>('RevenueControl/IPParking/Facilities', {
      params: {
        CommandServiceUrl: commandServiceUrl,
        CommandServiceUsername: commandServiceUsername,
        CommandServicePassword: commandServicePassword,
        ConfigurationServiceUrl: configurationServiceUrl,
        ConfigurationServiceUsername: configurationServiceUsername,
        ConfigurationServicePassword: configurationServicePassword,
      },
    });
  }

  private getHttpParams(params: any): HttpParams {
    let httpParams = new HttpParams();

    Object.keys(params).forEach((key) => {
      httpParams = httpParams.append(key, params[key].toString());
    });

    return httpParams;
  }
}
