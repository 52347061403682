import { Component, OnInit, HostBinding, ViewChild, Output, EventEmitter } from '@angular/core';

import { Observable, Observer, Subscription } from 'rxjs';

import { ModalDirective } from 'ngx-bootstrap/modal';

import { IncomingCallsService } from '../../../services/incoming-calls.service';
import { IConversation, IncomingCall } from '../../../../abstractions';
import { AppNotificationsService } from '@libs/portal-common/system';

declare const window: any;

const keys = {
  '1': 440,
  '2': 480,
  '3': 520,
  '4': 540,
  '5': 580,
  '6': 620,
  '7': 660,
  '8': 700,
  '9': 740,
  '0': 780,
  '#': 820,
  '*': 860,
  a: 900,
  b: 940,
  c: 980,
  d: 1020,
};

const managementKeys = {
  ArrowLeft: true,
  ArrowRight: true,
  Backspace: true,
  Delete: true,
  Shift: true,
};

@Component({
  selector: 'app-conversation-management',
  templateUrl: './conversation-management.component.html',
  styleUrls: ['./conversation-management.component.scss'],
})
export class ConversationManagementComponent implements OnInit {
  @ViewChild('modal', { static: true }) public modal: ModalDirective;

  conversation: IConversation = null;

  conversationSubscription: Subscription;

  resultObserver: Observer<string>;

  dtmf = '';
  context: AudioContext;

  volume = 0.05;
  muted = false;

  constructor(
    private incomingCallsService: IncomingCallsService,
    private notifications: AppNotificationsService,
  ) {}

  ngOnInit() {}

  open(conversation: IConversation): Observable<string> {
    this.conversation = conversation;
    this.dtmf = '';

    this.conversationSubscription = this.conversation.state$.subscribe((state) => {
      if (state === 'HangedUp' || state === 'Finished') {
        this.cancel();
      }
    });

    this.modal.show();

    return new Observable((observer) => (this.resultObserver = observer));
  }

  buttonClicked(key: string) {
    let tone = keys[key];
    if (tone) {
      this.generateTone(tone);
    }

    this.dtmf += key;

    this.conversation.sendDtmf(key).subscribe(
      () => {},
      (err) => {
        this.notifications.error(err);
      },
    );
  }

  textEnter(event: any) {
    let tone = keys[event.key];
    if (tone) {
      this.generateTone(tone);
      this.conversation.sendDtmf(event.key).subscribe(
        () => {},
        (err) => {
          // this.notifications.error(err);
        },
      );

      return;
    }

    let managementKey = managementKeys[event.key];
    if (!managementKey) {
      return;
    }
  }

  ok() {
    this.modal.hide();

    this.clearSubscriptions();

    if (!!this.resultObserver) {
      this.resultObserver.next(this.dtmf);
      this.resultObserver.complete();
    }
  }

  cancel() {
    this.modal.hide();

    this.clearSubscriptions();

    if (!!this.resultObserver) {
      this.resultObserver.next(null);
      this.resultObserver.complete();
    }
  }

  clearSubscriptions() {
    if (this.conversationSubscription) {
      this.conversationSubscription.unsubscribe();
      this.conversationSubscription = null;
    }
  }

  toggleMute() {
    this.muted = !this.muted;
  }

  private generateTone(tone: number): void {
    if (!this.hasAudioSupport()) {
      return;
    }

    let volume = this.muted ? 0 : this.volume;
    if (volume === 0) {
      return;
    }

    this.context = this.context || new AudioContext();
    let osc = this.context.createOscillator();
    let gainNode = this.context.createGain();

    osc.connect(gainNode);
    gainNode.connect(this.context.destination);

    osc.type = 'sine';
    osc.frequency.value = tone;

    gainNode.gain.value = volume;

    osc.start();
    osc.stop(this.context.currentTime + 0.1);
  }

  public hasAudioSupport(): boolean {
    return !!window['AudioContext'];
  }
}
