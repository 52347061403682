export const PresenceAndNoteSet = 'PresenceAndNoteSet';

export type Presence = 'Online' | 'Busy' | 'DoNotDisturb' | 'BeRightBack';

export interface PresenceAndNote {
  Availability: Presence;
  Activity: string;
  Id: string;
  PresenceType: number;
}
