import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { from, Observable, of, Subject } from 'rxjs';

import { AuthenticationParameters, AuthResponse, Configuration, UserAgentApplication } from 'msal';

import { AppNotificationsService } from '@libs/portal-common';

import { CallsMapType, IConversation, IIntegrationAppService, IOutgoingConversation, ISkypeConversation } from '../../../abstractions';

import { AppConfigurationService, APP_CONFIGURATION } from '../../../services/app-configuration.service';
import { CC4AllConversation } from '../cc4all-conversation';

import { mergeMap, map, tap, filter, timeout } from 'rxjs/operators';
import { CC4AllSocket } from './cc4all-socket';
import { CallSession, PresenceAndNote } from '../model';

import { CC4AllWarmTransferConversation } from '../cc4all-warm-transfer-conversation';
import { CC4AllOutboundConversation } from '../cc4all-outbound-conversation';

export interface FinishedConversation {
  sessionId: string;
  campaignId: string;
}

const AAD_TOKEN = 'cc4all-aad-token';

export const BUSY_PRESENSE = 'Busy';
export const BUSY_NOTE = 'OnBreak';

@Injectable()
export class CC4AllAppService implements IIntegrationAppService {
  private webSocket: CC4AllSocket;
  private _outgoingCall = new Subject<IOutgoingConversation>();

  public get name(): string {
    return 'CC4All';
  }

  public get calls$(): Observable<CallsMapType> {
    return of({});
  }

  get socket(): CC4AllSocket {
    return this.webSocket;
  }

  public get presenceAndNote$(): Observable<PresenceAndNote> {
    return this.webSocket.presenceAndNote$;
  }

  public get callSessions$(): Observable<CallSession> {
    return this.webSocket.callSessions$;
  }

  public get isAuthenticated$(): Observable<boolean> {
    if (!isPlatformBrowser(this.platformId)) {
      return of(true);
    }
    return this.webSocket.authenticated$;
  }

  public get initialized$(): Observable<boolean> {
    if (!!this.webSocket) {
      return this.webSocket.initialized$;
    }
    return of(false);
  }

  get outgoingCall$(): Observable<IOutgoingConversation> {
    return this._outgoingCall.asObservable();
  }

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(APP_CONFIGURATION) private configuration: AppConfigurationService,
    private notifications: AppNotificationsService,
  ) {
    this.initialize();
  }

  private initialize() {
    if (isPlatformBrowser(this.platformId) && this.configuration.data.integration.type === 'cc4all' && !this.webSocket) {
      this.webSocket = new CC4AllSocket(
        this.configuration.data.integration.cc4all_settings.socketsServerUrl,
        this.configuration.data.integration.cc4all_settings.cc4AllTenant,
        this.platformId,
        this.configuration.data.integration.cc4all_settings.debug,
      );
    }
  }

  public signInAAD(): Observable<boolean> {
    this.initialize();

    let userAgentApplication = this.getMsalApp();
    const clientId = this.configuration.data.integration.cc4all_settings.aadClientId;
    let loginRequest = <AuthenticationParameters>{
      scopes: ['openid', 'email', 'profile', `${clientId}/.default`],
      forceRefresh: false,
    };

    const signInPromise = userAgentApplication
      .loginPopup(loginRequest)
      .then(() => userAgentApplication.acquireTokenSilent(loginRequest))
      .catch((err) => {
        console.log(err);
        this.notifications.error(err);
      });

    return from(signInPromise).pipe(
      mergeMap((response: AuthResponse) => {
        localStorage.setItem(AAD_TOKEN, response.accessToken);
        return this.webSocket.aadSignIn(response.accessToken);
      }),
      map((res) => !!res.JWT),
    );
  }

  public signIn(username: string, password: string): Observable<boolean> {
    this.initialize();

    return this.webSocket.signIn(username, password).pipe(map((res) => !!res.JWT));
  }

  public signOut(): Observable<any> {
    this.signOutLocal();

    if (!!localStorage.getItem(AAD_TOKEN)) {
      return this.signOutAad().pipe(tap((res) => localStorage.removeItem(AAD_TOKEN)));
    }

    return of(true);
  }

  private signOutLocal() {
    this.webSocket.signOut();
  }

  private signOutAad(): Observable<any> {
    let userAgentApplication = this.getMsalApp();
    userAgentApplication.logout();
    return of(true);
  }

  private getMsalApp(): UserAgentApplication {
    const clientId = this.configuration.data.integration.cc4all_settings.aadClientId;
    let returnUrl = window.location.href.split('?')[0];
    let config = <Configuration>{
      auth: {
        clientId: clientId,
        authority: 'https://login.microsoftonline.com/common',
        redirectUri: returnUrl,
        postLogoutRedirectUri: returnUrl,
      },
    };

    return new UserAgentApplication(config);
  }

  finishCall(conversationKey: string, sessionId): Observable<any> {
    return this.webSocket.ejectFromMeeting(conversationKey, sessionId);
  }

  sendDtmf(dtmf: string, conversationKey: string): Observable<any> {
    return this.webSocket.sendDTMF(conversationKey, dtmf);
  }

  setReadyStatus(): Observable<any> {
    return this.webSocket.changePresence('Online', '');
  }
  setBusyStatus(): Observable<any> {
    return this.webSocket.changePresence(BUSY_PRESENSE, BUSY_NOTE);
  }

  pauseCall(conversationKey: string, sessionId: string): Observable<boolean> {
    return this.webSocket.pauseCall(conversationKey, sessionId);
  }
  resumeCall(conversationKey: string, sessionId: string): Observable<boolean> {
    return this.webSocket.resumeCall(conversationKey, sessionId);
  }

  public warmTransferStart(conversationKey: string, sessionId: string, targetUri: string): Observable<IOutgoingConversation> {
    return this.webSocket.warmTransferStart(conversationKey, sessionId, targetUri).pipe(
      map((res) => {
        let conversation = new CC4AllWarmTransferConversation(conversationKey, sessionId, targetUri, this);

        this._outgoingCall.next(conversation);
        return conversation;
      }),
    );
  }

  public warmTransferCancel(conversationKey: string, sessionId: string, targetUri: string): Observable<any> {
    return this.webSocket.warmTransferCancel(conversationKey, sessionId, targetUri);
  }

  public createOutboundCall(uri: string): Observable<IOutgoingConversation> {
    const callId = ((Math.random() * 1000) % 10000) + '';

    return this.webSocket.getEndPointListForAgent().pipe(
      mergeMap((res) => {
        console.log(res);
        let endpoint = res.find((x) => x.IsDefault) || res.find((x) => true);
        if (!endpoint) {
          throw Error('No active endpoints found.');
        }

        return this.webSocket.createOutboundCall(callId, endpoint.EndpointID, endpoint.EndpointLineUri, uri);
      }),
      mergeMap((res) => this.webSocket.callSessions$),
      filter((callSession) => callSession.ConversationKey === callId),
      map((callSession) => {
        console.log(callSession);
        let conversation = new CC4AllOutboundConversation(
          callSession.ConversationKey,
          callSession.SessionId,
          this.webSocket.sip,
          uri,
          this,
        );

        this._outgoingCall.next(conversation);
        return conversation;
      }),
      timeout(5000),
    );
  }

  createConversation(caller: string, callId: string, sessionId: string, agentSip: string): Observable<IConversation> {
    return this.webSocket.getAgentCallSession().pipe(
      map((res) => {
        if (!res || !res.ConversationKey) {
          throw Error('No active call session.');
        }

        let conversation = new CC4AllConversation(caller, agentSip, this);
        conversation.callId = res.ConversationKey;
        conversation.sessionId = sessionId;
        conversation.conversationKey = res.ConversationKey;

        this.setBusyStatus().subscribe((x) => {});
        return conversation;
      }),
    );
  }
}
