import { NgModule, ModuleWithProviders } from '@angular/core';

import { Five9AppService } from './services/five9-app.service';
import { FIVE9_HTTP_INTERCEPTORS, Five9HttpClient } from './services/five9-httpclient';
import { Five9AuthInterceptor } from './services/five9-auth.interceptor';
import { Five9StateService } from './services/five9-state.service';

@NgModule({})
export class Five9Module {
  static forRoot(): ModuleWithProviders<Five9Module> {
    return {
      ngModule: Five9Module,
      providers: [
        { provide: FIVE9_HTTP_INTERCEPTORS, useClass: Five9AuthInterceptor, multi: true },
        Five9StateService,
        Five9HttpClient,
        Five9AppService,
      ],
    };
  }

  constructor() {}
}
