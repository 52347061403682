<div class="panel-body" *ngIf="!isAuthenticated && integrationInitialized">
  <div class="internalAuthForm">
    <h2 class="text-center">{{ integration.name }} API</h2>
    <p *ngIf="error" class="alert-color text-center" style="padding-bottom: 1em">{{ error }}</p>
    <form method="post" name="loginForm" #loginForm="ngForm" novalidate>
      <div class="form-group validation-container" [class.has-error]="username | hasErrors: validate">
        <input
          class="form-control input-lg"
          type="text"
          name="username"
          [(ngModel)]="model.username"
          placeholder="Username"
          #username="ngModel"
          autofocus
          required
        />
        <app-validation-errors [model]="username" [validate]="validate"></app-validation-errors>
      </div>

      <div class="form-group validation-container" [class.has-error]="password | hasErrors: validate">
        <input
          class="form-control input-lg"
          type="password"
          name="password"
          [(ngModel)]="model.password"
          placeholder="Password"
          #password="ngModel"
          required
        />
        <app-validation-errors [model]="password" [validate]="validate"></app-validation-errors>
      </div>

      <div class="row">
        <div class="col-sm-12">
          <button type="submit" class="btn btn-lg btn-block button--primary" (click)="login()" [disabled]="busy">Sign in</button>
        </div>
      </div>
      <br />
    </form>
  </div>

  <div class="externalAuthButtons">
    <button class="btn btn-lg btn-block btn-social btn-adn" (click)="loginAAD()" [disabled]="busy">
      <i class="fa fa-windows"></i>
      Sign in with AAD
    </button>
  </div>
</div>
