import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { BaseResponse, IRestBaseListResponse } from '../models/rest/baseResponse';
import {
  CallCenterStatististicSettingsResponse,
  GetCallCenterStatististicQueuesResponse,
  CreateOrUpdateCallCenterStatisticSettingsRequest,
  CallCenterStatisticSettings,
} from './../models';

@Injectable({ providedIn: 'root' })
export class CallCenterStatisticService {
  path = 'settings/callcenterstatistic/';

  constructor(private httpClient: HttpClient) {}

  getAll(regionId?: number): Observable<IRestBaseListResponse<CallCenterStatisticSettings>> {
    let params = new HttpParams();
    if (!!regionId) {
      params = params.set('fkRegion', regionId.toString());
    }

    return this.httpClient.get<IRestBaseListResponse<CallCenterStatisticSettings>>(this.path, { params: params });
  }

  insertOrUpdate(request: CreateOrUpdateCallCenterStatisticSettingsRequest): Observable<CallCenterStatististicSettingsResponse> {
    return this.httpClient.put<CallCenterStatististicSettingsResponse>(this.path, request);
  }

  delete(id: number): Observable<BaseResponse> {
    return this.httpClient.delete<BaseResponse>(this.path + id);
  }

  getQueues(fkRegion?: number): Observable<GetCallCenterStatististicQueuesResponse> {
    return this.httpClient.post<GetCallCenterStatististicQueuesResponse>(`${this.path}queues`, { fkRegion });
  }

  getClientQueues(id: number, clientId: string, clientSecret: string): Observable<GetCallCenterStatististicQueuesResponse> {
    return this.httpClient.post<GetCallCenterStatististicQueuesResponse>(`${this.path}client-queues`, {
      id: id,
      clientId: clientId,
      clientSecret: clientSecret,
    });
  }

  getRegionsStatistic(regionIds: string): Observable<CallCenterStatisticsData> {
    let params = {
      RegionIds: regionIds,
    };
    return this.getStatistics(params);
  }

  getQueuesStatistic(queueIds: string): Observable<CallCenterStatisticsData> {
    let params = {
      QueueIds: queueIds,
    };
    return this.getStatistics(params);
  }

  getStatistics(params: any = null): Observable<CallCenterStatisticsData> {
    return this.httpClient.get<CallCenterStatisticsData>('CallCenterStatistics', { params: params });
  }
}

export class Call {
  Caller: string;
  LocationName: string;
  WaitSeconds: number;
}

export class Agent {
  AgentId: string;
  Availability: string;
  DisplayName: string;
  Calls24H: number;
  Activity: string;
  AgentUri?: string;
  AvailabilityId?: number;
  CallId?: any;
  IsAssigned?: boolean;
  PublishedTime?: string;
  QueueId?: string;
  WorkflowUri?: string;
}

export class CallCenterSummary {
  AvailableAgents = 0;
  ActiveCalls = 0;
  CallsWaitingInQueue = 0;
  TotalCalls24H = 0;
  AvgQueueLh = '0s';
  AvgQueue24H = '0s';
  AvgCallDurationLh = '0s';
  AvgCallDuration24H = '0s';
  AvgWrapUpLh = '0s';
  AvgWrapUp24H = '0s';
  MaxCall24H = 0;
  LongestQueueLh = '0s';
  LongestQueue24H = '0s';
  TotalCallsLh = '0';
  CallsOver30SecondsInQueueLh = 0;
  CallsOver30SecondsInQueue24H = 0;
  Abandoned24H = 0;
  Disconnected24H = 0;
  Cancelled24H = 0;
  AbandonedLh = 0;
  DisconnectedLh = 0;
  CancelledLh = 0;
}

export class CallCenterStatisticsData {
  CdrSummary: CallCenterSummary = new CallCenterSummary();
  CallsInQueue: Array<Call> = [];
  Agents: Array<Agent> = [];
}
