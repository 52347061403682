import { Injectable, Inject } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { AppConfigurationService, APP_CONFIGURATION, IAppConfigurationBase } from '../services/app-configuration.service';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  constructor(@Inject(APP_CONFIGURATION) private configuration: AppConfigurationService<IAppConfigurationBase>) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let url = request.url;
    if (!url.startsWith('http')) {
      url = `${this.configuration.apiBaseUrl}/${url}`;
    }

    let headers: {
      [name: string]: string;
    } = {};

    let ignoreContentType =
      typeof request.body === 'undefined' ||
      request.body === null ||
      request.body.toString() === '[object FormData]' ||
      request.headers.has('Content-Type');

    if (!ignoreContentType) {
      headers['content-type'] = 'application/json; charset=utf-8';
    }

    request = request.clone({
      url: url,
      setHeaders: headers,
    });

    return next.handle(request);
  }
}
