<div class="filter">
  <div class="filter-container row">
    <div class="form-group col-sm-6 col-md-4 col-lg-2">
      <label>From</label>
      <kendo-datetimepicker class="umj-datepicker-sm" [readOnlyInput]='true' [readonly]="disabled" [(value)]="filter.Start" style="width: 100%">
      </kendo-datetimepicker>
    </div>
    <div class="form-group col-sm-6 col-md-4 col-lg-2">
      <label>To</label>
      <kendo-datetimepicker class="umj-datepicker-sm" [readOnlyInput]='true' [readonly]="disabled" [(value)]="filter.End" style="width: 100%">
      </kendo-datetimepicker>
    </div>
    <div class="form-group col-sm-2">
      <label>Day of week</label>
      <kendo-dropdownlist
        [data]="days"
        [(value)]="filter.DayOfWeek"
        [filterable]="true"
        (filterChange)="handleDaysFilter($event)"
        [textField]="'Name'"
        [valueField]="'Id'"
        [defaultItem]="{ Id: null, Name: 'Select day...' }"
        class="dropdown-block form-control-sm"
        [disabled]="disabled"
        [valuePrimitive]="true"
      >
      </kendo-dropdownlist>
    </div>

    <div class="form-group col-sm-2" *ngIf="mode != 'agent' && displayLocations">
      <label>Location</label>
      <kendo-dropdownlist
        [data]="filteredLocations"
        [(value)]="filter.LotId"
        [filterable]="true"
        (filterChange)="handleLocationsFilter($event)"
        [textField]="'LotName'"
        [valueField]="'Id'"
        [defaultItem]="{ Id: null, LotName: 'Select location...' }"
        [valuePrimitive]="true"
        [disabled]="disabled"
        class="dropdown-block form-control-sm"
        name="location"
      >
      </kendo-dropdownlist>
    </div>

    <div class="form-group col-sm-2" *ngIf="mode == 'agent' && !skypeAgentEmail && displayAgents">
      <label>Agent</label>
      <kendo-dropdownlist
        [data]="filteredAgents"
        [(value)]="filter.SipAddress"
        [filterable]="true"
        (filterChange)="handleAgentsFilter($event)"
        [textField]="'Name'"
        [valueField]="'SIP'"
        [defaultItem]="{ SIP: null, Name: 'Select agent...' }"
        class="dropdown-block"
        [disabled]="disabled"
        [valuePrimitive]="true"
        class="form-control-sm"
      >
      </kendo-dropdownlist>
    </div>

    <div class="form-group col-sm-2">
      <label>Incident Id</label>
      <input type="number" class="form-control form-control-sm" [(ngModel)]="filter.IncidentId" />
    </div>

    <div class="form-group col-sm-2">
      <button class="button ND--buttons--primary btn-search" [disabled]="disabled" (click)="searchClick()">Select</button>
    </div>
  </div>
</div>
