import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { ANALYTICS, AuthService, IAnalyticsService, AnalyticsEvent } from '@libs/portal-common/services';

@Component({
  template: '',
})
export class LogoutComponent implements OnInit {
  constructor(
    private authService: AuthService,
    private router: Router,
    @Inject(ANALYTICS) private analytcis: IAnalyticsService,
  ) {}

  ngOnInit() {
    this.authService.logout().then((response) => {
      this.analytcis.track(AnalyticsEvent.Logout, null);
      if (response && response.LogoutUrl) {
        window.location.href = response.LogoutUrl;
        return;
      }
      this.router.navigate(['/auth/login']);
    });
  }
}
