<vg-player #player (onPlayerReady)="onPlayerReady($event)">
  <vg-controls>
    <vg-play-pause></vg-play-pause>
    <vg-playback-button></vg-playback-button>

    <vg-scrub-bar>
      <vg-scrub-bar-current-time></vg-scrub-bar-current-time>
      <vg-scrub-bar-buffering-time></vg-scrub-bar-buffering-time>
    </vg-scrub-bar>

    <vg-time-display vgProperty="total" vgFormat="mm:ss"></vg-time-display>

    <vg-mute></vg-mute>
    <vg-volume></vg-volume>
  </vg-controls>

  <audio #media [vgMedia]="media" preload="auto" [attr.type]="type" [src]="src | safeUrl"></audio>
</vg-player>
