import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'ConvertSecsToDate', pure: true })
export class ConvertSecsToDatePipe implements PipeTransform {
  transform(value: any, args: number): any {
    if (value) {
      var year: number = args || 2000;
      var date = new Date(year, 0, 1);
      date.setSeconds(date.getSeconds() + value);
      return date;
    }
  }
}
