import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import {
  CreateOrUpdateAudioRecordsSettingsRequest, CreateOrUpdateCallCabinetSettingsRequest,
  DeleteAudioRecordsSettingsRequest,
  GetAudioRecordsSettingsRequest, GetOrDeleteCallCabinetSettingsRequest,
} from '../models/rest/audio-records/audioRecordsRequests';
import {
  AudioRecordsSettingsResponse,
  CallCabinetSettingsResponse
} from '../models/rest/audio-records/audioRecordsResponses';
import { BaseResponse } from '../models/rest/baseResponse';
import { HttpClient, HttpParams } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class AudioRecordsService {
  constructor(private _httpClient: HttpClient) {}

  // Get list of incident's audio records
  getByIncident(incidentId): Observable<any> {
    return this._httpClient.get('AudioRecords/List?IncidentId=' + incidentId);
  }

  // todo: delete later
  // georgysurkov: this part is used in e2e tests, but cypress migration is not finished. I will delete it when I complete migration
  // Download an audio record (as Blob)
  download(recordId): Observable<Blob> {
    let headers = new HttpHeaders();
    headers.append('Accept', 'application/octet-stream');

    return this._httpClient.get('AudioRecords/Download/' + recordId, { headers: headers, responseType: 'blob' });
  }

  getAudioRecordsSettingsList(regionId?: number): Observable<any> {
    let params = new HttpParams();
    if (!!regionId) {
      params = params.set('fkRegion', regionId.toString());
    }

    return this._httpClient.get('audiorecords/repositorysettings', { params: params });
  }

  getAudioRecordsSettings(request: GetAudioRecordsSettingsRequest): Observable<AudioRecordsSettingsResponse> {
    return this._httpClient.get<AudioRecordsSettingsResponse>(`audiorecords/repositorysettings/${request.Id}`);
  }

  createOrUpdateAudioRecordsSettings(request: CreateOrUpdateAudioRecordsSettingsRequest): Observable<AudioRecordsSettingsResponse> {
    return this._httpClient.put<AudioRecordsSettingsResponse>('audiorecords/repositorysettings', request);
  }

  deleteAudioRecordsSettings(request: DeleteAudioRecordsSettingsRequest): Observable<BaseResponse> {
    return this._httpClient.delete<BaseResponse>(`audiorecords/repositorysettings/${request.Id}`);
  }

  getCallCabinetSettings(request: GetOrDeleteCallCabinetSettingsRequest): Observable<CallCabinetSettingsResponse> {
    return this._httpClient.get<CallCabinetSettingsResponse>(`audiorecords/callcabinetsettings/${request.Id}`);
  }

  getCallCabinetSettingsList(): Observable<any> {

    return this._httpClient.get('audiorecords/callcabinetsettings');
  }

  createOrUpdateCallCabinetSettings(request: CreateOrUpdateCallCabinetSettingsRequest): Observable<CallCabinetSettingsResponse>
  {
    return this._httpClient.put<CallCabinetSettingsResponse>('audiorecords/callcabinetsettings', request);
  }
  deleteCallCabinetSettings(request: GetOrDeleteCallCabinetSettingsRequest): Observable<BaseResponse>{
    return this._httpClient.delete<BaseResponse>(`audiorecords/callcabinetsettings/${request.Id}`);
  }
}
