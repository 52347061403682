<div class="ibox__title" *ngIf="!!header">
  <h3 class="ibox__heading">{{ header }}</h3>
  <div class="ibox__tools">
    <button class="button button--primary" (click)="save()"><i class="fa fa-check"></i> Save</button>
    <button class="button" (click)="cancel()"><i class="fa fa-ban"></i> Cancel</button>
    <button class="button" (click)="restoreDefaults()">Restore defaults</button>
  </div>
</div>

<div [class]="contentClass">
  <div class="row" style="padding: 0 10px">
    <div class="col-sm-6" style="padding-right: 30px">
      <h5 style="margin-left: -15px">Available widgets</h5>
      <kendo-sortable
        class="row widgets"
        [kendoSortableBinding]="availableWidgets"
        [navigable]="true"
        [animation]="true"
        [zone]="zone"
        [itemClass]="getItemClass('')"
        [disabledItemClass]="getItemClass('disabled')"
        [activeItemStyle]="activeItemStyle('left')"
        emptyText="No widgets available"
        emptyItemClass="empty-sortable"
        [activeItemClass]="getItemClass('active')"
        (dragStart)="onDragStart($event, 'left')"
        (dragEnd)="onDragEnd($event, 'left')"
        (mouseup)="onMouseUp($event, 'left')"
        (mouseover)="onMouseOver($event, 'left')"
        [class.dragging]="dragging === 'left'"
      >
        <ng-template let-item="item">
          <div #itemRef [style]="calculateStyle('left', itemRef)">
            {{ item.Title }}
          </div>
        </ng-template>
      </kendo-sortable>
    </div>

    <div class="col-sm-6" style="padding-left: 30px">
      <h5 style="margin-left: -15px">Selected widgets</h5>
      <kendo-sortable
        class="row widgets"
        [kendoSortableBinding]="selectedWidgets"
        [navigable]="true"
        [animation]="true"
        [zone]="zone"
        [itemClass]="getItemClass('', columns)"
        [activeItemClass]="getItemClass('active', columns)"
        [activeItemStyle]="activeItemStyle('right')"
        emptyText="No widgets available"
        emptyItemClass="empty-sortable"
        #sortableRight
        (dragStart)="onDragStart($event, 'right')"
        (dragEnd)="onDragEnd($event, 'right')"
        (mouseup)="onMouseUp($event, 'right')"
        (mouseover)="onMouseOver($event, 'right')"
        [class.dragging]="dragging === 'right'"
      >
        <ng-template let-item="item">
          <div #itemRef [style]="calculateStyle('right', itemRef)">
            {{ item.Title }}
          </div>
        </ng-template>
      </kendo-sortable>
    </div>
  </div>
</div>
