import { Component, OnInit, ViewChild, Inject } from '@angular/core';

import { ModalDirective } from 'ngx-bootstrap/modal';
import { VoiceBotsService } from '@libs/portal-common/services';
import { Subscription } from 'rxjs';
import moment from 'moment';

interface CallSegmentViewModel {
  Time: string;
  Message: string;
  IsBot: boolean;
}

@Component({
  selector: 'app-voicebot-transcription',
  templateUrl: './voicebot-transcription.component.html',
  styleUrls: ['./voicebot-transcription.component.scss'],
})
export class VoiceBotTranscriptionComponent implements OnInit {
  private subscription: Subscription;

  @ViewChild('modal', { static: true }) public modal: ModalDirective;

  callSegmentList: CallSegmentViewModel[] = [];
  agentName: string;
  transferTime: string;
  searchString: string;

  constructor(private voiceBotService: VoiceBotsService) {}

  ngOnInit() {}

  open(incidentId: number, botEventConferenceId: string) {
    this.voiceBotService.getBotEventDetails(incidentId, botEventConferenceId).subscribe((response) => {
      this.callSegmentList.length = 0;
      if (response.Success) {
        response.BotEvent.CallSegments.forEach((callSegment) =>
          this.callSegmentList.push({
            Time: moment.utc(callSegment.Offset * 1000).format('mm:ss'),
            Message: callSegment.Text,
            IsBot: callSegment.IsBot,
          }),
        );
        this.agentName = response.AgentName;
        this.transferTime = response.Incident.CallTime;
      }
    });
    this.modal.show();
  }

  cancel() {
    this.modal.hide();
  }
}
