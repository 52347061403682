import { Observable, BehaviorSubject, of, timer, Subscription, zip } from 'rxjs';
import { delay, map, mergeMap, skip, take, tap } from 'rxjs/operators';

import {
  IConversation,
  IConversationParameters,
  ConversationState,
  IOutgoingConversation,
  ConversationBase,
  SubmissionParameters,
} from '../../abstractions';
import { IUser } from './model';
import { FinesseAppService } from './services/finesse-app.service';

export class FinesseConversation extends ConversationBase implements IConversation {
  private _state = new BehaviorSubject<ConversationState>('Incoming');
  private _isPaused = new BehaviorSubject<boolean>(false);

  private _user = new BehaviorSubject<IUser>(null);
  private userStateSubscription: Subscription;
  private userDialogsSubscription: Subscription;

  private _acceptTime: Date;
  private _hangupTime: Date;
  private _wrapupTime: Date;

  private _parameters: IConversationParameters;

  public callId: string;
  public sessionId: string;

  public resolving = false;
  public _isCancelled = false;
  public skipSavingIncident = false;

  public get agentSip(): string {
    return this._agentSip;
  }

  public get agentName(): string {
    return this._agentSip;
  }

  public get callSource(): string {
    return 'cisco';
  }

  public get callDuration(): number {
    if (this._acceptTime && this._hangupTime) {
      let res = (+this._hangupTime - +this._acceptTime) / 1000;
      return Math.round(res);
    }

    return 0;
  }

  public get wrapupDuration(): number {
    if (this._hangupTime && this._wrapupTime) {
      let res = (+this._wrapupTime - +this._hangupTime) / 1000;
      return Math.round(res);
    }

    return 0;
  }

  public get queueDuration(): number {
    return 0;
  }

  get cancelled(): boolean {
    return this._isCancelled;
  }

  public get canAccept(): boolean {
    return this._state.value === 'Incoming';
  }

  public get state$(): Observable<ConversationState> {
    return this._state.asObservable();
  }

  public get isPaused$(): Observable<boolean> {
    return this._isPaused.asObservable();
  }

  public get callerUri(): string {
    return this._uri;
  }

  constructor(
    private _uri: string,
    private _agentSip: string,
    agent: IUser,
    private finesse: FinesseAppService,
  ) {
    super();
    this._user.next(agent);

    const userId = agent.loginId;
    this.userDialogsSubscription = timer(0, 800)
      .pipe(mergeMap((x) => this.finesse.getUserDialogs(userId)))
      .subscribe((res) => {
        let currentDialog = res.find((x) => x.id === this.callId);
        if (!currentDialog || currentDialog.state === 'FAILED' || currentDialog.state === 'DROPPED') {
          this._state.next('HangedUp');
          this._hangupTime = new Date();
          this.userDialogsSubscription.unsubscribe();
        }
      });
  }

  public static Parse(content: string, finesse: FinesseAppService): IConversation {
    let parsed = JSON.parse(content);
    const conversation = new FinesseConversation(parsed.uri, parsed.agentSip, parsed.user, finesse);
    conversation._state = new BehaviorSubject<ConversationState>(parsed.state);
    return conversation;
  }

  public setParameters(parameters: IConversationParameters) {
    this._parameters = parameters;
  }

  public accept(): Observable<boolean> {
    this._state.next('Accepted');

    this._acceptTime = new Date();

    return of(true);
  }

  public reject(): Observable<boolean> {
    this._state.next('Finished');
    return of(true);
  }

  public hangUp(): Observable<boolean> {
    let user = this._user.value;

    let leave = () => {
      this.finesse
        .finishCall(this.callId, user.extension)
        .pipe(mergeMap((res) => this.finesse.setUserState(user.loginId, 'NOT_READY')))
        .subscribe(
          (res) => {
            this._state.next('HangedUp');
            this._hangupTime = new Date();
          },
          (err) => {
            console.log(err);
          },
        );
    };

    if (!this._parameters || !this._parameters.terminateCallTone) {
      leave();
    } else {
      this.sendDtmf(this._parameters.terminateCallTone).subscribe((res) => {
        leave();
      });
    }

    return of(true);
  }

  public wrapUp(): Observable<boolean> {
    if (this._state.getValue() === 'Accepted') {
      this.hangUp();
    }

    if (!!this.userStateSubscription) {
      this.userStateSubscription.unsubscribe();
    }
    if (!!this.userDialogsSubscription) {
      this.userDialogsSubscription.unsubscribe();
    }

    this._wrapupTime = new Date();
    this._state.next('Finished');
    return of(true);
  }

  public submitWrapUp(parameters: SubmissionParameters): Observable<boolean> {
    return of(true);
  }

  public cancelProcessing(): Observable<boolean> {
    this._isCancelled = true;

    return this.wrapUp();
  }
  public markCancelled() {
    this._isCancelled = true;
  }

  sendDtmf(dtmf: string): Observable<boolean> {
    let user = this._user.value;

    if (dtmf) {
      let char = dtmf.slice(0, 1);
      return this.finesse.sendDtmf(this.callId, char, user.extension).pipe(
        delay(300),
        mergeMap((x) => this.sendDtmf(dtmf.slice(1))),
      );
    }

    return of(true);
  }

  pause(): Observable<boolean> {
    this._isPaused.next(true);
    return of(true);
  }
  resume(): Observable<boolean> {
    this._isPaused.next(false);
    return of(true);
  }

  outboundCall(uri: string): Observable<IOutgoingConversation> {
    return of(null);
  }

  public stringifyAndReset(): string {
    let state = this._state.value;
    this._state = null;
    return JSON.stringify({
      state: state,
      uri: this._uri,
      agentSip: this.agentSip,
      user: this._user.value,
      skipSavingIncident: this.skipSavingIncident,
    });
  }

  private getActivity() {
    let lotName = this._parameters ? this._parameters.lotName : 'test lot';
    return `Completing - ${lotName}`;
  }
}
