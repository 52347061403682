<div class="modal fade" bsModal #modal="bs-modal" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header clearfix">
        <h4 class="modal-title pull-left">Incident's video record</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="cancel()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body">
        <div class="row justify-content-center">
          <video #videoPlayer [src]="videoUrl" style="width: 720px; margin-bottom: 1rem" controls></video>
        </div>

        <div class="modal-footer">
          <button class="button" (click)="cancel()">Close</button>
        </div>
      </div>
    </div>
  </div>
</div>
