import { Component, OnInit, Input } from '@angular/core';
import { ISkypePerson } from '../../../abstractions';

@Component({
  selector: 'app-conversation-participant',
  templateUrl: './conversation-participant.component.html',
  styleUrls: ['./conversation-participant.component.scss'],
})
export class ConversationParticipantComponent implements OnInit {
  @Input() person: ISkypePerson;
  @Input() avatarUrl: string;

  constructor() {}

  ngOnInit() {}
}
