import { Injectable, Inject } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AppStateService } from '../app-state.service';
import { AccessManagementService, RequiredPermissions } from './access-management.service';

@Injectable()
export class PermissionsGuard implements CanActivate {
  constructor(
    private router: Router,
    private appStateService: AppStateService,
    private accessManagementService: AccessManagementService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let requiredPermissions = route.data['requiredPermissions'] as RequiredPermissions;
    if (!requiredPermissions) {
      return true;
    }

    return this.accessManagementService.hasAccessPermissions(requiredPermissions);
  }
}
