import { NgModule, ModuleWithProviders, Optional, SkipSelf, PLATFORM_ID, ErrorHandler } from '@angular/core';
import { TransferState } from '@angular/platform-browser';
import { HttpClient, HttpClientModule } from '@angular/common/http';

import { RedirectGuard } from './access-management/redirect.guard';

import { ANALYTICS } from '@libs/portal-common';
import { APP_CONFIGURATION, AppConfigurationService } from './app-configuration.service';
import { TeamsIntegrationService } from './teams-integration.service';
import { NavbarItemsService } from './access-management/navbar-items.service';
import { CredentialsStorageService } from './credentials-storage.service';
import { NavigationService } from '../skype-client/services';
import { AnalyticsService } from './analytics.service';
import { AgentErrorHandler } from './agent-error-handler.service';

@NgModule({
  imports: [HttpClientModule],
  exports: [HttpClientModule],
})
export class ServicesModule {
  static forRoot(): ModuleWithProviders<ServicesModule> {
    return {
      ngModule: ServicesModule,
      providers: [
        {
          provide: APP_CONFIGURATION,
          deps: [HttpClient, PLATFORM_ID, [new Optional(), 'ORIGIN_URL'], TransferState],
          useClass: AppConfigurationService,
        },
        {
          provide: ANALYTICS,
          deps: [APP_CONFIGURATION, NavigationService],
          useClass: AnalyticsService,
        },
        {
          provide: ErrorHandler,
          useClass: AgentErrorHandler,
        },
        NavbarItemsService,
        RedirectGuard,
        TeamsIntegrationService,
        CredentialsStorageService,
      ],
    };
  }

  constructor(@Optional() @SkipSelf() parentModule: ServicesModule) {
    if (parentModule) {
      throw new Error('ServicesModule is already loaded. Import it in the AppModule only');
    }
  }
}
