import { ILane, ILocationLane, ILocation, CreateTicketModel } from '@libs/portal-common/services';

import { LocationInfo } from './api-models';
import { IConversation } from './integration-app.serivce';

export class FieldValue {
  FieldName: string;
  FieldType: string;
  Value: any;
  IsStatic = false;
}

export interface ISortable {
  SortOrder: number;
}

export class SubmissionParameters {
  fkProblem: number;
  ProblemName: string;
  fkIssue: number;
  IssueName: string;

  GateVend = false;
  MgrNotified = false;
}

export class Problem implements ISortable {
  Id: number;
  ProblemName: string;
  fkOperator: number;
  IsActive = true;
  SortOrder: number;
  fkRuleSet: number;

  Issues: Array<Issue>;
}

export class Issue implements ISortable {
  Id: number;
  RuleDescription: string;

  fkProblem: number;
  fkOperator: number;
  IssueName: string;
  FieldsJson: string;
  ActionsJson: string;
  IsActive: boolean;
  IsNotify: boolean;
  GateVend: boolean;
  ShowInPage: boolean;
  SortOrder: number;

  Fields: Array<IssueField>;
  Actions: Array<IssueAction>;
}

export class IssueField {
  Id: number;
  FieldName: string;
  FieldType: string;
  fkOperator: number;
  DisplayName: string;
  IsPartOfField: boolean;
  RelatedField?: number;
  Related: any;
  Module: any;
  IsStatic: boolean;
  IsRequired: boolean;

  public static default(): IssueField {
    let result = new IssueField();
    result.DisplayName = 'Notes';
    result.FieldName = 'cNotes';
    result.FieldType = 'string';
    result.IsStatic = true;

    return result;
  }
}

export class IssueAction {
  Id: number;
  ActionType: string;
  ActionName: string;
}

export class LotIssue {
  Id: number;

  Actions: Array<IssueAction>;
  Fields: Array<IssueField>;

  GateVend: boolean;
  IsActive: boolean;
  IsNotify: boolean;
  IsSameAsParent: boolean;
  RuleDescription: string;

  FieldsJson: string;
  ActionsJson: string;

  ShowInPage: boolean;
  SortOrder: number;

  fkIssue: number;
  fkLot: number;
  fkOperator: number;
  fkProblem: number;
}

export interface IRevenueControlManufacturer {
  Id: number;
  Name: string;
  IsIntegrated: boolean;
  CanOpenGate: boolean;
  CanCloseGate: boolean;
  CanSetPrice: boolean;
  CanSetRate: boolean;
  CanEjectTicket: boolean;
  CanSwallowTicket: boolean;
  CanShowTicketInfo: boolean;
}

export class IncomingCall implements ILocationLane {
  locations = new Array<ILocation>();
  location: ILocation;

  lanes = new Array<ILane>();
  lane: ILane;
  ticket: CreateTicketModel;

  problems: Problem[] = [];
  public locationInfo: LocationInfo;

  constructor(public conversation: IConversation) {}

  isResolved() {
    return !!this.location && !!this.lane;
  }
}
