import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { IRestBaseListResponse, BaseResponse } from '../models/rest/baseResponse';

export interface IIncidentQualityAnalysis {
  Id: number;
  fkIncident: number;

  IncidentQualityAnalysisAnswers?: Array<IIncidentQualityAnalysisAnswer>;
  IncidentQualityAnalysisAnswersDict?: any;
}

export interface IIncidentQualityAnalysisAnswer {
  fkQualityAnalysisQuestion: number;

  Answer: string;
}

const baseUrl = 'IncidentsQualityAnalysis';

export interface IGetIncidentQualityAnalysisResponse extends BaseResponse {
  QualityAnalysis: IIncidentQualityAnalysis;
}

export interface IPostIncidentQualityAnalysisResponse extends BaseResponse {
  QualityAnalysis: IIncidentQualityAnalysis;
}

export interface IDeleteIncidentQualityAnalysisResponse extends BaseResponse {
  Id: number;
}

@Injectable({ providedIn: 'root' })
export class IncidentsQualityAnalysisService {
  constructor(private _httpClient: HttpClient) {}

  getAll(parameters?: any, state: any = {}): Observable<any> {
    let request = JSON.parse(JSON.stringify(state));
    console.log('request', request);
    request.parameters = parameters;

    return this._httpClient.get<IRestBaseListResponse<IIncidentQualityAnalysis>>(baseUrl + '/list', request);
  }

  getAllPost(parameters?: any, state: any = {}): Observable<any> {
    let request = JSON.parse(JSON.stringify(state));
    request.parameters = parameters;

    return this._httpClient.post<IRestBaseListResponse<IIncidentQualityAnalysis>>(baseUrl + '/list', request);
  }

  get(params: { id?: number; fkIncident?: number }): Observable<IGetIncidentQualityAnalysisResponse> {
    let url = !!params.id ? `${baseUrl}/${params.id}` : `${baseUrl}/incident/${params.fkIncident}`;

    return this._httpClient.get<IGetIncidentQualityAnalysisResponse>(url);
  }

  createOrUpdate(qualityAnalysis: IIncidentQualityAnalysis): Observable<IPostIncidentQualityAnalysisResponse> {
    return this._httpClient.post<IPostIncidentQualityAnalysisResponse>(baseUrl, { QualityAnalysis: qualityAnalysis });
  }

  delete(id: number): Observable<IDeleteIncidentQualityAnalysisResponse> {
    return this._httpClient.delete<IDeleteIncidentQualityAnalysisResponse>(`${baseUrl}/${id}`);
  }
}
