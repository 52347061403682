import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Observable, of, Subject } from 'rxjs';

import { AppNotificationsService } from '@libs/portal-common';

import { CallsMapType, IConversation, IIntegrationAppService, IOutgoingConversation } from '../../../abstractions';

import { AppConfigurationService, APP_CONFIGURATION } from '../../../services/app-configuration.service';
import { FinesseConversation } from '../finesse-conversation';

import { first, mergeMap, tap, map, filter } from 'rxjs/operators';

import { FinesseStateService } from './finesse-state.service';
import { FinesseHttpClient } from './finesse-httpclient';
import { IDialog, IUser, UserState } from '../model';

@Injectable()
export class FinesseAppService implements IIntegrationAppService {
  finesse: any;

  public get name(): string {
    return 'Finesse';
  }

  constructor(
    @Inject(PLATFORM_ID) platformId: Object,
    private finesseState: FinesseStateService,
    private finesseHttpClient: FinesseHttpClient,
    @Inject(APP_CONFIGURATION) private configuration: AppConfigurationService,
    private notifications: AppNotificationsService,
  ) {}

  public get calls$(): Observable<CallsMapType> {
    return of({});
  }

  public get isAuthenticated$(): Observable<boolean> {
    return of(true);
  }

  public get initialized$(): Observable<boolean> {
    return of(true);
  }

  public get outgoingCall$(): Observable<IOutgoingConversation> {
    return of(null);
  }

  signIn(username: string, password: string): Observable<boolean> {
    throw new Error('Method not implemented.');
  }
  signInAAD(): Observable<boolean> {
    throw new Error('Method not implemented.');
  }
  signOut(): Observable<any> {
    return of(true);
  }

  finishCall(callId: string, userExt: string): Observable<any> {
    return this.finesseHttpClient.finishCall(callId, userExt);
  }

  sendDtmf(callId: string, dtmf: string, userExt: string) {
    return this.finesseHttpClient.sendDTMF(callId, dtmf, userExt);
  }

  createConversation(caller: string, callId: string, sessionId: string, agentSip: string): Observable<IConversation> {
    let user: IUser;

    return this.finesseHttpClient.getUser(this.finesseState.gadgetConfig.id).pipe(
      tap((u) => (user = u)),
      mergeMap((u) => this.finesseHttpClient.getUserDialogs(u.loginId)),
      map((dialogs) => dialogs.filter((d) => d.state === 'ACTIVE' && d.mediaType === 'Voice')),
      map((dialogs) => {
        if (dialogs.length === 0) {
          return null;
        }

        let dialog = dialogs[0];
        let conversation = new FinesseConversation(dialog.fromAddress, dialog.toAddress, user, this);
        conversation.callId = dialog.id;
        conversation.sessionId = dialog.id;

        return conversation;
      }),
    );
  }

  getUser(): Observable<IUser> {
    return this.finesseHttpClient.getUser(this.finesseState.gadgetConfig.id);
  }
  getUserDialogs(userId: string): Observable<Array<IDialog>> {
    return this.finesseHttpClient.getUserDialogs(userId);
  }

  setUserState(userId: string, state: UserState): Observable<any> {
    return this.finesseHttpClient.setUserState(userId, state);
  }
}
