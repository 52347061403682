<div class="modal fade" bsModal #modal="bs-modal" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header clearfix">
        <h4 class="modal-title pull-left"></h4>

        <button type="button" class="close pull-right" aria-label="Close" (click)="cancel()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body" *ngIf="conversation">
        <div class="management-body">
          <div class="form-group">
            <input type="text" class="form-control" (keyup)="textEnter($event)" [(ngModel)]="dtmf" name="dtmf" />
          </div>

          <div class="buttons">
            <div class="btn-group" role="group">
              <button type="button" class="btn btn-default" (click)="buttonClicked('1')">
                <div>1</div>
                <div class="placeholder"></div>
              </button>
              <button type="button" class="btn btn-default" (click)="buttonClicked('2')">
                <div>2</div>
                <div class="btn-text">ABC</div>
              </button>
              <button type="button" class="btn btn-default" (click)="buttonClicked('3')">
                <div>3</div>
                <div class="btn-text">DEF</div>
              </button>
            </div>

            <div class="btn-group" role="group">
              <button type="button" class="btn btn-default" (click)="buttonClicked('4')">
                <div>4</div>
                <div class="btn-text">GHI</div>
              </button>
              <button type="button" class="btn btn-default" (click)="buttonClicked('5')">
                <div>5</div>
                <div class="btn-text">JKL</div>
              </button>
              <button type="button" class="btn btn-default" (click)="buttonClicked('6')">
                <div>6</div>
                <div class="btn-text">MNO</div>
              </button>
            </div>

            <div class="btn-group" role="group">
              <button type="button" class="btn btn-default" (click)="buttonClicked('7')">
                <div>7</div>
                <div class="btn-text">PQRS</div>
              </button>
              <button type="button" class="btn btn-default" (click)="buttonClicked('8')">
                <div>8</div>
                <div class="btn-text">TUV</div>
              </button>
              <button type="button" class="btn btn-default" (click)="buttonClicked('9')">
                <div>9</div>
                <div class="btn-text">WXYZ</div>
              </button>
            </div>

            <div class="btn-group" role="group">
              <button type="button" class="btn btn-default" (click)="buttonClicked('*')">
                <div>*</div>
                <div class="placeholder"></div>
              </button>
              <button type="button" class="btn btn-default" (click)="buttonClicked('0')">
                <div>0</div>
                <div class="placeholder"></div>
              </button>
              <button type="button" class="btn btn-default" (click)="buttonClicked('#')">
                <div>#</div>
                <div class="placeholder"></div>
              </button>
            </div>
          </div>

          <div class="row volume-control" *ngIf="hasAudioSupport()">
            <div class="col-sm-2" style='padding-left: 0'>
              <button class="btn btn-default mute-button" (click)="toggleMute()">
                <i class="fa fa-volume-up" *ngIf="!muted"></i>
                <i class="fa fa-volume-off" *ngIf="muted"></i>
              </button>
            </div>
            <div class="col-sm-10" style='padding-left: 0'>
              <kendo-slider
                class="volume-slider"
                [showButtons]="false"
                [disabled]="muted"
                [min]="0"
                [max]="0.5"
                [smallStep]="0.05"
                [fixedTickWidth]="20"
                [(ngModel)]="volume"
                name="volume"
              ></kendo-slider>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
