import { Component, OnInit, HostBinding, ViewChild, ElementRef, OnDestroy, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { of, Observable, Subscription } from 'rxjs';
import { tap, map, take } from 'rxjs/operators';

import { GridComponent, GridDataResult, DataStateChangeEvent, RowClassArgs, FilterService } from '@progress/kendo-angular-grid';
import { State } from '@progress/kendo-data-query';
import { ExcelExportEvent } from '@progress/kendo-angular-grid/dist/es2015/excel/excel-export-event';

import { toDataURL, ExcelExportData } from '@progress/kendo-angular-excel-export';
import { saveAs } from '@progress/kendo-file-saver';

import * as $ from 'jquery';
import * as moment from 'moment';

import { routerTransition } from '@libs/portal-common/system';
import { QualityAnalysisFilter, QualityAnalysisService } from '../services/quality-analysis.service';
import {
  ContactsService,
  AccessManagementService,
  IncidentQualityAnalysisQuestion,
  IncidentsQualityAnalysisQuestionService,
  AnswerType,
  ANALYTICS,
  IAnalyticsService,
  AnalyticsEvent,
  ActivityReportName,
  IIncidentQualityAnalysis,
  IIncidentQualityAnalysisAnswer,
} from '@libs/portal-common/services';

class IncidentQualityAnalysisQuestionGroup extends IncidentQualityAnalysisQuestion {
  deleted: Array<IncidentQualityAnalysisQuestion>;
}

@Component({
  selector: 'app-quality-analysis',
  templateUrl: './quality-analysis.component.html',
  styleUrls: ['./quality-analysis.component.scss'],
  animations: [routerTransition],
})
export class QualityAnalysisComponent implements OnInit, OnDestroy {
  @ViewChild(GridComponent, { static: true }) private grid: GridComponent;
  @HostBinding('@routerTransition') routerTransition = '';
  @ViewChild('qaGridContainer', { static: true }) private gridContainer: ElementRef;

  filter = new QualityAnalysisFilter();

  filterLoading = false;
  activatedRouteSubscription: Subscription;

  contacts = [];
  filteredContacts = [];
  contactsEnabled = false;

  questions: Array<IncidentQualityAnalysisQuestionGroup> = [];

  gridHeight = 300;

  state: State = {
    skip: 0,
    take: 30,
    group: [],
    filter: { logic: 'and', filters: [] },
    sort: [{ field: 'fkIncident', dir: 'desc' }],
  };

  view: Observable<GridDataResult>;

  excelFileName = 'QualityAnalysis.xlsx';
  cells: any = {
    textAlign: 'right',
  };

  private selectedContactValues: any[] = [];
  public get selectedContact(): any[] {
    return this.selectedContactValues;
  }

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private contactsService: ContactsService,
    private accessManagementService: AccessManagementService,
    private questionService: IncidentsQualityAnalysisQuestionService,
    private service: QualityAnalysisService,
    @Inject(ANALYTICS) private analytcis: IAnalyticsService,
  ) {
    this.view = service;

    this.view.subscribe((x) => {
      this.resizeGrid();
    });
  }

  ngOnInit() {
    this.analytcis.track(AnalyticsEvent.DailyActivityOpen, { ReportName: ActivityReportName.QualityAnalysis });

    this.activatedRouteSubscription = this.activatedRoute.queryParams.subscribe((params) => {
      this.filter = QualityAnalysisFilter.FromParams(params);
      if (!this.filterLoading) {
        this.queryData();
      }
    });

    this.questionService.getAll(true).subscribe((response) => {
      this.questions = this.groupQuestions(response.Data);
    });

    this.filterLoading = true;
    let common = of([]);
    if (this.accessManagementService.hasAccessPermissions({ entities: ['Locations'], access: 'CanRead' })) {
      common = this.contactsService.getAll().pipe(
        tap((response) => {
          this.contacts = (response || []).map(function (contact) {
            return {
              Id: contact.Id,
              SIP: contact.SipAddress,
              Name: contact.Name,
            };
          });

          this.filteredContacts = this.contacts;
          this.contactsEnabled = true;
        }),
      );
    } else {
      this.contactsEnabled = false;
    }

    common.pipe(take(1)).subscribe((result) => {
      this.filterLoading = false;
      this.queryData();
    });
  }

  ngOnDestroy(): void {
    this.activatedRouteSubscription.unsubscribe();
  }

  search() {
    this.router.navigate([], { queryParams: this.filter.toParams(), relativeTo: this.activatedRoute, queryParamsHandling: 'merge' });
  }

  private queryData() {
    this.service.query(this.filter, this.state);
  }

  handleContactsFilter(value) {
    this.filteredContacts = this.contacts.filter((s) => s.Name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
  }

  dataStateChange(state: DataStateChangeEvent): void {
    state.sort = state.sort.filter((item) => !state.group.find((gr) => gr.field === item.field));

    this.state = state;
    this.service.query(this.filter, this.state);
  }

  rowSelectionChange($event: any) {
    const dataItem = $event.selectedRows[0].dataItem;
    this.router.navigate([dataItem.fkIncident, 'quality'], { relativeTo: this.activatedRoute });
  }

  rowCallback(context: RowClassArgs) {
    return {
      incident: true,
    };
  }

  creatorName(contactId: number): string {
    let creator = this.contacts.find((x) => x.Id === contactId);
    return !!creator ? creator.Name : null;
  }

  parseArray(answerArray: string): string {
    if (answerArray) {
      return JSON.parse(answerArray).join(', ');
    } else {
      return answerArray;
    }
  }

  excelExport(event: ExcelExportEvent) {
    event.preventDefault();
    this.analytcis.track(AnalyticsEvent.DailyActivityExport, { ReportName: ActivityReportName.QualityAnalysis, ExportType: 'Excel' });

    let sheet = event.workbook.sheets[0];
    let columns = sheet.columns;
    let header = sheet.rows[0];

    sheet.rows.splice(1, sheet.rows.length);

    let state = JSON.parse(JSON.stringify(this.state));
    state.skip = 0;
    state.take = 0;

    // set columns width to auto
    columns.forEach((column) => {
      delete column.width;
      column.autoWidth = true;
      column.autoHeight = true;
    });

    this.service.getQA(this.filter, state).subscribe((res) => {
      // quality Analysis List
      const qA = res.data;
      const questionIds: any = {};

      // push question Names to header cells
      this.questions.forEach((question) => {
        questionIds[question.Id] = question;
        header.cells.push({
          background: '#7a7a7a',
          color: '#fff',
          type: 'header',
          value: question.QuestionName,
        });
        columns.push({
          autoWidth: true,
          autoHeight: true,
        });
      });

      let localDateTimeFormat = moment.localeData().longDateFormat('LLL');
      if (localDateTimeFormat.endsWith(' A')) {
        localDateTimeFormat += 'M/PM';
      }

      for (let index = 0; index < qA.length; index++) {
        let row: { type: string; cells: Array<any>; height: number } = { type: 'data', cells: [], height: 20 };

        let maxLength = -1;

        row.cells.push({ value: qA[index].fkIncident, textAligin: 'right' });
        row.cells.push({ value: this.creatorName(qA[index].fkAgent), textAligin: 'right' });
        row.cells.push({ value: new Date(qA[index].Created), textAligin: 'right' });

        this.questions.forEach((question) => {
          let answer: any = this.getQuestionAnswer(question, qA[index]);

          if (this.getString(question.AnswerType) === 'MultiChoice' && answer) {
            const strArray = JSON.parse(answer);
            if (strArray.length > maxLength) {
              maxLength = strArray.length;
              row.height += (maxLength - 1) * 20;
            }
            answer = strArray.join('\n');
          }
          row.cells.push({
            wrap: true,
            value: answer,
            textAlign: 'right',
          });

          if (answer === 'No') {
            row.cells[row.cells.length - 1].color = '#ff0000'; // red
          } else if (answer === 'Yes') {
            row.cells[row.cells.length - 1].color = '#008000'; // green
          }
        });

        sheet.rows.push(row);
      }

      let dataURL = toDataURL(event.workbook);
      Promise.resolve(dataURL).then((data) => {
        saveAs(data, this.excelFileName);
      });
    });
  }

  fetchData(grid: GridComponent): Promise<ExcelExportData> {
    let state = JSON.parse(JSON.stringify(this.state));
    state.skip = 0;
    state.take = 0;

    return this.service
      .getQA(this.filter, state)
      .pipe(
        map((res) => {
          return {
            data: res.data,
            group: grid.group,
          };
        }),
      )
      .toPromise();
  }

  private resizeGrid() {
    if (!this.grid) {
      return;
    }

    let page = $('.page-host'),
      pageHeight = page.innerHeight();

    let header = $('.page__heading'),
      headerHeight = header.innerHeight();

    let gridContainer = $('.grid-container'),
      gridContainerHeight = gridContainer.innerHeight();

    let limit = 30;

    let actualPageHeight = headerHeight + gridContainerHeight + 20;

    let delta = pageHeight - actualPageHeight;

    if (Math.abs(delta) > limit) {
      let $grid = $(this.grid.wrapper.nativeElement);
      let gridHeight = $grid.innerHeight();

      let newHeight = gridHeight + delta;
      $grid.css('height', newHeight + 'px');
    }
  }

  private getString(answerType: AnswerType) {
    return answerType + '';
  }

  private groupQuestions(questions: IncidentQualityAnalysisQuestion[]): IncidentQualityAnalysisQuestionGroup[] {
    const result: Array<IncidentQualityAnalysisQuestionGroup> = [];
    const groups = {};

    questions = questions.sort((a, b) => {
      const nameA = a.QuestionName.trim();
      const nameB = b.QuestionName.trim();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      return 0;
    });

    questions
      .filter((x) => !x.IsDeleted)
      .forEach((x) => {
        const item = x as IncidentQualityAnalysisQuestionGroup;
        item.deleted = [];
        result.push(item);
        groups[item.QuestionName.trim()] = item;
      });

    questions
      .filter((x) => x.IsDeleted)
      .forEach((x) => {
        const group = groups[x.QuestionName.trim()] as IncidentQualityAnalysisQuestionGroup;

        if (group) {
          group.deleted.push(x);
        } else {
          const item = x as IncidentQualityAnalysisQuestionGroup;
          item.deleted = [];
          result.push(item);
          groups[item.QuestionName] = item;
        }
      });

    return result;
  }

  public getQuestionAnswer(
    question: IncidentQualityAnalysisQuestionGroup,
    item: IIncidentQualityAnalysis,
  ): IIncidentQualityAnalysisAnswer | null {
    let result = item.IncidentQualityAnalysisAnswersDict[question.Id] as IIncidentQualityAnalysisAnswer;

    if (!result) {
      return question.deleted.map((x) => item.IncidentQualityAnalysisAnswersDict[x.Id]).find((x) => !!x);
    }

    return result;
  }

  public multiSelectValueChange(values: any[], columnField: string, filterService: FilterService): void {
    this.selectedContactValues = values;

    filterService.filter({
      filters: values.map((value) => ({
        field: columnField,
        operator: 'eq',
        value,
      })),
      logic: 'or',
    });
  }
}
