import { Component, ViewEncapsulation } from '@angular/core';
import { LoaderStatusService } from '@libs/portal-common/services';

@Component({
  selector: 'app-umojo-spinner',
  templateUrl: './umojo-spinner.component.html',
  styleUrls: ['./umojo-spinner.component.scss'],
  encapsulation: ViewEncapsulation.ShadowDom
})
export class UmojoSpinnerComponent {
  constructor(public loader: LoaderStatusService) { }
}

