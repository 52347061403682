import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';

import { Observable, Observer } from 'rxjs';

import { ModalDirective } from 'ngx-bootstrap/modal';
import { IncidentService } from '@libs/portal-common/services';

export class Reason {
  title: string;
}

@Component({
  selector: 'app-activity-add-note',
  templateUrl: './activity-add-note.component.html',
  styleUrls: ['./activity-add-note.component.scss'],
})
export class ActivityAddNoteComponent implements OnInit {
  @ViewChild('modal', { static: true }) public modal: ModalDirective;
  @ViewChild('form') public form: NgForm;

  incidentId: number;
  oldNote: string;
  note: string;
  sendAlert = false;

  validate = false;
  initialized = false;

  resultObserver: Observer<string>;

  constructor(private incidentsService: IncidentService) {}

  ngOnInit() {}

  open(incidentId: number, note: string): Observable<string> {
    this.initialized = false;
    this.validate = false;

    this.incidentId = incidentId;
    this.oldNote = note;
    this.note = null;
    this.sendAlert = false;

    this.modal.show();
    setTimeout(() => (this.initialized = true), 30);

    return new Observable((observer) => (this.resultObserver = observer));
  }

  save() {
    this.validate = true;
    if (!this.form.valid) {
      return;
    }

    this.incidentsService
      .updateNotes({
        IncidentId: this.incidentId,
        Notes: this.note,
        OldNotes: this.oldNote,
        SendAlert: this.sendAlert,
        AgentName: null,
      })
      .subscribe((res) => {
        this.modal.hide();
        this.resultObserver.next(this.note);
        this.resultObserver.complete();
      });
  }

  cancel() {
    this.modal.hide();
    this.resultObserver.next(null);
    this.resultObserver.complete();
  }
}
