import { Injectable } from '@angular/core';
import { of, Observable } from 'rxjs';
import { tap, map, filter, flatMap } from 'rxjs/operators';

import { HttpClient, HttpParams } from '@angular/common/http';

import { PubSubService } from '@libs/portal-common/system';
import { BaseResponse } from '../models/rest/baseResponse';

export enum ProblemTypesEnum {
  Regular = 1,
  EVCharger = 2,
}

export interface IProblem {
  Id: number;
  ProblemName: string;
  IsActive: boolean;
  Modified: Date;
  SortOrder: number;
  fkRegion: number;
  fkRuleSet: number;
  fkCategory: ProblemTypesEnum;
}

export interface IGetProblemResponse extends BaseResponse {
  Problem: IProblem;
}

@Injectable({ providedIn: 'root' })
export class ProblemsService {
  constructor(
    private _httpClient: HttpClient,
    private _pubSubService: PubSubService,
  ) {}

  // Get all Problems
  getAll(regionId?: number, ruleSetId?: number): Observable<any> {
    let params = new HttpParams();
    if (!!regionId) {
      params = params.set('fkRegion', regionId.toString());
    }
    if (!!ruleSetId) {
      params = params.set('fkRuleSet', ruleSetId.toString());
    }

    return this._httpClient.get<any>('Problems/', { params: params });
  }

  // Get a Problem
  get(problemId): Observable<IGetProblemResponse> {
    return this._httpClient.get<any>('Problems/' + problemId);
  }

  // Create a Problem
  create(problem): Observable<any> {
    return this._httpClient.put<any>('Problems/', { problem }).pipe(tap((response) => this._pubSubService.$pub('globalProblemsChanged')));
  }

  // Update a Problem
  update(problem): Observable<any> {
    return this._httpClient.put<any>('Problems/', { problem }).pipe(tap((response) => this._pubSubService.$pub('globalProblemsChanged')));
  }

  // Delete a Problem
  delete(problemId): Observable<any> {
    return this._httpClient.delete<any>('Problems/' + problemId).pipe(tap((response) => this._pubSubService.$pub('globalProblemsChanged')));
  }

  // Reorder Problems
  reorder(ids): Observable<any> {
    return this._httpClient
      .post<any>('Problems/Reorder', {
        Ids: ids,
      })
      .pipe(tap((response) => this._pubSubService.$pub('globalProblemsChanged')));
  }

  // Get all Categories
  getCategories(): Observable<any> {
    return this._httpClient.get<any>('ProblemCategories/');
  }

  resetCache() {}
}
