import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';

import { Observable, Observer } from 'rxjs';

import { ModalDirective } from 'ngx-bootstrap/modal';

import { LocationsService, TicketStatus, CreateTicketModel, TicketsService, ContactsService } from '@libs/portal-common/services';

export class TicketResult {
  constructor(public created = false) {}
}

@Component({
  selector: 'app-create-ticket',
  templateUrl: './create-ticket.component.html',
})
export class CreateTicketComponent implements OnInit {
  @ViewChild('createTicketModal', { static: true }) public createTicketModal: ModalDirective;
  @ViewChild('form', { static: false }) public form: NgForm;

  resultObserver: Observer<TicketResult>;

  initialized = false;
  validate = false;
  locationSelectorEnabled = false;

  ticket = new CreateTicketModel();
  locations: [object];
  ticketStatuses = new Array<TicketStatus>();
  ownerId: number;

  constructor(
    private lotService: LocationsService,
    private ticketsService: TicketsService,
    private contactService: ContactsService,
  ) {}

  ngOnInit() {
    this.lotService.getAll().subscribe((response) => {
      this.locations = response.Data;
    });

    this.ticketsService.getTicketStatuses().subscribe((response) => {
      this.ticketStatuses = response;
    });

    this.contactService.me().subscribe((response) => (this.ownerId = response.Contact.Id));
  }

  open(incidentId: number = null, fkLot: number = null): Observable<TicketResult> {
    this.ticket = new CreateTicketModel();
    this.ticket.incidentId = incidentId;
    this.ticket.locationId = fkLot;
    this.locationSelectorEnabled = !fkLot;

    this.validate = false;
    this.initialized = false;

    this.createTicketModal.show();
    setTimeout(() => (this.initialized = true), 30);

    return new Observable((observer) => (this.resultObserver = observer));
  }

  save() {
    this.validate = true;
    if (!this.form.valid) {
      return;
    }

    this.ticket.ownerId = this.ownerId;

    this.ticketsService.createOrUpdateTicket(this.ticket).subscribe((response) => {
      this.createTicketModal.hide();
      this.resultObserver.next(new TicketResult(true));
      this.resultObserver.complete();
    });
  }

  cancel() {
    this.createTicketModal.hide();
    this.resultObserver.next(new TicketResult(false));
    this.resultObserver.complete();
  }
}
