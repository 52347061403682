<nav class="flex__box flex__fluid flex__box--column">
  <div *ngIf="hasImage" class="brand has-image">
    <div class="brand__name" style="text-align: center">
      <img [src]="logo" class="img-fluid logo-img" />
    </div>
    <div class="menu__button" style="color: #333">{{ companyNameShortcut }}</div>
  </div>
  <div *ngIf="hasImage == false" class="brand">
    <h3 class="brand__name">{{ companyName }}</h3>
    <div class="menu__button">
      {{ companyNameShortcut }}
    </div>
  </div>

  <div class="nav-content" @insertRemoveNavList *ngIf="!_isMenuCollapsed">
    <ng-scrollbar class="app-scrollbar" track="all">
        <ul class="nav" *ngIf="!!incomingCall">
          <li style="padding-top: 8px">
          <span class="timer" [class]="hangupTimer.cssClass">
            {{ hangupTimer.minutes | number: '2.0-2' }}:{{ hangupTimer.seconds | number: '2.0-2' }}
          </span>
          </li>
          <li>
          <span class="timer" [class]="wrapupTimer.cssClass">
            {{ wrapupTimer.minutes | number: '2.0-2' }}:{{ wrapupTimer.seconds | number: '2.0-2' }}
          </span>
          </li>
          <li *ngIf="incomingCallProcessing">
            <a class="call-management" *ngIf="!hideCallControl && state == 'Accepted'" (click)="hangUp($event)" href="">
              <i class="skype-hang-up-icon"></i>
              <span class="left-nav__link">Hang Up</span>
            </a>
            <a class="call-management" *ngIf="state == 'HangedUp'" (click)="wrapUp($event)" href="">
              <i class="wrap-up"></i>
              <span class="left-nav__link">{{ wrapUpButtonText }}</span>
            </a>
          </li>
          <li *ngIf="state === 'Accepted'">
            <a class="call-management" (click)="dtmf($event)" href="">
              <i class="skype-icon skype-dial-number-icon"></i>
              <span class="left-nav__link">Dtmf</span>
            </a>
          </li>
          <li *ngIf="!hideCallControl">
            <a
              class="call-management"
              *ngIf="state == 'Accepted' && !(incomingCall.conversation.isPaused$ | async)"
              (click)="pause($event)"
              href=""
            >
              <i class="fa fa-pause"></i>
              <span class="left-nav__link">Pause</span>
            </a>
            <a class="call-management" *ngIf="incomingCall.conversation.isPaused$ | async" (click)="resume($event)" href="">
              <i class="fa fa-play"></i>
              <span class="left-nav__link">Resume</span>
            </a>
          </li>
        </ul>

        <ul class="nav">
          <li *ngFor="let route of routes" routerLinkActive="active">
            <a
              *ngIf="route.childs == null && !route.portal"
              [routerLink]="[route.route]"
              [routerLinkActive]="'active-link--bold'"
            >
              <img style="margin-right: 10px" [src]="route.icon" *ngIf="!!route.icon" alt="'icon'" />
              <i style="margin-right: 10px" class="nav-icon" [ngClass]="'nav-icon-' + route.iconClass" *ngIf="!!route.iconClass"></i>
              <span class="left-nav__link">{{ route.displayName }}</span>
            </a>

            <!-- Multilevel anchor -->
            <a
              class="au-target"
              *ngIf="route.childs != null && !route.portal"
              [attr.aria-expanded]="!isCollapsed"
              href="javascript:void(0)"
              [attr.aria-controls]="route.id"
              (click)="navigationsCollapsed[route.id] = !navigationsCollapsed[route.id]"
            >
              <i class="fa fa-{{ route.icon }}" *ngIf="!!route.icon"></i>
              <i class="nav-icon" [ngClass]="'nav-icon-' + route.iconClass" *ngIf="!!route.iconClass"></i>
              <span class="left-nav__link">{{ route.displayName }}</span>
              <i class="fa fa-angle-down right" style="margin-top: 3px"></i>
            </a>

            <ul
              *ngIf="route.childs != null && !route.portal"
              class="nav nav__second-level collapsed-block"
              id="{{ route.id }}"
              [collapse]="navigationsCollapsed[route.id]"
              (collapsed)="setCollapsedHeight(el, 0)"
              (expanded)="setCollapsedHeight(el, 0); setCollapsedHeight(el, el.scrollHeight)"
              #el
            >
              <li *ngFor="let childRoute of route.childs" routerLinkActive="active-link">
                <a
                  [routerLink]="[childRoute.route]"
                  class="external-link"
                >
                  <i class="fa fa-link"></i>
                  <span class="left-nav__link">{{ childRoute.displayName }}</span>
                </a>
              </li>
            </ul>
          </li>

          <li *ngIf="isRevenuEcontrolEnabled">
            <a href (click)="openRevenueControl($event)">
              <i class="nav-icon nav-icon-issues"></i>
              <span class="left-nav__link">Revenue control</span>
            </a>
          </li>
          <li *ngIf="!incomingCall">
            <a class="call-management" (click)="testCall($event)" href="">
              <i class="skype-icon skype-dial-number-icon"></i>
              <span class="left-nav__link">Test Call</span>
            </a>
          </li>
        </ul>
      <div
        class="additional-links-menu"
        *ngIf="(!!incomingCall && additionalLinks.length > 0) || incomingCall?.locationInfo?.Lot['ParkonectId']"
      >
        <div (click)="isOpenedCollapseMenu = !isOpenedCollapseMenu" class="additional-links-menu-title">
          <i *ngIf="isOpenedCollapseMenu" class="nav-icon nav-icon-down-arrow"></i>
          <i *ngIf="!isOpenedCollapseMenu" class="nav-icon nav-icon-up-arrow"></i>
          More
        </div>
        <div *ngIf="isOpenedCollapseMenu">
          <ul class="additional-links-menu-list">
            <li *ngIf="incomingCall?.locationInfo?.Lot['ParkonectId']" (click)="openParkonect()">Parkonect</li>
            <li *ngFor="let link of additionalLinks" (click)="openAdditionalLink(link)">
              <span class="left-nav__link">Open {{ link.Title }}</span>
            </li>
          </ul>
        </div>
      </div>
    </ng-scrollbar>
  </div>


</nav>

<app-revenue-control-selector #revenueControlSelector></app-revenue-control-selector>
<div class="left-nav__disclaimer left-nav_hidden-closed">Powered by Umojo © {{ currentYear }}</div>
