import { Component, Input } from '@angular/core';

import { zip } from 'rxjs';

import { TicketsService, Ticket, ContactsService, LocationsService } from '@libs/portal-common/services';

import { AppNotificationsService } from '@libs/portal-common/system';

@Component({
  selector: 'app-ticket-editor',
  templateUrl: 'ticket-editor.component.html',
})
export class TicketEditorComponent {
  private _ticket: Ticket;

  @Input('ticket')
  get ticket() {
    return this._ticket;
  }

  set ticket(ticket) {
    this.init(ticket);
  }

  @Input() title = 'Information';

  _ticketCopy: Ticket;
  ticketComments: any[];
  currentUser: any;
  isOwner = false;
  editing = false;
  locations: any[];
  ticketStatuses: any[];
  newComment: string;

  constructor(
    private contactService: ContactsService,
    private ticketsService: TicketsService,
    private notification: AppNotificationsService,
    private locationService: LocationsService,
  ) {}

  showEdit() {
    this.editing = true;
  }

  cancelEdit() {
    this._ticket = JSON.parse(JSON.stringify(this._ticketCopy));
    this.assignStatusAndLocation();
    this.editing = false;
  }

  saveChanges() {
    this.ticketsService.createOrUpdateTicket(this._ticket).subscribe((response) => {
      if (!response.Success) {
        this.notification.error(response.Message);
        return;
      }

      this._ticket = response.Ticket;
      this._ticketCopy = JSON.parse(JSON.stringify(this._ticket));
      this.assignStatusAndLocation();
      this.notification.success('Successfully updated');
      this.editing = false;
    });
  }

  subscribe() {
    if (this._ticket.IsSubscribed) {
      return;
    }

    this.ticketsService.subscribeTicket(this._ticket.Id).subscribe((response) => {
      if (!response.Success) {
        this.notification.error(response.Message);
      } else {
        this.notification.success('Successfully subscribed');
        this._ticket.IsSubscribed = this._ticketCopy.IsSubscribed = true;
      }
    });
  }

  unsubscribe() {
    if (!this._ticket.IsSubscribed) {
      return;
    }

    this.ticketsService.unsubscribeTicket(this._ticket.Id).subscribe((response) => {
      if (!response.Success) {
        this.notification.error(response.Message);
      } else {
        this.notification.success('Successfully unsubscribed');
        this._ticket.IsSubscribed = this._ticketCopy.IsSubscribed = false;
      }
    });
  }

  addNewComment() {
    let comment = {
      TicketId: this._ticket.Id,
      AuthorId: this.currentUser.Id,
      Author: this.currentUser.Name,
      Comment: this.newComment,
    };
    this.ticketsService.addTicketComment(comment).subscribe((response) => {
      if (!response.Success) {
        this.notification.error(response.Message);
        return;
      }

      this.ticketComments.push(response.TicketComment);
      this.newComment = '';
    });
  }

  assignStatusAndLocation() {
    const status = this.ticketStatuses.find((x) => x.Id === this._ticket.StatusId);
    this._ticket.Status = !!status ? status.Status : null;

    this._ticket.Location = this.locations.find((x) => x.Id === this._ticket.LocationId).LotName;
  }

  private init(ticket: Ticket) {
    this.ticketComments = [];
    this.ticketStatuses = [];

    if (!ticket) {
      return;
    }

    this.ticketsService.getTicketComments(ticket.Id).subscribe((response) => {
      this.ticketComments = response.Data;
    });

    zip(this.contactService.me(), this.ticketsService.getTicketStatuses(), this.locationService.getAll()).subscribe((response) => {
      this._ticket = ticket;
      this._ticketCopy = JSON.parse(JSON.stringify(this._ticket));

      this.currentUser = response[0].Contact;
      this.isOwner = this._ticket.OwnerId === this.currentUser.Id || this._ticket.IsEditable;

      this.ticketStatuses = response[1];

      this.locations = response[2].Data;
      this.assignStatusAndLocation();
    });
  }
}
