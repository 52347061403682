<div class="modal fade" bsModal #modal="bs-modal" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header clearfix">
        <h4 class="modal-title pull-left">Incident's audio records</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="cancel()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body">
        <div>
          <table class="table table-striped table-hover">
            <thead>
              <tr>
                <th>Playback</th>
                <th>Caller</th>
                <th></th>
              </tr>
            </thead>

            <tbody>
              <tr *ngFor="let record of audioRecordsList">
                <td class="audio">
                  <app-audio-player type="mp3" [src]="record.url"> </app-audio-player>
                </td>
                <td>
                  <p class="caller">{{ record.Caller }}</p>
                </td>
                <td style="text-align: right">
                  <a class="btn btn-info button--secondary btn-download" [href]="record.url"> <i class="fa fa-download"></i> Download </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="modal-footer">
          <button class="button" (click)="cancel()">Close</button>
        </div>
      </div>
    </div>
  </div>
</div>
