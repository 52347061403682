import { AppConfigurationService, APP_CONFIGURATION, IAppConfigurationBase } from './app-configuration.service';
import { Injectable, Inject } from '@angular/core';
import { SignalRBaseService } from './signalr-base.service';
import { Observable } from 'rxjs';
import { AppStateService } from './app-state.service';

export enum EntityOperations {
  Created = 0,
  Updated = 1,
  Deleted = 2,
}

export interface IDatabaseChangedEvent<T> {
  Operation: EntityOperations;
  Type: string;
  Entity: T;
  Version: number;
}

export interface IEntityBase {
  Id: number;
}

@Injectable({ providedIn: 'root' })
export class ActivitySignalRService extends SignalRBaseService {
  protected groupName = 'Portal';

  public get incidentChanged$(): Observable<IDatabaseChangedEvent<IEntityBase>> {
    return this.subscribeEntityChanged('Incident');
  }

  constructor(
    @Inject(APP_CONFIGURATION) private configuration: AppConfigurationService<IAppConfigurationBase>,
    appState: AppStateService,
  ) {
    super(configuration, appState);
  }

  private subscribeEntityChanged<T>(entityType: string): Observable<T> {
    let result = new Observable<T>((observer) => {
      let subscription = this.on<T>('DatabaseChangedEvent').subscribe((x) => observer.next(x));
      this.invoke('DatabaseChangesSubscribeEvent', { entityType: entityType });

      return () => {
        subscription.unsubscribe();
        this.invoke('DatabaseChangesUnsubscribeEvent', { entityType: entityType });
      };
    });

    return result;
  }
}
