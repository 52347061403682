import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Subscription } from 'rxjs';

import { AuthService } from '@libs/portal-common/services';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
})
export class RegistrationComponent implements OnInit, OnDestroy {
  private subscription: Subscription;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private auth: AuthService,
  ) {}

  ngOnInit() {
    this.subscription = this.route.queryParams.subscribe((params) => {
      this.auth.logout();

      let provider = params['provider'];
      let key = params['key'];

      if (provider && key) {
        let queryParams = {
          registration: true,
          provider: provider,
          key: key,
        };

        this.router.navigate(['login'], { queryParams: queryParams });
      }
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
