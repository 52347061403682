<div class='modal fade' bsModal #receiptModal='bs-modal' tabindex='-1' role='dialog' aria-hidden='true'>
  <div class='modal-dialog modal-sm' style='width: 400px'>
    <div class='modal-content'>
      <div class='modal-header clearfix'>
        <div class='modal-header-close'>
          <button type='button' class='close pull-right' aria-label='Close' (click)='close()'>
            <span aria-hidden='true'>&times;</span>
          </button>
        </div>
        <div style='width: 100%'>
          <div class='modal-logo'>
            <img src='img/splash.png' alt='logo-umojo'>
          </div>
        </div>
        <div class='modal-header-title'>
          <span class='modal-title pull-left'>Receipt</span>
        </div>
        <div style='width: 100%;' *ngIf='validationMessage && !this.formGroup.valid' class='form-group-receipt'>
          <div class='text-danger'>
            {{validationMessage}}
          </div>
        </div>
      </div>
      <div class='modal-body'>
        <form [formGroup]='formGroup'>
          <div class='form-group-receipt col-sm-12'>
            <div class='form-group-receipt-main'>
              <label>Customer Name</label>
              <input name='CustomerName' maxLength='220' type='text' formControlName='CustomerName'
                     class='form-control' />
            </div>
          </div>

          <div class='form-group-receipt col-sm-12'>
            <div class='form-group-receipt-main'>
              <label>Address</label>
              <input name='Address' maxlength='220' type='text' formControlName='Address' class='form-control' />
            </div>
          </div>

          <div class='form-group-receipt col-sm-12'>
            <div class='form-group-receipt-main'>
              <label>City</label>
              <input name='City' maxlength='220' type='text' formControlName='City' class='form-control' />
            </div>
          </div>

          <div class='form-group-receipt col-sm-12'>
            <div class='form-group-receipt-main'>
              <label>State / Province</label>
              <input name='StateProvince' maxlength='220' type='text' formControlName='StateProvince' class='form-control' />
            </div>
          </div>

          <div class='form-group-receipt col-sm-12'>
            <div class='form-group-receipt-main'>
              <label>Zip Code</label>
              <input name='ZipCode' maxlength='220' type='text' formControlName='ZipCode' class='form-control' />
            </div>
          </div>

          <div class='form-group-receipt col-sm-12'>
            <div class='form-group-receipt-main'>
              <label>Phone</label>
              <input name='Phone' maxlength='20' type='text' formControlName='Phone' class='form-control' />
            </div>
          </div>

          <div class='form-group-receipt col-sm-12'>
            <div class='form-group-receipt-main'>
              <label>Email Address</label>
              <input name='CustomerEmail' maxlength='50' type='text' formControlName='CustomerEmail' class='form-control' />
            </div>
          </div>

          <div class='form-group-receipt col-sm-12'>
            <div class='form-group-receipt-main'>
              <label>License Plate</label>
              <input name='LicensePlate' maxlength='220' type='text' formControlName='LicensePlate'
                     class='form-control' />
            </div>
          </div>

          <div class='form-group-receipt col-sm-12'>
            <div class='form-group-receipt-main'>
              <label>Parking Location</label>
              <input name='ParkingLocation' maxlength='220' type='text' formControlName='ParkingLocation'
                     class='form-control' disabled [value]='formGroup.controls.ParkingLocation' />
            </div>
          </div>

          <div class='form-group-receipt col-sm-12'>
            <div class='form-group-receipt-main'>
              <label>Cash / Credit Amount</label>
              <input name='CashCreditAmount' type='number' formControlName='CashCreditAmount'
                     min='0.00' step='1' max='1000000' class='form-control receipt-cash' />
              <span class='receipt-cash-currency'>$</span>
            </div>
          </div>

          <div class='form-group-receipt col-sm-12'>
            <div class='form-group-receipt-main'>
              <label>Date / Time</label>
              <input name='DateOfTransaction' maxlength='220' type='text' formControlName='DateOfTransaction' class='form-control' disabled
                     [value]='formGroup.controls.DateOfTransaction' />
            </div>
          </div>
        </form>
      </div>

      <div class='modal-footer'>
        <button class='button' type='reset' (click)='close()'>Cancel</button>
        <button class='button button--primary' (click)='save()'>Send</button>
      </div>

    </div>
  </div>
</div>
