import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';

import { AccessManagementService, AppStateService, INavbarItemsService, IRouteDescription } from '@libs/portal-common/services';

import { AppConfigurationService, APP_CONFIGURATION } from '../app-configuration.service';
import { IncomingCall } from '../../abstractions';

interface IAppRouteDescription extends IRouteDescription {
  requiresCall?: boolean;
}

const ROUTES: IAppRouteDescription[] = [
  { route: '/activity/agent', displayName: 'Agent Activity', icon: '/img/nav-menu-icons/Activity.svg', requiresCall: false },
  {
    route: '/call/call-processing/general-information',
    displayName: 'General Information',
    icon: '/img/skype/nav/homepage-hover.svg',
    requiresCall: true,
  },
  {
    route: '/call/call-processing/rate-information',
    displayName: 'Rate Information',
    icon: '/img/skype/nav/male-avatar-hover.svg',
    requiresCall: true,
  },
  {
    route: '/call/call-processing/equipment',
    displayName: 'Pictures',
    icon: '/img/skype/nav/picture-hover.svg',
    requiresCall: true,
  },
  {
    route: '/call/call-processing/common-issues',
    displayName: 'Common Issues',
    icon: '/img/skype/nav/common-issues.svg',
    requiresCall: true,
  },
  {
    route: '/call/call-processing/receipt-modal',
    displayName: 'Receipt',
    icon: '/img/skype/nav/issues-hover.svg',
    requiresCall: true,
  },
  {
    route: '/call/call-processing/create-ticket-modal',
    displayName: 'Ticket',
    icon: '/img/skype/nav/create-ticket.svg',
    requiresCall: true,
  },
];

@Injectable()
export class NavbarItemsService implements INavbarItemsService {
  private _routes$ = new BehaviorSubject<IRouteDescription[]>([]);
  private _redirectUrl$ = new BehaviorSubject<string>(null);
  private _incomingCall$ = new BehaviorSubject<IncomingCall>(null);
  private _incomingCallProcessing$ = new BehaviorSubject<boolean>(false);
  private _openConversationManagement$ = new BehaviorSubject<boolean>(false);

  public get routes$(): Observable<IRouteDescription[]> {
    return this._routes$.asObservable();
  }

  public get redirectUrl$(): Observable<string> {
    return this._redirectUrl$.asObservable();
  }

  public get incomingCall$(): Observable<IncomingCall> {
    return this._incomingCall$.asObservable();
  }

  public get incomingCallProcessing$(): Observable<boolean> {
    return this._incomingCallProcessing$.asObservable();
  }

  public get openConversationManagement$(): Observable<boolean> {
    return this._openConversationManagement$.asObservable();
  }

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private appStateService: AppStateService,
    @Inject(APP_CONFIGURATION) private configuration: AppConfigurationService,
    private accessManagementService: AccessManagementService,
  ) {
    combineLatest([this.appStateService.isAuthenticated$, this._incomingCall$]).subscribe((res) => {
      let inCall = !!res[1];

      let routes = ROUTES.filter((r) => {
        let permissionsAllowed = this.accessManagementService.hasAccessPermissions(r.requiredPermissions);

        let allowed = (!inCall && !r.requiresCall) || (inCall && r.requiresCall);

        return permissionsAllowed && allowed;
      });

      this._routes$.next(routes);

      if (routes.length > 0) {
        this._redirectUrl$.next(routes[0].route);
      }
    });
  }

  setIncomingCall(call: IncomingCall) {
    this._incomingCall$.next(call);
  }

  startIncomingCallProcessing() {
    this._incomingCallProcessing$.next(true);
  }
  leaveIncomingCallProcessing() {
    this._incomingCallProcessing$.next(false);
  }
  openConversationManagement() {
    this._openConversationManagement$.next(true);
  }
}
