export class ActivityCssClass {
  public static readonly Default = new ActivityCssClass(20, 35, 90, 120, 25, 40);

  constructor(
    private queueTimer_OkTimeoutSeconds?: number,
    private queueTimer_WarningTimeoutSeconds?: number,
    private hangUpTimer_OkTimeoutSeconds?: number,
    private hangUpTimer_WarningTimeoutSeconds?: number,
    private wrapUpTimer_OkTimeoutSeconds?: number,
    private wrapUpTimer_WarningTimeoutSeconds?: number,
  ) {}

  public queueFieldClass(value): string {
    let result = 'field-alarm';

    if (value <= this.queueTimer_OkTimeoutSeconds) {
      result = 'field-success';
    } else if (value <= this.queueTimer_WarningTimeoutSeconds) {
      result = 'field-warning';
    }

    return result;
  }

  public durationFieldClass(value) {
    let result = 'field-alarm';

    if (value <= this.hangUpTimer_OkTimeoutSeconds) {
      result = 'field-success';
    } else if (value <= this.hangUpTimer_WarningTimeoutSeconds) {
      result = 'field-warning';
    }

    return result;
  }

  public wrapupFieldClass(value) {
    let result = 'field-alarm';

    if (value <= this.wrapUpTimer_OkTimeoutSeconds) {
      result = 'field-success';
    } else if (value <= this.wrapUpTimer_WarningTimeoutSeconds) {
      result = 'field-warning';
    }

    return result;
  }

  public totalFieldClass(value) {
    let result = 'field-alarm';

    if (value <= 115) {
      result = 'field-success';
    } else if (value <= 160) {
      result = 'field-warning';
    }

    return result;
  }
}
