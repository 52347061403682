import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Params } from '@angular/router';

import { tap, map } from 'rxjs/operators';
import { AppStateService } from '../services/app-state.service';
import { AuthService } from '../services/access-management/auth.service';

const returnUrlHandler = (state: RouterStateSnapshot): string => {
  let returnUrl = state.url;
  if (returnUrl.startsWith('/auth/logout')) {
    returnUrl = '/';
  }

  return returnUrl;
};

@Injectable()
export class AuthenticatedGuard implements CanActivate {
  constructor(
    private router: Router,
    private appStateService: AppStateService,
    private authService: AuthService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.appStateService.isAuthenticated) {
      return true;
    }

    const username = state.root.queryParams['username'];
    const password = state.root.queryParams['password'];
    if (!!username && !!password) {
      return this.authService.login({ username, password }).pipe(map((res) => this.appStateService.isAuthenticated));
    }

    let queryParams: Params = { returnUrl: returnUrlHandler(state) };

    this.router.navigate(['/auth/login'], { queryParams: queryParams });

    return false;
  }
}

@Injectable()
export class OnlyAnonimousGuard implements CanActivate {
  constructor(
    private router: Router,
    private appStateService: AppStateService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.appStateService.isAuthenticated$.pipe(
      tap((isSignedIn) => {
        if (isSignedIn) {
          this.router.navigate(['/']);
        }
      }),
      map((isSignedIn) => !isSignedIn),
    );
  }
}
