import { Injectable } from '@angular/core';

import { GridComponent } from '@progress/kendo-angular-grid';
import * as $ from 'jquery';

@Injectable({ providedIn: 'root' })
export class GridResizerService {
  constructor() {}

  resizeGrid(grid: GridComponent) {
    if (!grid) {
      return;
    }

    let page = $('.page-host'),
      pageHeight = page.innerHeight();

    let header = $('.page__heading'),
      headerHeight = header.innerHeight();

    let gridContainer = $('.grid-container'),
      gridContainerHeight = gridContainer.innerHeight();

    let limit = 30;

    let actualPageHeight = headerHeight + gridContainerHeight + 20;

    let delta = pageHeight - actualPageHeight;

    if (Math.abs(delta) > limit) {
      let $grid = $(grid.wrapper.nativeElement);
      let gridHeight = $grid.innerHeight();

      let newHeight = gridHeight + delta;
      $grid.css('height', newHeight + 'px');
    }
  }
}
