import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { ActiveToast } from 'ngx-toastr';
import { ToastrService } from 'ngx-toastr';
import { IndividualConfig } from 'ngx-toastr/toastr/toastr-config';

export interface ExtendedIndividualConfig extends IndividualConfig {
  traceId?: string | undefined;
  date?: string | undefined;
}

const defaultConfigToast: Partial<ExtendedIndividualConfig> = {
  timeOut: 5000,
  closeButton: true,
};

@Injectable({ providedIn: 'root' })
export class AppNotificationsService {
  initialized = false;
  defaultCreateMessage = 'The item has been created successfully.';
  defaultUpdateMessage = 'The item has been updated successfully.';
  defaultDeleteMessage = 'The item has been deleted successfully.';

  get toastsManager(): ToastrService {
    return this.toastr;
  }

  constructor(
    private toastr: ToastrService,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) {}

  success(message: string) {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }

    this.toastr.show(message, null, defaultConfigToast, 'toast-success');
  }

  successCreated() {
    this.toastr.show(this.defaultCreateMessage, null, defaultConfigToast, 'toast-success');
  }

  successUpdated() {
    this.toastr.show(this.defaultUpdateMessage, null, defaultConfigToast, 'toast-success');
  }

  successDeleted() {
    this.toastr.show(this.defaultDeleteMessage, null, defaultConfigToast, 'toast-success');
  }

  error(message: string) {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }

    this.toastr.show(message, null, defaultConfigToast, 'toast-error');
  }

  apiError(message: string, traceId: string) {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }

    const date = new Date().toUTCString();
    this.toastr.show(message, null, { ...defaultConfigToast, ...{ timeOut: 10000, traceId, date } }, 'toast-error');
  }

  warning(message: string) {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }

    this.toastr.show(message, null, defaultConfigToast, 'toast-warning');
  }

  info(message: string, title?: string, options?: any): ActiveToast<any> {
    if (!isPlatformBrowser(this.platformId)) {
      return null;
    }

    return this.toastr.show(message, null, defaultConfigToast, 'toast-info');
  }

  custom(message: string, title?: string, options?: any): ActiveToast<any> {
    return this.toastr.show(message, title, options);
  }

  checkResponse(response: any) {
    if (!response.Success && response.Message) {
      this.error(response.Message);
    }
  }
}

export function appNotificationsServiceFactory(toastr: ToastrService, platformId: Object) {
  return new AppNotificationsService(toastr, platformId);
}
