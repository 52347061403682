import { Injectable, Inject } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { Observable } from 'rxjs';
import { tap, map } from 'rxjs/operators';

import { NavbarItemsService } from './navbar-items.service';

@Injectable()
export class RedirectGuard implements CanActivate {
  constructor(
    private router: Router,
    private navItemsService: NavbarItemsService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.navItemsService.redirectUrl$.pipe(
      tap((redirectUrl) => {
        let url = '';
        if (state.url.indexOf('/#/') > -1) {
          url = state.url.replace('/#/', '/');
        } else {
          url = redirectUrl || '/auth/login';
        }

        this.router.navigateByUrl(url);
      }),
      map((redirectUrl) => true),
    );
  }
}
