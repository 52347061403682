<div class="container">
  <div class="row" *ngIf="!isAuthenthicated && !busy">
    <div class="center-form panel">
      <div class="text-center logo">
        <img class="img-fluid" src="/img/splash.png" alt="" />
      </div>

      <div class="login-form">
        <div class="externalAuthButtons text-center" *ngIf="externalProviders && externalProviders.length > 0">
          <button
            *ngFor="let provider of externalProviders"
            class="btn btn-primary btn-block external-login-button"
            [class.btn-adn]="isAzureADProvider(provider.ProviderType)"
            (click)="externalAuth(provider.Name)"
          >
            <img class="img-fluid microsoft" src="/img/microsoft.svg" alt="" />
            Sign in with {{ provider.DisplayName }}
          </button>
        </div>

        <p class="text-center message">
          Alternatively, <a href="#" (click)="setInternal($event)"> sign in</a> using your username and password.
        </p>

        <p *ngIf="error" class="alert-color text-center message">{{ error }}</p>

        <div class="internalAuthForm" *ngIf="internalAuth">
          <form method="post" name="loginForm" #loginForm="ngForm" novalidate>
            <div class="form-group" [class.has-error]="username | hasErrors: validate">
              <label>Username</label>
              <input
                class="form-control"
                type="text"
                name="username"
                [(ngModel)]="model.username"
                placeholder="Username"
                #username="ngModel"
                autofocus
                required
              />
              <app-validation-errors [model]="username" [validate]="validate"></app-validation-errors>
            </div>

            <div class="form-group" [class.has-error]="password | hasErrors: validate">
              <label>Password</label>
              <div class="input-group">
                <input
                  class="form-control"
                  [type]="showPassword ? 'text' : 'password'"
                  name="password"
                  [(ngModel)]="model.password"
                  placeholder="Password"
                  #password="ngModel"
                  required
                />
                <div class="input-group-append">
                  <span class="input-group-text"
                    ><i class="fa" [ngClass]="showPassword ? 'fa-eye-slash' : 'fa-eye'" (click)="showPassword = !showPassword"></i
                  ></span>
                </div>
              </div>
              <app-validation-errors [model]="password" [validate]="validate"></app-validation-errors>
            </div>

            <div class="row">
              <div class="col-sm-12">
                <button type="submit" class="btn btn-block button-login" (click)="login()">Login</button>
              </div>
            </div>
            <br />
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
