import { Component, OnInit, Input } from '@angular/core';
import { NgModel } from '@angular/forms';

export enum ValidationErrorTypesEnum {
  required = 'required',
  minlength = 'minlength',
  maxlength = 'maxlength',
  pattern = 'pattern',
  minError = 'minError',
  appValidateEmail = 'appValidateEmail',
  appValidateEmailLength = 'appValidateEmailLength',
  appValidateNumber = 'appValidateNumber',
  appValidateSip = 'appValidateSip',
  serverSideValidation = 'server-side-validation',
  price = 'price',
}

export interface ICustomValidationMessage {
  text: string;
  errorType: ValidationErrorTypesEnum;
}

@Component({
  selector: 'app-validation-errors',
  templateUrl: './validation-errors.component.html',
})
export class ValidationErrorsComponent implements OnInit {
  @Input() model: NgModel;
  @Input() validate = false;
  @Input() customValidationMessage?: ICustomValidationMessage;

  get error(): string {
    return this.getFirstError();
  }

  constructor() {}

  ngOnInit() {}

  private getFirstError(): string {
    const model = this.model;

    if (!model || !this.validate) {
      return null;
    }

    return this.initialErrorMessage(model);
  }

  private initialErrorMessage(model: NgModel): string | null {
    let errors = new Array<string>();
    if (model.hasError(ValidationErrorTypesEnum.required)) {
      if (this.checkValidationMessage(ValidationErrorTypesEnum.required)) {
        errors.push(this.customValidationMessage.text);
      } else {
        errors.push('The field is required');
      }
    }

    if (model.hasError(ValidationErrorTypesEnum.minlength)) {
      if (this.checkValidationMessage(ValidationErrorTypesEnum.minlength)) {
        errors.push(this.customValidationMessage.text);
      } else {
        errors.push(`min length ${model.errors.minlength.requiredLength}`);
      }
    }

    if (model.hasError(ValidationErrorTypesEnum.maxlength)) {
      if (this.checkValidationMessage(ValidationErrorTypesEnum.maxlength)) {
        errors.push(this.customValidationMessage.text);
      } else {
        errors.push(`max length ${model.errors.maxlength.requiredLength}`);
      }
    }

    if (model.hasError(ValidationErrorTypesEnum.appValidateEmailLength)) {
      if (this.checkValidationMessage(ValidationErrorTypesEnum.appValidateEmail)) {
        errors.push(this.customValidationMessage.text);
      } else {
        errors.push('The email address must be 49 characters or less');
      }
    }

    if (model.hasError(ValidationErrorTypesEnum.appValidateEmail)) {
      if (this.checkValidationMessage(ValidationErrorTypesEnum.appValidateEmail)) {
        errors.push(this.customValidationMessage.text);
      } else {
        errors.push('Email address is not valid');
      }
    }

    if (model.hasError(ValidationErrorTypesEnum.pattern)) {
      if (this.checkValidationMessage(ValidationErrorTypesEnum.pattern)) {
        errors.push(this.customValidationMessage.text);
      } else {
        errors.push('invalid pattern');
      }
    }

    if (model.hasError(ValidationErrorTypesEnum.minError)) {
      if (this.checkValidationMessage(ValidationErrorTypesEnum.minError)) {
        errors.push(this.customValidationMessage.text);
      } else {
        errors.push(`min value ${model.errors.minError.minValue}`);
      }
    }

    if (model.hasError(ValidationErrorTypesEnum.appValidateNumber)) {
      if (this.checkValidationMessage(ValidationErrorTypesEnum.appValidateNumber)) {
        errors.push(this.customValidationMessage.text);
      } else {
        errors.push('invalid number');
      }
    }

    if (model.hasError(ValidationErrorTypesEnum.appValidateSip)) {
      if (this.checkValidationMessage(ValidationErrorTypesEnum.appValidateSip)) {
        errors.push(this.customValidationMessage.text);
      } else {
        errors.push("invalid sip, must match 'sip:xxxxx@xxxxx.xxx'");
      }
    }

    if (model.hasError(ValidationErrorTypesEnum.serverSideValidation)) {
      if (this.checkValidationMessage(ValidationErrorTypesEnum.serverSideValidation)) {
        errors.push(this.customValidationMessage.text);
      } else {
        errors.push(model.errors['server-side-validation'].message);
      }
    }

    if (model.name === ValidationErrorTypesEnum.price) {
      if (this.checkValidationMessage(ValidationErrorTypesEnum.price)) {
        errors.push(this.customValidationMessage.text);
      } else {
        errors.push('The price must be zero or greater');
      }
    }

    if (errors.length > 0) {
      return errors[0];
    }

    return null;
  }

  private checkValidationMessage(errorType: ValidationErrorTypesEnum) {
    return this.customValidationMessage && errorType === this.customValidationMessage.errorType;
  }
}
