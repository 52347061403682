import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { BaseResponse } from '../models/rest/baseResponse';

export interface ILqmEmbedTokenResponse extends BaseResponse{
  EmbedToken: string;
}

@Injectable({ providedIn: 'root' })
export class LiveQueueMonitorService {

  constructor(private _httpClient: HttpClient) {}

  getEmbedToken(tenantId: string, groupId: string,
                clientId: string, dashboardId: string,
                clientSecret: string): Observable<ILqmEmbedTokenResponse> {
    const requestUrl = `Reports/PowerBiDashboard/EmbedToken?TenantId=${tenantId}&GroupId=${groupId}&DashboardId=${dashboardId}&ClientId=${clientId}&ClientSecret=${clientSecret}`;
    return this._httpClient.get<ILqmEmbedTokenResponse>(requestUrl);
  }

}
