import { Directive, forwardRef, Input } from '@angular/core';
import { NG_VALIDATORS } from '@angular/forms';
import { Validator, AbstractControl } from '@angular/forms';

@Directive({
  selector: '[appValidateEmail][formControlName], [appValidateEmail][formControl],[appValidateEmail][ngModel]',
  providers: [{ provide: NG_VALIDATORS, useExisting: forwardRef(() => EmailValidatorDirective), multi: true }],
})
export class EmailValidatorDirective implements Validator {
  @Input('turnOffEmptyEmailValidation') turnOffEmptyEmailValidation?: boolean;
  constructor() {}

  validate(c: AbstractControl) {
    if (this.turnOffEmptyEmailValidation  && !c.value) {
      return null;
    }
    let EMAIL_REGEXP = /^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i;
    const valid = EMAIL_REGEXP.test(c.value);

    if (c.value && c.value.length > 49) {
      return {
        appValidateEmailLength: {
          valid: false,
        },
      };
    }

    return valid
      ? null
      : {
          appValidateEmail: {
            valid: false,
          },
        };
  }
}
