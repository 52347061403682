import {
  Component,
  ViewChild,
  Input,
  OnInit,
  OnChanges,
  OnDestroy,
  Output,
  EventEmitter,
  ElementRef,
  Renderer2,
  TemplateRef,
  NgZone,
  Inject,
} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { Observable, Subscription, zip } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { GridComponent, GridDataResult, DataStateChangeEvent, RowClassArgs, ColumnReorderEvent } from '@progress/kendo-angular-grid';
import { GroupDescriptor, State } from '@progress/kendo-data-query';
import { ExcelExportEvent } from '@progress/kendo-angular-grid/dist/es2015/excel/excel-export-event';

import { toDataURL, ExcelExportData } from '@progress/kendo-angular-excel-export';
import { saveAs } from '@progress/kendo-file-saver';

import * as $ from 'jquery';
import * as moment from 'moment';
declare let kendo: any;

import {
  ActivitySignalRService,
  ANALYTICS,
  AppConfigurationService,
  APP_CONFIGURATION,
  CallCenterService,
  EntityOperations,
  IAppConfigurationBase,
  IAnalyticsService,
  AnalyticsEvent,
  ActivityReportName,
} from '@libs/portal-common/services';

import { AudioRecordsComponent } from '../audio-records/audio-records.component';
import { VideoRecordComponent } from '../video-record/video-record.component';
import { ActivityCssClass } from '../activity-css-class';
import { ActivityFilter, CallHistoryService } from '../services/call-history.service';
import { GridStateService, IDynamicField, PredefinedFields, DynamicFieldComparer } from '../services/grid-state.service';
import { ActivityGridFieldsPickerComponent } from '../activity-grid-fields-picker/activity-grid-fields-picker.component';
import { ActivityAddNoteComponent } from '../activity-add-note/activity-add-note.component';
import { AppNotificationsService } from '@libs/portal-common/system';
import { VoiceBotTranscriptionComponent } from '../voicebot/voicebot-transcription.component';

const LIMIT_NUMBER_OF_RECORDS_FOR_PDF_EXPORT = 100;
const MESSAGE_PDF_EXPORT_LIMIT =
  'Limit for PDF export is ' +
  LIMIT_NUMBER_OF_RECORDS_FOR_PDF_EXPORT +
  ' records, please choose filters for table data before the next export process.';

interface IGridField extends IDynamicField {
  cellTemplate: TemplateRef<any>;
  groupHeaderTemplate: TemplateRef<any>;
  groupFooterTemplate: TemplateRef<any>;
}

interface IGridState {
  state: State;
  filter: ActivityFilter;
}

const DEFAULT_STATE: State = {
  skip: 0,
  take: 30,
  group: [],
  filter: { logic: 'and', filters: [] },
  sort: [{ field: 'CallTime', dir: 'desc' }],
};

@Component({
  selector: 'app-activity-grid',
  templateUrl: './activity-grid.component.html',
  styleUrls: ['./activity-grid.component.scss'],
})
export class ActivityGridComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('addNote', { static: true }) public addNote: ActivityAddNoteComponent;
  @ViewChild(GridComponent, { static: true }) private grid: GridComponent;
  @ViewChild('audioRecords', { static: true }) private audioRecordsModal: AudioRecordsComponent;
  @ViewChild('videoRecord', { static: true }) private videoRecordModal: VideoRecordComponent;
  @ViewChild('voiceBotTranscription', { static: true }) private voiceBotTranscriptionModal: VoiceBotTranscriptionComponent;
  @ViewChild('gridContainer', { static: true }) private gridContainer: ElementRef;
  @ViewChild('activityGridFieldsPicker', { static: true }) private activityGridFieldsPicker: ActivityGridFieldsPickerComponent;

  @ViewChild('Id_cell', { static: true }) Id_cell: TemplateRef<any>;
  @ViewChild('CallTime_cell', { static: true }) CallTime_cell: TemplateRef<any>;
  @ViewChild('CallTime_header', { static: true }) CallTime_header: TemplateRef<any>;
  @ViewChild('Vend_cell', { static: true }) Vend_cell: TemplateRef<any>;
  @ViewChild('Vend_footer', { static: true }) Vend_footer: TemplateRef<any>;
  @ViewChild('QueueTime_cell', { static: true }) QueueTime_cell: TemplateRef<any>;
  @ViewChild('QueueTime_footer', { static: true }) QueueTime_footer: TemplateRef<any>;
  @ViewChild('CallDuration_cell', { static: true }) CallDuration_cell: TemplateRef<any>;
  @ViewChild('CallDuration_footer', { static: true }) CallDuration_footer: TemplateRef<any>;
  @ViewChild('Wrapup_cell', { static: true }) Wrapup_cell: TemplateRef<any>;
  @ViewChild('Wrapup_footer', { static: true }) Wrapup_footer: TemplateRef<any>;
  @ViewChild('TotalTime_cell', { static: true }) TotalTime_cell: TemplateRef<any>;
  @ViewChild('TotalTime_footer', { static: true }) TotalTime_footer: TemplateRef<any>;
  @ViewChild('IsRecordingFound_cell', { static: true }) IsRecordingFound_cell: TemplateRef<any>;
  @ViewChild('IsVideoRecordingFound_cell', { static: true }) IsVideoRecordingFound_cell: TemplateRef<any>;
  @ViewChild('VBEventId_cell', { static: true }) VBEventId_cell: TemplateRef<any>;
  @ViewChild('VBEventFileName_cell', { static: true }) VBEventFileName_cell: TemplateRef<any>;
  @ViewChild('VBEventCallSegment_cell', { static: true }) VBEventCallSegment_cell: TemplateRef<any>;
  @ViewChild('VBEventDur_cell', { static: true }) VBEventDur_cell: TemplateRef<any>;
  @ViewChild('VBEventDur_footer', { static: true }) VBEventDur_footer: TemplateRef<any>;

  @ViewChild('bool_cell', { static: true }) bool_cell: TemplateRef<any>;
  @ViewChild('DateTime_cell', { static: true }) DateTime_cell: TemplateRef<any>;

  @ViewChild('NoodoeStationChargeStarted_cell', { static: true }) NoodoeStationChargeStarted_cell: TemplateRef<any>;
  @ViewChild('NoodoeStationChargeStopped_cell', { static: true }) NoodoeStationChargeStopped_cell: TemplateRef<any>;
  @ViewChild('NoodoeStationReseted_cell', { static: true }) NoodoeStationReseted_cell: TemplateRef<any>;

  @ViewChild('IsCallClosedByAgent_cell', { static: true }) IsCallClosedByAgent_cell: TemplateRef<any>;

  @Input() mode: string;
  @Input() filter: ActivityFilter;

  @Input() pdfFileName: string;
  @Input() excelFileName: string;

  @Output() busy = new EventEmitter<boolean>();

  @Input() reportName: ActivityReportName;

  subscription: Subscription;

  groupable: any = { showFooter: true };

  aggregates: any[] = [
    { field: 'Vend', aggregate: 'sum' },
    { field: 'QueueTime', aggregate: 'sum' },
    { field: 'CallDuration', aggregate: 'sum' },
    { field: 'Wrapup', aggregate: 'sum' },
    { field: 'TotalTime', aggregate: 'sum' },
    { field: 'VBEventDur', aggregate: 'sum' },
  ];

  state: State = JSON.parse(JSON.stringify(DEFAULT_STATE));

  view: Observable<GridDataResult>;

  private signalrSubscription;

  private updatedIncidents = new Array<number>();

  fields: Array<IGridField> = [];

  defaultCssSettings = ActivityCssClass.Default;
  cssSettings: { [key: number]: ActivityCssClass } = {};

  refreshTimeout: NodeJS.Timeout = null;

  constructor(
    private ngZone: NgZone,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private renderer: Renderer2,
    private activitySignalRService: ActivitySignalRService,
    private service: CallHistoryService,
    private gridStateService: GridStateService,
    private callCentersService: CallCenterService,
    private notifications: AppNotificationsService,
    @Inject(APP_CONFIGURATION) private configuration: AppConfigurationService<IAppConfigurationBase>,
    @Inject(ANALYTICS) private analytcis: IAnalyticsService,
  ) {
    this.view = service;

    this.view.subscribe((x) => {
      this.expandDetails(this.state);
    });
  }

  dataStateChange(state: DataStateChangeEvent): void {
    state.group.map((group) => (group.aggregates = this.aggregates));
    state.sort = state.sort.filter((item) => !state.group.find((gr) => gr.field === item.field));

    this.state = state;

    this.expandDetails(this.state);
    this.query();
  }

  groupChange(groups: Array<GroupDescriptor>): void {
    if (groups.length === 0) {
      return;
    }

    groups[0].aggregates = this.aggregates;
  }

  showDetail(dataItem: any, index: number) {
    return !!dataItem.MergedFields;
  }

  columnReorder(evt: ColumnReorderEvent, grid: GridComponent) {
    setTimeout(() => {
      let fields = new Array<IGridField>();
      grid.columns
        .toArray()
        .sort((a, b) => a.orderIndex - b.orderIndex)
        .forEach((column, idx) => {
          let field = this.fields.find((x) => x.fieldName === column['field']);
          if (!!field) {
            field.order = idx;
            fields.push(field);
          }
        });

      this.gridStateService.saveState({ dynamicFields: fields });
    }, 10);
  }

  openFieldsPicker() {
    this.activityGridFieldsPicker
      .open()
      .pipe(filter((gridState) => !!gridState))
      .subscribe((gridState) => {
        this.analytcis.track(AnalyticsEvent.DailyActivityFieldsSubmit, { ReportName: this.reportName });
        this.fields = this.mapFields(gridState.dynamicFields);

        this.state = JSON.parse(JSON.stringify(DEFAULT_STATE));
        let state = JSON.stringify({ filter: this.filter, state: this.state });
        this.router.navigate([], { relativeTo: this.activatedRoute, queryParams: { state: btoa(state) }, replaceUrl: true });
      });
  }

  ngOnInit() {
    this.subscription = this.activatedRoute.queryParams.subscribe((queryParams) => {
      if (!!!!queryParams.state) {
        let gridState: IGridState = JSON.parse(atob(queryParams.state));

        this.state = gridState.state;
        this.filter = gridState.filter;
      }
    });

    this.updatedIncidents = [];

    if (this.mode === 'lane') {
      this.state.group = [{ field: 'Lane', aggregates: this.aggregates }];
      this.groupable = false;
    }

    zip(this.gridStateService.getState(), this.callCentersService.getAll()).subscribe((res) => {
      const state = res[0];

      res[1].CallCenters.forEach((callCenter) => {
        this.cssSettings[callCenter.Id] = new ActivityCssClass(
          callCenter.QueueOkSeconds,
          callCenter.QueueWarningSeconds,
          callCenter.HangUpOkSeconds,
          callCenter.HangUpWarningSeconds,
          callCenter.WrapUpOkSeconds,
          callCenter.WrapUpWarningSeconds,
        );
      });

      this.fields = this.mapFields(state.dynamicFields);
      this.query();
    });

    this.ngZone.runOutsideAngular(() => {
      this.signalrSubscription = this.activitySignalRService.incidentChanged$.subscribe((data) => {
        this.ngZone.runTask(() => this.onDatabaseChanged(data));
      });

      this.activitySignalRService.init();
    });
  }

  ngOnDestroy() {
    this.service.clear();

    if (!!this.signalrSubscription) {
      this.signalrSubscription.unsubscribe();
      this.signalrSubscription = null;
    }

    if (!!this.subscription) {
      this.subscription.unsubscribe();
      this.subscription = null;
    }
  }

  ngOnChanges(changes) {
    if (changes.filter && changes.filter.currentValue !== changes.filter.previousValue) {
      this.state.skip = 0;
      this.query();
    }
  }

  rowSelectionChange($event: any) {
    const dataItem = $event.selectedRows[0].dataItem;
    if (this.configuration.mode === 'portal') {
      this.router.navigate([dataItem.Id], { relativeTo: this.activatedRoute });
    }
  }

  cellClick($event: any) {
    const dataItem = $event.dataItem;
    if (this.configuration.mode === 'webagent') {
      this.addNote.open(dataItem.Id, dataItem.Notes).subscribe((res) => {
        if (!!res) {
          this.query();
        }
      });
    }
  }

  private onDatabaseChanged(data: any) {
    if (data.type !== 'Incident' || data.entity === null) {
      return;
    }

    if (data.operation === EntityOperations.Created || data.operation === EntityOperations.Updated) {
      const entityId: number = data.entity.id;
      this.updatedIncidents.push(entityId);

      setTimeout(() => {
        this.updatedIncidents = this.updatedIncidents.filter((x) => x !== entityId);
      }, 5000);
    }

    if (!this.refreshTimeout) {
      this.refreshTimeout = setTimeout(() => {
        this.query();
      }, 5000);
    }
  }

  public rowCallback(context: RowClassArgs) {
    const id: number = context.dataItem.Id;
    const updated = !!this.updatedIncidents.find((x) => x === id);

    let result = {
      incident: true,
      'updated-incident': updated,
    };

    return result;
  }

  expandDetails(state: State) {
    if (this.grid) {
      let skip = state.skip || 0;
      for (let index = 0; index < state.take; index++) {
        this.grid.expandRow(index + skip);
      }
    }

    this.resizeGrid();
  }

  excelExport(event: ExcelExportEvent) {
    event.preventDefault();
    this.analytcis.track(AnalyticsEvent.DailyActivityExport, { ReportName: this.reportName, ExportType: 'Excel' });

    let sheet = event.workbook.sheets[0];
    let header = sheet.rows[0];
    let index_Date = -1,
      index_QueueTime = -1,
      index_CallDuration = -1,
      index_Wrapup = -1,
      index_TotalTime = -1,
      index_Vend = -1,
      index_fkCallCenter = -1;

    for (let cellIndex = 0; cellIndex < header.cells.length; cellIndex++) {
      if (header.cells[cellIndex].value === 'Date') {
        index_Date = cellIndex;
      } else if (header.cells[cellIndex].value === 'Queue') {
        index_QueueTime = cellIndex;
      } else if (header.cells[cellIndex].value === 'Dur') {
        index_CallDuration = cellIndex;
      } else if (header.cells[cellIndex].value === 'Wrapup') {
        index_Wrapup = cellIndex;
      } else if (header.cells[cellIndex].value === 'Total') {
        index_TotalTime = cellIndex;
      } else if (header.cells[cellIndex].value === 'Vend') {
        index_Vend = cellIndex;
      } else if (header.cells[cellIndex].value === 'fkCallCenter') {
        index_fkCallCenter = cellIndex;
      }
    }

    let localDateTimeFormat = moment.localeData().longDateFormat('LLL');
    if (localDateTimeFormat.endsWith(' A')) {
      localDateTimeFormat += 'M/PM';
    }

    let rows: Array<any> = sheet.rows.filter((row) => row.type === 'data');
    for (let rowIndex = 0; rowIndex < rows.length; rowIndex++) {
      let row = rows[rowIndex];

      if (index_Date > -1) {
        row.cells[index_Date].value = new Date(row.cells[index_Date].value);
        row.cells[index_Date].format = localDateTimeFormat;
      }

      let fkCallCenter = row.cells[index_fkCallCenter].value;

      if (index_QueueTime > -1) {
        this.applyClass(row.cells[index_QueueTime], this.queueFieldClass(null, row.cells[index_QueueTime].value, fkCallCenter));
        row.cells[index_QueueTime].value = this.formatDuration(row.cells[index_QueueTime].value);
      }

      if (index_CallDuration > -1) {
        this.applyClass(row.cells[index_CallDuration], this.durationFieldClass(null, row.cells[index_CallDuration].value, fkCallCenter));
        row.cells[index_CallDuration].value = this.formatDuration(row.cells[index_CallDuration].value);
      }

      if (index_Wrapup > -1) {
        this.applyClass(row.cells[index_Wrapup], this.wrapupFieldClass(null, row.cells[index_Wrapup].value, fkCallCenter));
        row.cells[index_Wrapup].value = this.formatDuration(row.cells[index_Wrapup].value);
      }

      if (index_TotalTime > -1) {
        this.applyClass(row.cells[index_TotalTime], this.totalFieldClass(null, row.cells[index_TotalTime].value, fkCallCenter));
        row.cells[index_TotalTime].value = this.formatDuration(row.cells[index_TotalTime].value);
      }

      if (index_Vend > -1) {
        if (row.cells[index_Vend].value) {
          row.cells[index_Vend].value = 'YES';
          row.cells[index_Vend].color = '#ff0000'; // red
        } else {
          row.cells[index_Vend].value = 'NO';
          row.cells[index_Vend].color = '#008000'; // green
        }
      }

      if (index_fkCallCenter > -1) {
        row.cells.pop();
      }
    }

    if (index_fkCallCenter > -1) {
      header.cells.pop();
    }

    let dataURL = toDataURL(event.workbook);
    Promise.resolve(dataURL).then((data) => {
      saveAs(data, this.excelFileName);
    });
  }

  fetchData(grid: GridComponent): Promise<ExcelExportData> {
    let state = JSON.parse(JSON.stringify(this.state));
    state.skip = 0;
    state.take = 0;

    return this.service
      .getCallHistory(this.filter, state, this.fields)
      .pipe(
        map((res) => {
          return {
            data: res.data,
            group: grid.group,
          };
        }),
      )
      .toPromise();
  }

  showRecords(id: number, $event: any) {
    $event.preventDefault();
    $event.stopPropagation();

    this.audioRecordsModal.open(id);
  }

  showVBEventRecords(conferenceId: string, $event: any) {
    $event.preventDefault();
    $event.stopPropagation();

    this.audioRecordsModal.openVBEventAudio(conferenceId);
  }

  showTranscript(incidentId: number, botEventConferenceId: string, $event: any) {
    $event.preventDefault();
    $event.stopPropagation();

    this.voiceBotTranscriptionModal.open(incidentId, botEventConferenceId);
  }

  showVideoRecord(id: number, $event: any) {
    $event.preventDefault();
    $event.stopPropagation();

    this.videoRecordModal.open(id);
  }

  queueFieldClass(cell, value, fkCallCenter: number): string {
    let result = this.getActivityCssClass(fkCallCenter).queueFieldClass(value);

    if (cell) {
      this.applyClassToParent(cell, result);
    }

    return result;
  }

  durationFieldClass(cell, value, fkCallCenter: number) {
    let result = this.getActivityCssClass(fkCallCenter).durationFieldClass(value);

    if (cell) {
      this.applyClassToParent(cell, result);
    }

    return result;
  }

  wrapupFieldClass(cell, value, fkCallCenter: number) {
    let result = this.getActivityCssClass(fkCallCenter).wrapupFieldClass(value);

    if (cell) {
      this.applyClassToParent(cell, result);
    }

    return result;
  }

  totalFieldClass(cell, value, fkCallCenter: number) {
    let result = this.getActivityCssClass(fkCallCenter).totalFieldClass(value);

    if (cell) {
      this.applyClassToParent(cell, result);
    }

    return result;
  }

  private getActivityCssClass(fkCallCenter: number): ActivityCssClass {
    if (!fkCallCenter) {
      return this.defaultCssSettings;
    }

    return this.cssSettings[fkCallCenter] || this.defaultCssSettings;
  }

  private query() {
    if (this.fields.length === 0) {
      return;
    }

    this.refreshTimeout = null;

    if (this.configuration.mode === 'portal') {
      let state = JSON.stringify({ filter: this.filter, state: this.state });
      this.router.navigate([], { relativeTo: this.activatedRoute, queryParams: { state: btoa(state) }, replaceUrl: true });
    }

    this.service.query(this.filter, this.state, this.fields);
  }

  private mapFields(fields: Array<IDynamicField>): Array<IGridField> {
    let self = this;

    let selectedFields = fields.filter((x) => x.isSelected).sort(DynamicFieldComparer);

    return selectedFields.map((field) => {
      let cellTemplate: TemplateRef<any> = !!PredefinedFields.find((x) => x.fieldName === field.fieldName)
        ? this[field.fieldName + '_cell']
        : this[field.fieldType + '_cell'];

      let groupHeaderTemplate: TemplateRef<any> = this[field.fieldName + '_header'];
      let groupFooterTemplate: TemplateRef<any> = this[field.fieldName + '_footer'];

      return {
        fieldName: field.fieldName,
        fieldType: field.fieldType,
        displayName: field.displayName,
        isSelected: field.isSelected,
        isStatic: field.isStatic,
        order: field.order,
        width: field.width,
        cellTemplate: cellTemplate,
        groupHeaderTemplate: groupHeaderTemplate,
        groupFooterTemplate: groupFooterTemplate,
      };
    });
  }

  private applyClassToParent(el, cssClass: string) {
    if (el.parentNode) {
      this.renderer.removeClass(el.parentNode, 'field-alarm');
      this.renderer.removeClass(el.parentNode, 'field-success');
      this.renderer.removeClass(el.parentNode, 'field-warning');

      this.renderer.addClass(el.parentNode, cssClass);
    }

    // if (!this.changeDetectionScheduled) {
    //   this.changeDetectionScheduled = true;
    //   setTimeout(() => { this.changeDetectionScheduled = false; }, 1);
    // }
  }

  private applyClass(cell: any, cssClass: string) {
    if (cssClass === 'field-success') {
      cell.background = '#008000'; // green
      cell.color = '#ffff00'; // yellow
    } else if (cssClass === 'field-alarm') {
      cell.background = '#ff0000'; // red
      cell.color = '#ffff00'; // yellow
    } else if (cssClass === 'field-warning') {
      cell.background = '#ffff00'; // yellow
    }
  }

  private formatDuration(value: any): any {
    return moment('2000-01-01 00:00:00').add(moment.duration(value, 'minutes')).format('HH:mm');
  }

  resizeGrid() {
    if (!this.grid) {
      return;
    }

    let page = $('.page-host'),
      pageHeight = page.innerHeight();

    let header = $('.page__heading'),
      headerHeight = header.innerHeight();

    let gridContainer = $('.grid-container'),
      gridContainerHeight = gridContainer.innerHeight();

    let limit = 30;

    let actualPageHeight = headerHeight + gridContainerHeight + 20;

    let delta = pageHeight - actualPageHeight;

    if (Math.abs(delta) > limit) {
      let $grid = $(this.grid.wrapper.nativeElement);
      let gridHeight = $grid.innerHeight();

      let newHeight = gridHeight + delta;
      $grid.css('height', newHeight + 'px');
    }
  }

  pdfExport(event: MouseEvent): void {
    event.preventDefault();
    this.analytcis.track(AnalyticsEvent.DailyActivityExport, { ReportName: this.reportName, ExportType: 'Pdf' });
    if (this.grid.data['total'] >= LIMIT_NUMBER_OF_RECORDS_FOR_PDF_EXPORT) {
      this.notifications.warning(MESSAGE_PDF_EXPORT_LIMIT);
      return;
    }
    this.grid.saveAsPDF();
  }
}
