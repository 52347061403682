<div *ngIf="incomingCall">
  <h4 class="form-header"><i class="skype-icon skype-location-icon"></i> {{ incomingCall.location.Address }}</h4>
  <kendo-dropdownlist
    class="lanes-selector"
    [data]="incomingCall.lanes"
    [(value)]="incomingCall.lane"
    [textField]="'Lane_Type'"
    [valueField]="'Id'"
    (valueChange)="onLaneSelected($event)"
  >
  </kendo-dropdownlist>

  <div class="form-container">
    <div class="form-title">
      <span class="form-title-item" [class.inactive]="!selectedProblem" (click)="unSelectProblem($event)">Issue</span>
      <span *ngIf="selectedIssue">
        - <span class="form-title-item" (click)="unSelectIssue($event)">{{ selectedProblem.ProblemName }}</span>
      </span>
      <div class="issue-name" *ngIf="selectedIssue">{{ selectedIssue.IssueName }}</div>
    </div>

    <div class="buttons-list" *ngIf="!selectedProblem">
      <button class="issue-line1 btn btn-block" *ngFor="let problem of incomingCall.problems" (click)="selectProblem(problem)">
        {{ problem.ProblemName }}
      </button>
    </div>

    <div *ngIf="selectedProblem">
      <div class="buttons-list" *ngIf="!selectedIssue">
        <button class="issue-line1 btn btn-block" *ngFor="let issue of selectedProblem.Issues" (click)="selectIssue(issue, $event)">
          {{ issue.IssueName }}
        </button>
      </div>

      <div class="issue-form" *ngIf="selectedIssue">
        <div class="form-content">
          <form class="form-horizontal" #form="ngForm" ngNativeValidate>
            <div *ngFor="let field of excludeHidden(selectedIssue.Fields)">
              <div *ngIf="field.FieldType == 'string'">
                <div class="form-group" *ngIf="field.FieldName != 'cNotes'">
                  <span *ngIf="field.IsRequired" class="required-field text-danger">*</span>
                  <input
                    type="text"
                    class="form-control"
                    [ngModel]="getValue(field)"
                    [required]="field.IsRequired"
                    (ngModelChange)="setValue(field, $event)"
                    name="{{ field.FieldName }}"
                    [attr.placeholder]="field.DisplayName"
                  />
                </div>

                <div *ngIf="field.FieldName == 'cNotes'">
                  <div class="form-group">
                    <label class="control-label label-left">{{ field.DisplayName }}:</label>
                    <textarea
                      class="form-control"
                      rows="3"
                      [ngModel]="getValue(field)"
                      (ngModelChange)="setValue(field, $event)"
                      name="{{ field.FieldName }}"
                    >
                    </textarea>
                  </div>

                  <div class="form-group custom-checkbox">
                    <input
                      class=""
                      id="id-IsNotify"
                      name="name-IsNotify"
                      type="checkbox"
                      [ngModel]="selectedIssue.IsNotify"
                      (ngModelChange)="selectedIssue.IsNotify = $event"
                    />
                    <label for="id-IsNotify">Send alerts to manager</label>
                  </div>
                </div>
              </div>

              <div class="form-group form-row" *ngIf="field.FieldType == 'DateTime'">
                <label class="col-sm-5 text-left control-label">{{ field.DisplayName }}:</label>
                <div class="col-sm-7">
                  <kendo-datetimepicker
                    class="umj-datepicker-sm"
                    [readOnlyInput]='true'
                    [ngModel]="getValue(field)"
                    (ngModelChange)="setValue(field, $event)"
                    style="width: 100%"
                    name="{{ field.FieldName }}"
                  >
                  </kendo-datetimepicker>
                </div>
              </div>

              <div class="form-group form-row" *ngIf="field.FieldType == 'Decimal'">
                <label class="col-sm-5 control-label">{{ field.DisplayName }}:</label>
                <div class="col-sm-7">
                  <kendo-numerictextbox
                    class="k-form-control"
                    [ngModel]="getValue(field)"
                    (ngModelChange)="setValue(field, $event)"
                    style="width: 100%"
                    name="{{ field.FieldName }}"
                    [min]="0"
                    [autoCorrect]="autoCorrect"
                    [format]="'n2'"
                  >
                  </kendo-numerictextbox>
                </div>
              </div>

              <div class="form-group form-row" *ngIf="field.FieldType == 'bool'">
                <label class="col-sm-5 control-label">{{ field.DisplayName }}:</label>
                <div class="col-sm-7 custom-checkbox">
                  <input
                    class=""
                    id="id-{{ field.FieldName }}"
                    name="name-{{ field.FieldName }}"
                    type="checkbox"
                    [ngModel]="getValue(field)"
                    (ngModelChange)="setValue(field, $event)"
                  />
                  <label for="id-{{ field.FieldName }}"><span></span></label>
                </div>
              </div>

              <div class="form-group form-row" *ngIf="field.FieldType == 'DropDown'">
                <label class="col-sm-5 control-label">{{ field.DisplayName }}:</label>
                <div class="col-sm-7">
                  <app-dropdown
                    class="k-form-control"
                    [data]="fieldProperties(field)"
                    [ngModel]="getValue(field)"
                    (ngModelChange)="setValue(field, $event)"
                    name="{{ field.FieldName }}"
                  >
                  </app-dropdown>
                </div>
              </div>

              <div class="form-group form-row" *ngIf="field.FieldType == 'int'">
                <label class="col-sm-5 control-label">{{ field.DisplayName }}:</label>
                <div class="col-sm-7">
                  <kendo-numerictextbox
                    class="k-form-control"
                    [ngModel]="getValue(field)"
                    (ngModelChange)="setValue(field, $event)"
                    style="width: 100%"
                    name="{{ field.FieldName }}"
                    [min]="0"
                    [autoCorrect]="autoCorrect"
                    [format]="'n0'"
                  >
                  </kendo-numerictextbox>
                </div>
              </div>

              <div class="form-group" *ngIf="unknownFiled(field.FieldType)">
                <label class="col-sm-5 control-label">{{ field.DisplayName }} - {{ field.FieldType }} - {{ field.FieldName }}</label>
              </div>
            </div>

            <div class="vend-gate">
              <div class="form-group form-row" *ngIf="!selectedIssue.GateVend">
                <label class="col-sm-5 control-label"> Vend Gate Not Allowed </label>
              </div>
              <div class="form-group form-row" *ngIf="selectedIssue.GateVend">
                <div class="col-sm-6">
                  <button class="btn btn-vend-gate" (click)="vendGate()" [disabled]="!vendGateEnabled()">
                    <i class="skype-icon skype-icon-sign-out"></i>
                    Vend Gate
                  </button>
                </div>

                <div class="vend-legend">
                  <small class="help-info text-info" *ngIf="!!getRevenueControlName()">
                    <span class="text-danger">*</span>Vend will be performed using API integration with
                    {{ getRevenueControlName() }}
                  </small>
                  <small class="help-info text-info" *ngIf="!getRevenueControlName() && !!vendGateEnabled()">
                    <span class="text-danger">*</span>Vend will be performed using {{ vendCode }} tone
                  </small>
                  <small class="help-info text-info" *ngIf="!vendGateEnabled()">
                    <span class="text-danger">*</span>Please setup Vend code or enable Revenue control integration for selected lane to
                    perform vend
                  </small>
                </div>
              </div>
              <div *ngIf="!!vendGateResult">
                <div class="form-group form-row">
                  <label class="col-sm-12 control-label">
                    <span *ngIf="vendGateResult == 'failed'">Gate Vend failed</span>
                    <span *ngIf="vendGateResult == 'vended'">Vended</span>
                  </label>
                </div>
                <div class="form-group form-row" *ngIf="vendGateResult == 'failed'">
                  <div class="col-sm-6" *ngIf="!!vendCode">
                    <button class="btn btn-vend-gate" (click)="vendGate_dtmf()">
                      <i class="skype-icon skype-icon-sign-out"></i>
                      Vend with DTMF tone
                    </button>
                  </div>
                  <div class="col-sm-6" *ngIf="revenueControlEnabled">
                    <button class="btn btn-vend-gate" (click)="vendGate_revenueControl()">
                      <i class="skype-icon skype-icon-sign-out"></i>
                      Vend with vendor API
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>

          <div class="rule-description">
            <h4>Steps to Resolve</h4>
            <div class="description-body" [innerHTML]="selectedIssue.RuleDescription"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
