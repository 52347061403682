import { Injectable, Inject } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

import { Observable, throwError } from 'rxjs';
import { map, tap, flatMap, catchError, filter, mergeMap } from 'rxjs/operators';

import { AppConfigurationService, APP_CONFIGURATION, IAppConfigurationBase } from '../app-configuration.service';

import { AppStateService } from '../app-state.service';
import { AccessManagementService } from './access-management.service';
import { Permission } from './authorization.service';
import { AUTH_EXTENSIONS, IAuthExtensionsService } from './auth-extensions.service';

const authOptions = {
  tokenName: 'SessionId',
};

export interface ICredentials {
  username: string;
  password: string;
}

export class IAuthResult {
  Success: boolean;
  IsGrmActive: boolean;
  GrmUserRole: any;
  GrmUserId: any;
  Permissions: Array<Permission>;
  Message: string;
  token: any;
}

@Injectable()
export class AuthService {
  constructor(
    private httpClient: HttpClient,
    private router: Router,
    private appStateService: AppStateService,
    @Inject(APP_CONFIGURATION) private configuration: AppConfigurationService<IAppConfigurationBase>,
    @Inject(AUTH_EXTENSIONS) private authExtensionsService: IAuthExtensionsService,
    private accessManagementService: AccessManagementService,
  ) {}

  login(credentials: ICredentials): Observable<IAuthResult> {
    return this.authenticate(credentials).pipe(
      map((res) => {
        this.appStateService.setAuthenticated(credentials.username, res.token);
        return res;
      }),
    );
  }

  authenticate(credentials: ICredentials): Observable<IAuthResult> {
    let path = 'Authentication/Authenticate';

    let content = JSON.stringify({ username: credentials.username, password: credentials.password });

    return this.httpClient.post<IAuthResult>(path, content).pipe(
      filter((res) => {
        if (!res.Success) {
          console.log('User is not authenticated: ' + res.Message);
          throw new Error(res.Message);
        }

        return true;
      }),
      tap((res) => {
        this.accessManagementService.init(res.Permissions);
      }),
      map((res) => {
        // if (!res.IsGrmActive) {
        //   console.log('User is disabled');
        //   throw new Error('User is disabled');
        // }

        let result = res;
        result.token = res[authOptions.tokenName];

        return result;
      }),
      catchError(this.handleError),
    );
  }

  externalLogin(userId, provider, code): Observable<any> {
    let url = this.configuration.apiBaseUrl + '/Authentication/ExternalAuthenticate';

    return this.httpClient
      .post<IAuthResult>(url, {
        ExternalUserId: userId,
        ExternalProvider: provider,
        Code: code,
      })
      .pipe(
        tap((response) => {
          if (response.Success) {
            console.log('Logged in');

            this.accessManagementService.init(response.Permissions);

            this.appStateService.setAuthenticated(userId, response[authOptions.tokenName]);

            return response;
          } else {
            throw new Error('User is not authenticated');
          }
        }),
        catchError(this.handleError),
      );
  }

  logout(): Promise<{ LogoutUrl: string }> {
    return this.authExtensionsService
      .logout()
      .pipe(mergeMap((res) => this.httpClient.post('authentication/logout', null, { withCredentials: true })))
      .toPromise()
      .then((response: { LogoutUrl: string }) => {
        this.appStateService.clearAuthToken();
        this.accessManagementService.reset();

        console.log('Logged out');
        return response;
      });
  }

  sendCodeToSkype(sip: string, displayName: string): Observable<any> {
    let url = this.configuration.apiBaseUrl + 'Authentication/SendCodeToSkype';

    return this.httpClient
      .post<any>(url, {
        SkypeContact: sip,
        SkypeContactName: displayName,
      })
      .pipe(
        map((response) => {
          if (!response.Success) {
            throw { message: response.Message };
          }

          return response;
        }),

        catchError(this.handleError),
      );
  }

  skypeAuthenticate(sip: string, code: string): Observable<any> {
    let path = this.configuration.apiBaseUrl + 'Authentication/AgentAuthenticate';

    let content = JSON.stringify({ SkypeContact: sip, Code: code });

    return this.httpClient.post<any>(path, content).pipe(
      tap((res) => {
        if (!res.Success) {
          throw new Error(res.Message);
        }
      }),
      flatMap((res) => this.authenticate({ username: res.Login, password: res.Password })),
      catchError(this.handleError),
    );
  }

  private handleError(failure: any): Observable<any> {
    if (!!failure.error) {
      return throwError('Error: ' + failure.error.Message);
    }

    return throwError(failure);
  }
}
