import { Component, Input } from '@angular/core';
import { FilterService, BaseFilterCellComponent } from '@progress/kendo-angular-grid';
import { FilterDescriptor, CompositeFilterDescriptor } from '@progress/kendo-data-query';

const flatten = (filter) => {
  const filters = (filter || {}).filters;
  if (filters) {
    return filters.reduce((acc, curr) => acc.concat(curr.filters ? flatten(curr) : [curr]), []);
  }
  return [];
};

@Component({
  selector: 'app-multiselect-filter',
  templateUrl: './multiselect-filter.component.html',
  styleUrls: ['./multiselect-filter.component.scss'],
})
export class MultiselectFilterComponent extends BaseFilterCellComponent {
  private value: any[] = [];

  public get selected(): any[] {
    return this.value;
  }
  public set selected(values: any[]) {
    this.value = values;

    this.filterService.filter({
      filters: values.map((value) => ({
        field: this.field,
        operator: 'eq',
        value,
      })),
      logic: 'or',
    });
  }

  @Input() public field: string;

  @Input() public filter: CompositeFilterDescriptor;
  @Input() public data: any[];
  @Input() public textField: string;
  @Input() public valueField: string;
  @Input() public valuePrimitive = true;

  constructor(filterService: FilterService) {
    super(filterService);
  }
}
