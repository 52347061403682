<div class="modal fade modal-dark" bsModal #modal="bs-modal" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog revenue-modal">
    <div class="modal-content">
      <div class="modal-header clearfix">
        <form #form="ngForm" *ngIf="initialized" class="form-inline modal-form ticket-filter" novalidate>
          <div class="form-group from-group-sm validation-container" [class.has-error]="ticketField | hasErrors: validate">
            <input
              class="form-control"
              type="text"
              [(ngModel)]="ticketNum"
              name="ticketNum"
              #ticketField="ngModel"
              required
              placeholder="Ticket number"
            />
            <app-validation-errors [model]="ticketField" [validate]="validate"></app-validation-errors>
          </div>

          <button class="btn btn-sm button--primary btn-load-info" type="submit" [disabled]="busy" (click)="showTicketInfo()">
            <span *ngIf="!busy">Show ticket</span>
            <span *ngIf="busy"> <i class="fa fa-spinner fa-spin"></i> loading</span>
          </button>
        </form>

        <button type="button" class="close pull-right" aria-label="Close" (click)="cancel()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body ticket-info">
        <div class="form-horizontal modal-form" *ngIf="!!ticketInfo">
          <div class="form-group">
            <label class="col-sm-6 control-label">Ticket Number</label>
            <div class="col-sm-6">
              <p class="form-control-static">{{ ticketInfo.TicketNumber }}</p>
            </div>
          </div>
          <div class="form-group">
            <label class="col-sm-6 control-label">Retracted</label>
            <div class="col-sm-6">
              <p class="form-control-static">{{ ticketInfo.RetractedTicket ? 'YES' : 'NO' }}</p>
            </div>
          </div>
          <div class="form-group">
            <label class="col-sm-6 control-label">Paid Through</label>
            <div class="col-sm-6">
              <p class="form-control-static">{{ ticketInfo.PaidThroughDateTime | date: 'dd.MM.yyyy HH:mm' }}</p>
            </div>
          </div>
          <div class="form-group">
            <label class="col-sm-6 control-label">Parkhouse for Rate Calculation</label>
            <div class="col-sm-6">
              <p class="form-control-static">{{ ticketInfo.ParkhouseForRateCalculation }}</p>
            </div>
          </div>
          <div class="form-group" *ngIf="!!ticketInfo.EntryDateTime">
            <label class="col-sm-6 control-label">Entry Time</label>
            <div class="col-sm-6">
              <p class="form-control-static">{{ ticketInfo.EntryDateTime | date: 'dd.MM.yyyy HH:mm' }}</p>
            </div>
          </div>
          <div class="form-group" *ngIf="!!ticketInfo.ExitedDateTime">
            <label class="col-sm-6 control-label">Exited Time</label>
            <div class="col-sm-6">
              <p class="form-control-static">{{ ticketInfo.ExitedDateTime | date: 'dd.MM.yyyy HH:mm' }}</p>
            </div>
          </div>

          <div *ngIf="!!ticketInfo.PaymentsMade && ticketInfo.PaymentsMade.length > 0">
            <div class="form-group">
              <div class="col-sm-6">
                <label>
                  <input
                    type="checkbox"
                    class="expand-collapse"
                    [ngModel]="isExpanded(ticketInfo.PaymentsMade)"
                    (ngModelChange)="toggle(ticketInfo.PaymentsMade)"
                  />
                  <span class="expand-collapse-lbl"></span>
                </label>
                <label class="control-label">Payments</label>
              </div>
            </div>
            <div class="row" *ngIf="isExpanded(ticketInfo.PaymentsMade)">
              <div class="col-sm-offset-1 col-sm-11 info-block" *ngFor="let payment of ticketInfo.PaymentsMade">
                <div class="form-group">
                  <label class="col-sm-4 control-label">Id</label>
                  <div class="col-sm-8">
                    <p class="form-control-static">{{ payment.PaymentId }}</p>
                  </div>
                </div>
                <div class="form-group">
                  <label class="col-sm-4 control-label">Amount</label>
                  <div class="col-sm-8">
                    <p class="form-control-static">{{ payment.PaymentAmount | currency: 'USD' : true : '2.2-4' }}</p>
                  </div>
                </div>
                <div class="form-group">
                  <label class="col-sm-4 control-label">Payment Time</label>
                  <div class="col-sm-8">
                    <p class="form-control-static">{{ payment.PaymentDateTime | date: 'dd.MM.yyyy HH:mm' }}</p>
                  </div>
                </div>
                <div class="form-group">
                  <label class="col-sm-4 control-label">Pay Method</label>
                  <div class="col-sm-8">
                    <p class="form-control-static">{{ payment.PayMethod }}</p>
                  </div>
                </div>
                <div class="form-group">
                  <label class="col-sm-4 control-label">Last Four Digits</label>
                  <div class="col-sm-8">
                    <p class="form-control-static">{{ payment.LastFourDigits }}</p>
                  </div>
                </div>

                <div *ngIf="!!payment.ValidationsApplied && payment.ValidationsApplied.length > 0">
                  <div class="form-group">
                    <div class="col-sm-6">
                      <label>
                        <input
                          type="checkbox"
                          class="expand-collapse"
                          [ngModel]="isExpanded(payment.ValidationsApplied)"
                          (ngModelChange)="toggle(payment.ValidationsApplied)"
                        />
                        <span class="expand-collapse-lbl"></span>
                      </label>
                      <label class="control-label">Validations</label>
                    </div>
                  </div>
                  <div class="row" *ngIf="isExpanded(payment.ValidationsApplied)">
                    <div
                      class="col-sm-offset-1 col-sm-11 info-block"
                      *ngFor="let validation of payment.ValidationsApplied | orderBy: 'ValidationOrder'"
                    >
                      <div class="form-group">
                        <label class="col-sm-6 control-label">Id</label>
                        <div class="col-sm-6">
                          <p class="form-control-static">{{ validation.AppliedValidation.ValidationId }}</p>
                        </div>
                      </div>
                      <div class="form-group">
                        <label class="col-sm-6 control-label">Validation Name</label>
                        <div class="col-sm-6">
                          <p class="form-control-static">{{ validation.AppliedValidation.ValidationName }}</p>
                        </div>
                      </div>
                      <div class="form-group">
                        <label class="col-sm-6 control-label">Validation Type</label>
                        <div class="col-sm-6">
                          <p class="form-control-static">{{ validation.AppliedValidation.TypeOfValidation }}</p>
                        </div>
                      </div>
                      <div class="form-group">
                        <label class="col-sm-6 control-label">Amount</label>
                        <div class="col-sm-6">
                          <p class="form-control-static">{{ validation.AppliedValidation.Amount | currency: 'USD' : true : '2.2-4' }}</p>
                        </div>
                      </div>
                      <div class="form-group">
                        <label class="col-sm-6 control-label">Percentage Off</label>
                        <div class="col-sm-6">
                          <p class="form-control-static">{{ validation.AppliedValidation.PercentageOff }}</p>
                        </div>
                      </div>
                      <div class="form-group">
                        <label class="col-sm-6 control-label">Minutes Off</label>
                        <div class="col-sm-6">
                          <p class="form-control-static">{{ validation.AppliedValidation.MinutesOff }}</p>
                        </div>
                      </div>
                      <div class="form-group">
                        <label class="col-sm-6 control-label">Rate Table Id</label>
                        <div class="col-sm-6">
                          <p class="form-control-static">{{ validation.AppliedValidation.RateTableId }}</p>
                        </div>
                      </div>
                      <div class="form-group">
                        <label class="col-sm-6 control-label">Rate Table name</label>
                        <div class="col-sm-6">
                          <p class="form-control-static">{{ validation.AppliedValidation.RateTableName }}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="modal-footer">
        <button class="button" (click)="cancel()">Close</button>
      </div>
    </div>
  </div>
</div>
