import { map, debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { Component, OnInit, ViewChild, Inject, Output, EventEmitter, OnDestroy } from '@angular/core';

import { Observable, Observer, BehaviorSubject, from as fromPromise, of, Subscription } from 'rxjs';

import { ModalDirective } from 'ngx-bootstrap/modal';

import { ISkypeAppServiceToken, ISkypeAppService, ISkypeGroup, ISkypePerson } from '../../abstractions';
import { SkypeGroup } from '../services/skype-group';
import { ContactsSearchResult } from './contacts-search-result';

@Component({
  selector: 'app-contacts-manager',
  templateUrl: './contacts-manager.component.html',
  styleUrls: ['./contacts-manager.component.scss'],
})
export class ContactsManagerComponent implements OnInit, OnDestroy {
  @ViewChild('modal', { static: true }) public modal: ModalDirective;
  @Output() modalComponent = new EventEmitter<ContactsManagerComponent>();

  phoneNumber: string = null;
  searchString$ = new BehaviorSubject<string>(null);

  private _rootGroup: ISkypeGroup;
  private subscriptions = new Subscription();
  get rootGroup(): ISkypeGroup {
    return this._rootGroup;
  }
  set rootGroup(value: ISkypeGroup) {
    if (!!this._rootGroup) {
      this._rootGroup.dispose();
    }

    this._rootGroup = value;
  }

  resultObserver: Observer<Array<string>>;

  constructor(@Inject(ISkypeAppServiceToken) private _skypeApp: ISkypeAppService) {}

  ngOnInit() {
    this.modalComponent.emit(this);
  }

  open(): Observable<Array<string>> {
    this.phoneNumber = null;

    this.rootGroup = null;

    this.initSearch();
    this.searchString$.next(null);

    this.modal.show();

    return new Observable((observer) => (this.resultObserver = observer));
  }

  selectPerson(person: ISkypePerson) {
    this.modal.hide();

    this.resultObserver.next([person.id]);
    this.resultObserver.complete();
  }

  callPhone() {
    if (!this.phoneNumber) {
      return;
    }

    this.modal.hide();

    let phoneNumber = this.phoneNumber.indexOf('@') > 0 ? `sip:${this.phoneNumber}` : `tel:${this.phoneNumber}`;
    this.resultObserver.next([phoneNumber]);
    this.resultObserver.complete();
  }

  callContact(contact: ISkypePerson) {
    if (!contact) {
      return;
    }

    this.modal.hide();

    this.resultObserver.next([contact.id]);
    this.resultObserver.complete();
  }

  cancel() {
    this.modal.hide();
    this.resultObserver.next(null);
    this.resultObserver.complete();
  }

  private initSearch() {
    this.subscriptions.add(
      this.searchString$
        .pipe(
          debounceTime(400),
          distinctUntilChanged(),
          switchMap((searchTerm): Observable<ISkypeGroup> => {
            if (!searchTerm) {
              let rootGroup = this._skypeApp.personsAndGroupsManager.all;
              return of(new SkypeGroup(rootGroup));
            } else {
              let personsSearchQuery = this._skypeApp.personsAndGroupsManager.createPersonSearchQuery();
              personsSearchQuery.text(searchTerm);
              return fromPromise(personsSearchQuery.getMore()).pipe(map(() => new ContactsSearchResult(personsSearchQuery)));
            }
          }),
        )
        .subscribe((result) => {
          this.rootGroup = result;
        }),
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
