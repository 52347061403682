import { map, flatMap, tap } from 'rxjs/operators';
import { Component, OnInit, HostBinding, ViewChild, Inject, PLATFORM_ID, Injector } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

import { GridComponent, GridDataResult, DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { process, State } from '@progress/kendo-data-query';

import { Observable, Observer, of } from 'rxjs';

import { ModalDirective } from 'ngx-bootstrap/modal';

import { ANALYTICS, IAnalyticsService, IncidentService } from '@libs/portal-common/services';

import { IncomingCallsService } from '../../services/incoming-calls.service';
import { IssueSubmissionService } from '../../services/issue-submission.service';
import { IncomingCall, FieldValue } from '../../../abstractions';
import { AgentAnalyticsEvent } from '../../../services';

const initialState: State = {
  sort: [{ field: 'LotNumber', dir: 'desc' }],
  filter: {
    logic: 'and',
    filters: [{ field: 'Active', operator: 'eq', value: true }],
  },
};

@Component({
  selector: 'app-location-selector',
  templateUrl: './location-selector.component.html',
  styleUrls: ['./location-selector.component.scss'],
})
export class LocationSelectorComponent implements OnInit {
  @ViewChild('modal', { static: true }) public modal: ModalDirective;
  @ViewChild(GridComponent, { static: true }) private grid: GridComponent;

  anonymousSelected = false;
  anonymousNotes: string = null;

  public state = Object.assign({}, initialState);

  filter: string;

  public view: GridDataResult = { data: [], total: 0 };
  private data = [];

  incomingCall = new IncomingCall(null);

  resultObserver: Observer<IncomingCall>;

  private get analytics(): IAnalyticsService {
    return this.injector.get(ANALYTICS);
  }

  constructor(
    private incomingCallsService: IncomingCallsService,
    private incidentService: IncidentService,
    private issueSubmission: IssueSubmissionService,
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(Injector) private readonly injector: Injector,
  ) {}

  ngOnInit() {}

  filterChanged($event) {
    this.filter = $event;

    if (this.filter) {
      this.state.filter = {
        logic: 'and',
        filters: [
          { field: 'Active', operator: 'eq', value: true },
          {
            logic: 'or',
            filters: [
              { field: 'LotNumber', operator: 'contains', value: this.filter },
              { field: 'LotName', operator: 'contains', value: this.filter },
              { field: 'Address', operator: 'contains', value: this.filter },
            ],
          },
        ],
      };
    } else {
      this.state.filter = {
        logic: 'and',
        filters: [{ field: 'Active', operator: 'eq', value: true }],
      };
    }

    this.view = process(this.data || [], this.state);
  }

  clearFilter() {
    this.filterChanged('');
  }

  open(incomingCall: IncomingCall): Observable<IncomingCall> {
    this.anonymousSelected = false;
    this.anonymousNotes = null;

    this.incomingCall = incomingCall;
    this.filter = '';
    this.state = Object.assign({}, initialState);

    this.data = incomingCall.locations;
    this.view = process(this.data || [], this.state);

    this.incomingCall.lane = null;

    if (isPlatformBrowser(this.platformId)) {
      this.modal.show();
    }

    return new Observable((observer) => (this.resultObserver = observer));
  }

  public dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;

    this.view = process(this.data, this.state);
  }

  selectLocation(location) {
    this.modal.hide();

    this.incomingCall.location = location;

    this.resultObserver.next(this.incomingCall);
    this.resultObserver.complete();
    this.analytics.track(AgentAnalyticsEvent.LocationSelected, null);
  }

  removeTab(tabset: any, tab: any) {
    tabset.removeTab(tab);
    this.anonymousSelected = false;
  }

  anonymous() {
    this.anonymousSelected = true;
  }

  saveAnonymous() {
    this.anonymousSelected = false;

    this.modal.hide();
    this.incomingCall.conversation
      .cancelProcessing()
      .pipe(
        flatMap((result) => {
          if ((<any>this.incomingCall.conversation).test) {
            return of(this.incomingCall);
          }

          let fields: Array<FieldValue> = [
            {
              FieldName: 'cNotes',
              FieldType: 'string',
              Value: this.anonymousNotes,
              IsStatic: true,
            },
          ];

          return this.issueSubmission
            .wrapupAndSave(this.incomingCall, fields, this.incomingCall.conversation.submissionParameters)
            .pipe(map((res) => this.incomingCall));
        }),
      )
      .subscribe(
        (incomingCall) => {
          this.resultObserver.next(incomingCall);
          this.resultObserver.complete();
          close();
        },
        (err) => {
          console.log(err);
        },
      );
  }

  cancel() {
    this.modal.hide();
    // this.callNotificationService.showCallToast(this.incomingCall)
    //   .subscribe(incomingCall => {
    //     this.resultObserver.next(incomingCall);
    //     this.resultObserver.complete();
    //   });
  }
}
