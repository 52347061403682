import { Component, OnInit, ViewChild, OnDestroy, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Subscription, zip, Observable, of } from 'rxjs';
import { tap, map, filter, flatMap, first } from 'rxjs/operators';

import * as moment from 'moment';

import { CreateTicketComponent } from '@libs/portal-common/shared';
import {
  ReportsService,
  FieldSetService,
  IField,
  ContactsService,
  ProblemsService,
  APP_CONFIGURATION,
  AppConfigurationService,
  IAppConfigurationBase,
  CallCenterService,
} from '@libs/portal-common/services';
import { AppNotificationsService } from '@libs/portal-common/system';

import { ActivityCssClass } from '../../activity-css-class';
import { AudioRecordsComponent } from '../../audio-records/audio-records.component';
import { VideoRecordComponent } from '../../video-record/video-record.component';
import { VoiceBotTranscriptionComponent } from '../../voicebot/voicebot-transcription.component';

type Formatter = (value: any) => string;

const DefaultFormatter: Formatter = (val: any) => val;

const formatters: { [key: string]: Formatter } = {
  string: (val: any) => val,
  Decimal: (val: any) => val,
  DateTime: (value: string): string => moment(value).format('MMMM Do YYYY, h:mm:ss a'),
  bool: (val: boolean) => (val ? 'YES' : 'NO'),
};

interface ICallHistoryItem {
  Id: number;

  CallTime?: Date;
  H?: string;
  LotNumber?: number;
  LotName?: string;
  Lane?: string;
  Issue?: string;
  Vend?: number;
  QueueTime?: number;
  CallDuration?: number;
  Wrapup?: number;
  TotalTime?: number;
  Rec: boolean;
  Agent?: string;
  IsCallClosedByAgent?: boolean;
  Parker?: string;

  MergedFields?: string;
  IsRecordingFound?: boolean;
  Notes?: string;
  DynamicFieldsJson?: string;
  CallCenterRegionId?: number;
  fkLot: number;
  fkAgent: number;
  fkProblem: number;
  CallMatchingGroupId?: number;
  CallMatchingGroupName: string;

  NoodoeStationId?: string;
  NoodoeStationChargeStarted: boolean;
  NoodoeStationChargeStopped: boolean;
  NoodoeStationReseted: boolean;
  NoodoeStationMaintenaceModeLastAction?: string;
}

@Component({
  selector: 'app-incident-details',
  templateUrl: './incident-details.component.html',
  styleUrls: ['./incident-details.component.scss'],
})
export class IncidentDetailsComponent implements OnInit, OnDestroy {
  @ViewChild('audioRecords', { static: true }) private audioRecordsModal: AudioRecordsComponent;
  @ViewChild('videoRecord', { static: true }) private videoRecordModal: VideoRecordComponent;
  @ViewChild('createTicket', { static: true }) public createTicket: CreateTicketComponent;
  @ViewChild('voiceBotTranscription', { static: true }) private voiceBotTranscriptionModal: VoiceBotTranscriptionComponent;

  activityCss = ActivityCssClass.Default;

  busy = false;
  subscription: Subscription;
  canAddTicket = false;

  validate = false;

  dynamicFieldsDefinitions: { [key: string]: IField } = {};
  dynamicFields = new Array<{ Name: string; Value: string }>();

  staticFieldsDefinitions = new Array<IField>();
  staticFields = new Array<{ Name: string; Value: string }>();

  model = <ICallHistoryItem>{
    Id: null,
    IsRecordingFound: false,
  };

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private reports: ReportsService,
    private notifications: AppNotificationsService,
    private fieldSetService: FieldSetService,
    private problemsService: ProblemsService,
    private contactsService: ContactsService,
    @Inject(APP_CONFIGURATION) private configuration: AppConfigurationService<IAppConfigurationBase>,
    private callCentersService: CallCenterService,
  ) {}

  ngOnInit() {
    this.subscription = zip(this.route.parent.params, this.route.parent.data)
      .pipe(
        tap((result) => {
          this.busy = true;

          this.staticFieldsDefinitions = [];
          this.dynamicFieldsDefinitions = {};

          this.dynamicFields = [];
          this.staticFields = [];

          this.model = <ICallHistoryItem>{
            Id: null,
            IsRecordingFound: false,
          };

          this.canAddTicket = this.configuration.data.uiCustomization.ticketsEnabled && !result[1].ticket;
        }),
        map((res) => res[0]),
        flatMap((routeParams) => this.getCallHistoryItem(+routeParams['id'])),
        filter((incident) => !!incident),
        tap((incident) => {
          this.activityCss = ActivityCssClass.Default;
          this.callCentersService
            .getCallCenter(incident.fkCallCenter)
            .pipe(
              first(),
              filter((res) => !!res.CallCenter),
            )
            .subscribe((res) => {
              this.activityCss = new ActivityCssClass(
                res.CallCenter.QueueOkSeconds,
                res.CallCenter.QueueWarningSeconds,
                res.CallCenter.HangUpOkSeconds,
                res.CallCenter.HangUpWarningSeconds,
                res.CallCenter.WrapUpOkSeconds,
                res.CallCenter.WrapUpWarningSeconds,
              );
            });
        }),
        flatMap((incident) => this.getFields(incident)),
      )
      .subscribe(
        (res) => {
          this.model = res.incident;

          res.fields.forEach((field) => {
            if (field.IsStatic) {
              if (field.FieldName !== 'cNotes') {
                field.FieldName = field.FieldName.substring(1);
                this.staticFieldsDefinitions.push(field);
              }
            } else {
              this.dynamicFieldsDefinitions[field.FieldName] = field;
            }
          });

          let dynFields = !!res.incident.DynamicFieldsJson ? JSON.parse(res.incident.DynamicFieldsJson) : {};
          this.dynamicFields = Object.keys(dynFields).map((key) => {
            let field = this.dynamicFieldsDefinitions[key];
            let formatter = formatters[field.FieldType] || DefaultFormatter;

            return {
              Name: field.DisplayName,
              Value: formatter(dynFields[key]),
            };
          });

          this.staticFields = this.staticFieldsDefinitions
            .map((field) => {
              let value = this.model[field.FieldName];
              if (value === null || value === undefined) {
                return null;
              }

              let formatter = formatters[field.FieldType] || DefaultFormatter;

              return {
                Name: field.DisplayName,
                Value: formatter(value),
              };
            })
            .filter((x) => !!x);

          this.busy = false;
        },
        (err) => {
          this.busy = false;
        },
      );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  showRecords(id: number) {
    this.audioRecordsModal.open(id);
  }

  showVideoRecord(id: number) {
    this.videoRecordModal.open(id);
  }

  showVBEventRecords(conferenceId: string) {
    this.audioRecordsModal.openVBEventAudio(conferenceId);
  }

  showTranscript(incidentId: number, botEventConferenceId: string) {
    this.voiceBotTranscriptionModal.open(incidentId, botEventConferenceId);
  }

  addTicket() {
    this.createTicket.open(this.model.Id, this.model.fkLot).subscribe((result) => {
      if (result.created) {
        this.router.navigate([`../../${this.model.Id}/ticket`], { relativeTo: this.route });
      }
    });
  }

  private getCallHistoryItem(incidentId: number): Observable<any> {
    let params = {
      group: null,
      filter: {
        logic: 'and',
        filters: [{ field: 'Id', operator: 'eq', value: incidentId }],
      },
      skip: 0,
      limit: 10,
      sort: null,
    };

    return this.reports.getCallHistory(null, params).pipe(
      tap((response) => {
        this.notifications.checkResponse(response);
      }),
      map((response) => {
        let data = response.Groups || response.Data || [];
        if (data.length > 0) {
          return data[0];
        }

        return null;
      }),
    );
  }

  private getFields(incident: ICallHistoryItem): Observable<{ incident: ICallHistoryItem; fields: Array<IField> }> {
    if (!incident.fkProblem) {
      return of({ incident: incident, fields: [] });
    }

    return this.problemsService.get(incident.fkProblem).pipe(
      flatMap((res) => this.fieldSetService.getAll(null, res.Problem.fkRuleSet)),
      tap((response) => {
        this.notifications.checkResponse(response);
      }),
      map((response) => {
        return {
          incident: incident,
          fields: response.Data,
        };
      }),
    );
  }
}
