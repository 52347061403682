import { Component, OnInit, OnDestroy, HostBinding, NgZone, ViewChild, Inject } from '@angular/core';

import { routerTransition } from '@libs/portal-common/system';

import { forkJoin, of, Subscription, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

import {
  Agent,
  CallCenterStatisticsData,
  Call,
  LocationLaneResolverService,
  RegionsService,
  CallCenterStatisticService,
  IRegion,
  AccessManagementService,
  CallCenterSummary,
  Queue,
  MonitoringSettingsService,
  IMonitoringWidget,
  IAnalyticsService,
  ANALYTICS,
  AnalyticsEvent,
} from '@libs/portal-common/services';
import { EditMonitoringSettingsComponent } from '../edit-monitoring-settings/edit-monitoring-settings.component';

interface IInfoBoard {
  infoClass: string;
  iconClass: string;
  title: string;
  period: string;
  getValue(): string;
}

class CallInQueue extends Call {
  LaneNumber: string = null;
  LaneName: string = null;

  constructor(call: Call) {
    super();

    this.Caller = call.Caller;
    this.WaitSeconds = call.WaitSeconds;
  }

  get lotLane(): string {
    if (!this.LocationName && !this.LaneName) {
      return null;
    }

    return `${this.LocationName} - ${this.LaneName}`;
  }
}

@Component({
  selector: 'app-monitoring',
  templateUrl: './monitoring.component.html',
  styleUrls: ['./monitoring.component.scss'],
  animations: [routerTransition],
})
export class MonitoringComponent implements OnInit, OnDestroy {
  @HostBinding('@routerTransition') routerTransition = '';
  @HostBinding('class') class = 'full-width';
  @ViewChild('editMonitoringSettings', { static: true })
  private editMonitoringSettings: EditMonitoringSettingsComponent;

  timerIntervalSec = 1;
  timer: any = null;

  stats = new CallCenterStatisticsData();

  resolvingCache: {
    [uri: string]: {
      locationName: string;
      laneNumber: string;
      laneName: string;
    };
  } = {};

  infoboards: IInfoBoard[] = [];
  callsInQueue = new Array<CallInQueue>();
  subscription: Subscription;

  regions: Array<any> = [];
  queues: Array<Queue> = [];
  settingsQueues: Array<Queue> = [];
  isRegionFilter = true;

  regionButtons = [];
  queuesButtons = [];

  private infoboardComponents: { [id: string]: IInfoBoard } = {
    CallsInQueue: {
      infoClass: 'widget style1 navy-bg p-lg text-center',
      iconClass: 'fa fa-phone fa-3x',
      title: 'Calls in queue',
      period: 'Now',
      getValue: () => this.stats.CdrSummary.CallsWaitingInQueue + '',
    },
    TotalCalls: {
      infoClass: 'widget blue-bg p-lg text-center',
      iconClass: 'fa fa-phone fa-3x',
      title: 'Total Calls',
      period: 'Last hour / Today',
      getValue: () => this.stats.CdrSummary.TotalCallsLh + ' / ' + this.stats.CdrSummary.TotalCalls24H,
    },
    AverageQueueTimeLH: {
      infoClass: 'widget blue-bg p-lg text-center',
      iconClass: 'fa fa-clock-o fa-3x',
      title: 'Average Queue Time',
      period: 'Last hour',
      getValue: () => this.stats.CdrSummary.AvgQueueLh + '',
    },
    AverageQueueTimeLD: {
      infoClass: 'widget blue-bg p-lg text-center',
      iconClass: 'fa fa-clock-o fa-3x',
      title: 'Average Queue Time',
      period: 'Today',
      getValue: () => this.stats.CdrSummary.AvgQueue24H + '',
    },
    AverageCallLength: {
      infoClass: 'widget blue-bg p-lg text-center',
      iconClass: 'fa fa-clock-o fa-3x',
      title: 'Average Call Length',
      period: 'Last hour / Today',
      getValue: () => this.stats.CdrSummary.AvgCallDurationLh + ' / ' + this.stats.CdrSummary.AvgCallDuration24H,
    },
    AverageWrapupTime: {
      infoClass: 'widget blue-bg p-lg text-center',
      iconClass: 'fa fa-clock-o fa-3x',
      title: 'Avg Wrap-Up Time',
      period: 'Last hour / Today',
      getValue: () => this.stats.CdrSummary.AvgWrapUpLh + ' / ' + this.stats.CdrSummary.AvgWrapUp24H,
    },
    AbandonedCalls: {
      infoClass: 'widget light-red-bg p-lg text-center',
      iconClass: 'fa fa-phone fa-3x',
      title: 'Abandoned Calls',
      period: 'Last hour / Today',
      getValue: () => this.stats.CdrSummary.AbandonedLh + ' / ' + this.stats.CdrSummary.Abandoned24H,
    },
    CancelledByAgentCalls: {
      infoClass: 'widget light-red-bg p-lg text-center',
      iconClass: 'fa fa-phone fa-3x',
      title: 'Cancelled by Agent',
      period: 'Last hour / Today',
      getValue: () => this.stats.CdrSummary.CancelledLh + ' / ' + this.stats.CdrSummary.Cancelled24H,
    },
    DisconnectedCalls: {
      infoClass: 'widget light-red-bg p-lg text-center',
      iconClass: 'fa fa-phone fa-3x',
      title: 'Disconnected Calls',
      period: 'Last hour / Today',
      getValue: () => this.stats.CdrSummary.DisconnectedLh + ' / ' + this.stats.CdrSummary.Disconnected24H,
    },
    MaxCallsInQueue: {
      infoClass: 'widget lazur-bg p-lg text-center',
      iconClass: 'fa fa-phone fa-3x',
      title: 'Max Calls in Queue',
      period: 'Today',
      getValue: () => this.stats.CdrSummary.MaxCall24H + '',
    },
    LongestQueueTime: {
      infoClass: 'widget lazur-bg p-lg text-center',
      iconClass: 'fa fa-clock-o fa-3x',
      title: 'Longest Queue Time ',
      period: 'Today',
      getValue: () => this.stats.CdrSummary.LongestQueue24H + '',
    },
    QueueTimeOver30s: {
      infoClass: 'widget blue-bg p-lg text-center',
      iconClass: 'fa fa-clock-o fa-3x',
      title: 'Queue Time over 30s',
      period: 'Last hour / Today',
      getValue: () => this.stats.CdrSummary.CallsOver30SecondsInQueueLh + ' / ' + this.stats.CdrSummary.CallsOver30SecondsInQueue24H,
    },

    CallsWaitingInQueue: {
      infoClass: 'widget blue-bg p-lg text-center',
      iconClass: 'fa fa-phone fa-3x',
      title: 'Calls in queue',
      period: 'Last hour / Today',
      getValue: () => this.stats.CdrSummary.CallsWaitingInQueue + '',
    },
    AvgQueue24H: {
      infoClass: 'widget blue-bg p-lg text-center',
      iconClass: 'fa fa-clock-o fa-3x',
      title: 'Average wait time',
      period: 'Last 24 hours',
      getValue: () => this.stats.CdrSummary.AvgQueue24H + '',
    },
    AvgCallDuration24H: {
      infoClass: 'widget blue-bg p-lg text-center',
      iconClass: 'fa fa-clock-o fa-3x',
      title: 'Average call time',
      period: 'Last 24 hours',
      getValue: () => this.stats.CdrSummary.AvgCallDuration24H + '',
    },
    AvgWrapUp24H: {
      infoClass: 'widget blue-bg p-lg text-center',
      iconClass: 'fa fa-clock-o fa-3x',
      title: 'Average Wrap-Up Time',
      period: 'Last 24 hours',
      getValue: () => this.stats.CdrSummary.AvgWrapUp24H + '',
    },
  };

  constructor(
    private ngZone: NgZone,
    private locationlaneResolver: LocationLaneResolverService,
    private ccSettingsService: CallCenterStatisticService,
    private regionsService: RegionsService,
    private accessManagementService: AccessManagementService,
    private monitoringSettingsService: MonitoringSettingsService,
    @Inject(ANALYTICS) private analytcis: IAnalyticsService,
  ) {}

  classToUse(availability: string): string {
    if (availability === 'Free' || availability === 'Alerting') {
      return 'tdAvail';
    } else if (availability === 'On Call') {
      return 'blue-bg';
    } else if (availability === 'Busy' || availability === 'Wrapup') {
      return 'tdBusy';
    } else if (availability === 'Away' || availability === 'Not Ready' || availability === 'Logged Off') {
      return 'tdNonAvail';
    } else {
      return '';
    }
  }

  ngOnInit() {
    this.infoboards = [];
    this.monitoringSettingsService.getPortalSettings().subscribe((res) => {
      this.buildInfoBoards(res);
    });

    this.createTimer();

    let regionStream = of({
      Data: new Array<IRegion>(),
      Total: 0,
      Groups: null,
      Success: true,
    });

    if (
      this.accessManagementService.hasAccessPermissions({
        entities: ['Locations'],
        access: 'CanRead',
      })
    ) {
      regionStream = this.regionsService.getAll();
    }

    let settingsStream = this.ccSettingsService.getAll();
    let queuesStream = this.ccSettingsService.getQueues();

    this.subscription = forkJoin([settingsStream, regionStream, queuesStream]).subscribe(
      (response) => {
        let regionIds = [];
        let queues = <Array<string>>[];

        if (!response[0].Success) {
          return;
        }

        response[0].Data.forEach((v) => {
          if (regionIds.indexOf(v.fkRegion) < 0) {
            regionIds.push(v.fkRegion);
          }

          if (v.QueueIds) {
            let settingsQueues = v.QueueIds.split(',');
            settingsQueues.forEach((i) => {
              if (queues.indexOf(i) < 0) {
                queues.push(i);
              }
            });
          }
        });

        if (!response[2].Success && response[2].Queues.length > 0) {
          return;
        }

        this.queues = response[2].Queues;

        this.settingsQueues = this.queues.filter((lq) => queues.indexOf(lq.Id) >= 0);
        this.settingsQueues = this.settingsQueues.filter(
          (obj, index) =>
            this.settingsQueues.findIndex((item) => item.Id === obj.Id) === index
        );
        this.settingsQueues.forEach((v) => {
          this.queuesButtons[v.Id] = true;
        });

        if (!(response[1].Success && regionIds.length > 0)) {
          return;
        }
        this.regions = response[1].Data.filter((v) => regionIds.indexOf(v.Id) >= 0);
        this.regions.forEach((v) => {
          this.regionButtons[v.Id] = true;
        });

        if (this.regions.length === 0) {
          this.isRegionFilter = false;
        }
      },
    );

    this.analytcis.track(AnalyticsEvent.MonitoringOpen, null);
  }

  ngOnDestroy() {
    this.destroyTimer();
    if (!!this.subscription) {
      this.subscription.unsubscribe();
      this.subscription = null;
    }
  }

  openMonitoringSettingsEditor() {
    this.analytcis.track(AnalyticsEvent.MonitoringSettingsOpen, null);
    this.editMonitoringSettings
      .open()
      .pipe(filter((res) => !!res))
      .subscribe((res) => {
        this.buildInfoBoards(res);
        this.analytcis.track(AnalyticsEvent.MonitoringSettingsSave, null);
      });
  }

  createTimer() {
    if (this.timer) {
      return;
    }

    this.ngZone.runOutsideAngular(() => {
      const target = this.timerIntervalSec * 1000;
      const comp = this;

      let lastLoop = 0;

      (function loop() {
        let delay = target - lastLoop;
        if (delay < 0) {
          delay = 0;
        }

        let lastStart = Date.now();

        comp.timer = setTimeout(() => {
          comp.getStatistic(comp.ccSettingsService).subscribe(
            (response) => {
              comp.ngZone.run(() => {
                comp.stats = response;
                comp.stats.CdrSummary = response.CdrSummary || new CallCenterSummary();
                comp.stats.Agents = response.Agents ? comp.filterAgentsWhoNotChangedStatusInTwoDays(response.Agents) : [];
                comp.callsInQueue = comp.prepareCallsInQueue(response.CallsInQueue || []);
                console.log('Monitoring timer function has been triggered');
              });
            },
            (err) => {
              console.log('ERROR', err);
              comp.refreshTimer();
            },
            () => {
              lastLoop = Date.now() - lastStart;
              loop();
            },
          );
        }, comp.timerIntervalSec * 1000);
      })();
    });

    console.log('Monitoring timer has been created');
  }

  destroyTimer() {
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = null;
      console.log('Monitoring timer has been stopped');
    }
  }

  refreshTimer() {
    const delayTimeForReconnect = 3000;
    setTimeout(() => {
      this.destroyTimer();
      this.createTimer();
    }, delayTimeForReconnect);
  }

  getStatistic(stats: CallCenterStatisticService): Observable<CallCenterStatisticsData> {
    if (this.isRegionFilter) {
      let regionIds = [];
      this.regionButtons.forEach((v, k) => {
        if (v) {
          regionIds.push(k);
        }
      });
      return stats.getRegionsStatistic(JSON.stringify(regionIds));
    } else {
      let queueIds = [];
      for (let k in this.queuesButtons) {
        if (this.queuesButtons.hasOwnProperty(k) && this.queuesButtons[k]) {
          queueIds.push(k);
        }
      }

      return stats.getQueuesStatistic(JSON.stringify(queueIds));
    }
  }

  private buildInfoBoards(widgets: Array<IMonitoringWidget>) {
    let infoboards = [];
    widgets.forEach((widget) => {
      let board = this.infoboardComponents[widget.Type];
      if (board) {
        infoboards.push(board);
      }
    });

    this.infoboards = infoboards;
  }

  private prepareCallsInQueue(source: Array<Call>): Array<CallInQueue> {
    let calls = source.map((call) => {
      let result = new CallInQueue(call);

      this.resolveUri(call.Caller, result);
      // this.resolveUri'tel:0010019002;phone-context=chicag*/, result);  // for debugging purpose

      return result;
    });

    return calls.sort((left, right) => (left.WaitSeconds > right.WaitSeconds ? -1 : 1));
  }

  private resolveUri(uri: string, call: CallInQueue) {
    uri = uri.replace('sip:', '');

    let cached = this.resolvingCache[uri];
    if (!!cached) {
      call.LocationName = cached.locationName;
      call.LaneNumber = cached.laneNumber;
      call.LaneName = cached.laneName;
      return;
    }

    this.locationlaneResolver.resolveUri(uri).subscribe((res) => {
      call.LaneNumber = !!res.lane ? res.lane.Lane_Number + '' : null;
      call.LaneName = !!res.lane ? res.lane.Lane_Type : null;
      call.LocationName = !!res.location ? res.location.LotName : null;

      this.resolvingCache[uri] = {
        locationName: call.LocationName,
        laneNumber: call.LaneNumber,
        laneName: call.LaneName,
      };
    });
  }

  private filterAgentsWhoNotChangedStatusInTwoDays(agents: Array<Agent>): Array<Agent> {
    const twoDays = 2;
    const currentTime = new Date(Date.now());

    return agents.filter((agent: Agent) => {
      let agentPublishedTimeDate = new Date(agent.PublishedTime);
      agentPublishedTimeDate.setDate(agentPublishedTimeDate.getDate() + twoDays);

      return agentPublishedTimeDate >= currentTime ? agent : null;
    }).filter((x) => x);
  }

  switchFilter(button: boolean) {
    if (button === this.isRegionFilter) {
      return;
    }
    this.isRegionFilter = !this.isRegionFilter;
  }

  selectRegion(regionId: number) {
    this.regionButtons[regionId] = !this.regionButtons[regionId];
  }

  selectQueue(queue: Queue) {
    this.queuesButtons[queue.Id] = !this.queuesButtons[queue.Id];
  }
}
