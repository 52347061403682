import { Observable, BehaviorSubject, Subscription, of } from 'rxjs';
import { filter, first, mergeMap, tap } from 'rxjs/operators';

import { OutgoingConversationState, IOutgoingConversation, ISkypeMePerson, ISkypePerson } from '../../abstractions';

import { CC4AllAppService } from './services/cc4all-app.service';

export class CC4AllWarmTransferConversation implements IOutgoingConversation {
  private _state = new BehaviorSubject<OutgoingConversationState>('Created');
  private subscription: Subscription;
  private _isPaused = new BehaviorSubject<boolean>(false);

  public get state$(): Observable<OutgoingConversationState> {
    return this._state.asObservable();
  }
  public get selfParticipant$(): Observable<ISkypeMePerson> {
    return of(null);
  }
  public get participants$(): Observable<ISkypePerson[]> {
    return of([
      {
        id: this.sip,
        displayName$: of(this.sip.replace('sip:', '').replace('tel:', '')),
        avatarUrl$: null,
        status$: null,
        activity$: null,

        dispose: () => {},
      },
    ]);
  }

  constructor(
    public conversationKey: string,
    public sessionId: string,
    public sip: string,
    private cc4all: CC4AllAppService,
  ) {
    this.subscription = this.cc4all.callSessions$.pipe(filter((x) => x.ConversationKey === this.conversationKey)).subscribe((res) => {
      switch (res.ConferenceActionAsString) {
        case 'WarmInviteSent':
          this._state.next('Connecting');
          break;

        case 'WarmInviteAccepted':
          this._state.next('Connected');
          break;

        case 'WarmAccepted':
          this._state.next('Connected');
          break;

        case 'WarmInviteRejected':
          this._state.next('Disconnected');
          this.subscription.unsubscribe();
          break;

        case 'WarmCanceled':
          this._state.next('Disconnected');
          this.subscription.unsubscribe();
          break;

        case 'OnHold':
          this._isPaused.next(true);
          break;

        case 'Resumed':
          this._isPaused.next(false);
          break;

        default:
          break;
      }
    });
  }

  public leave(): Observable<boolean> {
    return this.cc4all.warmTransferCancel(this.conversationKey, this.sessionId, this.sip);
  }

  pause(): Observable<boolean> {
    return this.handlePause(true);
  }
  resume(): Observable<boolean> {
    return this.handlePause(false);
  }

  private handlePause(pause: boolean): Observable<boolean> {
    if (pause) {
      return this.cc4all.pauseCall(this.conversationKey, this.sessionId);
    }

    return this.cc4all.resumeCall(this.conversationKey, this.sessionId);
  }
}
