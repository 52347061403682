import { Component, OnInit, ViewChild, Type, ComponentFactoryResolver } from '@angular/core';

import { Observable, Observer } from 'rxjs';

import { AppNotificationsService } from '@libs/portal-common/system';
import { DynComponentHostDirective } from '@libs/portal-common/shared';

import { ModalDirective } from 'ngx-bootstrap/modal';
import { IRevenueForm, RevenueFormInfo } from '../revenue-forms/revenue-form-factory.service';
import { IncomingCall } from '../../../../abstractions';

@Component({
  selector: 'app-revenue-control-form',
  templateUrl: './revenue-control-form.component.html',
  styleUrls: ['./revenue-control-form.component.scss'],
})
export class RevenueControlFormComponent implements OnInit {
  @ViewChild('modal', { static: true }) public modal: ModalDirective;
  @ViewChild(DynComponentHostDirective, { static: false }) dynComponentHost: DynComponentHostDirective;

  parameters: RevenueFormInfo;
  incomingCall: IncomingCall;
  revenueForm: IRevenueForm;

  resultObserver: Observer<boolean>;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private notifications: AppNotificationsService,
  ) {}

  ngOnInit() {}

  open(parameters: RevenueFormInfo, incomingCall: IncomingCall): Observable<boolean> {
    this.parameters = parameters;
    this.incomingCall = incomingCall;

    setTimeout(() => {
      this.modal.show();
      this.loadComponent(parameters.type, incomingCall);
    }, 10);

    return new Observable((observer) => (this.resultObserver = observer));
  }

  canSave(): boolean {
    if (!this.revenueForm || !this.revenueForm.canSubmit()) {
      return false;
    }

    return true;
  }

  save() {
    if (!this.revenueForm.validate()) {
      return;
    }

    this.revenueForm.submit().subscribe(
      (response) => {
        if (!response.Success) {
          this.notifications.error(response.Message);
          return;
        }

        this.notifications.success(this.parameters.successMessage);

        this.resultObserver.next(true);
        if (this.parameters.closeOnSuccess) {
          this.modal.hide();
          this.resultObserver.complete();
        }
      },
      (err) => {
        this.notifications.error(err.Message || 'Error');
      },
    );
  }

  cancel() {
    this.modal.hide();

    if (!!this.resultObserver) {
      this.resultObserver.next(null);
      this.resultObserver.complete();
    }
  }

  private loadComponent(type: Type<IRevenueForm>, incomingCall: IncomingCall) {
    this.revenueForm = null;
    const viewContainerRef = this.dynComponentHost.viewContainerRef;
    viewContainerRef.clear();

    const facotry = this.componentFactoryResolver.resolveComponentFactory(type);
    const componentRef = viewContainerRef.createComponent<IRevenueForm>(facotry);
    this.revenueForm = componentRef.instance;
    this.revenueForm.init(this.parameters.provider, incomingCall, this.parameters.formParams);
  }
}
