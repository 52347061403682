<form #form="ngForm" *ngIf="initialized" class="form-inline modal-form ticket-filter" novalidate>
  <div class="form-group col-sm-6 from-group-sm validation-container" [class.has-error]="ticketNumberField | hasErrors: validating">
    <input
      class="form-control"
      type="text"
      [(ngModel)]="ticketNum"
      name="ticketNum"
      #ticketNumberField="ngModel"
      appValidateNumber
      placeholder="Ticket №"
    />
    <app-validation-errors [model]="ticketNumberField" [validate]="validating"></app-validation-errors>
  </div>

  <div class="form-group col-sm-6 from-group-sm validation-container" [class.has-error]="firstNameField | hasErrors: validating">
    <input class="form-control" type="text" [(ngModel)]="firstName" name="firstName" #firstNameField="ngModel" placeholder="First name" />
    <app-validation-errors [model]="firstNameField" [validate]="validating"></app-validation-errors>
  </div>

  <div class="form-group col-sm-6 from-group-sm validation-container" [class.has-error]="lastNameField | hasErrors: validating">
    <input class="form-control" type="text" [(ngModel)]="lastName" name="lastName" #lastNameField="ngModel" placeholder="Last name" />
    <app-validation-errors [model]="lastNameField" [validate]="validating"></app-validation-errors>
  </div>

  <div class="form-group col-sm-6 from-group-sm validation-container" [class.has-error]="mobileFirstDigitsField | hasErrors: validating">
    <input
      class="form-control"
      type="text"
      [(ngModel)]="mobileFirstDigits"
      name="mobileFirstDigits"
      appValidateNumber
      #mobileFirstDigitsField="ngModel"
      placeholder="Mobile first 6 digits"
    />
    <app-validation-errors [model]="mobileFirstDigitsField" [validate]="validating"></app-validation-errors>
  </div>

  <div class="form-group col-sm-6 from-group-sm validation-container" [class.has-error]="mobileLastDigitsField | hasErrors: validating">
    <input
      class="form-control"
      type="text"
      [(ngModel)]="mobileLastDigits"
      name="mobileLastDigits"
      #mobileLastDigitsField="ngModel"
      appValidateNumber
      placeholder="Mobile last 4 digits"
    />
    <app-validation-errors [model]="mobileLastDigitsField" [validate]="validating"></app-validation-errors>
  </div>

  <div class="form-group col-sm-6 from-group-sm validation-container" [class.has-error]="cardLastDigitsField | hasErrors: validating">
    <input
      class="form-control"
      type="text"
      [(ngModel)]="cardLastDigits"
      name="cardLastDigits"
      #cardLastDigitsField="ngModel"
      appValidateNumber
      placeholder="Card last 4 digits"
    />
    <app-validation-errors [model]="cardLastDigitsField" [validate]="validating"></app-validation-errors>
  </div>
</form>
<button class="btn btn-sm button--primary btn-load-info" type="submit" [disabled]="busy" (click)="showTicketInfo()">
  <span *ngIf="!busy">Show ticket</span>
  <span *ngIf="busy"> <i class="fa fa-spinner fa-spin"></i> loading</span>
</button>

<table class="table table-sm table-hover">
  <thead>
    <tr>
      <th>Ticket №</th>
      <th>Arrived</th>
      <th>Departed</th>
      <th>Parker</th>
      <th>Phone</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let ticket of tickets" (click)="select(ticket)" [class.active]="ticket.TicketId === selectedTicket?.TicketId">
      <td>{{ ticket.TicketNumber }}</td>
      <td>{{ ticket.Arrived | dateFormat: 'M/D/YYYY' }}</td>
      <td>{{ ticket.Departed | dateFormat: 'M/D/YYYY' }}</td>
      <td>{{ ticket.CustomerName }}</td>
      <td>{{ ticket.Phone }}</td>
    </tr>
  </tbody>
</table>

<form
  #priceForm="ngForm"
  *ngIf="initialized && validationEnabled && !!selectedTicket"
  class="form-horizontal modal-form form-validate-ticket ticket-filter"
  novalidate
>
  <div class="form-group from-group-sm validation-container" [class.has-error]="price | hasErrors: validating">
    <label class="col-sm-3 control-label">Price</label>
    <kendo-dropdownlist
      [data]="prices"
      [(value)]="priceId"
      [textField]="'ValidationName'"
      [valueField]="'ValidationId'"
      class="dropdown-block"
      [valuePrimitive]="true"
      class="form-control-sm col-sm-9"
      name="price"
      required
    >
    </kendo-dropdownlist>
    <app-validation-errors [model]="price" [validate]="validating"></app-validation-errors>
  </div>
</form>
