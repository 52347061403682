<h3 class="info-header">Rate Information</h3>

<table class="table table-bordered">
  <thead>
    <tr>
      <th>Days</th>
      <th>Time, min</th>
      <th class="text-right">Price, $</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let rate of rates">
      <td scope="row">{{ rate.days }}</td>
      <td>{{ rate.time }}</td>
      <td class="text-right">{{ rate.rate || 0 | currency: 'USD' : true : '1.2-2' }}</td>
    </tr>
  </tbody>
</table>
