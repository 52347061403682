import { PipeTransform, Pipe } from '@angular/core';
import { NgModel } from '@angular/forms';

@Pipe({ name: 'valueIsEmpty', pure: true })
export class ValueIsEmptyPipe implements PipeTransform {
  transform(input: NgModel, validate = false): any {
    if (input && input.value && validate) {
      return true;
    }

    return false;
  }
}
