/// <reference types="@types/googlemaps" />

import { AfterViewInit, Directive, ElementRef, EventEmitter, Input, NgZone, Output } from '@angular/core';

import { GoogleAddress } from './google-address';
import { Address } from './Address';

@Directive({
  selector: '[appGooglePlacesAutocomplete]',
  exportAs: 'appGooglePlacesAutocomplete',
})
export class GooglePlacesAutocompleteDirective implements AfterViewInit {
  @Input() options: google.maps.places.AutocompleteOptions;
  @Output() addressChange: EventEmitter<Address> = new EventEmitter();
  private autocomplete: any;
  private eventListener: any;
  public place: GoogleAddress;

  constructor(
    private el: ElementRef,
    private ngZone: NgZone,
  ) {}

  ngAfterViewInit(): void {
    if (!this.options) {
      this.options = <google.maps.places.AutocompleteOptions>{};
    }

    this.initialize();
  }

  private isGoogleLibExists(): boolean {
    return !(!google || !google.maps || !google.maps.places);
  }

  private initialize(): void {
    if (!this.isGoogleLibExists()) {
      throw new Error('Google maps library can not be found');
    }

    this.autocomplete = new google.maps.places.Autocomplete(this.el.nativeElement, this.options);

    if (!this.autocomplete) {
      throw new Error('Autocomplete is not initialized');
    }

    google.maps.event.addListener(this.autocomplete, 'place_changed', () => {
      this.handleChangeEvent();
    });

    this.el.nativeElement.addEventListener('keydown', (event: KeyboardEvent) => {
      if (!event.key) {
        return;
      }

      let key = event.key.toLowerCase();

      if (key === 'enter' && event.target === this.el.nativeElement) {
        event.preventDefault();
        event.stopPropagation();
      }
    });

    // according to https://gist.github.com/schoenobates/ef578a02ac8ab6726487
    if (window && window.navigator && window.navigator.userAgent && navigator.userAgent.match(/(iPad|iPhone|iPod)/g)) {
      setTimeout(() => {
        let containers = document.getElementsByClassName('pac-container');

        if (containers) {
          let arr = Array.from(containers);

          if (arr) {
            for (let container of arr) {
              if (!container) {
                continue;
              }

              container.addEventListener('touchend', (e) => {
                e.stopImmediatePropagation();
              });
            }
          }
        }
      }, 500);
    }
  }

  public reset(autocompleteInput: string): void {
    if (!!this.autocomplete) {
      this.autocomplete.setComponentRestrictions(this.options.componentRestrictions);

      if (!!this.options.fields) {
        this.autocomplete.setFields(this.options.fields);
      }

      if (!!this.options.types) {
        this.autocomplete.setTypes(this.options.types);
      }

      this.el.nativeElement.value = autocompleteInput;
    }
  }

  private handleChangeEvent(): void {
    this.ngZone.run(() => {
      this.place = this.autocomplete.getPlace();

      if (this.place && this.place.place_id) {
        this.addressChange.emit(this.parseAddress(this.place));
      }
    });
  }

  getAddrComponent(place: GoogleAddress, componentTemplate): string {
    for (let i = 0; i < place.address_components.length; i++) {
      const addressType = place.address_components[i].types[0];
      if (componentTemplate[addressType]) {
        return place.address_components[i][componentTemplate[addressType]];
      }
    }

    return null;
  }

  private parseAddress(place: GoogleAddress): Address {
    const street = [this.getAddrComponent(place, { street_number: 'short_name' }), this.getAddrComponent(place, { route: 'long_name' })];

    const geo = this.parseGeometry(place);

    return {
      id: place.place_id,
      city:
        this.getAddrComponent(place, { locality: 'long_name' }) ||
        this.getAddrComponent(place, { sublocality_level_1: 'long_name' }) ||
        this.getAddrComponent(place, { postal_town: 'long_name' }),
      street: street.filter((x) => !!x).join(' '),
      state: this.getAddrComponent(place, { administrative_area_level_1: 'short_name' }),
      zip: this.getAddrComponent(place, { postal_code: 'long_name' }) || this.getAddrComponent(place, { postal_code_prefix: 'long_name' }),
      lat: geo.lat,
      lng: geo.lng,
    };
  }

  private parseGeometry(place: GoogleAddress): { lat: number; lng: number } {
    let result = {
      lat: null,
      lng: null,
    };

    if (!place || !place.geometry || !place.geometry.location) {
      return result;
    }

    result.lat = (<any>place.geometry.location.lat)();
    result.lng = (<any>place.geometry.location.lng)();

    return result;
  }
}
