<div *ngIf="locationInfo">
  <h3 class="info-header">{{ locationInfo.LotNumber }} - {{ locationInfo.LotName }}</h3>

  <div class="info-block" [innerHTML]="locationInfo.GeneralInfo | safeHtml"></div>

  <div class="info-block" *ngIf="instructions.length > 0">
    <h3 class="info-header">Immediatelly contact company's employee</h3>
    <div class="panel panel-default panel-callout">
      <div class="panel-body">
        <ol>
          <li *ngFor="let instruction of instructions">
            <p>{{ instruction.title }}</p>
          </li>
        </ol>
      </div>
    </div>
  </div>

  <div class="info-block" *ngIf="lotContacts.length > 0">
    <h3 class="info-header no-border">Contact List</h3>
    <table class="table table-bordered">
      <thead>
        <tr>
          <th>Name</th>
          <th>Contact number</th>
          <th>Email</th>
          <th>Shifts</th>
        </tr>
      </thead>
      <tbody>
        <ng-template ngFor let-contact [ngForOf]="lotContacts">
          <tr>
            <td>
              {{ contact.Name }}
            </td>
            <td>
              <button
                class="btn-skype-call"
                *ngFor="let phone of contact.ContactPhones"
                (click)="skypeCall(phone.Phone, $event)"
                [disabled]="calling"
              >
                {{ phone.Phone }}
              </button>
            </td>
            <td>
              <a [href]="buildHref(contact.Email, 'mailto')">{{ contact.Email }}</a>
            </td>
            <td colspan="2">
              <div *ngFor="let shift of contact.Shifts">
                {{ getShiftByContactIdAndShiftId(shift.fkContact, shift.fkContactShift) | shiftViewCorrectionPipe }}
              </div>
            </td>
          </tr>
        </ng-template>
      </tbody>
    </table>
  </div>

  <app-gate-instructions [lot]="locationInfo"></app-gate-instructions>
</div>
