import { Component, OnInit, Inject, PLATFORM_ID, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';

import { Subscription, zip } from 'rxjs';

import { LocationsService } from '@libs/portal-common/services';
import { NgForm } from '@angular/forms';
import { filter, first, tap } from 'rxjs/operators';

import { AppNotificationsService } from '@libs/portal-common';

import { IIntegrationAppService } from '../../abstractions';
import { IssueSubmissionService } from '../services';
import { IntegrationAppFactoryService } from '../services';

@Component({
  selector: 'app-start-incoming-call',
  templateUrl: './start-incoming-call.component.html',
  styleUrls: ['./start-incoming-call.component.scss'],
})
export class StartIncomingCallComponent implements OnInit, OnDestroy {
  @ViewChild('loginForm') public loginForm: NgForm;

  validate = false;

  private _appSubscription: Subscription;
  integration: IIntegrationAppService;

  public isAuthenticated = false;
  public busy = false;
  public integrationInitialized = false;

  model = {
    username: '',
    password: '',
  };

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private notifications: AppNotificationsService,
    @Inject(PLATFORM_ID) private platformId: Object,
    private locations: LocationsService,
    private issueSubmissionService: IssueSubmissionService,
    integrationAppFactory: IntegrationAppFactoryService,
  ) {
    this.integration = integrationAppFactory.getApp(false);

    this.integration.initialized$
      .pipe(
        filter((x) => x),
        first(),
      )
      .subscribe((res) => (this.integrationInitialized = res));

    this._appSubscription = this.integration.isAuthenticated$.subscribe((res) => {
      this.isAuthenticated = res;
      if (this.isAuthenticated) {
        this.redirect();
      }
    });
  }

  ngOnInit(): void {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }

    zip(this.locations.getAll(), this.issueSubmissionService.init()).subscribe((res) => {});

    this.redirect();
  }

  login() {
    this.validate = true;
    if (!this.loginForm.valid) {
      return;
    }

    this.busy = true;
    this.integration
      .signIn(this.model.username, this.model.password)
      .pipe(
        filter((x) => x),
        first(),
      )
      .subscribe(
        (res) => {
          this.busy = false;
          this.redirect();
        },
        (err) => {
          this.busy = false;
          this.notifications.error(err);
        },
      );
  }

  loginAAD() {
    this.busy = true;
    this.integration
      .signInAAD()
      .pipe(
        tap((x) => (this.busy = false)),
        filter((x) => x),
        first(),
      )
      .subscribe(
        (res) => {
          this.busy = false;
          this.redirect();
        },
        (err) => {
          this.busy = false;
          this.notifications.error(err);
        },
      );
  }

  private redirect(): void {
    this.route.queryParams.subscribe((params) => {
      let caller: string = params['caller'];
      let callId: string = params['callId'];
      let sessionId: string = params['session_id'];
      let agentSip: string = params['agentSip'];
      let test: string = params['test'];

      if (!caller) {
        this.router.navigate(['/home']);
        return;
      }

      this.router.navigate(['/call', 'call-processing', 'general-information'], {
        queryParams: { caller: caller, callId: callId, session_id: sessionId, agentSip: agentSip, test: test },
      });
    });
  }

  ngOnDestroy(): void {
    if (!!this._appSubscription) {
      this._appSubscription.unsubscribe();
    }
  }
}
