import { Observable, Subscription, BehaviorSubject, of } from 'rxjs';
import { tap, map, filter, flatMap, catchError, first } from 'rxjs/operators';

import {
  ConversationState,
  IConversation,
  ISkypeAppService,
  IConversationParameters,
  IOutgoingConversation,
  IMePerson,
  ISkypeConversation,
  ConversationBase,
  SubmissionParameters,
} from '../../abstractions';

export class SkypeConversation extends ConversationBase implements IConversation {
  private _state = new BehaviorSubject<ConversationState>('Incoming');
  private _isPaused = new BehaviorSubject<boolean>(false);

  private _acceptTime: Date;
  private _hangupTime: Date;
  private _wrapupTime: Date;

  private subscription: Subscription;

  private _parameters: IConversationParameters;

  private hangingUp = false;

  public callId: string;
  public sessionId: string;

  public resolving = false;
  public _isCancelled = false;
  public skipSavingIncident = false;

  public get canAccept(): boolean {
    return this._state.value === 'Incoming';
  }

  public get state$(): Observable<ConversationState> {
    return this._state.asObservable();
  }

  public get isPaused$(): Observable<boolean> {
    return this._isPaused.asObservable();
  }

  public get callerUri(): string {
    // return 'tel:0010019002;phone-context=chicago';
    return this._conversation.creator.id();
  }

  public get agentSip(): string {
    return this._skypeApp.getSip();
  }

  public get agentName(): string {
    return this._skypeApp.getName();
  }

  public get callSource(): string {
    return 'skype';
  }

  public get callDuration(): number {
    if (this._acceptTime && this._hangupTime) {
      let res = (+this._hangupTime - +this._acceptTime) / 1000;
      return Math.round(res);
    }

    return 0;
  }

  get cancelled(): boolean {
    return this._isCancelled;
  }

  public setParameters(parameters: IConversationParameters) {
    this._parameters = parameters || { lotName: null, terminateCallTone: null };
  }

  public get wrapupDuration(): number {
    if (this._hangupTime && this._wrapupTime) {
      let res = (+this._wrapupTime - +this._hangupTime) / 1000;
      return Math.round(res);
    }

    return 0;
  }

  get queueDuration(): number {
    return 0;
  }

  constructor(
    private _conversation: ISkypeConversation,
    private _skypeApp: ISkypeAppService,
    private mePerson: IMePerson,
  ) {
    super();
    let self = this;

    this._state.next(<ConversationState>this._conversation.state());

    let subscription = this._conversation.state.changed((newValue, reason, oldValue) => {
      console.log('Conversation state changed from', oldValue, 'to', newValue);
      if (newValue === 'Incoming') {
        self._acceptTime = null;
        self._hangupTime = null;
        self._state.next('Incoming');
      }

      if (newValue === 'Connected') {
        self._acceptTime = new Date();
        self._state.next('Accepted');
      }

      if (newValue === 'Disconnected') {
        self._hangupTime = new Date();
        subscription.dispose();

        if (this._state.getValue() === 'Accepted' && !this._isCancelled) {
          self._state.next('HangedUp');
          self._skypeApp.setStatus('Busy', self.getActivity());
        } else {
          this._state.next('Finished');
          this._state.complete();

          this._skypeApp.setStatus('Online', 'Waiting for new calls');
        }

        subscription.dispose();
      }
    });
  }

  public accept(): Observable<boolean> {
    let self = this;

    let skypeStatus = new Observable<boolean>((observer) => {
      let subscription = this._conversation.audioService.accept.enabled.when(true, () => {
        console.log('Accepting ', self._conversation.audioService.state());

        if (self._conversation.audioService.state() === 'Notified') {
          self._conversation.audioService
            .accept()
            .then((accepted) => {
              observer.next(!!accepted);
              observer.complete();
            })
            .catch((err) => {
              console.log('ERROR ACCEPTING AUDIO SERVICE', err);
              observer.error(err);
            });
        } else {
          observer.next(false);
        }
      });

      return () => {
        console.log('unsubscribed');
        subscription.dispose();
      };
    }).pipe(first());

    return skypeStatus;
  }

  public reject(): Observable<boolean> {
    let self = this;

    return new Observable<boolean>((observer) => {
      let subscription = this._conversation.audioService.accept.enabled.when(true, () => {
        console.log('rejecting ', self._conversation.audioService.state());

        if (self._conversation.audioService.state() === 'Notified') {
          self._conversation.audioService
            .reject()
            .then((rejected) => {
              observer.next(!!rejected);
              observer.complete();
            })
            .catch((err) => {
              console.log(err);
              observer.error(err);
            });
        } else {
          observer.next(false);
        }
      });

      return () => {
        console.log('unsubscribed');
        subscription.dispose();
      };
    }).pipe(first());
  }

  public hangUp(): Observable<boolean> {
    let self = this;

    return new Observable((observer) => {
      self.hangingUp = true;

      let leave = () => {
        this._conversation.leave().then(
          function () {
            observer.next(true);
            observer.complete();

            self.hangingUp = false;
          },
          function (error) {
            observer.error(error);
          },
        );
      };

      if (!this._parameters || !this._parameters.terminateCallTone) {
        leave();
      } else {
        this.sendDtmf(this._parameters.terminateCallTone).subscribe((res) => {
          leave();
        });
      }
    });
  }

  public wrapUp(): Observable<boolean> {
    this._wrapupTime = new Date();

    this._state.next('Finished');
    this._state.complete();

    console.log('Wrap up');
    this._skypeApp.setStatus('Online', 'Waiting for new calls');
    return of(true);
  }

  public submitWrapUp(parameters: SubmissionParameters): Observable<boolean> {
    return of(true);
  }

  public cancelProcessing(): Observable<boolean> {
    this._isCancelled = true;

    return this.hangUp().pipe(
      flatMap((res) => this.wrapUp()),
      map((x) => true),
      catchError((err) => {
        console.log(err);
        return of(false);
      }),
    );
  }
  public markCancelled() {
    this._isCancelled = true;
  }

  sendDtmf(dtmf: string): Observable<boolean> {
    return new Observable((observer) => {
      if (dtmf) {
        let promises = dtmf.split('').map((char) => {
          return this._conversation.audioService.sendDtmf(char);
        });

        Promise.all(promises).then(
          () => {
            observer.next(true);
            observer.complete();
          },
          (error) => {
            console.log(error);
            observer.error(error);
          },
        );
      } else {
        observer.next(false);
        observer.complete();
      }
    });
  }

  pause(): Observable<boolean> {
    return this.handlePause(true);
  }
  resume(): Observable<boolean> {
    return this.handlePause(false);
  }

  outboundCall(uri: string): Observable<IOutgoingConversation> {
    return this.pause().pipe(
      filter((res) => res),
      flatMap((res) => this._skypeApp.outboundCall([uri])),
      flatMap((conversation) => conversation.state$.pipe(map((state) => ({ state: state, conversation: conversation })))),
      filter((res) => res.state === 'Disconnected'),
      first(),
      flatMap((res) => this.resume().pipe(map((resumeRes) => res.conversation))),
      tap((res) => {
        if (this._state.value === 'HangedUp') {
          this._skypeApp.setStatus('Busy', this.getActivity());
        }
      }),
    );
  }

  stringifyAndReset(): string {
    return '{}';
  }

  private handlePause(pause: boolean): Observable<boolean> {
    let selfParticipant = this._conversation.selfParticipant;
    let audio = selfParticipant.audio;
    let onHold = audio.isOnHold();

    let self = this;

    return new Observable<boolean>((observer) => {
      if (onHold !== pause) {
        audio.isOnHold.set(pause).then(
          function () {
            self._isPaused.next(pause);
            observer.next(pause);
            observer.complete();
          },
          function (error) {
            observer.error(error);
          },
        );
      } else {
        observer.next(pause);
        observer.complete();
      }
    }).pipe(first());
  }

  private getActivity() {
    if (!!this._parameters) {
      return `Completing - ${this._parameters.lotName}`;
    }

    return '';
  }
}
