import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { IRestBaseListResponse, BaseResponse } from '../models/rest/baseResponse';

export enum AnswerType {
  Integer,
  Boolean,
  String,
  SingleChoice,
  MultiChoice,
}

export class IncidentQualityAnalysisQuestion {
  Id: number;

  QuestionName: string;
  QuestionTitle: string;
  Question: string;
  PossibleAnswersList: Array<string>;
  AnswerType: AnswerType;
  LeftBorder: string;
  RightBorder: string;
  Visible: boolean;
  Required: boolean;
  IsDeleted: boolean;
}

const baseUrl = 'IncidentsQualityAnalysisQuestion';

export interface IGetAllIncidentQualityQuestions extends IRestBaseListResponse<IncidentQualityAnalysisQuestion> {}

export interface IRestBaseQuestionResponse extends BaseResponse {
  IncidentQualityAnalysisQuestion: IncidentQualityAnalysisQuestion;
}

@Injectable({ providedIn: 'root' })
export class IncidentsQualityAnalysisQuestionService {
  constructor(private _httpClient: HttpClient) {}

  // Get All IncidentQualityAnalysisQuestions Dynamic
  getAll(includeDeleted = false): Observable<IGetAllIncidentQualityQuestions> {
    let url = `${baseUrl}/List?includeDeleted=${includeDeleted}`;
    return this._httpClient.get<IGetAllIncidentQualityQuestions>(url);
  }

  insertOrUpdate(question): Observable<any> {
    return this._httpClient.put<any>(`${baseUrl}`, { IncidentQualityAnalysisQuestion: question });
  }

  delete(Id): Observable<any> {
    return this._httpClient.delete<any>(`${baseUrl}/` + Id);
  }
}
