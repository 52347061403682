import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ClaimMapping } from '../models/claimMapping';
import { BaseResponse, IRestBaseListResponse } from '../models';
import { Observable } from 'rxjs';
import { ClaimMappingResponse } from '../models/rest/claimMapping/claimMappingResponses';

@Injectable({ providedIn: 'root' })
export class ClaimMappingService {
  constructor(private _httpClient: HttpClient) {}

  get(): Observable<IRestBaseListResponse<ClaimMapping>> {
    return this._httpClient.get<IRestBaseListResponse<ClaimMapping>>('IdentityProvider/ClaimsMapping');
  }

  insertOrUpdate(claimMapping: ClaimMapping): Observable<ClaimMappingResponse> {
    return this._httpClient.put<ClaimMappingResponse>('IdentityProvider/ClaimsMapping', { ClaimMapping: claimMapping });
  }

  delete(id: number): Observable<BaseResponse> {
    return this._httpClient.delete<BaseResponse>('IdentityProvider/ClaimsMapping/' + id);
  }
}
