import { Component, EventEmitter, Input, Output } from '@angular/core';

export interface IBadgeView {
  message: string;
  fieldKeyTracker: string;
  reverse?: boolean;
}

@Component({
  selector: 'app-table-info',
  templateUrl: 'table-info.component.html',
  styleUrls: ['table-info.component.scss'],
})
export class TableInfoComponent {
  // fieldKeyNames it's key names of your object params,
  // which you want to display in the table cells
  @Input() fieldKeyNames: string[];
  @Input() headers: string[];
  @Input() contentList: any[];
  @Input() headersWidth?: string[];
  @Input() orderBy?: string;
  @Input() busy?: boolean;
  @Input() badge?: IBadgeView;
  @Output() deleteBtnClicked: EventEmitter<any> = new EventEmitter<any>();
  @Output() editBtnClicked: EventEmitter<any> = new EventEmitter<any>();
  constructor() {}
}
