import { Directive, ElementRef, AfterViewChecked, Input, HostListener } from '@angular/core';

@Directive({
  selector: '[appMatchHeight]',
})
export class MatchHeightDirective implements AfterViewChecked {
  // class name to match height
  @Input()
  appMatchHeight: any;

  constructor(private el: ElementRef) {}

  ngAfterViewChecked() {
    // call our matchHeight function here later
    this.fixHeight(this.el.nativeElement, this.appMatchHeight);
  }

  @HostListener('window:resize')
  onResize() {
    // call our matchHeight function here later
    this.fixHeight(this.el.nativeElement, this.appMatchHeight);
  }

  fixHeight(parent: HTMLElement, className: string) {
    // match height logic here
    if (!parent) {
      return;
    }

    let $parent = $(parent);
    const children = $parent.find('.' + className);

    if (!children) {
      return;
    }

    // gather all height
    const itemHeights = Array.from(children).map((x: HTMLElement) => {
      let height = x.getBoundingClientRect().height;

      x.style.height = 'initial';
      let initialHeight = x.getBoundingClientRect().height;
      x.style.height = `${height}px`;

      return initialHeight;
    });

    // find max height
    const maxHeight = itemHeights.reduce((prev, curr) => {
      return curr > prev ? curr : prev;
    }, 0);

    // apply max height
    Array.from(children).forEach((x: HTMLElement) => (x.style.height = `${maxHeight}px`));
  }
}
