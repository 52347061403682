import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'orderBy', pure: false })
export class OrderByPipe implements PipeTransform {
  transform(input: Array<any>, fieldName: string): any {
    if (!input || !fieldName) {
      return input;
    }

    return input.sort((a, b) => {
      let left = a[fieldName],
        right = b[fieldName];

      if (!!left && !!right && !!left.toLowerCase && !!right.toLowerCase) {
        left = left.toLowerCase();
        right = right.toLowerCase();
      }

      if (left > right) {
        return 1;
      } else if (left < right) {
        return -1;
      }

      return 0;
    });
  }
}
