import { Component, OnInit, Input, OnDestroy, Inject } from '@angular/core';
import { Subscription } from 'rxjs';
import { NavigationService } from '../../../services';
import { AgentAnalyticsEvent } from '../../../../services';
import { ANALYTICS, IAnalyticsService } from '@libs/portal-common';

class Rate {
  order?: number;
  time?: string;
  days: string;
  rate: number;
  lastPush: any;
  reason: string;
}

@Component({
  selector: 'app-rate-information',
  templateUrl: './rate-information.component.html',
  styleUrls: ['./rate-information.component.scss'],
})
export class RateInformationComponent implements OnInit, OnDestroy {
  private subscription: Subscription;

  rates: Array<Rate> = [];

  constructor(
    private navService: NavigationService,
    @Inject(ANALYTICS) private analytics: IAnalyticsService,
  ) {}

  ngOnInit() {
    this.subscription = this.navService.incomingCall$.subscribe((incomingCall) => {
      const info = !!incomingCall ? incomingCall.locationInfo : null;
      if (!!info) {
        this.rates = info && info.Lot && info.Lot.Rates ? JSON.parse(info.Lot.Rates) : [];

        this.rates.forEach((rate, index) => {
          if (!rate.order) {
            rate.order = index + 1;
          }
        });

        this.rates.sort((r1, r2) => {
          if (r1.order < r2.order) {
            return -1;
          }
          if (r1.order > r2.order) {
            return 1;
          }

          return 0;
        });
      } else {
        this.rates = [];
      }
    });

    this.analytics.track(AgentAnalyticsEvent.RateInfoClicked, null);
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
      this.subscription = null;
    }
  }
}
