import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

export interface InexLPRLocationViewModel {
  LocationId: string;
  LocationName: string;
  LocationDisplayName: string;
}

@Injectable({ providedIn: 'root' })
export class InexLPRService {
  constructor(private _httpClient: HttpClient) {}

  // Get all locations
  getLocations(): Observable<any> {
    return this._httpClient.get<any>('InexLPR/Locations');
  }
}
