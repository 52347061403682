import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseResponse } from '../models/rest/baseResponse';

export interface IPowerAutomateIntegration {
  Id: number;
  Code: string;
  Name: string;
}

export interface IPowerAutomateIntegrationResponse extends BaseResponse {
  Data: IPowerAutomateIntegration[];
}

export interface IPowerAutomateIntegrationCreateResponse extends BaseResponse {
  PowerAutomateIntegration: IPowerAutomateIntegration;
}

@Injectable({ providedIn: 'root' })
export class PowerAutomateService {
  constructor(private _httpClient: HttpClient) {}

  getAll(): Observable<IPowerAutomateIntegrationResponse> {
    return this._httpClient.get<IPowerAutomateIntegrationResponse>('PowerAutomateIntegrations');
  }

  insertPowerAutomate(model: IPowerAutomateIntegration): Observable<IPowerAutomateIntegrationCreateResponse> {
    return this._httpClient.post<IPowerAutomateIntegrationCreateResponse>('PowerAutomateIntegrations', {PowerAutomateIntegration: model});
  }

  updatePowerAutomate(model: IPowerAutomateIntegration): Observable<IPowerAutomateIntegrationCreateResponse> {
    return this._httpClient.put<IPowerAutomateIntegrationCreateResponse>('PowerAutomateIntegrations', {PowerAutomateIntegration: model});
  }

  deletePowerAutomate(Id: number): Observable<void> {
    return this._httpClient.delete<void>(`/PowerAutomateIntegrations/${Id}`);
  }

}
