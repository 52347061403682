<div class="modal fade" bsModal #fieldsPickerModal="bs-modal" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-sm">
    <div class="modal-content">
      <div class="modal-header clearfix">
        <h4 class="modal-title pull-left">Grid fields</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="cancel()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body" style="margin-right: 5px">
        <div class="row">
          <div class="col-sm-6">
            <label>Available</label>
            <ul class="list--overflow">
              <li
                id="{{ field.Id }}"
                class="flex__box flex__box--row flex__box--align-center panel__item"
                *ngFor="let field of model.availableFields"
              >
                {{ field.displayName }}
                <div class="flex__fluid text-right">
                  <button class="button button--tiny button--secondary" (click)="toggleField(field)"><i class="fa fa-plus"></i></button>
                </div>
              </li>
            </ul>
          </div>

          <div class="col-sm-6">
            <label>Selected</label>
            <ul class="list--overflow">
              <kendo-sortable [kendoSortableBinding]="model.selectedFields" [itemStyle]="{ 'min-width': '300px' }">
                <ng-template let-field="item">
                  <li class="flex__box flex__box--row flex__box--align-center panel__item draggable-item">
                    <span class="fa fa-bars fa-lg" style="margin-right: 1em"></span>
                    {{ field.displayName }}
                    <div class="flex__fluid text-right">
                      <button class="button button--tiny button--alert" (click)="toggleField(field)"><i class="fa fa-minus"></i></button>
                    </div>
                  </li>
                </ng-template>
              </kendo-sortable>
            </ul>
          </div>
        </div>
      </div>

      <div class="modal-footer">
        <button class="button pull-left" (click)="reset()">Reset</button>

        <button class="button button--primary" (click)="submit()">Submit</button>
        <button class="button" style="margin-right: 25px" (click)="cancel()">Cancel</button>
      </div>
    </div>
  </div>
</div>
