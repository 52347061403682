import { IPowerAutomateIntegration } from '@libs/portal-common/services';

export class FieldSetConverter {
  static convertFieldsJsonToFieldSet(fieldsJson: string, fieldSet: any) {
    if (!fieldsJson || !fieldSet) {
      return [];
    }

    let fields = JSON.parse(fieldsJson);

    if (typeof fields !== 'undefined' && fields.length > 0) {
      let fieldsArr = [];

      for (let i = 0; i < fields.length; i++) {
        let fieldSetField = fieldSet.findById(fields[i].Id);
        if (!fieldSetField) {
          continue;
        }
        let field = JSON.parse(JSON.stringify(fieldSetField));
        if (!field) {
          continue;
        }
        field.IsRequired = fields[i].IsRequired;
        fieldsArr.push(field);
      }
      return fieldsArr;
    }
    return [];
  }

  static convertProblemIssuesFieldJson(problem, fieldSet) {
    if (!problem) {
      return;
    }

    problem.Issues.forEach((issue) => {
      issue.Fields = this.convertFieldsJsonToFieldSet(issue.FieldsJson, fieldSet);
    });
  }

  static convertPowerAutomateIntegrationsJson(
    json: string,
    integrations: Array<IPowerAutomateIntegration>,
  ): Array<IPowerAutomateIntegration> {
    if (!json || !integrations) {
      return [];
    }

    let fields = JSON.parse(json);

    if (typeof fields !== 'undefined' && fields.length > 0) {
      let result: Array<IPowerAutomateIntegration> = [];

      for (let i = 0; i < fields.length; i++) {
        let integration = integrations.findById(fields[i].Id);
        if (!integration) {
          continue;
        }

        result.push(integration);
      }
      return result;
    }
    return [];
  }
}
