import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent } from '@angular/common/http';
import { Observable, of } from 'rxjs';

import { FinesseStateService } from './finesse-state.service';

@Injectable()
export class FinesseAuthInterceptor implements HttpInterceptor {
  constructor(private finesseState: FinesseStateService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let authHeader = this.finesseState.authHeader;

    let url = request.url;
    if (!url.startsWith('http')) {
      url = `${this.finesseState.finesseRestUrl}/finesse/api/${url}`;
    }

    let headers: {
      [name: string]: string;
    } = {};

    let ignoreContentType =
      typeof request.body === 'undefined' ||
      request.body === null ||
      request.body.toString() === '[object FormData]' ||
      request.headers.has('Content-Type');

    if (!ignoreContentType) {
      headers['content-type'] = 'application/xml; charset=utf-8';
    }

    if (!!authHeader) {
      headers['Authorization'] = authHeader;
    }

    headers['Accept'] = '*/*';

    request = request.clone({
      url: url,
      setHeaders: headers,
    });

    return next.handle(request);
  }
}
