import { Inject, Injectable, Injector } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { mergeMap, shareReplay, catchError } from 'rxjs/operators';

import { APP_CONFIGURATION, AppNotificationsService } from '@libs/portal-common';
import { AppConfigurationService } from '../../../services';
import { GenesysAppService } from './genesys-app.service';

@Injectable()
export class GenesysAuthInterceptor implements HttpInterceptor {
  token: Observable<string>;

  constructor(
    @Inject(Injector) private injector: Injector,
    private notifications: AppNotificationsService,
    @Inject(APP_CONFIGURATION) private configuration: AppConfigurationService,
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.token) {
      this.token = this.genesys.getAuthToken().pipe(shareReplay(1));
    }

    return this.token.pipe(
      mergeMap((token) => {
        let url = request.url;
        if (!url.startsWith('http')) {
          let baseUrl = this.getApiBaseUrl();
          url = `${baseUrl}/api/v2/${url}`;
        }

        let headers: {
          [name: string]: string;
        } = {};

        let ignoreContentType =
          typeof request.body === 'undefined' ||
          request.body === null ||
          request.body.toString() === '[object FormData]' ||
          request.headers.has('Content-Type');

        if (!ignoreContentType) {
          headers['content-type'] = 'application/json; charset=utf-8';
        }

        if (!!token) {
          headers['Authorization'] = `bearer ${token}`;
        }

        request = request.clone({
          url: url,
          setHeaders: headers,
        });

        return next.handle(request).pipe(catchError((err) => this.catchError(err, next)));
      }),
    );
  }

  private get genesys(): GenesysAppService {
    return this.injector.get(GenesysAppService);
  }

  private getApiBaseUrl(): string {
    const env = this.configuration.data.integration.genesys_settings.environment;
    return `https://api.${env}/api/v2/`;
  }

  private catchError = (response: HttpResponse<any>, next: HttpHandler) => {
    console.log(response);
    let error: HttpErrorResponse = null;

    if (response instanceof HttpErrorResponse) {
      error = <HttpErrorResponse>response;
    } else {
      return throwError(response);
    }

    let errorMessage = error.message;
    this.notifications.error(errorMessage);

    return throwError(errorMessage);
  };
}
