<form #form="ngForm" *ngIf="initialized" class="modal-form ticket-filter" novalidate>
  <div class="form-group from-group-sm validation-container" [class.has-error]="price | hasErrors: validating">
    <!-- <input class="form-control" type="text" [(ngModel)]="ticketNum" name="ticketNum" #ticketField="ngModel" required
            placeholder="Ticket number"> -->
    <label class="col-sm-3 control-label">Price</label>
    <kendo-dropdownlist
      [data]="prices"
      [(value)]="priceId"
      [textField]="'ValidationName'"
      [valueField]="'ValidationId'"
      class="dropdown-block"
      [valuePrimitive]="true"
      class="form-control-sm col-sm-9"
      name="price"
      required
    >
    </kendo-dropdownlist>
    <app-validation-errors [model]="price" [validate]="validating"></app-validation-errors>
  </div>

  <div class="form-group from-group-sm validation-container" [class.has-error]="date | hasErrors: validating">
    <label class="col-sm-3 control-label">Date</label>
    <kendo-datetimepicker class="form-control-sm col-sm-9" [readOnlyInput]='true' [(value)]="ticketDate" name="date" required> </kendo-datetimepicker>
    <app-validation-errors [model]="date" [validate]="validating"></app-validation-errors>
  </div>
</form>
