<div class="ibox__title">
  <h3 class="ibox__heading">
    Call Monitoring Scorecard
  </h3>

  <div class="header-tools">
    <div [hidden]="editing">
      <button class="button button--primary" (click)="showEdit()"><i class="fa fa-pencil"></i> Edit</button>
    </div>
  </div>
</div>

<div class="ibox__content">
  <div class="legend-required"><sup class="required">*</sup>Required</div>

  <form class="quality-analisys" #form="ngForm" (ngSubmit)="submit()">
    <div class="row">
      <div class="col-sm-6 col-sm-10">
        <div *ngFor="let question of questions" class="form-group form-group-sm">
          <label class="control-label" [class.has-error]="editing && !dynamicModel[question.Id] && question.Required && submitPressed">
            {{ question.Question }}
            <span class='info' style='font-style: italic'>{{ question.IsDeleted ? ' (This question has been deleted and cannot be modified.)' : '' }}</span>
            <sup *ngIf="question.Required && !question.IsDeleted " class="required">*</sup>
          </label>
          <ng-container [ngSwitch]="question.AnswerType">
            <ng-container *ngSwitchCase="'Integer'">
              <br />
              <div [ngClass]="question.IsDeleted && editing ? 'is-deleted-question-background' : ''">
                <div class="radio-label-vertical-wrapper">
                  <span class="radio-aside">{{ question.LeftBorder }}</span>
                  <label *ngFor="let answer of question.PossibleAnswersList" class="radio-label-vertical">
                    <input
                      type="radio"
                      [name]="question.QuestionTitle + answer"
                      [value]="answer"
                      [disabled]="!editing || question.IsDeleted"
                      [(ngModel)]="dynamicModel[question.Id]"
                      [required]="question.Required"
                    />
                    {{ answer }}
                  </label>
                  <span class="radio-aside">{{ question.RightBorder }}</span>
                </div>
              </div>
            </ng-container>

            <ng-container *ngSwitchCase="'String'">
              <div *ngIf='editing'>
                <input
                  type="text"
                  [(ngModel)]="dynamicModel[question.Id]"
                  class="form-control"
                  [name]="question.QuestionTitle"
                  placeholder="Your answer"
                  maxlength="1000"
                  [disabled]='question.IsDeleted'
                  [required]='question.Required'
                />
              </div>
              <p *ngIf="!editing" class="form-control-static">{{ dynamicModel[question.Id] }}</p>
            </ng-container>

            <ng-container *ngSwitchCase="'MultiChoice'">
              <div [ngClass]="question.IsDeleted && editing ? 'is-deleted-question-background' : ''">
                <div *ngFor="let answer of question.PossibleAnswersList" class="checkbox" >
                  <label>
                    <input
                      type="checkbox"
                      (click)="itemSelected(answer, question.Id)"
                      [checked]="this.dynamicModel[question.Id] ? this.dynamicModel[question.Id].includes(answer) : false"
                      [name]="question?.QuestionTitle + answer"
                      [disabled]="!editing || question.IsDeleted"
                      [required]="question.Required"
                    />
                    {{ answer }}
                  </label>
                </div>
              </div>
            </ng-container>

            <ng-container *ngSwitchDefault>
              <div [ngClass]="question.IsDeleted && editing ? 'is-deleted-question-background' : ''">
                <div *ngFor="let answer of question.PossibleAnswersList" class="radio">
                  <label>
                    <input
                      type="radio"
                      [name]="question.QuestionTitle + answer"
                      [value]="answer"
                      [disabled]="!editing || question.IsDeleted"
                      [(ngModel)]="dynamicModel[question.Id]"
                      [required]="question.Required"
                    />
                    {{ answer }}
                  </label>
                </div>
              </div>
            </ng-container>
          </ng-container>
          <p class='text-danger' *ngIf='editing && !dynamicModel[question.Id] && question.Required && submitPressed'>This field is required</p>
        </div>
      </div>
    </div>

    <div class='row' style="padding: 15px">
      <button [disabled]="!editing" type="submit" class="button ND--buttons--primary">Save</button>
      <button [hidden]="!editing" type="button" class="button button--margin-left ND--buttons--second-button" (click)="cancelEdit()"><i class="fa fa-disk"></i>Cancel</button>
    </div>

  </form>
</div>
