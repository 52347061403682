import { Injectable } from '@angular/core';

import { PermissionEntities, Permission, Access } from './authorization.service';

export interface RequiredPermissions {
  entities: PermissionEntities[];
  access: Access;
}

@Injectable()
export class AccessManagementService {
  private userPermissions = new Array<Permission>();
  private accessCache = new Map<string, boolean>();

  constructor() {
    const savedPermissions = localStorage.getItem('permissions');
    if (!!savedPermissions) {
      this.userPermissions = JSON.parse(savedPermissions);
    }
  }

  public init(permissions: Array<Permission>) {
    this.reset();
    this.userPermissions = permissions || [];

    localStorage.setItem('permissions', JSON.stringify(this.userPermissions));
  }

  public reset() {
    this.accessCache.clear();
    this.userPermissions = new Array<Permission>();
    localStorage.removeItem('permissions');
  }

  public hasAccessPermission(entity: PermissionEntities, access: Access): boolean {
    const cacheKey = JSON.stringify({ entity, access });
    if (this.accessCache.has(cacheKey)) {
      return this.accessCache.get(cacheKey);
    }

    let res = this.userPermissions.find((x) => x.Entity === entity && !!x[access]);
    this.accessCache.set(cacheKey, !!res);

    return !!res;
  }

  public hasAccessPermissions(permissions: RequiredPermissions): boolean {
    if (!permissions) {
      return true;
    }

    const entities: PermissionEntities[] = [...permissions.entities, 'All'];

    let res = entities.find((x) => this.hasAccessPermission(x, permissions.access));
    return !!res;
  }

  public isAdminRole(): boolean {
    return !!this.userPermissions.find((userPermission: Permission) => userPermission.fkRole === 1);
  }

  public getPermissionByEntity(entity: string): Permission {
    const adminPermission = this.userPermissions
      .find((userPermission: Permission) => userPermission.fkRole === 1
        || userPermission.Entity === 'All'
        || userPermission.Entity === 'ALL');
    return adminPermission ? adminPermission : this.userPermissions
      .find((userPermission: Permission) => userPermission.Entity === entity);
  }
}
