<table *ngIf="!busy">
  <thead>
    <tr>
      <th *ngFor="let header of headers; let i = index" [ngStyle]="{ width: headersWidth ? headersWidth[i] : '' }">
        {{ header }}
      </th>
    </tr>
  </thead>
  <div class="thead-divider"></div>
  <tbody *ngIf='contentList.length > 0'>
    <tr *ngFor="let item of contentList | orderBy: orderBy; let i = index">
      <ng-container *ngFor="let fieldKey of fieldKeyNames; let j = index">
        <td class="edit-btn" *ngIf="fieldKey === 'Edit'">
          <button class="btn btn-sm btn-no-border"
                  (click)="editBtnClicked.emit(item)">
            <i class="fa fa-pencil"></i>
          </button>
        </td>
        <td class="delete-btn" *ngIf="fieldKey === 'Delete'">
          <button class="btn btn-sm btn-no-border"
                  (click)="deleteBtnClicked.emit(item)">
            <i class="fa fa-trash"></i>
          </button>
        </td>

        <td *ngIf="fieldKey === 'Actions'">
          <div class='flex__box'>
            <button class="btn btn-sm btn-no-border"
                    (click)="editBtnClicked.emit(item)">
              <i class="fa fa-pencil"></i>
            </button>
            <button class="btn btn-sm btn-no-border"
                    (click)="deleteBtnClicked.emit(item)">
              <i class="fa fa-trash"></i>
            </button>
          </div>
        </td>

        <td *ngIf="fieldKey !== 'Edit' && fieldKey !== 'Delete' && fieldKey !== 'Actions'">
          <span [innerHTML]="item[fieldKey] | typeFieldExecutor"></span>
          <ng-container *ngIf="badge?.reverse">
            <span
              (click)="editBtnClicked.emit(item)"
              class="badge badge-danger table-badge"
              *ngIf="!item[badge?.fieldKeyTracker] && j === 0"
            >
              {{ badge.message }}
              <i class="fa fa-pencil"></i>
            </span>
          </ng-container>
          <ng-container *ngIf="!badge?.reverse">
            <span
              (click)="editBtnClicked.emit(item)"
              class="badge badge-danger table-badge"
              *ngIf="item[badge?.fieldKeyTracker] && j === 0"
            >
              {{ badge.message }}
              <i class="fa fa-pencil"></i>
            </span>
          </ng-container>
        </td>
      </ng-container>
    </tr>
  </tbody>
</table>
<app-no-content [isWhiteBackground]='true' *ngIf='contentList.length === 0'></app-no-content>

<div *ngIf="busy" class="loader">
  <i class="fa fa-spinner fa-spin fa-2x"></i>
</div>
