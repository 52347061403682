import { finalize } from 'rxjs/operators';
import { Component, HostBinding, Inject, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';

import { Subscription, zip } from 'rxjs';

import {
  AppConfigurationService,
  IdentityProvidersService,
  IdentityProvider,
  AuthService,
  APP_CONFIGURATION,
  IAppConfigurationBase,
  AppStateService,
  isAzureADProvider,
  ANALYTICS,
  IAnalyticsService,
} from '@libs/portal-common/services';
import { AppNotificationsService } from '@libs/portal-common/system';

const RENDERING_TIMEOUT = 300;
const EXTERNAL_LOGIN_ERROR =
  'Sign in with Microsoft\' was unsuccessful. Please give it another attempt, or opt to log in using your username and password. If the issue continues, feel free to get in touch with your system administrator for further assistance.';

@Component({
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  @ViewChild('loginForm') public loginForm: NgForm;

  isAzureADProvider = isAzureADProvider;
  heading = 'Login';
  error = '';

  validate = false;

  returnUrl: string;

  model = {
    username: '',
    password: '',
  };

  defaultLoginPage = this.configuration.data.applicationOptions.defaultLoginPage;
  defaultProvider: IdentityProvider;
  internalAuth = false;

  showPassword = false;
  isAuthenthicated = false;
  busy = false;

  @HostBinding('class') get class() {
    return this.isAuthenthicated || this.busy ? 'blank' : null;
  }

  private _routeParamsSubscription: Subscription;

  externalProviders: Array<IdentityProvider> = null;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private auth: AuthService,
    @Inject(APP_CONFIGURATION) private configuration: AppConfigurationService<IAppConfigurationBase>,
    private notifications: AppNotificationsService,
    private providersService: IdentityProvidersService,
    private appState: AppStateService,
    @Inject(ANALYTICS) private analytcis: IAnalyticsService,
    private ngZone: NgZone,
  ) {}

  async ngOnInit() {
    this.busy = true;
    this.isAuthenthicated = this.appState.isAuthenticated;
    this.appState.isAuthenticated$.subscribe((x) => (this.isAuthenthicated = x));

    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    let state = this.route.snapshot.queryParams['state'];
    if (!!state) {
      this.returnUrl = atob(state);
    }

    let providers = this.providersService.getAllInfo();
    let routeQueryParams = this.route.queryParams;
    let routeUrl = this.route.url;
    this._routeParamsSubscription = zip(routeQueryParams, providers, routeUrl).subscribe((result) => {
      let params = result[0];
      let response = result[1];
      this.internalAuth = !!result[2].find((s) => s.path === 'internal');
      if (response.Success) {
        this.externalProviders = response.Data.filter((p) => p.IsEnabled);
        this.defaultProvider = this.externalProviders.find((p) => p.Name === this.defaultLoginPage);
      }

      this.error = params['error'] || null;

      if (this.error) {
        this.busy = false;
        return;
      }

      if (params['externalLogin'] && params['provider'] && params['code']) {
        this.externalLogin(params['externalLogin'], params['provider'], params['code']);
      } else if (params['registration'] && params['provider'] && params['key']) {
        this.registerAuth(params['provider'], params['key']);
      } else {
        this.busy = false;
      }
    });
  }

  ngOnDestroy() {
    this._routeParamsSubscription.unsubscribe();
  }

  setInternal($event) {
    $event.preventDefault();
    this.internalAuth = true;
  }

  public async login() {
    this.validate = true;
    if (!this.loginForm.valid) {
      return;
    }

    this.error = '';

    this.auth
      .login({ username: this.model.username, password: this.model.password })
      .pipe(finalize(() => (this.busy = false)))
      .subscribe(
        (res) => {
          if (res.Success) {
            this.isAuthenthicated = true;
            this.analytcis.trackLogin(this.model.username);
            this.router.navigateByUrl(this.returnUrl);
          } else {
            this.error = res.Message;
          }
        },
        (err) => {
          this.error = "Login details not recognized. Please ensure you've entered the correct username and password.";
        },
      );
  }

  public async externalAuth(provider) {
    const urlParams = new URLSearchParams(window.location.search);
    const returnUrl = urlParams.get('returnUrl');
    let authUrl = this.configuration.apiBaseUrl + '/auth/' + provider;
    if (!!returnUrl) {
      authUrl += '?state=' + btoa(returnUrl);
    }

    console.log('Logging in by using ' + provider + '...', 'url', authUrl);
    window.location.href = authUrl;
  }

  // Registration session
  public async registerAuth(provider, key) {
    console.log('Registering by using ' + provider + '...');
    window.location.href = this.configuration.apiBaseUrl + '/auth/' + provider + '?key=' + key;
  }

  // Perform external authentication for already authenticated user session on the server
  public async externalLogin(userId, provider, code) {
    console.log('External login in...');

    this.error = '';
    this.auth
      .externalLogin(userId, provider, code)
      .pipe(finalize(() => setTimeout(() => this.ngZone.run(() => (this.busy = false)), RENDERING_TIMEOUT)))
      .subscribe(
        (response) => {
          if (response.Success) {
            this.analytcis.trackLogin(userId);
            this.router.navigateByUrl(this.returnUrl);
          } else {
            this.error = EXTERNAL_LOGIN_ERROR;
          }
        },
        (err) => {
          this.error = EXTERNAL_LOGIN_ERROR;
          console.log(err);
        },
      );
  }
}
