<nav class="flex__box flex__fluid flex__box--column">
  <a *ngIf="hasImage" class="brand has-image" href="#">
    <div class="brand__name" style="text-align: center">
      <img src="{{ logoImage }}" class="img-fluid logo-img btn-block" />
    </div>
    <div class="menu__button" style="color: #333">{{ companyNameShortcut }}</div>
  </a>
  <a *ngIf="hasImage == false" class="brand" href="#">
    <h3 class="brand__name">{{ companyName }}</h3>
    <div class="menu__button">
      {{ companyNameShortcut }}
    </div>
  </a>

  <div class="nav-content">
    <ng-scrollbar class="app-scrollbar" track="all">
      <ul class="nav" @insertRemoveNavList *ngIf="!_isMenuCollapsed">
        <li *ngFor="let route of routes" routerLinkActive="active">
          <a
            *ngIf="route.childs == null"
            [routerLink]="[route.route]"
            [tooltip]="route.displayName"
            [placement]="'right'"
            [tooltipAppendToBody]="true"
            [tooltipEnable]="!_isMenuCollapsed"
            [routerLinkActive]="'active-link--bold'"
          >
            <img style="margin-right: 10px" [src]="route.icon" *ngIf="!!route.icon" alt="'icon'" />
            <span class="left-nav__link">{{ route.displayName }}</span>
          </a>

          <!-- Multilevel anchor -->
          <a
            class="au-target"
            *ngIf="route.childs != null"
            [attr.aria-expanded]="!isCollapsed"
            href="javascript:void(0)"
            [attr.aria-controls]="route.id"
            (click)="navigationsCollapsed[route.id] = !navigationsCollapsed[route.id]"
            [routerLinkActive]="'active-link--bold'"
            tooltip="{{ route.displayName }}"
            [placement]="'right'"
            [tooltipAppendToBody]="true"
            [tooltipEnable]="!_isMenuCollapsed"
          >
            <img style="margin-right: 10px" [src]="route.icon" *ngIf="!!route.icon" alt="'icon'" />
            <span class="left-nav__link">{{ route.displayName }}</span>
            <i [ngClass]="!!navigationsCollapsed[route.id] ? 'fa-angle-down' : 'fa-angle-up'" class="fa right" style="margin-top: 3px"></i>
          </a>

          <ul
            *ngIf="route.childs != null"
            class="nav nav__second-level collapsed-block"
            id="{{ route.id }}"
            [collapse]="navigationsCollapsed[route.id]"
            (collapsed)="setCollapsedHeight(el, 0)"
            (expanded)="setCollapsedHeight(el, 0); setCollapsedHeight(el, el.scrollHeight)"
            #el
          >
            <li *ngFor="let childRoute of route.childs" routerLinkActive="active-link">
              <a
                [routerLink]="[childRoute.route]"
                [routerLinkActive]="'active-link--bold'"
                class="external-link"
                [tooltip]="childRoute.displayName"
                [placement]="'right'"
                [tooltipAppendToBody]="true"
                [tooltipEnable]="!_isMenuCollapsed"
              >
                <img style="margin-right: 10px" [src]="childRoute.icon" *ngIf="!!childRoute.icon" alt="'icon'" />
                <span [ngStyle]="{ 'margin-left': !childRoute.icon ? '26px' : '0' }" class="left-nav__link">{{
                  childRoute.displayName
                }}</span>
              </a>
            </li>
          </ul>
        </li>
      </ul>
    </ng-scrollbar>
  </div>
</nav>
<div class="left-nav__disclaimer left-nav_hidden-closed">Powered by Umojo © {{ currentYear }}</div>
