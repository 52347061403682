<div>
  <div class="page__heading spinner__container">
    <h3 class="ND--titles--page-subtitle">Incident - {{ incidentId }}</h3>

    <div class="page__heading__tools">
      <button class="button ND--buttons--second-button" (click)="back()">Back to Directory</button>
    </div>
  </div>
</div>

<div class="flex__box flex__box--row flex__fluid">
  <div class="left-nav light-nav">
    <ul class="nav">
      <li *ngFor="let item of routes" routerLinkActive="active">
        <a [routerLink]="[item.path]">{{ item.title }}</a>
      </li>
    </ul>
  </div>

  <div class="secondary-view flex__box flex__fluid uj-container">
    <router-outlet></router-outlet>
  </div>
</div>
