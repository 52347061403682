import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';

import { Observable, of } from 'rxjs';

import { ANALYTICS, BaseResponse, IAnalyticsService, IValidationDiscount, RevenueControlService } from '@libs/portal-common/services';
import { AppNotificationsService } from '@libs/portal-common/system';

import { IncomingCall } from '../../../../../../abstractions';
import { IRevenueForm } from '../../revenue-form-factory.service';
import { AgentAnalyticsEvent } from '../../../../../../services';

interface ITibaTicket {
  Id: number;
  TotalDiscounts: number;
  TicketNum: number;
  EntryDateTime: number;
  LicensePlate: string;
}

@Component({
  selector: 'app-ticket-info-tiba',
  templateUrl: './ticket-info-tiba.component.html',
  styleUrls: ['./ticket-info-tiba.component.scss'],
})
export class TicketInfoTibaComponent implements IRevenueForm, OnInit {
  @ViewChild('form', { static: false }) public form: NgForm;

  incomingCall: IncomingCall;

  ticketNum: number = null;

  tickets: Array<ITibaTicket> = [];
  selectedTicket: ITibaTicket = null;

  prices = new Array<IValidationDiscount>();
  priceId: string = null;

  busy = false;

  validating = false;
  initialized = false;

  public validationEnabled = false;
  provider: string;

  constructor(
    private revenueControl: RevenueControlService,
    private notifications: AppNotificationsService,
    @Inject(ANALYTICS) private analytics: IAnalyticsService,
  ) {}

  ngOnInit() {
    this.analytics.track(AgentAnalyticsEvent.RevenueFormOpen, { Provider: this.provider, RCForm: 'ShowTicketForm' });
  }

  init(provider: string, incomingCall: IncomingCall, formParams: any): void {
    this.validationEnabled = !!formParams ? !!formParams.validationEnabled : false;
    this.validating = false;
    this.initialized = false;

    this.incomingCall = incomingCall;

    this.tickets = [];
    this.selectedTicket = null;

    this.prices = [];
    this.priceId = null;

    this.ticketNum = null;
    this.provider = provider;

    setTimeout(() => (this.initialized = true), 30);

    this.revenueControl.getValidationDiscounts({ LotId: this.incomingCall.location.Id, LaneId: this.incomingCall.lane.Id }).subscribe(
      (res) => {
        if (!res.Success) {
          this.notifications.error(res.Message);
          return;
        }

        this.prices = res.ValidationDiscounts;

        if (this.prices.length > 0) {
          this.priceId = this.prices[0].ValidationId;
        }
      },
      (err) => {
        this.notifications.error(err.Message || 'Error');
      },
    );
  }

  validate(): boolean {
    return true;
  }

  canSubmit(): boolean {
    return !!this.selectedTicket;
  }

  submit(): Observable<BaseResponse> {
    if (!this.validationEnabled) {
      return of(null);
    }

    this.analytics.track(AgentAnalyticsEvent.RevenueFormExecute, { Provider: this.provider, RCActionType: 'rc_show_ticket_search' });
    return this.revenueControl.validateTicket({
      LotId: this.incomingCall.location.Id,
      LaneId: this.incomingCall.lane.Id,
      CommandParams: {
        __type: 'grm.service.model.RevenueControl.ValidateTicketCommandParams, GRM.Service.Model',
        PriceId: this.priceId,
        Ticket: this.selectedTicket.Id,
      },
    });
  }

  showTicketInfo() {
    this.validating = true;
    if (!this.form.valid) {
      return;
    }

    let request = {
      LotId: this.incomingCall.location.Id,
      LaneId: this.incomingCall.lane.Id,
      CommandParams: {
        __type: 'grm.service.model.RevenueControl.TicketCommandParams, GRM.Service.Model',
        Ticket: this.ticketNum * 1,
      },
    };

    this.busy = true;
    this.tickets = [];
    this.selectedTicket = null;
    this.revenueControl.showTicketInfo(request).subscribe(
      (response) => {
        this.busy = false;

        if (!response.Success) {
          this.notifications.error(response.Message || 'Failed fetching ticket info.');
          return;
        }

        this.tickets = response.TibaTicket;
      },
      (err) => {
        this.notifications.error(err.message || 'Failed fetching ticket info.');
        this.busy = false;
      },
    );
  }

  select(ticket: ITibaTicket) {
    this.selectedTicket = ticket;
  }
}
