import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap, map, filter, flatMap } from 'rxjs/operators';

import { PubSubService } from '@libs/portal-common/system';

import { HttpClient } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class LotIssuesService {
  constructor(
    private _httpClient: HttpClient,
    private _pubSubService: PubSubService,
  ) {}

  // Get all LotIssues for the specified problem
  getAllForProblem(problemId): Observable<any> {
    return this._httpClient.get<any>('LotIssues?ProblemId=' + problemId);
  }

  // Get all LotIssues for the specified lot
  getAllForLot(lotId): Observable<any> {
    return this._httpClient.get('LotIssues?LotId=' + lotId);
  }

  // Get a LotIssue
  get(lotIssueId): Observable<any> {
    return this._httpClient.get<any>('LotIssues/' + lotIssueId);
  }

  // Create a LotIssue
  create(lotIssue): Observable<any> {
    return this._httpClient
      .put<any>('LotIssues/', { lotIssue })
      .pipe(tap((response) => this._pubSubService.$pub('lotIssuesChanged', lotIssue.Id)));
  }

  // Update a LotIssue
  update(lotIssue): Observable<any> {
    return this._httpClient
      .put<any>('LotIssues/', { lotIssue })
      .pipe(tap((response) => this._pubSubService.$pub('lotIssuesChanged', lotIssue.Id)));
  }

  // Delete a LotIssue
  delete(lotIssueId): Observable<any> {
    return this._httpClient
      .delete<any>('LotIssues/' + lotIssueId)
      .pipe(tap((response) => this._pubSubService.$pub('lotIssuesChanged', lotIssueId)));
  }
}
