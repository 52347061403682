import { Injectable, Type } from '@angular/core';
import { Observable } from 'rxjs';

import { BaseResponse } from '@libs/portal-common/services';

import { IncomingCall } from '../../../../abstractions';

import { OpenGateBaseComponent } from './base/open-gate-base/open-gate-base.component';
import { CloseGateBaseComponent } from './base/close-gate-base/close-gate-base.component';
import { EjectTicketBaseComponent } from './base/eject-ticket-base/eject-ticket-base.component';
import { SwallowTicketBaseComponent } from './base/swallow-ticket-base/swallow-ticket-base.component';
import { SetPriceBaseComponent } from './base/set-price-base/set-price-base.component';
import { LostTicketBaseComponent } from './base/lost-ticket-base/lost-ticket-base.component';
import { TicketInfoBaseComponent } from './base/ticket-info-base/ticket-info-base.component';

import { LostTicketFlashParcsComponent } from './flash-parcs/lost-ticket-flash-parcs/lost-ticket-flash-parcs.component';
import { TicketInfoFlashParcsComponent } from './flash-parcs/ticket-info-flash-parcs/ticket-info-flash-parcs.component';
import { TicketInfoTibaComponent } from './tiba/ticket-info-tiba/ticket-info-tiba.component';

export interface IRevenueForm {
  init(provider: string, incomingCall: IncomingCall, formParams: any): void;
  validate(): boolean;

  submit(): Observable<BaseResponse>;
  canSubmit(): boolean;
}

const FlashParcs = 'FlashParcs';
const Tiba = 'TIBA';

export class RevenueFormInfo {
  okButtonText = 'OK';
  cancelButtonText = 'Cancel';
  closeOnSuccess = true;
  formParams: any = null;

  constructor(
    public type: Type<IRevenueForm>,
    public provider: string,
    public title: string,
    public successMessage: string = 'Success',
  ) {}
}

@Injectable()
export class RevenueFormFactoryService {
  constructor() {}

  openGateForm(provider: string): RevenueFormInfo {
    return new RevenueFormInfo(OpenGateBaseComponent, provider, 'Vend', 'Vended');
  }

  closeGateForm(provider: string): RevenueFormInfo {
    return new RevenueFormInfo(CloseGateBaseComponent, provider, 'Close Gate', 'Closed');
  }

  ejectTicketForm(provider: string): RevenueFormInfo {
    return new RevenueFormInfo(EjectTicketBaseComponent, provider, 'Eject Ticket', 'Ejected');
  }

  swallowTicketForm(provider: string): RevenueFormInfo {
    return new RevenueFormInfo(SwallowTicketBaseComponent, provider, 'Swallow Ticket', 'Swallowed');
  }

  setPriceTicketForm(provider: string): RevenueFormInfo {
    return new RevenueFormInfo(SetPriceBaseComponent, provider, 'Set Price', 'Price set');
  }

  lostTicketForm(provider: string): RevenueFormInfo {
    let type: Type<IRevenueForm>;
    switch (provider) {
      case FlashParcs: {
        type = LostTicketFlashParcsComponent;
        break;
      }

      default: {
        type = LostTicketBaseComponent;
        break;
      }
    }

    return new RevenueFormInfo(type, provider, 'Lost Ticket', 'Lost ticket set.');
  }

  ticketInfoForm(provider: string): RevenueFormInfo {
    let type: Type<IRevenueForm>;
    switch (provider) {
      case FlashParcs: {
        type = TicketInfoFlashParcsComponent;
        break;
      }

      case Tiba: {
        type = TicketInfoTibaComponent;
        break;
      }

      default: {
        type = TicketInfoBaseComponent;
        break;
      }
    }

    const res = new RevenueFormInfo(type, provider, null, 'Show ticket info success.');

    res.okButtonText = null;
    res.cancelButtonText = 'Close';

    return res;
  }

  validateTicketForm(provider: string): RevenueFormInfo {
    if (provider === FlashParcs) {
      const res = new RevenueFormInfo(TicketInfoFlashParcsComponent, null, 'Ticket validation success.');

      res.okButtonText = 'Validate';
      res.cancelButtonText = 'Close';
      res.closeOnSuccess = false;
      res.formParams = { validationEnabled: true };

      return res;
    } else if (provider === Tiba) {
      const res = new RevenueFormInfo(TicketInfoTibaComponent, provider, null, 'Ticket validation success.');

      res.okButtonText = 'Validate';
      res.cancelButtonText = 'Close';
      res.closeOnSuccess = false;
      res.formParams = { validationEnabled: true };

      return res;
    }

    throw Error(`Method not implemented for provider: ${provider}`);
  }

  executeActionForm(provider: string): RevenueFormInfo {
    throw Error(`Method not implemented for provider: ${provider}`);
  }
}
