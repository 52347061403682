import { Component, OnInit, AfterContentInit, QueryList, Output, EventEmitter, ContentChildren, Input } from '@angular/core';
import { finalize } from 'rxjs/operators';

import { WizardStepComponent } from './wizard-step/wizard-step.component';

@Component({
  selector: 'app-wizard',
  templateUrl: './wizard.component.html',
  styleUrls: ['./wizard.component.scss'],
})
export class WizardComponent implements AfterContentInit {
  @ContentChildren(WizardStepComponent)
  wizardSteps: QueryList<WizardStepComponent>;

  private _steps: Array<WizardStepComponent> = [];
  private _isCompleted = false;

  @Input() nextButtonText = 'Next';

  @Output()
  stepChanged: EventEmitter<WizardStepComponent> = new EventEmitter<WizardStepComponent>();

  busy = false;

  constructor() {}

  ngAfterContentInit() {
    this.wizardSteps.forEach((step) => this._steps.push(step));
    this.steps[0].isActive = true;
  }

  get steps(): Array<WizardStepComponent> {
    return this._steps.filter((step) => !step.hidden);
  }

  get isCompleted(): boolean {
    return this._isCompleted;
  }

  get activeStep(): WizardStepComponent {
    return this.steps.find((step) => step.isActive);
  }

  set activeStep(step: WizardStepComponent) {
    if (step !== this.activeStep && !step.isDisabled) {
      this.activeStep.isActive = false;
      step.isActive = true;
      this.stepChanged.emit(step);
    }
  }

  public get activeStepIndex(): number {
    return this.steps.indexOf(this.activeStep);
  }

  get hasNextStep(): boolean {
    return this.activeStepIndex < this.steps.length - 1;
  }

  get hasPrevStep(): boolean {
    return this.activeStepIndex > 0;
  }

  get allStepsCompleted(): boolean {
    return !this.steps.find((step) => !(step.isValid && step.completed));
  }

  public goToStep(step: WizardStepComponent): void {
    if (!this.isCompleted && !this.busy) {
      this.activeStep = step;
    }
  }

  public next(): void {
    if (this.hasNextStep) {
      let nextStep: WizardStepComponent = this.steps[this.activeStepIndex + 1];

      this.busy = true;
      this.activeStep
        .validateFunc()
        .pipe(finalize(() => (this.busy = false)))
        .subscribe((res) => {
          if (res) {
            this.activeStep.next.emit();
            nextStep.isDisabled = false;
            this.activeStep = nextStep;
          }
        });
    }
  }

  public previous(): void {
    if (this.hasPrevStep) {
      let prevStep: WizardStepComponent = this.steps[this.activeStepIndex - 1];
      this.activeStep.prev.emit();
      prevStep.isDisabled = false;
      this.activeStep = prevStep;
    }
  }

  public complete(): void {
    this.busy = true;
    this.activeStep
      .validateFunc()
      .pipe(finalize(() => (this.busy = false)))
      .subscribe((res) => {
        if (res) {
          this.activeStep.complete.emit();
          // this._isCompleted = true;
        }
      });
  }
}
