import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

import { Context } from '@microsoft/teams-js';

import { Observable, of, interval, from } from 'rxjs';
import { first, tap, timeout, catchError, map, flatMap } from 'rxjs/operators';

@Injectable()
export class TeamsIntegrationService {
  private context: Context = null;

  private teamsPromise: Promise<any>;

  private teams$: Observable<any>;
  private teams: any = null;

  public get isInitialized(): boolean {
    return !!this.context;
  }

  constructor(@Inject(PLATFORM_ID) private platformId: Object) {
    if (isPlatformBrowser(this.platformId)) {
      this.teams$ = this.initTeams();
    } else {
      this.teams$ = interval(1000).pipe(
        map((tik) => isPlatformBrowser(this.platformId)),
        first((isBrowser) => isBrowser),
        flatMap((res) => this.initTeams()),
      );
    }
  }

  public getContext(): Observable<Context> {
    return this.teams$.pipe(
      flatMap((teams) => {
        return new Observable<Context>((observer) => {
          teams.getContext((context) => {
            this.context = context;

            observer.next(context);
            observer.complete();
          });

          return () => {};
        });
      }),
      first(),
      tap((context) => {
        this.context = context;
      }),
      timeout(2500), // 2.5 seconds timeout to initialize teams app
      catchError((err) => {
        console.log('Error getting teams context', err);
        throw new Error('Error getting teams context');
      }),
    );
  }

  public startTask(taskInfo: microsoftTeams.TaskInfo, submitHandler?: (err: string, result: string) => void) {
    if (!!this.teams) {
      this.teams.tasks.startTask(taskInfo, submitHandler);
    }
  }

  public submitTask(result: any) {
    console.log('Submit Task called.');
    if (!!this.teams) {
      this.teams.tasks.submitTask(result);
    }
  }

  private initTeams(): Observable<any> {
    this.teamsPromise =
      this.teamsPromise ||
      import('@microsoft/teams-js').then((microsoftTeams) => {
        microsoftTeams.initialize();
        this.teams = microsoftTeams;
        return microsoftTeams;
      });

    return from(this.teamsPromise);
  }
}
