export class Issue {
  Id: number;
  fkProblem?: number;
  SortOrder: number;

  RuleDescription: string;
  IssueName: string;
  Fields: Array<any> = [];
  FieldsJson: string;

  IsActive: boolean;
  ShowInPage: boolean;
  IsNotify: boolean;
  GateVend: boolean;
  PowerAutomate: boolean;

  hasIncident: boolean;
}
