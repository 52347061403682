import { Component, OnInit, HostBinding, ViewChild } from '@angular/core';

import { ModalDirective } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-picture-details',
  templateUrl: './picture-details.component.html',
  styleUrls: ['./picture-details.component.scss'],
})
export class PictureDetailsComponent implements OnInit {
  @ViewChild('modal', { static: true }) public modal: ModalDirective;

  imageUrl = '';

  constructor() {}

  ngOnInit() {}

  open(imageUrl: string) {
    this.imageUrl = imageUrl;
    this.modal.show();
  }

  cancel() {
    this.modal.hide();
  }

  onImageError() {
    this.imageUrl = '/img/no-image.png';
  }
}
