import { BrowserModule, BrowserTransferStateModule } from '@angular/platform-browser';
import { LOCATION_INITIALIZED } from '@angular/common';
import { NgModule, Injector, APP_INITIALIZER } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { UtilsModule } from '@progress/kendo-angular-utils';
import { DragAndDropModule } from '@progress/kendo-angular-utils';

import { ModalModule } from 'ngx-bootstrap/modal';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { ButtonsModule } from 'ngx-bootstrap/buttons';

import { SystemModule } from '@libs/portal-common/system';
import { SharedModule } from '@libs/portal-common/shared';
import { AUTH_EXTENSIONS, ServicesModule as PortalCommonServices } from '@libs/portal-common/services';
import { NavigationModule } from '@libs/portal-common/navigation';
import { GoogleModule } from '@libs/portal-common/google';
import { MonitoringModule } from '@libs/portal-common/monitoring';

import { ServicesModule, AppConfigurationService, APP_CONFIGURATION, AuthExtensionsService } from './services';
import { SkypeClientModule } from './skype-client/skype-client.module';
import { AppRoutingModule } from './app-routing.module';

import { Five9Module } from './integrations/five9';
import { FinesseModule } from './integrations/cisco-finesse';
import { CC4AllModule } from './integrations/cc4all';
import { GenesysModule } from './integrations/genesys';
import { NoIntegrationModule } from './integrations/no-integration';

import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { SidebarNavComponent } from './navigation/components/sidebar-nav/sidebar-nav.component';
import { NgScrollbarModule } from 'ngx-scrollbar';

export function getBaseUrl() {
  return document.getElementsByTagName('base')[0].href;
}

export function loadConfig(injector: Injector, config: AppConfigurationService) {
  return () =>
    new Promise((resolve) => {
      const locationInitialized = injector.get(LOCATION_INITIALIZED, Promise.resolve(null));

      locationInitialized.then(() => {
        config.load().then((res) => resolve(res));
      });
    });
}

@NgModule({
  declarations: [AppComponent, SidebarNavComponent, HomeComponent],
  imports: [
    BrowserAnimationsModule,
    BrowserModule.withServerTransition({ appId: 'app-root' }),
    BrowserTransferStateModule,
    FormsModule,

    UtilsModule,
    DragAndDropModule,
    NgScrollbarModule,

    SharedModule,
    PortalCommonServices.forRoot(),
    ServicesModule.forRoot(),
    SystemModule.forRoot(),
    NavigationModule,
    GoogleModule.forRoot(),
    MonitoringModule,

    ModalModule.forRoot(),
    TooltipModule.forRoot(),
    AccordionModule.forRoot(),
    TabsModule.forRoot(),
    CarouselModule.forRoot(),
    ButtonsModule.forRoot(),
    SkypeClientModule.forRoot(),

    Five9Module.forRoot(),
    FinesseModule.forRoot(),
    CC4AllModule.forRoot(),
    NoIntegrationModule.forRoot(),
    GenesysModule.forRoot(),

    AppRoutingModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: loadConfig,
      deps: [Injector, APP_CONFIGURATION],
      multi: true,
    },
    { provide: 'BASE_URL', useFactory: getBaseUrl, deps: [] },
    { provide: AUTH_EXTENSIONS, useClass: AuthExtensionsService },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
