import { Injectable, InjectionToken, Injector } from '@angular/core';
import { HttpBackend, HttpClient, HttpInterceptor } from '@angular/common/http';

import { CustomHttpHandler } from '../../custom-http-handler';

export const GENESYS_HTTP_INTERCEPTORS = new InjectionToken<HttpInterceptor[]>('GENESYS_HTTP_INTERCEPTORS');

@Injectable()
export class GenesysHttpClient extends HttpClient {
  constructor(
    backend: HttpBackend,
    private injector: Injector,
  ) {
    super(new CustomHttpHandler(backend, injector, GENESYS_HTTP_INTERCEPTORS));
  }
}
