<div class="modal fade modal-dark" bsModal #modal="bs-modal" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header clearfix">
        <button type="button" class="close pull-right" (click)="cancel()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body">
        <div *ngIf="rootGroup">
          <div class="form-horizontal search-form">
            <div class="input-group">
              <input type="text" class="form-control" [(ngModel)]="phoneNumber" placeholder="Phone number" />
              <span class="input-group-btn">
                <button class="btn btn-ok" type="button" [disabled]="!phoneNumber" (click)="callPhone()">
                  <i class="fa fa-lg fa-phone"></i>
                </button>
              </span>
            </div>

            <div class="input-group">
              <input
                type="text"
                class="form-control"
                [ngModel]="searchString$ | async"
                (input)="searchString$.next($event.target.value)"
                placeholder="Search"
              />
              <span class="input-group-btn">
                <button class="btn btn-cancel" type="button" [disabled]="!(searchString$ | async)" (click)="searchString$.next(null)">
                  <i class="fa fa-lg fa-times"></i>
                </button>
              </span>
            </div>
          </div>

          <div class="contacts">
            <div *ngFor="let contact of rootGroup.persons$ | async">
              <app-skype-contact
                [person]="contact"
                [avatarUrl]="contact.avatarUrl$ | async"
                (click)="callContact(contact)"
              ></app-skype-contact>
            </div>
          </div>

          <!-- <div class="groups">
            <div *ngFor="let group of (rootGroup.groups$ | async)">
              <app-skype-group [group]="group"></app-skype-group>
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</div>
