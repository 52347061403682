import { NgModule, ModuleWithProviders } from '@angular/core';

import { CC4AllAppService } from './services/cc4all-app.service';

@NgModule({})
export class CC4AllModule {
  static forRoot(): ModuleWithProviders<CC4AllModule> {
    return {
      ngModule: CC4AllModule,
      providers: [CC4AllAppService],
    };
  }

  constructor() {}
}
