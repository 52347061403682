import { Component, OnInit, Input, OnDestroy, ViewChild, Inject } from '@angular/core';

import { Subscription } from 'rxjs';

import { NavigationService } from '../../../services';
import { APP_CONFIGURATION, AppConfigurationService } from '../../../../services';
import { PictureDetailsComponent } from './picture-details/picture-details.component';
import { AgentAnalyticsEvent } from '../../../../services';
import { ANALYTICS, IAnalyticsService } from '@libs/portal-common';

@Component({
  selector: 'app-equipment',
  templateUrl: './equipment.component.html',
  styleUrls: ['./equipment.component.scss'],
})
export class EquipmentComponent implements OnInit, OnDestroy {
  @ViewChild('pictureDetailsModal', { static: true }) public pictureDetailsModal: PictureDetailsComponent;

  private subscription: Subscription;

  baseUrl = this.configuration.apiBaseUrl;

  images = new Array<any>();

  constructor(
    private navService: NavigationService,
    @Inject(APP_CONFIGURATION) private configuration: AppConfigurationService,
    @Inject(ANALYTICS) private analytics: IAnalyticsService,
  ) {}

  ngOnInit() {
    this.subscription = this.navService.incomingCall$.subscribe((incomingCall) => {
      const info = !!incomingCall ? incomingCall.locationInfo : null;
      if (!!info) {
        let images = info && info.Lot && info.Lot.Equipment ? JSON.parse(info.Lot.Equipment) : [];
        images.forEach((image) => {
          image.imageUrl = this.imageUrl(image.src, info.Lot.LotNumber);
          image.thumbUrl = this.thumbUrl(image.imageUrl);
        });

        this.images = images;
      } else {
        this.images = [];
      }
    });

    this.analytics.track(AgentAnalyticsEvent.PicturesClicked, null);
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
      this.subscription = null;
    }
  }

  openDetails(imageUrl) {
    this.pictureDetailsModal.open(imageUrl);
  }

  private imageUrl(src: string, lotNumber: number): string {
    return `${this.baseUrl}/locations/${lotNumber}/images/${src}`;
  }

  private thumbUrl(imageUrl: string): string {
    let lastDotIndex = imageUrl.lastIndexOf('.');
    let fileExtension = imageUrl.substr(lastDotIndex + 1);
    let srcWithoutFileExtension = imageUrl.substr(0, lastDotIndex);

    return `${srcWithoutFileExtension}-thumb.${fileExtension}`;
  }

  private onThumbError(image) {
    if (image.thumbUrl === image.imageUrl) {
      image.thumbUrl = '/img/no-image.png';
      return;
    }

    image.thumbUrl = image.imageUrl;
  }
}
