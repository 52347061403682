import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Router } from '@angular/router';

import { BehaviorSubject, from, Observable, of, Subject, zip, Subscription } from 'rxjs';
import { map, filter, tap } from 'rxjs/operators';

import * as platformClient from 'purecloud-platform-client-v2';

import { ANALYTICS, AppNotificationsService, IAnalyticsService } from '@libs/portal-common';

import { CallState, ICall, IConversation, IIntegrationAppService, IOutgoingConversation } from '../../../abstractions';

import { AppConfigurationService, APP_CONFIGURATION } from '../../../services/app-configuration.service';
import { GenesysConversation } from '../genesys-conversation';

import { IGenesysUser } from '../model';
import { AppWebSocket } from '../../app-web-socket';
import { GenesysEmbeddedService } from './genesys-embedded.service';
import { AgentAnalyticsEvent } from '../../../services';

export interface IGenesysCall extends ICall {
  conversation: platformClient.Models.ConversationEventTopicConversation;
}

@Injectable()
export class GenesysAppService implements IIntegrationAppService {
  private token: string = null;

  private client: platformClient.ApiClientClass;
  private usersApi: platformClient.UsersApi;
  private conversationsApi: platformClient.ConversationsApi;
  private notificationsApi: platformClient.NotificationsApi;
  private presenceApi: platformClient.PresenceApi;
  private notificationChannel: platformClient.Models.Channel;
  private routingApi: platformClient.RoutingApi;

  private webSocket: AppWebSocket;
  private webSocketSubscription: Subscription = null;

  private conversationsTopic: string;
  private presenceTopic: string;

  private presenceDefinitions: Array<{ id: string; name: string }> = [];

  private _isInitialized$ = new BehaviorSubject<boolean>(false);
  private _isAuthenticated$ = new BehaviorSubject<boolean>(false);
  private _user$ = new BehaviorSubject<IGenesysUser>(null);
  private _calls$ = new BehaviorSubject<{ [key: string]: IGenesysCall }>({});

  public get user(): IGenesysUser {
    return this._user$.value;
  }
  public get user$(): Observable<IGenesysUser> {
    return this._user$.asObservable();
  }

  public get calls$(): Observable<{ [key: string]: IGenesysCall }> {
    return this._calls$.asObservable();
  }

  public get name(): string {
    return 'Genesys';
  }

  constructor(
    @Inject(ANALYTICS) public analytcis: IAnalyticsService,
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(APP_CONFIGURATION) private configuration: AppConfigurationService,
    private notifications: AppNotificationsService,
    private embedded: GenesysEmbeddedService,
    private router: Router,
  ) {
    if (isPlatformBrowser(platformId)) {
      if (configuration.data.integration.genesys_settings.embedded) {
        this.embedded.token$
          .pipe(
            tap((token) => {
              if (!token) {
                this.dispose();

                if (this.token) {
                  this.setAwayStatus().subscribe((x) => {
                    console.log('[UMOJO] set AWAY status success', (err) => console.log('[UMOJO] set away status', err));
                  });
                }
              }

              this.token = token;
            }),
            filter((token) => !!token),
          )
          .subscribe((token) => this.initialize(token));
      } else {
        this.initialize();
      }
    }
  }

  public get isAuthenticated$(): Observable<boolean> {
    return this._isAuthenticated$.asObservable();
  }
  public get initialized$(): Observable<boolean> {
    return this._isInitialized$.asObservable();
  }

  public get outgoingCall$(): Observable<IOutgoingConversation> {
    return of(null);
  }

  signIn(username: string, password: string): Observable<boolean> {
    throw new Error('Method not implemented.');
  }
  signInAAD(): Observable<boolean> {
    return this.genesysSignIn().pipe(map((x) => !!x));
  }
  signOut(): Observable<any> {
    if (this.configuration.data.integration.type === 'genesys') {
      this._user$.next(null);
      this.client?.logout('/');
      return of(true);
    }

    return of(false);
  }

  getAuthToken(): Observable<string> {
    return of(null);
  }

  finishCall(callId: string, participantId: string, communicationId: string): Observable<void> {
    return from(this.disconnect(callId, participantId, communicationId));
  }

  disposeCall(callId: string, participantId: string): Observable<void> {
    return from(
      this.conversationsApi
        .getConversationParticipantWrapupcodes(callId, participantId)
        .then((codes) => {
          const code = (codes || [])[0];
          if (code) {
            return this.conversationsApi.patchConversationParticipant(callId, participantId, {
              wrapup: {
                code: code.id,
                durationSeconds: 0,
              },
            });
          }

          return Promise.resolve();
        })
        .catch((err) => console.error(err)),
    );
  }

  sendDtmf(dtmf: string, callId: string, participantId): Observable<any> {
    return from(this.conversationsApi.postConversationParticipantDigits(callId, participantId, { body: { digits: dtmf } }));
  }

  setReadyStatus(): Observable<platformClient.Models.UserPresence> {
    this.analytcis.track(AgentAnalyticsEvent.GenesysSetReadyStatus, null);
    return this.setStatus('ON_QUEUE', 'AVAILABLE');
  }
  setBusyStatus(): Observable<platformClient.Models.UserPresence> {
    this.analytcis.track(AgentAnalyticsEvent.GenesysSetBusyStatus, null);
    return this.setStatus('BUSY');
  }
  setAwayStatus(): Observable<platformClient.Models.UserPresence> {
    this.analytcis.track(AgentAnalyticsEvent.GenesysSetAwayStatus, null);
    return this.setStatus('AWAY');
  }

  public getGenesysConversation(id: string): Observable<platformClient.Models.Conversation> {
    return from(this.conversationsApi.getConversation(id));
  }

  public async findWrapUpCode(problemName: string, issueName: string): Promise<string> {
    try {
      const name = `${problemName}/${issueName}`.trim();
      const searchQuery = `*${name}*`;

      let result = await this.routingApi.getRoutingWrapupcodes({ name: searchQuery });
      const codeId = (result.entities || []).find((x) => x.name === name)?.id;

      if (!codeId) {
        this.notifications.error(`Wrap-up code not found: '${name}'`);
      }

      return codeId;
    } catch (err) {
      this.notifications.error(`Error fetching wrap-up code: '${err}'`);
      return null;
    }
  }
  public async submitWrapUpCode(callId: string, participantId: string, codeId: string): Promise<boolean> {
    try {
      let body = {
        wrapup: { code: codeId },
      };

      await this.conversationsApi.patchConversationsCallParticipant(callId, participantId, body);
      return true;
    } catch (err) {
      this.notifications.error(`Error placing wrap-up code: '${err}'`);
      return false;
    }
  }

  private setStatus(...statuses: string[]): Observable<platformClient.Models.UserPresence> {
    statuses = statuses || [];
    let statusId: string = null;
    statuses.forEach((status) => {
      if (!statusId) {
        statusId = this.presenceDefinitions.find((x) => x.name === status)?.id;
      }
    });

    return from(this.presenceApi.patchUserPresence(this.user.id, 'PURECLOUD', { presenceDefinition: { id: statusId || statuses[0] } }));
  }

  createConversation(caller: string, callId: string, sessionId: string, agentSip: string): Observable<IConversation> {
    const conv = this._calls$.value[callId]?.conversation;
    if (conv) {
      const selfParticipant = this.getSelfParticipant(conv);
      const callerParticipant = this.getOtherParticipant(conv);
      const communication = this.getSelfParticipantCall(conv);
      const callerCommunication = this.getParticipantCall(callerParticipant);
      const queueDur = this.getEventQueueTime(conv);

      let conversation = new GenesysConversation(
        caller,
        agentSip,
        this,
        selfParticipant.id,
        callerParticipant.id,
        communication.id,
        queueDur,
      );
      conversation.callId = callId;
      conversation.sessionId = sessionId || callerCommunication.id;
      return of(conversation);
    }

    return this.getGenesysConversation(callId).pipe(
      map((x) => {
        const selfParticipant = this.getSelfConvParticipant(x);
        const callerParticipant = this.getOtherConvParticipant(x);
        const communication = this.getSelfConvParticipantCall(x);
        const callerCommunication = this.getConvParticipantCall(callerParticipant);
        const queueDur = this.getQueueTime(x);

        let conversation = new GenesysConversation(
          caller,
          agentSip,
          this,
          selfParticipant.id,
          callerParticipant.id,
          communication.id,
          queueDur,
        );
        conversation.callId = callId;
        conversation.sessionId = sessionId || callerCommunication.id;

        return conversation;
      }),
    );
  }

  public initialize(token?: string): Observable<any> {
    const p = window.require('platformClient');

    this.client = p.ApiClient.instance;
    this.usersApi = new p.UsersApi();
    this.conversationsApi = new p.ConversationsApi();
    this.notificationsApi = new p.NotificationsApi();
    this.presenceApi = new p.PresenceApi();
    this.routingApi = new p.RoutingApi();

    this.client.setEnvironment(this.configuration.data.integration.genesys_settings.environment);
    this.client.setPersistSettings(true, 'umojo');

    this.analytcis.track(AgentAnalyticsEvent.GenesysInitSuccessfully, null);

    return this.genesysSignIn(token);
  }

  private genesysSignIn(token?: string): Observable<IGenesysUser> {
    let result = new Subject<IGenesysUser>();

    this.dispose();

    if (this.configuration.data.integration.type === 'genesys' && (window.location.pathname || '').indexOf('auth') === -1) {
      let user: IGenesysUser = null;
      let returnUrl: string = null;

      const authPromise = token
        ? Promise.resolve(this.client.setAccessToken(token))
        : this.client
            .loginImplicitGrant(this.configuration.data.integration.genesys_settings.clientId, window.location.origin, {
              state: JSON.stringify({ returnUrl: window.location.pathname }),
            })
            .then((authData: platformClient.AuthData) => {
              const state = authData.state ? JSON.parse(authData.state) : null;
              returnUrl = state?.returnUrl;
            });

      authPromise
        .then(() => this.usersApi.getUsersMe())
        .then((userMe) => {
          console.log('[UMOJO_GENESYS] userMe', userMe);
          user = {
            id: userMe.id,
            name: userMe.name,
          };
          return this.presenceApi.getSystempresences();
        })
        .then((presences) => {
          this.presenceDefinitions = presences.map((x) => ({ id: x.id, name: x.name }));
          return this.notificationsApi.postNotificationsChannels();
        })
        .then((channel) => {
          this.notificationChannel = channel;

          this.webSocket = new AppWebSocket(channel.connectUri);
          this.webSocket.connect();
          this.webSocketSubscription = this.webSocket.messages$.subscribe((x) => this.handleNotification(x));

          this.conversationsTopic = 'v2.users.' + user.id + '.conversations';
          this.presenceTopic = 'v2.users.' + user.id + '.presence';
          return this.notificationsApi.putNotificationsChannelSubscriptions(this.notificationChannel.id, [
            { id: this.conversationsTopic },
            { id: this.presenceTopic },
          ]);
        })
        .then(() => {
          this._isAuthenticated$.next(true);
          this._isInitialized$.next(true);
          this._user$.next(user);
          result.next(user);

          this.analytcis.track(AgentAnalyticsEvent.GenesysUserLogged, user);

          if (returnUrl && returnUrl !== window.location.pathname) {
            this.router.navigateByUrl(returnUrl);
          }
        })
        .catch((err) => {
          console.log('[UMOJO_GENESYS] auth error', err);
          this.analytcis.track(AgentAnalyticsEvent.GenesysUserFailedAuth, err);
          this._isAuthenticated$.next(false);
          this._isInitialized$.next(true);
          result.next(null);
        });
    } else {
      result.next(null);
    }

    return result.asObservable();
  }

  private handleNotification(notification) {
    console.log('[UMOJO GENESYS SDK]', 'message', notification);

    if (notification?.topicName.toLowerCase() === this.conversationsTopic.toLowerCase()) {
      let conversation = notification?.eventBody as platformClient.Models.QueueConversationEventTopicConversation;
      let call = this.getSelfParticipantCall(conversation);

      if (call?.direction === 'inbound') {
        this.analytcis.track(AgentAnalyticsEvent.GenesysCallInbound, conversation);
        if (call?.state === 'dialing') {
          this.analytcis.track(AgentAnalyticsEvent.GenesysCallDialing, conversation);
          this.addOrUpdateCall(this.conversationToCall(conversation, 'Incoming'));
        } else if (call?.state === 'connected') {
          this.addOrUpdateCall(this.conversationToCall(conversation, 'Accepted'));
          this.analytcis.track(AgentAnalyticsEvent.GenesysCallConnected, conversation);
        } else if (call?.state === 'disconnected' || call?.state === 'terminated') {
          this.addOrUpdateCall(this.conversationToCall(conversation, 'Finished'));
          this.analytcis.track(AgentAnalyticsEvent.GenesysCallDisconnected, conversation);
        }
      }
    }
  }

  private addOrUpdateCall(call: IGenesysCall) {
    const calls = this._calls$.value;
    const existingCall = calls[call.id];

    if (call.state === 'Finished' && calls[call.id]) {
      delete calls[call.id];
      this._calls$.next({ ...calls });
    } else {
      calls[call.id] = call;

      if (existingCall?.state !== call.state) {
        this._calls$.next({ ...calls });
      }
    }
  }

  private getSelfParticipant(
    conversation: platformClient.Models.QueueConversationEventTopicConversation,
  ): platformClient.Models.QueueConversationEventTopicParticipant {
    return (conversation?.participants || []).find((x) => x.userId === this.user.id);
  }

  private getSelfConvParticipant(conversation: platformClient.Models.Conversation): platformClient.Models.Participant {
    return (conversation?.participants || []).find((x) => x.userId === this.user.id);
  }

  private getSelfParticipantCall(
    conversation: platformClient.Models.QueueConversationEventTopicConversation,
  ): platformClient.Models.QueueConversationEventTopicCall {
    return this.getSelfParticipant(conversation)?.calls[0];
  }

  private getSelfConvParticipantCall(conversation: platformClient.Models.Conversation): platformClient.Models.Call {
    return this.getSelfConvParticipant(conversation)?.calls[0];
  }

  private getOtherParticipant(
    conversation: platformClient.Models.QueueConversationEventTopicConversation,
  ): platformClient.Models.QueueConversationEventTopicParticipant {
    return (conversation?.participants || []).find((x) => x.userId !== this.user.id);
  }

  private getOtherConvParticipant(conversation: platformClient.Models.Conversation): platformClient.Models.Participant {
    return (conversation?.participants || []).find((x) => x.userId !== this.user.id);
  }

  private getParticipantCall(
    participant: platformClient.Models.QueueConversationEventTopicParticipant,
  ): platformClient.Models.QueueConversationEventTopicCall {
    return participant?.calls[0];
  }
  private getConvParticipantCall(participant: platformClient.Models.Participant): platformClient.Models.Call {
    return participant?.calls[0];
  }

  private getQueueTime(conversation: platformClient.Models.Conversation): number {
    const participants = conversation?.participants || [];
    const acd = participants.find((x) => x.purpose === 'acd');
    const agent = participants.find((x) => x.purpose === 'agent');
    if (!acd || !acd.connectedTime || !agent || !agent.connectedTime) {
      return 0;
    }

    const acdConnected = new Date(acd.connectedTime);
    const agentConnected = new Date(agent.connectedTime);
    const queueTime = (+agentConnected - +acdConnected) / 1000;
    return Math.round(queueTime);
  }

  private getEventQueueTime(conversation: platformClient.Models.ConversationEventTopicConversation): number {
    const participants = conversation?.participants || [];
    const acd = participants.find((x) => x.purpose === 'acd');
    const agent = participants.find((x) => x.purpose === 'agent');
    if (!acd || !acd.connectedTime || !agent || !agent.connectedTime) {
      return 0;
    }

    const acdConnected = new Date(acd.connectedTime);
    const agentConnected = new Date(agent.connectedTime);
    const queueTime = (+agentConnected - +acdConnected) / 1000;
    return Math.round(queueTime);
  }

  private conversationToCall(conversation: platformClient.Models.QueueConversationEventTopicConversation, state: CallState): IGenesysCall {
    const callerParticipant = this.getOtherParticipant(conversation);
    const callerCommunication = this.getParticipantCall(callerParticipant);
    const call = this.getSelfParticipantCall(conversation);

    return {
      id: conversation.id,
      conversation,
      state,

      caller: call.other.addressNormalized,
      agentSip: call.self.addressNormalized,
      sessionId: callerCommunication.id,
    };
  }

  private disconnect(callId, participantId, communicationId): Promise<any> {
    let body = {
      state: 'disconnected',
    };

    return this.conversationsApi
      .patchConversationsCallParticipantCommunication(callId, participantId, communicationId, body)
      .catch((err) => {
        this.analytcis.track(AgentAnalyticsEvent.GenesysErrorPatchConversation, err);
        console.error(err);
      });
  }

  private dispose() {
    if (this.webSocketSubscription) {
      this.webSocketSubscription.unsubscribe();
      this.webSocketSubscription = null;
    }

    if (this.webSocket) {
      this.webSocket.close();
    }
  }
}
