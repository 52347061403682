import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

export interface ParkChirpLocationViewModel {
  LocationId: string;
  LocationName: string;
  LocationDisplayName: string;
}

@Injectable({ providedIn: 'root' })
export class ParkChirpService {
  constructor(private _httpClient: HttpClient) {}

  // Get all locations
  getLocations(): Observable<any> {
    return this._httpClient.get<any>('Parkchirp/Locations');
  }
}
