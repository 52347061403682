import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-primary-button',
  template: `
    <button class="button button--primary" [disabled]='disabled' (click)="clickEvent.emit()">
      <i *ngIf="buttonText === 'Edit'" class="fa fa-pencil" style="font-size: 14px; line-height:16px; margin-right: 5px;"></i>
      <i *ngIf="buttonText === 'Save'" class="fa fa-floppy-o" style="font-size: 14px; line-height:16px; margin-right: 5px;"></i>
      <i *ngIf="buttonText === 'Add'" class="fa fa-plus" style="font-size: 14px; line-height:16px; margin-right: 5px;"></i>
      <span>{{ buttonText }}</span>
    </button>
  `,
})
export class PrimaryButtonComponent {
  @Input() buttonText = 'Add';
  @Input() disabled?: boolean;
  @Output() clickEvent: EventEmitter<void> = new EventEmitter<void>();
}
