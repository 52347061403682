import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { AccessManagementService } from '@libs/portal-common/services';
import { daysOfWeek } from '@libs/portal-common/shared';
import { ContactsService } from '@libs/portal-common/services';
import { LocationsService } from '@libs/portal-common/services';
import { ActivityFilter } from '../services/call-history.service';

import { zip, of } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-activity-filter',
  templateUrl: './activity-filter.component.html',
  styleUrls: ['./activity-filter.component.scss'],
})
export class ActivityFilterComponent implements OnInit {
  @Input() mode: string;

  @Input() filter: ActivityFilter;

  @Input() disabled = false;

  loading = false;

  days = [];

  locations = [];
  filteredLocations = [];

  agents = [];
  filteredAgents = [];

  skypeAgentEmail = '';

  displayLocations = false;
  displayAgents = false;

  @Output() search = new EventEmitter<ActivityFilter>();

  constructor(
    private locationsService: LocationsService,
    private contactsService: ContactsService,
    private accessManagementService: AccessManagementService,
  ) {
    this.days = daysOfWeek;
  }

  handleDaysFilter(value) {
    this.days = daysOfWeek.filter((s) => s.Name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
  }
  handleLocationsFilter(value) {
    this.filteredLocations = this.locations.filter((s) => s.LotName.toLowerCase().indexOf(value.toLowerCase()) !== -1);
  }
  handleAgentsFilter(value) {
    this.filteredAgents = this.agents.filter((s) => s.Name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
  }

  ngOnInit() {
    this.loading = true;

    let loc = of([]);
    if (this.accessManagementService.hasAccessPermissions({ entities: ['Locations'], access: 'CanRead' })) {
      this.displayLocations = true;

      loc = this.locationsService.getAll().pipe(
        tap((response) => {
          this.locations = (response.Data || []).map(function (lot) {
            return {
              Id: lot.Id,
              LotName: `${lot.LotNumber} ${lot.LotName}`,
            };
          });

          this.filteredLocations = this.locations;
        }),
      );
    }

    let common = of([]);
    if (this.accessManagementService.hasAccessPermissions({ entities: ['Locations'], access: 'CanRead' })) {
      this.displayAgents = true;

      common = this.contactsService.getAgents().pipe(
        tap((response) => {
          this.agents = (response.Data || []).map(function (agent) {
            return {
              Id: agent.Id,
              SIP: agent.SipAddress,
              Name: agent.Name,
            };
          });

          this.filteredAgents = this.agents;
        }),
      );
    }

    zip(loc, common).subscribe((result) => {
      this.loading = false;
    });
  }

  applyAgentSip(sip: string) {
    this.skypeAgentEmail = sip;
    this.filter.SipAddress = sip;

    this.search.emit(this.filter);
  }

  searchClick() {
    this.search.emit(this.filter);
  }
}
