<div class="row call-processing-row" *ngIf="incomingCall">
  <app-call-processing-form
    class="call-processing-column col-xl-4 col-lg-6 col-xs-12 flex__box flex__box--column form-column"
    [incomingCall]="incomingCall"
    (laneSelected)="onLaneSelected($event)"
  >
  </app-call-processing-form>

  <div class="call-processing-column info-block col-xl-8 col-lg-6 col-xs-12">
    <div class="view-tabs-container">
      <div
        class="view-tab"
        *ngFor="let tab of viewTabsData.tabs; let i = index"
        (click)="openTab(i)"
        [ngClass]="{ 'view-tab-active': viewTabsData.activeTab === i }"
      >
        {{ tab.name }}
      </div>
    </div>
    <div *ngIf="viewTabsData.activeTab === 0">
      <h4 class="manufacturer" *ngIf="!!locationInfo?.RevenueControlManufacturer">
        <i class="skype-icon skype-voucher-icon"></i> Revenue Control Manufacturer:
        {{ locationInfo.RevenueControlManufacturer.Name }}
      </h4>
      <div class="carousel-container" *ngIf="isBrowser">
        <carousel [interval]="0" [activeSlide]="activeSlideIndex" (activeSlideChange)="activeSlideChange($event)">
          <slide *ngFor="let slide of carouselSlides; let idx = index">
            <app-dvr-video *ngIf="slide.type == 'CAM'" [location]="incomingCall.location" [lane]="incomingCall.lane"></app-dvr-video>
            <app-map *ngIf="slide.type == 'Map' && activeSlideIndex == idx" [location]="incomingCall.location"></app-map>
          </slide>
        </carousel>
        <div class="btn-group clearfix">
          <label
            class="btn btn-nav"
            *ngFor="let slide of carouselSlides; let idx = index"
            [(ngModel)]="activeSlideIndex"
            btnRadio="{{ idx }}"
          >
            {{ slide.type }}
          </label>
        </div>
      </div>
      <router-outlet></router-outlet>
    </div>
    <div class="iframe-container" *ngIf="viewTabsData.activeTab !== 0">
      <h4 class="manufacturer">
        <i class="skype-icon skype-location-icon"></i>
        <a [href]="viewTabsData.tabs[viewTabsData.activeTab].url" target="_blank">Open in a new web browser tab</a>
      </h4>

      <iframe id="iframeWindow" [src]="viewTabsData.tabs[viewTabsData.activeTab].url" width="100%" height="100%" frameborder="1"> </iframe>
    </div>
  </div>
</div>
