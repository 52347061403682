import { Component, OnInit, ViewChild } from '@angular/core';

import { Observable, Observer } from 'rxjs';

import { ModalDirective } from 'ngx-bootstrap/modal';

import { MonitoringSettingsService, IMonitoringWidget } from '@libs/portal-common/services';
import { MonitoringSettingsEditorComponent } from '../monitoring-settings-editor/monitoring-settings-editor.component';
import { AppNotificationsService } from '@libs/portal-common/system';

@Component({
  selector: 'app-edit-monitoring-settings',
  templateUrl: './edit-monitoring-settings.component.html',
  styleUrls: ['./edit-monitoring-settings.component.scss'],
})
export class EditMonitoringSettingsComponent implements OnInit {
  @ViewChild('modal', { static: true }) public modal: ModalDirective;
  @ViewChild('editor', { static: false }) private editor: MonitoringSettingsEditorComponent;

  resultObserver: Observer<Array<IMonitoringWidget>>;

  model = new Array<IMonitoringWidget>();

  constructor(
    private appNotificationsService: AppNotificationsService,
    private monitoringSettingsService: MonitoringSettingsService,
  ) {}

  ngOnInit() {}

  open(): Observable<Array<IMonitoringWidget>> {
    this.monitoringSettingsService.getPortalSettings().subscribe((res) => {
      this.model = res;
    });

    this.modal.show();
    return new Observable((observer) => (this.resultObserver = observer));
  }

  save() {
    this.monitoringSettingsService.savePortalSettings(this.editor.selectedWidgets).subscribe((response) => {
      this.modal.hide();
      this.resultObserver.next(response);
      this.resultObserver.complete();
    });
  }

  cancel() {
    this.modal.hide();
    this.resultObserver.next(null);
    this.resultObserver.complete();
  }

  restoreDefaults() {
    this.monitoringSettingsService.restoreDefaultPortalSettings().subscribe((response) => {
      this.appNotificationsService.success('Page settings have been successfully restored');
      this.resultObserver.next(response);
      this.model = response;
      this.resultObserver.complete();
    });
  }
}
