import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { HttpClient, HttpParams } from '@angular/common/http';
import { IRestBaseListResponse } from '../models/rest/baseResponse';

export interface IField {
  Id: number;
  FieldName: string;
  FieldType: string;
  Length: number;
  DisplayName: string;
  IsPartOfField: boolean;
  RelatedField?: number;
  Module: string;
  IsStatic: boolean;
  IsRequired: boolean;
  Value: string;
  fkRegion: number;
  fkRuleSet: number;
  AutofillMapping: string;
}

export interface MappingFieldInfo {
  Key: string;
  Description: string;
}

export interface IAutofillSourcesRegistry {
  [key: string]: MappingFieldInfo[];
}

interface IAutoFillSourceResponse {
  Sources: IAutofillSourcesRegistry;
}

@Injectable({ providedIn: 'root' })
export class FieldSetService {
  constructor(private _httpClient: HttpClient) {}

  // Get all Fields
  getAll(regionId?: number, ruleSetId?: number, isStatic?: boolean): Observable<IRestBaseListResponse<IField>> {
    let params = new HttpParams();
    if (!!regionId) {
      params = params.set('fkRegion', regionId.toString());
    }
    if (!!ruleSetId) {
      params = params.set('fkRuleSet', ruleSetId.toString());
    }
    if (isStatic !== null && isStatic !== undefined) {
      params = params.set('isStatic', isStatic.toString());
    }

    if (isStatic !== null && isStatic !== undefined) {
      params = params.set('isStatic', isStatic.toString());
    }

    return this._httpClient.get<IRestBaseListResponse<IField>>('FieldSet/', { params: params });
  }

  // Get a Field
  get(fieldId): Observable<any> {
    return this._httpClient.get<any>('FieldSet/' + fieldId);
  }

  // Create a Field
  create(field): Observable<any> {
    return this._httpClient.put<any>('FieldSet/', { field });
  }

  // Update a Field
  update(field): Observable<any> {
    return this._httpClient.put<any>('FieldSet/', { field });
  }

  // Delete a Field
  delete(fieldId): Observable<any> {
    return this._httpClient.delete<any>('FieldSet/' + fieldId);
  }

  getAutoFillingSources(): Observable<IAutoFillSourceResponse> {
    return this._httpClient.get<any>('Autofilling/Sources');
  }

  resetCache() {}
}
