<div class="grid-container grid-page">
  <app-activity-filter [mode]="'lane'" #activityFilter [filter]="filter" (search)="search($event)"></app-activity-filter>

  <app-activity-grid
    [mode]="'lane'"
    (busy)="activityFilter.disabled = $event"
    [filter]="filter"
    [pdfFileName]="'LaneActivity.pdf'"
    [excelFileName]="'LaneActivity.xlsx'"
    reportName="LaneActivity"
  ></app-activity-grid>
</div>
