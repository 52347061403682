import { Injectable } from '@angular/core';

import { Observable, BehaviorSubject } from 'rxjs';
import { tap, map } from 'rxjs/operators';

import { State } from '@progress/kendo-data-query';

import { GridDataResult } from '@progress/kendo-angular-grid';

import { HttpClient } from '@angular/common/http';

import { AppNotificationsService } from '@libs/portal-common/system';

export interface ILocationView {
  Id: number;
  Name: string;
}

@Injectable({ providedIn: 'root' })
export class LocationsListService extends BehaviorSubject<GridDataResult> {
  defaultState: State = {
    skip: 0,
    take: 20,
    group: [],
    filter: { logic: 'and', filters: [] },
    sort: [],
  };

  constructor(
    private _httpClient: HttpClient,
    private notifications: AppNotificationsService,
  ) {
    super(null);
  }

  public query(state: State): void {
    this.getLocationsList(state).subscribe((x) => {
      super.next(x);
    });
  }

  public clear(): void {
    super.next(<GridDataResult>{
      data: [],
      total: 0,
    });
  }

  getLocationsList(state: State = this.defaultState): Observable<GridDataResult> {
    let params = {
      group: state.group,
      filter: state.filter,
      skip: state.skip,
      limit: state.take,
      sort: state.sort,
    };

    return this._httpClient.post<any>('Lot/LotDirectory', params).pipe(
      tap((response) => {
        this.notifications.checkResponse(response);
      }),
      map(
        (response) =>
          <GridDataResult>{
            data: response.Groups || response.Data || [],
            total: response.Total,
          },
      ),
    );
  }
}
