export * from './router-transition.animation';
export * from './system.module';

export * from './confirmation/confirmation.component';
export * from './confirmation/confirmation.service';
export * from './guards/confirm-deactivate.guard';

export * from './services/app-notifications.service';
export * from './services/pub-sub.service';
export * from './services/dom.service';

declare global {
  interface Array<T> {
    findById(o: T): number;
    findBy(field: string, value: any): T;
    removeById(id: number);
    removeBy(field: string, key: any);
    existsId(id: number): boolean;
    findAndRemove(search_term: any);
    findAndRemoveById(id: number);
    selectMany(select: (arg: any) => Array<any>): Array<T>;
  }
}

Array.prototype.findById = function (Id): number {
  return this[this.map((x) => x.Id).indexOf(parseInt(Id, 10))];
};

Array.prototype.findBy = function (field, value) {
  return this[
    this.map(function (x) {
      return x[field];
    }).indexOf(value)
  ];
};

Array.prototype.removeById = function (id) {
  for (let i = this.length - 1; i >= 0; i--) {
    if (this[i].Id === id) {
      this.splice(i, 1);
    }
  }
};

Array.prototype.removeBy = function (field, key) {
  for (let i = this.length - 1; i >= 0; i--) {
    if (this[i][field] === key) {
      this.splice(i, 1);
    }
  }
};

Array.prototype.existsId = function (id) {
  for (let i = 0; i < this.length; i++) {
    if (this[i].Id === id) {
      return true;
    }
  }
  return false;
};

Array.prototype.findAndRemove = function (search_term) {
  for (let i = this.length - 1; i >= 0; i--) {
    if (this[i] === search_term) {
      this.splice(i, 1);
    }
  }
};

Array.prototype.findAndRemoveById = function (id) {
  for (let i = this.length - 1; i >= 0; i--) {
    if (this[i].Id === id) {
      this.splice(i, 1);
    }
  }
};

Array.prototype.selectMany = function (select: (arg: any) => Array<any>) {
  return this.map((x) => select(x)).reduce((a, b) => a.concat(b));
};
