import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { HasErrorsPipe } from './pipes/has-errors.pipe';
import { ValueIsEmptyPipe } from './pipes/value-is-empty.pipe';

import { EmailValidatorDirective } from './directives/email-validator.directive';
import { NumberValidatorDirective } from './directives/number-validator.directive';
import { SipValidatorDirective } from './directives/sip-validator.directive';

import { ValidationErrorsComponent } from './components/validation-errors/validation-errors.component';


@NgModule({
  imports: [CommonModule, FormsModule],
  declarations: [EmailValidatorDirective, HasErrorsPipe,
    ValueIsEmptyPipe, NumberValidatorDirective, SipValidatorDirective, ValidationErrorsComponent],
  exports: [EmailValidatorDirective, HasErrorsPipe,
    ValueIsEmptyPipe, NumberValidatorDirective, SipValidatorDirective, ValidationErrorsComponent],
})
export class ValidationModule {}
