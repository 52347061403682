import { Component, Input } from '@angular/core';
import { CompositeFilterDescriptor } from '../../composite-filter-descriptor';
import { FilterService, BaseFilterCellComponent } from '@progress/kendo-angular-grid';

@Component({
  selector: 'app-switch-filter',
  templateUrl: './switch-filter.component.html',
  styleUrls: ['./switch-filter.component.scss'],
})
export class SwitchFilterComponent extends BaseFilterCellComponent {
  public get selectedValue(): any {
    const filter = this.filterByField(this.field);
    return filter ? filter.value : null;
  }

  public set selectedValue(value: any) {
    this.applyFilter(
      value === null // value of the default item
        ? this.removeFilter(this.field) // remove the filter
        : this.updateFilter({
            // add a filter for the field with the value
            field: this.field,
            operator: 'eq',
            value: value,
          }),
    ); // update the root filter
  }

  @Input() public field: string;

  @Input() public filter: CompositeFilterDescriptor;
  @Input() public onLabel = 'Y';
  @Input() public offLabel = 'N';

  constructor(filterService: FilterService) {
    super(filterService);
  }

  public clear() {
    this.removeFilter(this.field);
  }
}
