import { NgModule, ModuleWithProviders } from '@angular/core';

import { NoIntegrationAppService } from './services/no-integration.service';

@NgModule({})
export class NoIntegrationModule {
  static forRoot(): ModuleWithProviders<NoIntegrationModule> {
    return {
      ngModule: NoIntegrationModule,
      providers: [NoIntegrationAppService],
    };
  }

  constructor() {}
}
