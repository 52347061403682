import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ModalModule } from 'ngx-bootstrap/modal';
import { SortableModule } from '@progress/kendo-angular-sortable';

import { MonitoringComponent } from './components/monitoring/monitoring.component';
import { ServicesModule } from '@libs/portal-common/services';
import { SystemModule } from '@libs/portal-common/system';
import { EditMonitoringSettingsComponent } from './components/edit-monitoring-settings/edit-monitoring-settings.component';
import { MonitoringSettingsEditorComponent } from './components/monitoring-settings-editor/monitoring-settings-editor.component';
import { SharedModule } from '@libs/portal-common/shared';

@NgModule({
  imports: [CommonModule, ModalModule, SortableModule, SystemModule, ServicesModule, SharedModule],
  declarations: [MonitoringComponent, EditMonitoringSettingsComponent, MonitoringSettingsEditorComponent],
  exports: [MonitoringComponent, EditMonitoringSettingsComponent, MonitoringSettingsEditorComponent],
})
export class MonitoringModule {}
