import { Routes, RouterModule } from '@angular/router';

import { ConfirmDeactivateGuard } from '@libs/portal-common/system';

import { IncidentDetailsComponent } from './activity-item/incident-details/incident-details.component';
import { IncidentQualityAnalysisComponent } from './activity-item/incident-quality-analysis/incident-quality-analysis.component';
import { IncidentTicketComponent } from './activity-item/incident-ticket/incident-ticket.component';

export const ACTIVITY_ROUTES: Routes = [
  {
    path: 'details',
    component: IncidentDetailsComponent,
    data: { title: 'Details', header: 'Incident Details' },
  },
  {
    path: 'quality',
    component: IncidentQualityAnalysisComponent,
    canDeactivate: [ConfirmDeactivateGuard],
    data: { title: 'Quality Analysis', header: 'Quality Analysis' },
  },
  {
    path: 'ticket',
    component: IncidentTicketComponent,
    canDeactivate: [ConfirmDeactivateGuard],
    data: { title: 'Ticket', header: 'Ticket' },
  },
  { path: '', redirectTo: 'details', pathMatch: 'full' },
  { path: '**', redirectTo: 'details' },
];

export const NavList = ACTIVITY_ROUTES.filter((x) => !!x.data).map((x) => {
  return {
    path: x.path,
    title: (<any>x).data.title,
  };
});
