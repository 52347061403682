import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class LoaderStatusService {
  private _loading = false;

  get getLoadingStatus() {
    return this._loading;
  }

  setLoadingStatus(flag: boolean) {
    this._loading = flag;
  }
  constructor() {
  }
}
