import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { DragStartEvent, SortableEvent } from '@progress/kendo-angular-sortable';

import { IMonitoringWidget, MonitoringWidgetType } from '@libs/portal-common/services';

interface IUiMonitoringWidget extends IMonitoringWidget {
  Title: string;
}

const WIDGETS = new Map<MonitoringWidgetType, IUiMonitoringWidget>([
  ['CallsInQueue', { Type: 'CallsInQueue', Title: 'Calls waiting in queue', Order: 0 }],
  ['TotalCalls', { Type: 'TotalCalls', Title: 'Total Calls', Order: 1 }],
  ['AverageQueueTimeLH', { Type: 'AverageQueueTimeLH', Title: 'Average Queue Time', Order: 2 }],
  ['AverageQueueTimeLD', { Type: 'AverageQueueTimeLD', Title: 'Average Queue Time', Order: 3 }],
  ['AverageCallLength', { Type: 'AverageCallLength', Title: 'Average Call Length', Order: 4 }],
  ['AverageWrapupTime', { Type: 'AverageWrapupTime', Title: 'Avg Wrap-Up Time', Order: 5 }],
  ['AbandonedCalls', { Type: 'AbandonedCalls', Title: 'Abandoned Calls', Order: 6 }],
  ['CancelledByAgentCalls', { Type: 'CancelledByAgentCalls', Title: 'Cancelled by Agent', Order: 7 }],
  ['DisconnectedCalls', { Type: 'DisconnectedCalls', Title: 'Disconnected Calls', Order: 8 }],
  ['MaxCallsInQueue', { Type: 'MaxCallsInQueue', Title: 'Max Calls in Queue', Order: 9 }],
  ['LongestQueueTime', { Type: 'LongestQueueTime', Title: 'Longest Queue Time', Order: 10 }],
  ['QueueTimeOver30s', { Type: 'QueueTimeOver30s', Title: 'Queue Time over 30s', Order: 11 }],
  ['AvgCallDuration24H', { Type: 'AvgCallDuration24H', Title: 'Average call time', Order: 12 }],
  ['AvgWrapUp24H', { Type: 'AvgWrapUp24H', Title: 'Average Wrap-Up Time', Order: 13 }],
]);

@Component({
  selector: 'app-monitoring-settings-editor',
  templateUrl: './monitoring-settings-editor.component.html',
  styleUrls: ['./monitoring-settings-editor.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MonitoringSettingsEditorComponent implements OnInit {
  private itemWidth: { [id: string]: number } = {};

  private _initial: Array<IMonitoringWidget> = [];
  private _selectedWidgets: Array<IUiMonitoringWidget> = [];
  private _indexes = [];

  private _draggedItem: { evt: DragStartEvent; sortable: string; widgetTitle: string } = null;
  dragging: string = null;

  @Input()
  get selectedWidgets(): Array<IUiMonitoringWidget> {
    return this._selectedWidgets;
  }
  set selectedWidgets(val: Array<IUiMonitoringWidget>) {
    this._selectedWidgets = val;
  }

  availableWidgets: Array<IUiMonitoringWidget> = [];

  @Input() maxSelected: number;
  @Input() header: string;
  @Input() columns: number = null;
  @Input() zone: string;
  @Input() contentClass = 'ibox__content';

  @Input()
  get selected(): Array<IMonitoringWidget> {
    return this.selectedWidgets.map((x, idx) => ({ Type: x.Type, Order: idx }));
  }
  set selected(val: Array<IMonitoringWidget>) {
    this._initial = val;

    this.selectedWidgets = this.mapWidgets(val || []);
    let selected = new Map(this._selectedWidgets.map((x) => [x.Type, x]));
    this.availableWidgets = Array.from(WIDGETS.values())
      .filter((x) => !selected.has(x.Type))
      .sort((x) => x.Order);

    let indexes = [];
    const allWidgets = this.selectedWidgets.length + this.availableWidgets.length;
    for (let idx = 0; idx < allWidgets; idx++) {
      indexes.push(idx);
    }
    this._indexes = indexes;
  }

  @Output() onSave = new EventEmitter<Array<IMonitoringWidget>>();
  @Output() onRestoreDefault = new EventEmitter<Array<IMonitoringWidget>>();

  constructor() {}

  ngOnInit(): void {}

  getItemClass(state: string, columns: number = null): string {
    let col = 'col-xs-6 col-sm-3';
    if (columns + '' === '3') {
      col = 'col-xs-4 col-sm-4';
    } else if (columns + '' === '2') {
      col = 'col-xs-6 col-sm-6';
    }

    return `monitoring-item ${col} ${state}`;
  }

  save() {
    this.onSave.emit(this.selected);
  }

  cancel() {
    this.selected = this._initial;
  }

  restoreDefaults() {
    this.onRestoreDefault.emit(this.selected);
  }

  activeItemStyle(sortable: string) {
    let width = this.itemWidth[sortable];
    if (!!width) {
      return {
        width: width + 'px',
      };
    }

    return {};
  }

  calculateStyle(sortable: string, itemRef: any) {
    if (!!itemRef) {
      const parent = itemRef.parentNode;
      if (!parent.classList.contains('active')) {
        this.itemWidth[sortable] = parent.offsetWidth;
      }
    }

    return {};
  }

  onDragStart(evt: DragStartEvent, sortable: string) {
    if (sortable === 'left' && this._selectedWidgets.length >= this.maxSelected) {
      evt.preventDefault();
      return;
    }

    let src = this.getWidgetsArray(sortable);

    this._draggedItem = { evt, sortable, widgetTitle: src[evt.index].Title };
  }
  onDragEnd(evt: SortableEvent, sortable: string) {
    if (evt.index > -1) {
      this._draggedItem = null;
    } else {
      setTimeout(() => (this._draggedItem = null), 100);
    }
  }
  onMouseUp(evt: MouseEvent, sortable: string) {
    this.dragging = null;

    if (!!this._draggedItem && this._draggedItem.evt.index > -1 && this._draggedItem.sortable !== sortable) {
      let src = this.getWidgetsArray(this._draggedItem.sortable);
      let target = this.getWidgetsArray(sortable);
      let item = src.find((x) => x.Title === this._draggedItem.widgetTitle);
      if (!!item) {
        src = src.filter((x) => x.Title !== this._draggedItem.widgetTitle);

        this.updateWidgetsArray(this._draggedItem.sortable, src);
        this.updateWidgetsArray(sortable, [...target, item]);
      }

      this._draggedItem = null;
    }
  }
  onMouseOver(evt: MouseEvent, sortable: string) {
    if (!!this._draggedItem) {
      this.dragging = sortable;
    }
  }

  private getWidgetsArray(sortable: string): Array<IUiMonitoringWidget> {
    switch (sortable) {
      case 'left':
        return this.availableWidgets;
      case 'right':
        return this.selectedWidgets;
      default:
        throw new Error(`Invalid sortable: ${sortable}.`);
    }
  }
  private updateWidgetsArray(sortable: string, widgets: Array<IUiMonitoringWidget>) {
    switch (sortable) {
      case 'left':
        this.availableWidgets = widgets;
        break;
      case 'right':
        this.selectedWidgets = widgets;
        break;
      default:
        throw new Error(`Invalid sortable: ${sortable}.`);
    }
  }

  private mapWidgets(widgets: Array<IMonitoringWidget>): Array<IUiMonitoringWidget> {
    return widgets.map((x, idx) => {
      const uiWidget = WIDGETS.get(x.Type);

      return {
        Type: uiWidget.Type,
        Title: uiWidget.Title,
        Order: idx,
      };
    });
  }
}
