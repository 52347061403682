import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { mergeMap, shareReplay, tap, catchError } from 'rxjs/operators';

import { Five9StateService } from './five9-state.service';
import { Metadata } from '../model';
import { AppNotificationsService } from '@libs/portal-common';

@Injectable()
export class Five9AuthInterceptor implements HttpInterceptor {
  metadata: Observable<Metadata>;

  constructor(
    private five9State: Five9StateService,
    private notifications: AppNotificationsService,
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.metadata) {
      this.metadata = this.five9State.getMetadata().pipe(shareReplay(1));
    }

    return this.metadata.pipe(
      mergeMap((metadata) => {
        let url = request.url;
        if (!url.startsWith('http') && !!metadata) {
          let baseUrl = Five9StateService.GetApiUrlHost(metadata);
          url = `https://${baseUrl}/appsvcs/rs/svc/${url}`;
        }

        let headers: {
          [name: string]: string;
        } = {};

        let ignoreContentType =
          typeof request.body === 'undefined' ||
          request.body === null ||
          request.body.toString() === '[object FormData]' ||
          request.headers.has('Content-Type');

        if (!ignoreContentType) {
          headers['content-type'] = 'application/json; charset=utf-8';
        }

        request = request.clone({
          url: url,
          withCredentials: true,
          setHeaders: headers,
        });

        return next.handle(request).pipe(catchError((err) => this.catchError(err, next)));
      }),
    );
  }

  private catchError = (response: HttpResponse<any>, next: HttpHandler) => {
    console.log(response);
    let error: HttpErrorResponse = null;

    if (response instanceof HttpErrorResponse) {
      error = <HttpErrorResponse>response;
    } else {
      return throwError(response);
    }

    let errorMessage = error.statusText;
    if (!!error.error.five9ExceptionDetail) {
      errorMessage = `five9 auth error: ${error.error.five9ExceptionDetail.errorCode} - ${error.error.five9ExceptionDetail.message}`;
    }

    this.notifications.error(errorMessage);

    return throwError(errorMessage);
  };
}
