<div *ngIf="!!stats">
  <div class="stats">
    <div class="stats-value">{{ stats.CdrSummary.CallsWaitingInQueue }}</div>
    <div class="stats-legend">
      <i class="nav-icon nav-icon-phone"></i>
      <span class="left-nav__link">Calls in queue</span>
    </div>
    <div class="progress-bar calls-queue" role="progressbar" [style.width]="progress.callsQueue"></div>
  </div>

  <div class="stats">
    <div class="stats-value">{{ stats.CdrSummary.AvgQueueLh }}</div>
    <div class="stats-legend">
      <i class="nav-icon nav-icon-wait-time"></i>
      <span class="left-nav__link">Average wait time</span>
    </div>
    <div class="progress-bar wait-time" role="progressbar" [style.width]="progress.waitTime"></div>
  </div>

  <div class="stats">
    <div class="stats-value">{{ stats.CdrSummary.AvgCallDurationLh }}</div>
    <div class="stats-legend">
      <i class="nav-icon nav-icon-call-time"></i>
      <span class="left-nav__link">Average call time</span>
    </div>
    <div class="progress-bar call-time" role="progressbar" [style.width]="progress.callTime"></div>
  </div>

  <div class="stats">
    <div class="stats-value">{{ stats.CdrSummary.AvgWrapUpLh }}</div>
    <div class="stats-legend">
      <i class="nav-icon nav-icon-wrapup-time"></i>
      <span class="left-nav__link">Average wrapup time</span>
    </div>
    <div class="progress-bar wrapup-time" role="progressbar" [style.width]="progress.wrapupTime"></div>
  </div>
</div>
