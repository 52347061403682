import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseResponse } from '../models/rest/baseResponse';

export interface IVoicePlatformAccount {
  id: string;
  name: string;
}

export interface IVoicePlatformDevice {
  id: string;
  name: string;
}

export interface IVoicePlatformDeviceDetails {
  Id: string;
  Name: string;
  SipUri: string;
}

export enum DeviceType {
  All = 1,
  SipDevice,
  SipUri,
  SoftPhone,
  CellPhone,
  LandLine,
  Fax,
  SmartPhone,
}

export interface AccountsResponse extends BaseResponse {
  Data: Array<IVoicePlatformAccount>;
}

export interface DevicesResponse extends BaseResponse {
  Data: Array<IVoicePlatformDevice>;
}

export interface DevicesDetailsResponse extends BaseResponse {
  Data: Array<IVoicePlatformDeviceDetails>;
}

export interface DeviceCredentialsResponse extends BaseResponse {
  Id: string;
  Name: string;
  UserName: string;
  Password: string;
  Realm: string;
}

@Injectable()
export class VoicePlatformService {
  constructor(private _httpClient: HttpClient) {}

  getAccounts(): Observable<Array<IVoicePlatformAccount>> {
    return this._httpClient.get<AccountsResponse>('VoicePlatform/Accounts').pipe(
      map((response) => {
        return response.Data;
      }),
    );
  }

  getDevices(locationId: number): Observable<Array<IVoicePlatformDevice>> {
    return this._httpClient.get<DevicesResponse>(`VoicePlatform/Locations/${locationId}/Devices`).pipe(
      map((response) => {
        return response.Data;
      }),
    );
  }

  getDevicesByType(
    locationId: number,
    deviceType: number
  ): Observable<Array<IVoicePlatformDeviceDetails>> {
    return this._httpClient.get<DevicesDetailsResponse>(
        `VoicePlatform/Locations/${locationId}/Devices/Type/${deviceType}`
      ).pipe(
      map((response) => {
        return response.Data;
      }),
    );
  }

  getDeviceCredentials(
    accountId: string,
    deviceId: string
  ): Observable<DeviceCredentialsResponse> {
    if (!accountId) {
      throw new Error('accountId is null or empty.');
    }

    if (!deviceId) {
      throw new Error('deviceId is null or empty.');
    }

    return this._httpClient.get<DeviceCredentialsResponse>(`VoicePlatform/Accounts/${accountId}/Devices/${deviceId}/Credentials`).pipe(
      map((response) => {
        return response;
      }),
    );
  }

  createVoicePlatformDevice(
    accountId: string,
    laneId: number
  ): Observable<DeviceCredentialsResponse> {
    if (!accountId) {
      throw new Error('accountId is null or empty.');
    }

    return this._httpClient.post<DeviceCredentialsResponse>(`VoicePlatform/Accounts/${accountId}/Devices/New?LaneId=${laneId}`, {}).pipe(
      map((response) => {
        return response;
      }),
    );
  }

  vendGate(lotId: number, laneId: number): Observable<BaseResponse> {
    return this._httpClient.post<BaseResponse>(`VoicePlatform/Location/${lotId}/Lane/${laneId}/VendGate`, {}).pipe(
      map((response) => {
        return response;
      }),
    );
  }

  syncLotDevices(lotId: number): Observable<any> {
    return this._httpClient.post<DevicesResponse>(`VoicePlatform/Locations/${lotId}/Devices/Sync`, null);
  }
}
