import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';

import { IAuthExtensionsService } from '@libs/portal-common/services';
import { IIntegrationAppService } from '../../abstractions';

@Injectable()
export class AuthExtensionsService implements IAuthExtensionsService {
  private integration: IIntegrationAppService = null;

  constructor() {}

  logout(): Observable<any> {
    if (!!this.integration) {
      return this.integration.signOut();
    }

    return of(true);
  }

  setIntegration(integration: IIntegrationAppService) {
    this.integration = integration;
  }
}
