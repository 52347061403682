import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { HttpClient } from '@angular/common/http';
import { ContactResponse } from '../models/rest/contact/contactResponses';
import {
  GetContactLocationsRequest,
  InsertOrUpdateContactLocationRequest,
  InsertOrUpdateContactRequest,
} from '../models/rest/contact/contactRequests';
import { BaseResponse, IRestBaseListResponse } from '../models/rest/baseResponse';
import { ContactLocationResponse, ContactLocationsResponse } from '../models/rest/contact/lotContactResponses';
import { GetOrDeleteLocationContactRequest, InsertOrUpdateLocationContactRequest } from '../models/rest/contact/lotContactRequests';
import { Contact, ContactIssue } from '../models/contact';
import { Issue } from '../models';

export interface IContactIssuesResponse extends BaseResponse {
  Issues: Array<Issue>;
}
export interface IContactIssueResponse extends BaseResponse {
  ContactIssue: ContactIssue;
}

@Injectable({ providedIn: 'root' })
export class ContactsService {
  constructor(private _httpClient: HttpClient) {}

  getAll(): Observable<Array<Contact>> {
    return this._httpClient.get<IRestBaseListResponse<Contact>>('Contacts').pipe(map((response) => response.Data));
  }

  getContact(contactId: number): Observable<ContactResponse> {
    return this._httpClient.get<ContactResponse>('Contacts/' + contactId);
  }

  createOrUpdateContact(request: InsertOrUpdateContactRequest): Observable<ContactResponse> {
    return this._httpClient.put<ContactResponse>('Contacts', request);
  }

  getContactUserAuth(userAuthId: number): Observable<any> {
    return this._httpClient.get<any>('Contacts/UserAuthDetails/' + userAuthId);
  }

  updatePortalAccess(Model): Observable<BaseResponse> {
    return this._httpClient.post<BaseResponse>('PortalAccess/Update', Model);
  }

  removePortalAccess(contactId): Observable<any> {
    return this._httpClient.request<any>('delete', 'PortalAccess', {
      body: { ContactId: contactId },
    });
  }

  getAgents(): Observable<any> {
    return this._httpClient.get<any>('Contacts?IsAgent=true');
  }

  sendRegKey(contactId, provider): Observable<any> {
    return this._httpClient.post<any>('Contact/SendRegKey', {
      ContactId: contactId,
      Provider: provider,
    });
  }

  me(): Observable<any> {
    return this._httpClient.post<any>('Contact/Me', null);
  }

  delete(Id): Observable<any> {
    return this._httpClient.delete<any>('Contacts?Id=' + Id);
  }

  getContactLocations(request: GetContactLocationsRequest): Observable<ContactLocationsResponse> {
    return this._httpClient.get<ContactLocationsResponse>(`Contact/${request.ContactId}/Lots`);
  }

  getContactLocation(request: GetOrDeleteLocationContactRequest): Observable<ContactLocationResponse> {
    return this._httpClient.get<ContactLocationResponse>(`Contact/${request.ContactId}/Lots/${request.LotId}`);
  }

  createOrUpdateContactLocation(request: InsertOrUpdateContactLocationRequest): Observable<ContactLocationResponse> {
    return this._httpClient.put<ContactLocationResponse>(
      `Contact/${request.ContactLocation.fkContact}/Lots/${request.ContactLocation.fkLot}`,
      request,
    );
  }

  deleteContactLocation(request: GetOrDeleteLocationContactRequest): Observable<ContactLocationResponse> {
    return this._httpClient.delete<ContactLocationResponse>(`Contact/${request.ContactId}/Lots/${request.LotId}`);
  }

  getContactIssues(contactId: number): Observable<IContactIssuesResponse> {
    return this._httpClient.get<IContactIssuesResponse>(`Contact/${contactId}/Issues`);
  }

  createOrUpdateContactIssue(contactIssue: ContactIssue): Observable<IContactIssueResponse> {
    return this._httpClient.post<IContactIssueResponse>('Contact/Issues', { ContactIssue: contactIssue });
  }

  deleteContactIssue(contactIssue: ContactIssue): Observable<BaseResponse> {
    return this._httpClient.delete<BaseResponse>(`Contact/${contactIssue.fkContact}/Issues/${contactIssue.fkIssue}`);
  }

  mergeContacts(contacts: Array<Contact>): Observable<ContactResponse> {
    return this._httpClient.put<ContactResponse>('Contacts/Merge', {Contacts: contacts});
  }
}
