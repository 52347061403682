import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { BsModalService, ModalDirective, ModalOptions } from 'ngx-bootstrap/modal';
import { ANALYTICS, CreateTicketModel, IAnalyticsService, TicketsService, TicketStatus } from '@libs/portal-common';
import { Location } from '@angular/common';
import { NavbarItemsService } from '../../../../services/access-management/navbar-items.service';
import { IncomingCall } from '../../../../abstractions';
import { filter } from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { AgentAnalyticsEvent } from '../../../../services';

@Component({
  templateUrl: 'create-ticket-modal.component.html',
  styleUrls: ['create-ticket-modal.component.scss'],
})
export class CreateTicketModalComponent implements OnInit {
  @ViewChild('createTicketModal', { static: true }) public createTicketModal: ModalDirective;

  private subscriptions = new Subscription();
  formGroup: FormGroup;
  ticket = new CreateTicketModel();
  incomingCall: IncomingCall;
  ticketStatuses = new Array<TicketStatus>();
  modalConfig: ModalOptions = { backdrop: 'static', keyboard: false, show: true };

  constructor(
    private bsModalService: BsModalService,
    private ticketsService: TicketsService,
    private location: Location,
    private navbarItemsService: NavbarItemsService,
    private formBuilder: FormBuilder,
    @Inject(ANALYTICS) private analytics: IAnalyticsService,
  ) {}

  ngOnInit() {
    this.formGroup = this.formBuilder.group({
      title: ['', [Validators.required, Validators.maxLength(30)]],
      statusId: ['', [Validators.required]],
      description: ['', [Validators.maxLength(220)]],
    });
    this.createTicketModal.config = this.modalConfig;
    this.ticketsService.getTicketStatuses().subscribe((response) => {
      this.ticketStatuses = response;
    });
    this.subscriptions.add(
      this.navbarItemsService.incomingCall$
        .pipe(filter((incomingCall) => !!incomingCall))
        .subscribe((incomingCall: IncomingCall) => (this.incomingCall = incomingCall)),
    );

    this.analytics.track(AgentAnalyticsEvent.CreateTicketOpen, null);
  }

  save() {
    if (this.formGroup.valid) {
      this.incomingCall.ticket = this.formGroup.getRawValue();
      this.navbarItemsService.setIncomingCall(this.incomingCall);
      this.close();
      this.analytics.track(AgentAnalyticsEvent.CreateTicketSaved, null);
    }
  }

  close() {
    this.createTicketModal.hide();
    this.location.back();
    this.subscriptions.unsubscribe();
  }
}
