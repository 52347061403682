import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

import { AppConfigurationService, APP_CONFIGURATION } from '../../../services/app-configuration.service';
import { isPlatformBrowser } from '@angular/common';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

export type CondensedConversationsMapType = { [key: string]: ICondensedConversation };

export interface ICondensedConversation {
  id: string;
  ani: string; // caller phone number
  calledNumber: string;
}

@Injectable()
export class GenesysEmbeddedService {
  private get softphone(): HTMLIFrameElement {
    return document.getElementById('genesysSoftphone') as HTMLIFrameElement;
  }

  private _initialized$ = new Subject<boolean>();
  private _conversations$ = new BehaviorSubject<CondensedConversationsMapType>({});
  private _token$ = new BehaviorSubject<string>(null);

  public get initialized$(): Observable<boolean> {
    return this._initialized$.asObservable();
  }
  public get conversations$(): Observable<CondensedConversationsMapType> {
    return this._conversations$.asObservable();
  }
  public get token$(): Observable<string> {
    return this._token$.asObservable();
  }

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(APP_CONFIGURATION) private configuration: AppConfigurationService,
  ) {
    if (isPlatformBrowser(platformId) && configuration.data.integration.type === 'genesys') {
      this.init();
    }
  }

  public updateUserStatus(status: string) {
    this.softphone.contentWindow.postMessage(
      JSON.stringify({
        type: 'updateUserStatus',
        data: { id: status },
      }),
      '*',
    );
  }

  public getAuthToken() {
    this.softphone.contentWindow.postMessage(
      JSON.stringify({
        type: 'getAuthToken',
      }),
      '*',
    );
  }

  public updateInteractionState(action, interactionId) {
    let payload = {
      action: action,
      id: interactionId,
    };
    this.softphone.contentWindow.postMessage(
      JSON.stringify({
        type: 'updateInteractionState',
        data: payload,
      }),
      '*',
    );
  }

  public setView(event) {
    let payload = {
      type: 'main',
      view: {
        name: event.target.outerText,
      },
    };
    this.softphone.contentWindow.postMessage(
      JSON.stringify({
        type: 'setView',
        data: payload,
      }),
      '*',
    );
  }

  private init() {
    const self = this;

    window.addEventListener('message', function (event: any) {
      if (!event?.data) {
        return;
      }

      var message = null;
      try {
        message = JSON.parse(event.data);
      } catch (err) {}

      if (!message) {
        return;
      }

      console.log('[UMOJO GENESYS]', 'message', message);

      if (message.type == 'genesys-initialized') {
        self._initialized$.next(true);
      } else if (message.type == 'genesys-authToken') {
        self._token$.next(message.data.token);
      } else if (message.type == 'genesys-userActionSubscription') {
        if (message.data?.category === 'login') {
          self.getAuthToken();
        } else if (message.data?.category === 'logout') {
          self._token$.next(null);
        }
      } else if (message.type == 'genesys-notificationSubscription') {
      }
    });
  }
}
