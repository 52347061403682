import { Component, OnInit, HostBinding, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';

import { Observable, Observer } from 'rxjs';

import { ModalDirective } from 'ngx-bootstrap/modal';

export class RevenueControlConfirmationParametes {
  okText = 'OK';
  cancelText = 'Cancel';

  constructor(
    public title: string,
    public message: string,
  ) {}
}

@Component({
  selector: 'app-revenue-control-confirmation',
  templateUrl: './revenue-control-confirmation.component.html',
  styleUrls: ['./revenue-control-confirmation.component.scss'],
})
export class RevenueControlConfirmationComponent implements OnInit {
  @ViewChild('modal', { static: true }) public modal: ModalDirective;
  @ViewChild('form', { static: false }) public form: NgForm;

  parameters: RevenueControlConfirmationParametes;

  validate = false;
  initialized = false;

  resultObserver: Observer<boolean>;

  constructor() {}

  ngOnInit() {}

  open(parameters: RevenueControlConfirmationParametes): Observable<boolean> {
    this.parameters = parameters;

    this.modal.show();

    setTimeout(() => (this.initialized = true), 30);

    return new Observable((observer) => (this.resultObserver = observer));
  }

  save() {
    this.modal.hide();
    this.resultObserver.next(true);
    this.resultObserver.complete();
  }

  cancel() {
    this.modal.hide();
    this.resultObserver.next(null);
    this.resultObserver.complete();
  }
}
