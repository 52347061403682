import { Directive, forwardRef, Input } from '@angular/core';
import { NG_VALIDATORS } from '@angular/forms';
import { Validator, AbstractControl } from '@angular/forms';

@Directive({
  selector: '[appValidateSip][formControlName], [appValidateSip][formControl],[appValidateSip][ngModel]',
  providers: [{ provide: NG_VALIDATORS, useExisting: forwardRef(() => SipValidatorDirective), multi: true }],
})
export class SipValidatorDirective implements Validator {
  constructor() {}

  validate(c: AbstractControl) {
    let value = <string>c.value;

    let EMAIL_REGEXP = /^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i;

    let isSip = !!value && value.startsWith('sip:');
    if (isSip) {
      let email = value.replace('sip:', '');
      if (EMAIL_REGEXP.test(email)) {
        return null;
      }
    }

    return {
      appValidateSip: {
        valid: false,
      },
    };
  }
}
