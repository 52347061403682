<div class="modal fade modal-dark" bsModal #modal="bs-modal" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header clearfix">
        <button
          type="button"
          class="close pull-right"
          (click)="leaveConversation()"
          [disabled]="leaveDisabled()"
          title="Leave conversation"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body">
        <div class="row participants" *ngIf="!!conversation">
          <div class="col-sm-4" *ngFor="let participant of conversation.participants$ | async">
            <app-conversation-participant [person]="participant" [avatarUrl]="participant.avatarUrl$ | async">
            </app-conversation-participant>
          </div>
        </div>
      </div>

      <div class="modal-footer">
        <button class="btn" (click)="leaveConversation()" [disabled]="leaveDisabled()" title="Leave conversation">
          <i class="skype-icon skype-wrap-up-icon"></i>
        </button>
        <!-- <button class="btn pull-right" (click)="cancel()" *ngIf="cancelEnabled()" title="Cancel">
          Cancel
        </button> -->
      </div>
    </div>
  </div>
</div>
