import { Component, OnInit, Input, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { VgApiService } from '@videogular/ngx-videogular/core';

@Component({
  selector: 'app-audio-player',
  templateUrl: './audio-player.component.html',
  styleUrls: ['./audio-player.component.scss'],
})
export class AudioPlayerComponent implements OnInit {
  @ViewChild('player', { static: true }) player: any;

  @Input() type: string;
  @Input() src: string;

  api: VgApiService;

  constructor() {}

  ngOnInit() {}

  pause() {
    this.api.pause();
  }

  onPlayerReady(api: VgApiService) {
    this.api = api;
  }
}
