import { Component, OnInit, HostBinding, Inject, PLATFORM_ID, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';

import { tap, filter, flatMap, first } from 'rxjs/operators';

import { ANALYTICS, IAnalyticsService, ILane } from '@libs/portal-common/services';
import { NavigationService } from '../services';
import { IncomingCall, Lot } from '../../abstractions';
import { Subscription } from 'rxjs';
import { NavbarItemsService } from '../../services/access-management/navbar-items.service';
import { CallProcessingService, IViewTabsData } from '../services/call-processing.service';
import { AgentAnalyticsEvent } from '../../services';

type CarouselSlideType = 'CAM' | 'Map';
interface CarouselSlide {
  type: CarouselSlideType;
}

@Component({
  selector: 'app-call-processing',
  templateUrl: './call-processing.component.html',
  styleUrls: ['./call-processing.component.scss'],
})
export class CallProcessingComponent implements OnInit, OnDestroy {
  @HostBinding('class') class = 'flex__fluid flex__box';

  hasNotifications = false;

  incomingCall: IncomingCall = null;

  selectedLane: ILane;

  onlyInfoPanel = false;

  activeSlideIndex = '0';
  carouselSlides = new Array<CarouselSlide>();
  isBrowser = false;

  locationInfo: Lot;
  incomingCallSubscription: Subscription;
  viewTabsData: IViewTabsData;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private route: ActivatedRoute,
    private navService: NavigationService,
    private callProcessingService: CallProcessingService,
    private navbarItemsService: NavbarItemsService,
    @Inject(ANALYTICS) private analytics: IAnalyticsService,
  ) {}

  ngOnInit() {
    this.isBrowser = isPlatformBrowser(this.platformId);

    this.navbarItemsService.startIncomingCallProcessing();
    this.clearSubscription();
    this.incomingCallSubscription = this.navService.incomingCall$
      .pipe(
        tap((incomingCall) => {
          this.locationInfo = null;
          this.incomingCall = incomingCall;
        }),

        filter((incomingCall) => !!incomingCall),
        flatMap((incomingCall) => {
          this.selectedLane = incomingCall.lane;

          this.hasNotifications = false;

          this.incomingCall.conversation.setParameters({
            lotName: incomingCall.location.LotName,
            terminateCallTone: incomingCall.lane ? incomingCall.lane.TerminateCallTone : null,
          });

          return this.incomingCall.conversation.state$;
        }),
        tap((state) => {
          this.locationInfo = !!this.incomingCall ? this.incomingCall.locationInfo.Lot : null;
          this.buildSlides(this.incomingCall);
        }),

        filter((state) => state === 'Finished'),
        first(),
      )
      .subscribe((state) => {
        this.navService.incomingCallFinished();
      });

    this.route.url.subscribe((url) => {
      let segment = this.route.snapshot.firstChild.url[0].path;
      this.onlyInfoPanel = segment === 'reservations-list' || segment === 'call-history';
    });

    this.callProcessingService.viewTabsData$.subscribe((data: IViewTabsData) => {
      this.viewTabsData = data;
    });
  }

  ngOnDestroy() {
    this.navbarItemsService.leaveIncomingCallProcessing();
    this.clearSubscription();
  }

  onLaneSelected(lane: ILane) {
    this.selectedLane = lane;
  }

  activeSlideChange(slideIndex: number) {
    this.activeSlideIndex = slideIndex.toString();

    const slide = slideIndex * 1;
    if (slide == 0) {
      this.analytics.track(AgentAnalyticsEvent.MapClicked, null);
    } else if (slide == 1) {
      this.analytics.track(AgentAnalyticsEvent.CamClicked, null);
    }
  }

  private buildSlides(incomingCall: IncomingCall) {
    let isVideoAvailable = !!incomingCall.location && !!incomingCall.lane && !!incomingCall.lane.DVR_Channel;

    let slides: Array<CarouselSlide> = [];
    if (isVideoAvailable) {
      slides = [{ type: 'CAM' }, { type: 'Map' }];
    } else {
      slides = [{ type: 'Map' }, { type: 'CAM' }];
    }

    let changed = JSON.stringify(slides) !== JSON.stringify(this.carouselSlides);
    if (changed) {
      this.carouselSlides = slides;
    }
  }

  public openTab(index) {
    this.viewTabsData.activeTab = index;
  }

  private clearSubscription() {
    if (!!this.incomingCallSubscription) {
      this.incomingCallSubscription.unsubscribe();
      this.incomingCallSubscription = null;
    }
  }
}
