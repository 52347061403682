import { NgModule, ModuleWithProviders } from '@angular/core';

import { FinesseAppService } from './services/finesse-app.service';
import { FinesseHttpClient, FINESSE_HTTP_INTERCEPTORS } from './services/finesse-httpclient';
import { FinesseStateService } from './services/finesse-state.service';
import { FinesseAuthInterceptor } from './services/finesse-auth.interceptor';

@NgModule({})
export class FinesseModule {
  static forRoot(): ModuleWithProviders<FinesseModule> {
    return {
      ngModule: FinesseModule,
      providers: [
        { provide: FINESSE_HTTP_INTERCEPTORS, useClass: FinesseAuthInterceptor, multi: true },
        FinesseStateService,
        FinesseHttpClient,
        FinesseAppService,
      ],
    };
  }

  constructor() {}
}
