import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { IRestBaseListResponse } from '../models/rest/baseResponse';

export interface IRegion {
  Id?: number;
  fkParent?: number;
  Name: string;
  RegionCode?: number;
}

@Injectable({ providedIn: 'root' })
export class RegionsService {
  constructor(private _httpClient: HttpClient) {}

  getAll(): Observable<IRestBaseListResponse<IRegion>> {
    return this._httpClient.get<IRestBaseListResponse<IRegion>>('Region');
  }

  getById(Id): Observable<any> {
    return this._httpClient.request<any>('get', 'Region', {
      body: { Id },
    });
  }
  insert(model): Observable<any> {
    return this._httpClient.post<any>('Region', { Region: model });
  }
  update(model): Observable<any> {
    return this._httpClient.put<any>('Region', { Region: model });
  }
  delete(Id): Observable<any> {
    return this._httpClient.delete<any>('Region', {
      body: { Id },
    });
  }
  deleteHierarchy(Id): Observable<any> {
    return this._httpClient.delete<any>(`Region/${Id}/hierarchy`, {
      body: { Id },
    });
  }
}
