import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { OnlyAnonimousGuard, AuthenticatedGuard } from '@libs/portal-common/services';

import { LoginComponent } from './login.component';
import { LogoutComponent } from './logout.component';
import { RegistrationComponent } from './registration/registration.component';
import { AuthErrorComponent } from './auth-error/auth-error.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent, canActivate: [OnlyAnonimousGuard] },
  { path: 'login/internal', component: LoginComponent, canActivate: [OnlyAnonimousGuard] },
  { path: 'logout', component: LogoutComponent, canActivate: [AuthenticatedGuard] },
  { path: 'registration', component: RegistrationComponent, canActivate: [OnlyAnonimousGuard] },
  { path: 'error', component: AuthErrorComponent },

  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthRoutingModule {}
