export const CallSessionStateChanged = 'CallSessionStateChanged';

export enum ConferenceStatusAction {
  Accepted = 0,
  CallerClosedBeforeAnswer = 1,
  CallerClosedAfterAnswer = 2,
  SupervisorClosed = 3,
  AgentClosed = 4,
  OnHold = 5,
  Terminated = 6,
  Ringing = 7,
  Ignored = 8,
  Resumed = 9,
  Monitoring = 10,
  PickedUp = 11,
  WarmInviteSent = 12,
  WarmInviteAccepted = 13,
  WarmInviteRejected = 14,
  WarmAccepted = 15,
  WarmCanceled = 16,
  WarmSwitchToCaller = 17,
  WarmSwitchToAgent = 18,
  OperatorPark = 19,
  OperatorHold = 20,
  OperatorColdTransfered = 21,
  OperatorColdTransferInviteSent = 22,
  OperatorColdTransferInviteAccepted = 23,
  OperatorColdTransferInviteRejected = 24,
  OperatorColdTransferInviteCancelled = 25,
  Fallback = 26,
  SimultaniousIgnored = 27,
  OperatorInterruptEnded = 28,
  CallerAudioAddedToConference = 29,
  TrustedParticipantAudioAdded = 30,
  TrustedParticipantForAutoRecordingAdded = 31,
  TrustedParticipantForManualRecordingAdded = 32,
  TrustedParticipantForVoiceMailRecordingAdded = 33,
  TrustedParticipantAudioForAgentsAdded = 34,
  ConferenceReceivedFromPool = 35,
  NewConferenceCreated = 36,
  PersonalParked = 37,
  MonitoringEnded = 38,
  QueueWarmTransferStarted = 39,
  QueueWarmTransferCommited = 40,
  QueueWarmTransferCancelled = 41,
  QueueWarmTransferAcceptedBySecondAgent = 42,
  QueueWarmTransferSwitchedToCaller = 43,
  QueueWarmTransferSwitchedToAgent = 44,
  QueueColdTransferToCC4SQueue = 45,
  AgentColdTransfered = 46,
  InviteAccepted = 47,
}

export type ConferenceStatusActionStr =
  | 'Accepted'
  | 'CallerClosedBeforeAnswer'
  | 'CallerClosedAfterAnswer'
  | 'SupervisorClosed'
  | 'AgentClosed'
  | 'OnHold'
  | 'Terminated'
  | 'Ringing'
  | 'Ignored'
  | 'Resumed'
  | 'Monitoring'
  | 'PickedUp'
  | 'WarmInviteSent'
  | 'WarmInviteAccepted'
  | 'WarmInviteRejected'
  | 'WarmAccepted'
  | 'WarmCanceled'
  | 'WarmSwitchToCaller'
  | 'WarmSwitchToAgent'
  | 'OperatorPark'
  | 'OperatorHold'
  | 'OperatorColdTransfered'
  | 'OperatorColdTransferInviteSent'
  | 'OperatorColdTransferInviteAccepted'
  | 'OperatorColdTransferInviteRejected'
  | 'OperatorColdTransferInviteCancelled'
  | 'Fallback'
  | 'SimultaniousIgnored'
  | 'OperatorInterruptEnded'
  | 'CallerAudioAddedToConference'
  | 'TrustedParticipantAudioAdded'
  | 'TrustedParticipantForAutoRecordingAdded'
  | 'TrustedParticipantForManualRecordingAdded'
  | 'TrustedParticipantForVoiceMailRecordingAdded'
  | 'TrustedParticipantAudioForAgentsAdded'
  | 'ConferenceReceivedFromPool'
  | 'NewConferenceCreated'
  | 'PersonalParked'
  | 'MonitoringEnded'
  | 'QueueWarmTransferStarted'
  | 'QueueWarmTransferCommited'
  | 'QueueWarmTransferCancelled'
  | 'QueueWarmTransferAcceptedBySecondAgent'
  | 'QueueWarmTransferSwitchedToCaller'
  | 'QueueWarmTransferSwitchedToAgent'
  | 'QueueColdTransferToCC4SQueue'
  | 'AgentColdTransfered'
  | 'InviteAccepted';

export enum MonitorTypes {
  None = 0,
  Silent,
  Whisper,
  BargeIn,
}

export enum MediaType {
  Call = 0,
  Mail = 1,
  Instant = 2,
  Chat = 3,
  SocialMedia = 4,
}

export enum HandlingStatus {
  UnAssigned = 0,
  Ringing,
  InCall,
  WarmRequested,
  WarmAgent,
  WarmCaller,
  Terminated,
  OnHold,
  Resumed,
  OperatorColdTransferInviteSent,
  OperatorColdTransferInviteAccepted,
  OperatorColdTransferInviteRejected,
  OperatorColdTransferInviteCancelled,
  PersonalParked,
  QueueWarmTransferStarted,
  QueueWarmTransferCancelled,
  QueueWarmTransferCommited,
  QueueWarmTransferAcceptedBySecondAgent,
  QueueWarmTransferSwitchedToCaller,
  QueueWarmTransferSwitchedToAgent,
  QueueColdTransferToCC4SQueue,
  AgentColdTransfered,
}

export interface CallSession {
  QueueRef: number;
  AgentRef: number;
  PrimaryAgentSIP: string;
  CallerUri: string;
  CallerName: string;
  AgentName: string;
  QueueName: string;
  Skill: string;
  StartDate: Date;
  ConferenceAction: ConferenceStatusAction;
  ConferenceActionAsString: ConferenceStatusActionStr;
  ConversationKey: string;
  ConferenceUri: string;
  TrustedEndPoint: string;
  MonitorType: MonitorTypes;
  MonitorTypeAsString: string;
  ActiveSupervisorSIP: string;
  MediaType: MediaType;
  MediaTypeAsString: string;
  SupervisorRef: number;
  SupervisorRefList: Array<number>;
  SecondaryAgentSIP: string;
  IsOperatorCall: boolean;
  IsOutboundCall: boolean;
  Status: HandlingStatus;
  StatusAsString: string;
  SupervisorSIPList: Array<string>;
  PrimaryAgentName: string;
  SecondaryAgentName: string;
  ActiveSupervisorName: string;
  SessionId: string;
  TransferSourceSIP: string;
  TransferTargetUri: string;
  CallStartDate: Date;
  MessageDate: Date;
  LineUri: string;
  CallerSIP: string;
  IsCampaignCall: boolean;
  IsTeamsCampaignOutboundCall: boolean;
  IsTransferedToExternal: boolean;
  IsWarmTransferInProgress: boolean;
  IsCallerOnHold: boolean;
  QueueWarmTransferCommitedForQueue: boolean;
  ActionHistory: Array<ConferenceStatusAction>;
  ActionHistoryAsString: Array<string>;
  OwnerKey: string;
  AllRelatedTargets: Array<string>;
  UrlExecuter: Map<string, string>;
}
