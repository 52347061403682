<div class="modal fade modal-dark" bsModal #modal="bs-modal" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog revenue-modal">
    <div class="modal-content" *ngIf="parameters">
      <div class="modal-header clearfix" [class.modal-header-empty]="!parameters.title">
        <h4 *ngIf="!!parameters.title" class="modal-title pull-left">{{ parameters.title }}</h4>

        <button type="button" class="close pull-right" aria-label="Close" (click)="cancel()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body">
        <ng-template appDynComponentHost></ng-template>
      </div>

      <div class="modal-footer">
        <button class="btn btn-primary btn-sm pull-left" (click)="save()" [disabled]="!canSave()" *ngIf="!!parameters?.okButtonText">
          {{ parameters.okButtonText }}
        </button>
        <button class="btn btn-default btn-sm" (click)="cancel()">{{ parameters.cancelButtonText }}</button>
      </div>
    </div>
  </div>
</div>
