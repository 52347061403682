import { Injectable, Inject } from '@angular/core';

import { AES, enc } from 'crypto-js';

import { AppConfigurationService, APP_CONFIGURATION } from './app-configuration.service';

export class Credentials {
  login = '';
  password = '';
}

@Injectable()
export class CredentialsStorageService {
  constructor(@Inject(APP_CONFIGURATION) private configuration: AppConfigurationService) {}

  saveCredentials(name: string, credentials: Credentials) {
    let json = JSON.stringify(credentials);
    let ciphertext = AES.encrypt(json, this.configuration.data.skype.appCryptoKey);
    localStorage.setItem(name, /*'skype'*/ ciphertext);
  }

  readCredentials(name: string): Credentials {
    let ciphertext = localStorage.getItem(name);
    if (!ciphertext) {
      return null;
    }

    let bytes = AES.decrypt(ciphertext, this.configuration.data.skype.appCryptoKey);
    let plaintext = bytes.toString(enc.Utf8);
    if (plaintext) {
      let json = JSON.parse(plaintext);
      return json;
    }

    return null;
  }

  removeCredentials(name: string) {
    localStorage.removeItem(name);
  }

  saveSkypeCredentials(credentials: Credentials) {
    this.saveCredentials('skype', credentials);
  }
  readSkypeCredentials(): Credentials {
    return this.readCredentials('skype');
  }
  removeSkypeCredentials() {
    localStorage.removeItem('skype');
  }
}
