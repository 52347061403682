import { Component, Input } from '@angular/core';
@Component({
  selector: 'app-field-view',
  templateUrl: './field-view.component.html',
  styleUrls: ['./field-view.component.scss'],
})
export class FieldViewComponent {
  _fieldValue = '';
  @Input('fieldValue') set fieldValue(value: any) {
    if (value || value === 0) {
      this._fieldValue = String(value);
    }
    if (!value) {
      this._fieldValue = '';
    }
  }
  constructor() {
  }
}
