import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseResponse } from '../models/rest/baseResponse';

export class CreateTicketModel {
  title: string;
  description: string;
  statusId: number;
  ownerId: number;
  incidentId: number;
  locationId: number;
}

export class Ticket {
  Id: number;
  Title: string;
  Description: string;
  StatusId: number;
  Status: string;
  OwnerId: number;
  LocationId: number;
  Location: any;
  IncidentId: number;
  Incident: any;
  IsSubscribed: boolean;
  Subscribers: [any];
  Modified: any;
  Created: any;
  Name: string;
  IsEditable: boolean;
}

export class TicketStatus {
  Id: number;
  Status: string;
}

export interface TicketsResponse extends BaseResponse {
  Groups: Array<any>;
  Data: Array<Ticket>;
  Total: number;
}

export interface StatusesResponse extends BaseResponse {
  Data: Array<TicketStatus>;
}

@Injectable()
export class TicketsService {
  constructor(private _httpClient: HttpClient) {}

  // Statuses

  getTicketStatuses(regionId?: number): Observable<Array<TicketStatus>> {
    let params = new HttpParams();
    if (!!regionId) {
      params = params.set('fkRegion', regionId.toString());
    }

    return this._httpClient.get<StatusesResponse>('Tickets/Statuses', { params: params }).pipe(
      map((response) => {
        return response.Data;
      }),
    );
  }

  getTicketStatuseById(ticketStatusId): Observable<any> {
    return this._httpClient.get<any>('Tickets/Statuses/' + ticketStatusId);
  }

  addTicketStatus(ticketStatus): Observable<any> {
    return this._httpClient.put<any>('Tickets/Statuses/', { ticketStatus });
  }

  updateTicketStatus(ticketStatus): Observable<any> {
    return this._httpClient.post<any>('Tickets/Statuses/', { ticketStatus });
  }

  deleteTicketStatus(ticketStatusId): Observable<any> {
    return this._httpClient.delete<any>('Tickets/Statuses/' + ticketStatusId);
  }

  // Tickets

  getTickets(state: any): Observable<TicketsResponse> {
    let url = 'Tickets/';
    let params = {
      group: state.group,
      filter: state.filter,
      skip: state.skip,
      limit: state.take,
      sort: state.sort,
    };

    return this._httpClient.post<TicketsResponse>(url, params);
  }

  getTicketById(ticketId): Observable<any> {
    return this._httpClient.get<any>('Tickets/' + ticketId);
  }

  getTicketByIncidentId(incidentId): Observable<any> {
    return this._httpClient.get<any>(`Incidents/${incidentId}/Ticket`);
  }

  createOrUpdateTicket(ticket: any): Observable<any> {
    return this._httpClient.put<any>('Tickets/', { ticket });
  }

  deleteTicket(ticketId): Observable<any> {
    return this._httpClient.delete<any>('Tickets/' + ticketId);
  }

  // Ticket Comments

  getTicketComments(ticketId): Observable<any> {
    return this._httpClient.get<any>('Tickets/' + ticketId + '/Comments');
  }

  addTicketComment(ticketComment): Observable<any> {
    return this._httpClient.put<any>('Tickets/' + ticketComment.TicketId + '/Comments', { ticketComment });
  }

  updateTicketComment(ticketComment): Observable<any> {
    return this._httpClient.post<any>('Tickets/' + ticketComment.TicketId + '/Comments', { ticketComment });
  }

  deleteTicketComment(ticketId, ticketCommentId): Observable<any> {
    return this._httpClient.delete<any>('Ticket/' + ticketId + '/Comments/' + ticketCommentId);
  }

  // Ticket Subscription

  subscribeTicket(ticketId): Observable<any> {
    return this._httpClient.put<any>('Tickets/' + ticketId + '/Subscription/', { TicketId: ticketId });
  }

  unsubscribeTicket(ticketId): Observable<any> {
    return this._httpClient.delete<any>('Tickets/' + ticketId + '/Subscription');
  }
}
