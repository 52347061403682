import { Injectable, ErrorHandler, Inject, Injector } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { AppNotificationsService } from '@libs/portal-common/system';
import { ANALYTICS, IAnalyticsService } from './services';

const NG_ERRORS = ['NG0100', 'NG0200', 'NG0201', 'NG0203',
  'NG0209', 'NG0300', 'NG0301', 'NG0302', 'NG0403', 'NG0500',
  'NG0501', 'NG0502', 'NG0503', 'NG0504', 'NG0505', 'NG0506',
  'NG0507', 'NG602', 'NG0910', 'NG0912', 'NG0913', 'NG01101',
  'NG01203', 'NG02200', 'NG02800', 'NG5000', 'NG05104'];

@Injectable()
export class GlobalErrorHandler extends ErrorHandler {
  constructor(@Inject(Injector) private readonly injector: Injector) {
    super();
  }

  private get toast() {
    return this.injector.get(AppNotificationsService);
  }

  protected get analytics(): IAnalyticsService {
    return this.injector.get(ANALYTICS);
  }

  handleError(error: Error) {
    if (this.checkNGErrors(error.message)) {
      console.log(error.message);
      return;
    }
    if (!(error instanceof HttpErrorResponse)) {
      this.toast.error('Oops! We encountered a specific error. Please try again later.');
    }

    super.handleError(error);
  }

  private checkNGErrors(errorMessage: string) {
    let invalid = false;
    NG_ERRORS.forEach((ngErrorCode: string) => {
      if (errorMessage.includes(ngErrorCode)) {
        invalid = true;
      }
    });

    return invalid;
  }
}
