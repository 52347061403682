<div class="grid-container grid-page">
  <div class="filter-container filter row">
    <div class="form-group col-sm-6 col-sm-6 col-md-4 col-lg-2">
      <label>From</label>
      <kendo-datetimepicker
        [readOnlyInput]="true"
        [readonly]="filterLoading"
        class="umj-datepicker-sm"
        [(value)]="filter.start"
        style="width: 100%"
      >
      </kendo-datetimepicker>
    </div>
    <div class="form-group col-sm-6 col-sm-6 col-md-4 col-lg-2">
      <label>To</label>
      <kendo-datetimepicker
        class="umj-datepicker-sm"
        [readOnlyInput]="true"
        [readonly]="filterLoading"
        [(value)]="filter.end"
        style="width: 100%"
      >
      </kendo-datetimepicker>
    </div>

    <div class="form-group col-sm-2" *ngIf="contactsEnabled">
      <label>Contact</label>
      <kendo-dropdownlist
        [data]="filteredContacts"
        [(value)]="filter.contactId"
        [filterable]="true"
        (filterChange)="handleContactsFilter($event)"
        [textField]="'Name'"
        [valueField]="'Id'"
        [defaultItem]="{ Id: null, Name: 'Select contact...' }"
        class="form-control-sm dropdown-block"
        [disabled]="filterLoading"
        [valuePrimitive]="true"
        style="width: 100%"
      >
      </kendo-dropdownlist>
    </div>

    <div class="form-group col-sm-2">
      <label>Incident Id</label>
      <input type="number" class="form-control" [(ngModel)]="filter.incidentId" [disabled]="filterLoading" style="height: 30px" />
    </div>

    <div class="form-group col-sm-2">
      <button class="btn btn-primary btn-sm btn-search" [disabled]="filterLoading" (click)="search()">Select</button>
    </div>
  </div>

  <div class="activity-grid kendo-grid-bordered" #qaGridContainer>
    <kendo-grid
      [data]="view | async"
      [pageSize]="state.take"
      [skip]="state.skip"
      [sort]="state.sort"
      [sortable]="{ mode: 'multiple' }"
      [height]="300"
      [pageable]="true"
      [groupable]="false"
      [rowClass]="rowCallback.bind(this)"
      [selectable]="true"
      (dataStateChange)="dataStateChange($event)"
      (excelExport)="excelExport($event)"
      [filterable]="'menu'"
      [filter]="state.filter"
      (selectionChange)="rowSelectionChange($event)"
    >
      <ng-template kendoGridToolbarTemplate>
        <button kendoGridPDFCommand><span class="k-icon k-i-file-pdf"></span>Export to PDF</button>
        <button kendoGridExcelCommand><span class="k-icon k-i-file-excel"></span>Export to Excel</button>
      </ng-template>

      <kendo-grid-pdf
        [fileName]="'QualityAnalysis.pdf'"
        [allPages]="true"
        paperSize="A4"
        [repeatHeaders]="false"
        [landscape]="true"
        [scale]="0.5"
      >
        <kendo-grid-pdf-margin top="2cm" left="1cm" right="1cm" bottom="2cm"></kendo-grid-pdf-margin>
      </kendo-grid-pdf>

      <kendo-grid-excel [fileName]="excelFileName">
        <kendo-excelexport-column title="Incident ID" field="fkIncident" [cellOptions]="cells"> </kendo-excelexport-column>
        <kendo-excelexport-column title="Created" field="Created" [cellOptions]="cells"></kendo-excelexport-column>
        <kendo-excelexport-column title="Contact" field="fkContact" [hidden]="!contactsEnabled" [cellOptions]="cells">
        </kendo-excelexport-column>
      </kendo-grid-excel>

      <kendo-grid-column title="Incident ID" field="fkIncident" filter="numeric" format="{0:n0}" width="80">
        <ng-template kendoGridCellTemplate let-dataItem>
          {{ dataItem.fkIncident }}
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column title="Created" field="Created" width="120" [filterable]="false">
        <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
          Date: {{ value | kendoDate: "MMM d hh:mm:ss a" }}
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          {{ dataItem.Created | kendoDate: "MMM d hh:mm:ss a" }}
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column title="Call Time" field="CallTime" width="120" [filterable]="false">
        <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
          Call Time Date: {{ value | kendoDate: "MMM d hh:mm:ss a" }}
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          {{ dataItem.CallTime | kendoDate: "MMM d hh:mm:ss a" }}
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column title="Contact" field="fkAgent" [hidden]="!contactsEnabled" width="80">
        <ng-template kendoGridCellTemplate let-dataItem>
          {{ creatorName(dataItem.fkAgent) }}
        </ng-template>

        <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter" let-filterService="filterService">
          <kendo-multiselect
            [data]="contacts"
            [value]="selectedContact"
            (valueChange)="multiSelectValueChange($event, column.field, filterService)"
            [valuePrimitive]="true"
            [textField]="'Name'"
            [valueField]="'Id'"
          >
          </kendo-multiselect>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column
        *ngFor="let question of questions"
        [title]="question.QuestionName"
        [field]="question.QuestionTitle"
        [filterable]="false"
        [sortable]="false"
        width="80"
      >
        <ng-template kendoGridCellTemplate let-dataItem>
          <span
            [class.activity-alarm]="getQuestionAnswer(question, dataItem) === 'No'"
            [class.activity-success]="getQuestionAnswer(question, dataItem) === 'Yes'"
          >
            {{
              question.AnswerType == 'MultiChoice'
                ? parseArray(getQuestionAnswer(question, dataItem))
                : getQuestionAnswer(question, dataItem)
            }}
          </span>
        </ng-template>
      </kendo-grid-column>
    </kendo-grid>
  </div>
</div>
