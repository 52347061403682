import { NgModule, ModuleWithProviders, Injector, APP_INITIALIZER, PLATFORM_ID } from '@angular/core';
import { CommonModule, LOCATION_INITIALIZED, DOCUMENT, isPlatformServer } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { ValidationModule } from '@libs/portal-common/validation';

import { GooglePlacesAutocompleteDirective } from './google-places-autocomplete/google-places-autocomplete.directive';
import { LocationAddressComponent } from './location-address/location-address.component';

import { AppConfigurationService, APP_CONFIGURATION, IAppConfigurationBase } from '@libs/portal-common/services';

export function loadGoogleApi(
  injector: Injector,
  document: Document,
  config: AppConfigurationService<IAppConfigurationBase>,
  platformId: Object,
) {
  return () =>
    new Promise((resolve) => {
      if (isPlatformServer(platformId)) {
        resolve(null);
        return;
      }

      const locationInitialized = injector.get(LOCATION_INITIALIZED, Promise.resolve(null));

      Promise.all([locationInitialized, config.load()]).then(() => {
        const key = config.data.keys.googleApi;
        const scriptUrl = `https://maps.googleapis.com/maps/api/js?key=${key}&libraries=places&language=en-US&region=us`;

        loadExternalScript(scriptUrl)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            console.log('loading Google API failed:', err);
          });
      });
    });

  function loadExternalScript(scriptUrl: string): Promise<boolean> {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.defer = true;
    script.src = scriptUrl;

    let promise = new Promise<boolean>((resolve: Function, reject: Function) => {
      script.onload = (evt: Event) => {
        resolve(true);
      };
      script.onerror = (error: Event) => {
        reject(error);
      };
    });

    document.body.appendChild(script);
    return promise;
  }
}

@NgModule({
  imports: [CommonModule, FormsModule, ValidationModule],
  declarations: [GooglePlacesAutocompleteDirective, LocationAddressComponent],
  exports: [LocationAddressComponent, GooglePlacesAutocompleteDirective],
})
export class GoogleModule {
  static forRoot(): ModuleWithProviders<GoogleModule> {
    return {
      ngModule: GoogleModule,
      providers: [
        {
          provide: APP_INITIALIZER,
          useFactory: loadGoogleApi,
          deps: [Injector, DOCUMENT, APP_CONFIGURATION, PLATFORM_ID],
          multi: true,
        },
      ],
    };
  }
}
