import { Component, OnInit, OnDestroy, HostBinding, Inject } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

import { Subscription, zip } from 'rxjs';

import { NavigationService } from '../../../services';
import {
  LocationsService,
  ContactsService,
  GetLocationContactsRequest,
  LocationContact,
  Shift,
  ANALYTICS,
  IAnalyticsService,
} from '@libs/portal-common/services';
import { Contact, IncomingCall } from '../../../../abstractions';
import { AppNotificationsService } from '@libs/portal-common';
import { AgentAnalyticsEvent } from '../../../../services';

@Component({
  selector: 'app-general-information',
  templateUrl: './general-information.component.html',
  styleUrls: ['./general-information.component.scss'],
})
export class GeneralInformationComponent implements OnInit, OnDestroy {
  @HostBinding('class') class = 'info-tab-content';

  private subscriptions: Subscription = new Subscription();
  calling = false;

  private incomingCall: IncomingCall;

  locationInfo: any;
  instructions: Array<{ title?: string }> = [];
  lotContacts: Array<LocationContact> = [];
  allContacts: Contact[] = [];

  constructor(
    private sanitizer: DomSanitizer,
    private navService: NavigationService,
    private locationsService: LocationsService,
    private contactsService: ContactsService,
    private notifications: AppNotificationsService,
    @Inject(ANALYTICS) private analytics: IAnalyticsService,
  ) {}

  ngOnInit() {
    this.subscriptions.add(
      this.navService.incomingCall$.subscribe((incomingCall) => {
        let info = !!incomingCall ? incomingCall.locationInfo : null;
        if (info) {
          this.locationInfo = info.Lot;
          this.instructions = this.locationInfo && this.locationInfo.Contacts ? JSON.parse(this.locationInfo.Contacts) : [];

          this.getContacts(info.Lot.Id);
        } else {
          this.locationInfo = null;
          this.instructions = [];
          this.lotContacts = [];
        }

        this.incomingCall = incomingCall;
      }),
    );

    this.analytics.track(AgentAnalyticsEvent.GeneralInfoClicked, null);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  buildHref(target: string, proto: string) {
    let result = target ? `${proto}:${target}` : '#';
    return this.sanitizer.bypassSecurityTrustUrl(result);
  }

  skypeCall(phone: string, $event: any) {
    let uri = phone.indexOf('tel:') > -1 || phone.indexOf('sip:') > -1 ? phone : `tel:${phone}`;

    this.calling = true;
    this.incomingCall.conversation.outboundCall(uri).subscribe(
      (call) => {
        this.calling = false;
      },
      (err) => {
        this.notifications.error(`Failed to call ${phone}`);
        this.calling = false;
      },
    );
  }

  getShiftByContactIdAndShiftId(contactId: number, shiftId: number): string {
    return this.allContacts.find((contact: Contact) => contact.Id === contactId)?.Shifts.find((shift: Shift) => shift.Id === shiftId)
      ?.Shift;
  }

  private getContacts(locationId: number) {
    let request = <GetLocationContactsRequest>{ LotId: locationId };
    zip(this.locationsService.getLocationContacts(request), this.contactsService.getAll()).subscribe((result) => {
      let lotContacts: LocationContact[] = result[0].LocationContacts.filter((x) => x.IsAgentPage);
      let contacts: Contact[] = result[1];
      this.allContacts = contacts;

      lotContacts.forEach((lotContact) => {
        let contact = contacts.find((item) => item.Id === lotContact.fkContact);
        if (contact) {
          lotContact.Email = contact.Email;
          lotContact.Name = contact.Name;
        }
      });

      this.lotContacts = lotContacts;
    });
  }
}
