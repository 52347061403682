<div class="container">
  <div class="row">
    <div class="center-form panel" style="width: 50%; margin: 10% auto; border: none">
      <div class="text-center" style="background-color: #f3f3f4; margin-top: -1px; padding-bottom: 2em">
        <img class="img-responsive" src="/img/splash.png" alt="" />
      </div>

      <div class="panel-body">
        <div class="internalAuthForm">
          <h2 class="text-center">Error</h2>
          <p>{{ error }}</p>
          <div class="row">
            <div class="col-sm-6">
              <button class="btn btn-lg btn-block button--primary" (click)="mainPage()">Main page</button>
            </div>
            <div class="col-sm-6">
              <button class="btn btn-lg btn-block button--primary" (click)="logout()">Logout</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
