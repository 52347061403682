<form #form="ngForm" *ngIf="initialized" class="form-inline modal-form ticket-filter" novalidate>
  <div class="form-group col-sm-12 from-group-sm validation-container" [class.has-error]="ticketNumberField | hasErrors: validating">
    <input
      class="form-control"
      type="text"
      [(ngModel)]="ticketNum"
      name="ticketNum"
      #ticketNumberField="ngModel"
      appValidateNumber
      placeholder="Ticket №"
    />
    <app-validation-errors [model]="ticketNumberField" [validate]="validating"></app-validation-errors>
  </div>
</form>
<button class="btn btn-sm button--primary btn-load-info" type="submit" [disabled]="busy" (click)="showTicketInfo()">
  <span *ngIf="!busy">Show ticket</span>
  <span *ngIf="busy"> <i class="fa fa-spinner fa-spin"></i> loading</span>
</button>

<table class="table table-sm table-hover">
  <thead>
    <tr>
      <th>Ticket Id</th>
      <th>Ticket №</th>
      <th>Arrived</th>
      <th>Total Discounts</th>
      <th>License Plate</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let ticket of tickets" (click)="select(ticket)" [class.active]="ticket.Id === selectedTicket?.Id">
      <td>{{ ticket.Id }}</td>
      <td>{{ ticket.TicketNum }}</td>
      <td>{{ ticket.EntryDateTime | ConvertSecsToDate: 2000 | dateFormat: 'M/D/YYYY HH:mm' }}</td>
      <td>{{ ticket.TotalDiscounts }}</td>
      <td>{{ ticket.LicensePlate }}</td>
    </tr>
  </tbody>
</table>

<form
  #priceForm="ngForm"
  *ngIf="initialized && validationEnabled && !!selectedTicket"
  class="form-horizontal modal-form form-validate-ticket ticket-filter"
  novalidate
>
  <div class="form-group from-group-sm validation-container" [class.has-error]="price | hasErrors: validating">
    <label class="col-sm-3 control-label">Price</label>
    <kendo-dropdownlist
      [data]="prices"
      [(value)]="priceId"
      [textField]="'ValidationName'"
      [valueField]="'ValidationId'"
      class="dropdown-block"
      [valuePrimitive]="true"
      class="form-control-sm col-sm-9"
      name="price"
      required
    >
    </kendo-dropdownlist>
    <app-validation-errors [model]="price" [validate]="validating"></app-validation-errors>
  </div>
</form>
