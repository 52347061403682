<div class="heatmap-container" #heatmapContainer>
  <table class="table table-condensed table-bordered heatmap-table" #heatmap>
    <thead>
      <tr>
        <th class="dates">DATES</th>
        <th *ngFor="let item of timeScale">{{ item }}</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let row of rows">
        <td>{{ row.date | dateFormat: 'ddd MMM DD' }}</td>
        <td
          *ngFor="let time of timeScale"
          [style.background-color]="interpolateColor(row[time], maxCalls).toRGB()"
          [style.color]="getCellColor(row[time])"
        >
          {{ row[time] }}
        </td>
      </tr>
    </tbody>
  </table>
  <app-no-content *ngIf='rows?.length === 0' [isWhiteBackground]='true'></app-no-content>
  <kendo-excelexport [data]="rows" #excel>
    <kendo-excelexport-column width="80" field="date" title="DATES"> </kendo-excelexport-column>

    <kendo-excelexport-column *ngFor="let item of timeScale" [field]="item" [title]="item"> </kendo-excelexport-column>
  </kendo-excelexport>
</div>
