import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';

export const enum AgentFilterLists {
  WhiteDomains = 1,
  WhiteWorkflows = 2,
  WhiteContacts = 3,
}

export class AgentFilter {
  Id: number;
  List: AgentFilterLists;
  Value: string;
  fkRegion?: number;
}

@Injectable({ providedIn: 'root' })
export class AgentFiltersService {
  constructor(private _httpClient: HttpClient) {}

  // Get all filters for a list
  getList(listId: number, regionId?: number): Observable<any> {
    let params = new HttpParams().set('List', listId.toString());
    if (!!regionId) {
      params = params.set('RegionId', regionId.toString());
    }

    return this._httpClient.get<any>('Authentication/AgentFilters', { params: params });
  }

  // Get a filter
  get(filterId: number): Observable<any> {
    return this._httpClient.get<any>('Authentication/AgentFilters/' + filterId);
  }

  // Create (if id = 0) or Update (if id > 0) a filter
  createOrUpdate(filter: AgentFilter): Observable<any> {
    return this._httpClient.put<any>('Authentication/AgentFilters/', { filter });
  }

  // Delete a filter
  delete(filterId: number): Observable<any> {
    return this._httpClient.delete<any>('Authentication/AgentFilters/' + filterId);
  }
}
