import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap, map, filter, flatMap } from 'rxjs/operators';

import { HttpClient } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class LanesService {
  constructor(private _httpClient: HttpClient) {}

  getLotLanes(LotId): Observable<any> {
    return this._httpClient.get<any>('Lot/Lanes?LotId=' + LotId).pipe(map((response) => response.Data));
  }

  insertOrUpdate(lane): Observable<any> {
    return this._httpClient.put<any>('Lot/Lanes', { lane });
  }

  delete(Id): Observable<any> {
    return this._httpClient.delete<any>('Lot/Lanes/' + Id);
  }
}
