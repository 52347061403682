import { AppConfigurationService, APP_CONFIGURATION, IAppConfigurationBase } from './app-configuration.service';
import { Injectable, Inject } from '@angular/core';
import { SignalRBaseService } from './signalr-base.service';
import { Observable } from 'rxjs';
import { AppStateService } from './app-state.service';

export interface IAgentInfo {
  ConnectionId: any;
  ConnectionTime: any;
  ComputerName: string;
  IpAddress: string;
  OSVersion: string;
  Version: string;
  SkypeDisplayName: string;
  WindowsUserName: string;
}

export interface IAgentChangedEvent {
  agentInfo: IAgentInfo;
  senderId: string;
}

@Injectable({ providedIn: 'root' })
export class AgentsMonitoringSignalRService extends SignalRBaseService {
  protected groupName = 'Portal';

  public get agentConnectedEvent$(): Observable<IAgentChangedEvent> {
    return this.on<IAgentChangedEvent>('AgentConnectedEvent');
  }
  public get agentDisconnectedEvent$(): Observable<IAgentChangedEvent> {
    return this.on<IAgentChangedEvent>('AgentDisconnectedEvent');
  }
  public get agentChangedEvent$(): Observable<IAgentChangedEvent> {
    return this.on<IAgentChangedEvent>('AgentChangedEvent');
  }

  constructor(
    @Inject(APP_CONFIGURATION) private configuration: AppConfigurationService<IAppConfigurationBase>,
    appState: AppStateService,
  ) {
    super(configuration, appState);
  }
}
