import { Component, OnInit, Inject } from '@angular/core';

import { Router } from '@angular/router';

import { AppStateService } from '@libs/portal-common/services';
import { TeamsIntegrationService } from '../services';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
})
export class HomeComponent implements OnInit {
  model = {
    caller: null,
    callId: null,
    sessionId: null,
    agentSip: null,
  };

  constructor(
    private router: Router,
    private teams: TeamsIntegrationService,
    private appState: AppStateService,
  ) {}

  ngOnInit(): void {}

  public submitForm() {
    this.router.navigate(['/call', 'start-incoming-call'], {
      queryParams: {
        caller: this.model.caller,
        callId: this.model.callId,
        session_id: this.model.sessionId,
        agentSip: this.model.agentSip,
        test: true,
      },
    });

    // let url = `${this.getBaseUrl()}/skype/start-incoming-call/${this.model.caller}`;
    // let taskInfo: microsoftTeams.TaskInfo = {
    //   title: 'Incoming call',
    //   height: microsoftTeams.TaskModuleDimension.Large,
    //   width: 1400,
    //   url: url,
    //   card: null,
    //   fallbackUrl: null,
    //   completionBotId: null,
    // };

    // let result = this.teams.startTask(taskInfo, (err, result) => {
    //   console.log(`Submit handler - err`, err);
    //   console.log(`Submit handler - result`, result);
    // });
  }

  private getBaseUrl(): string {
    const loc = window.location;
    return `${loc.protocol}//${loc.hostname}` + (loc.port ? ':' + loc.port : '');
  }

  private getQueryStringValue(key: string): string {
    return decodeURIComponent(
      window.location.search.replace(
        new RegExp('^(?:.*[&\\?]' + encodeURIComponent(key).replace(/[\.\+\*]/g, '\\$&') + '(?:\\=([^&]*))?)?.*$', 'i'),
        '$1',
      ),
    );
  }
}
