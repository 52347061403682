<div class="modal fade app-modal" bsModal #modal="bs-modal" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-body">
        <button type="button" class="close" aria-label="Close" (click)="cancel()">
          <span aria-hidden="true">&times;</span>
        </button>

        <img [src]="imageUrl" (error)="onImageError()" />
      </div>
    </div>
  </div>
</div>
