import { Component, OnInit, Input } from '@angular/core';
import { ISkypePerson } from '../../../abstractions';

@Component({
  selector: 'app-skype-contact',
  templateUrl: './skype-contact.component.html',
  styleUrls: ['./skype-contact.component.scss'],
})
export class SkypeContactComponent implements OnInit {
  @Input() person: ISkypePerson;
  @Input() avatarUrl: string;

  constructor() {}

  ngOnInit() {}
}
