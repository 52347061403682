import { Inject, Injectable } from '@angular/core';

import { IIntegrationAppService } from '../../abstractions';
import { Five9AppService } from '../../integrations/five9';
import { FinesseAppService } from '../../integrations/cisco-finesse';
import { CC4AllAppService } from '../../integrations/cc4all';
import { GenesysAppService } from '../../integrations/genesys';
import { NoIntegrationAppService } from '../../integrations/no-integration';

import { AppConfigurationService, APP_CONFIGURATION } from '../../services/app-configuration.service';

@Injectable()
export class IntegrationAppFactoryService {
  private services = {};

  constructor(
    private five9: Five9AppService,
    private finesse: FinesseAppService,
    private cc4All: CC4AllAppService,
    private genesys: GenesysAppService,
    private noIntegration: NoIntegrationAppService,
    @Inject(APP_CONFIGURATION) private configuration: AppConfigurationService,
  ) {}

  getApp(test: boolean = false): IIntegrationAppService {
    const type = test ? 'test' : this.configuration.data.integration.type;

    if (!this.services[type]) {
      this.services[type] = this.createAppService(test);
    }

    return this.services[type];
  }

  private createAppService(test: boolean = false): IIntegrationAppService {
    if (test) {
      return this.noIntegration;
    }

    if (this.configuration.data.integration.type === 'five9') {
      return this.five9;
    } else if (this.configuration.data.integration.type === 'cisco') {
      return this.finesse;
    } else if (this.configuration.data.integration.type === 'cc4all') {
      return this.cc4All;
    } else if (this.configuration.data.integration.type === 'genesys') {
      return this.genesys as GenesysAppService;
    }

    return this.noIntegration;
  }
}
