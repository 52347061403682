import { AppConfigurationService, IAppConfigurationBase } from './app-configuration.service';
import { Observable, fromEventPattern } from 'rxjs';

import { HttpTransportType, HubConnectionBuilder } from '@microsoft/signalr';
import { HttpParams } from '@angular/common/http';
import { AppStateService } from './app-state.service';

export abstract class SignalRBaseService {
  private connection: any = null;
  private running: Promise<boolean> = null;

  private hubName = 'GRMHub';
  private signalrAddress: string;

  protected abstract get groupName(): string;

  protected constructor(
    configuration: AppConfigurationService<IAppConfigurationBase>,
    protected appState: AppStateService,
  ) {
    let baseUrl: string = configuration.signalrBaseUrl;

    this.signalrAddress = `${baseUrl}/${this.hubName}`;
  }

  public init(): Promise<boolean> {
    this.createConnection();
    return this.startConnection();
  }

  protected on<TEvent>(event: string): Observable<TEvent> {
    const self = this;
    self.createConnection();

    let observable = fromEventPattern<TEvent>(
      function add(h) {
        self.connection.on(event, h);
      },
      function remove(h) {
        self.connection.off(event, h);
      },
    );

    return observable;
  }

  protected invoke<TParams>(method: string, params: TParams = null): Promise<void> {
    let self = this;

    return this.startConnection().then((res) => self.connection.invoke(method, params));
  }

  private startConnection(): Promise<boolean> {
    if (this.running) {
      return this.running;
    }

    this.running = this.connection
      .start()
      .then(() => {
        console.log('SignalR connection has been established');
        return true;
      })
      .catch((error) => {
        console.error('SignalR connection establishing has been failed');
        console.log(error);
        return false;
      });

    return this.running;
  }

  private createConnection(): void {
    if (this.connection) {
      return;
    }
    console.log(`SignalR connection to '${this.signalrAddress}' is being created...`);

    let params = new HttpParams();
    params = params.set('ClientType', this.groupName);

    const authToken = this.appState.getAuthToken();
    if (!!authToken) {
      params = params.set('ss-id', authToken);
    }

    this.connection = new HubConnectionBuilder()
      .withUrl(this.signalrAddress + '?' + params.toString(), {
        skipNegotiation: true,
        transport: HttpTransportType.WebSockets,
      })
      .withAutomaticReconnect()
      .build();
  }
}
