<button *ngIf="options.closeButton" (click)="remove()" type="button" class="toast-close-button" aria-label="Close">
  <span aria-hidden="true">&times;</span>
</button>
<div *ngIf="title" [class]="options.titleClass" [attr.aria-label]="title">
  {{ title }} <ng-container *ngIf="duplicatesCount">[{{ duplicatesCount + 1 }}]</ng-container>
</div>
<div *ngIf="message && options.enableHtml" role="alert" [class]="options.messageClass" [innerHTML]="message"></div>
<div *ngIf="message && !options.enableHtml" role="alert" [class]="options.messageClass" [attr.aria-label]="message">
  {{ message }}
</div>
<a *ngIf="options.traceId" role="alert" class="dev-data" (click)="traceIdClick($event)">
  {{ options.traceId }}
</a>
<div *ngIf="options.date" role="alert" class="dev-data" [class]="options.messageClass">
  {{ options.date }}
</div>
<div *ngIf="options.progressBar">
  <div class="toast-progress" [style.width]="width + '%'"></div>
</div>
