import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ProvidersService {
  constructor(private _httpClient: HttpClient) {}

  getByType(ProviderType: string): Observable<any> {
    let params = new HttpParams().set('ProviderType', ProviderType);
    return this._httpClient.get<any>('Providers', { params: params });
  }

  insertOrUpdate(provider): Observable<any> {
    return this._httpClient.put<any>('Providers', { provider });
  }

  delete(Id): Observable<any> {
    return this._httpClient.delete<any>('Providers/' + Id);
  }
}
