import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';

import { FieldValue, IncomingCall, Issue, IssueField, Problem } from '../../../abstractions';
import { IncomingCallsService, IssueSubmissionService, NavigationService } from '../../services';

import { Observable, of, Subscription, zip } from 'rxjs';
import { filter, finalize, first, mergeMap, tap } from 'rxjs/operators';

import { ANALYTICS, IAnalyticsService, ILane, RevenueControlService, TicketsService } from '@libs/portal-common/services';
import { AppNotificationsService } from '@libs/portal-common/system';

import { AgentAnalyticsEvent, TeamsIntegrationService } from '../../../services';
import { NgForm } from '@angular/forms';

const COMMON_FIELDS = [
  { FieldName: 'cAudioVideoQuality', IsStatic: true, FieldType: 'string' },
  { FieldName: 'cSupport', IsStatic: true, FieldType: 'string' },
];

@Component({
  selector: 'app-call-processing-form',
  templateUrl: './call-processing-form.component.html',
  styleUrls: ['./call-processing-form.component.scss'],
})
export class CallProcessingFormComponent implements OnInit, OnDestroy {
  public _form: NgForm;

  _incomingCall: IncomingCall;
  get incomingCall(): IncomingCall {
    return this._incomingCall;
  }
  @Input() set incomingCall(value: IncomingCall) {
    this.clearSubscriptions();

    this._incomingCall = value;
    if (value) {
      this.subscribeToIncomingCall();
    }
  }

  @Output() laneSelected = new EventEmitter<ILane>();
  @ViewChild('form') set formControlsAndValidationRules(form: NgForm) {
    this._form = form;
    if (this._form) this.patchFormValidStatus();
  }
  private subscriptions: Array<Subscription> = [];
  selectedProblem: Problem = null;
  selectedIssue: Issue = null;

  values = new Map<string, any>();

  _vendGate = false;
  vendGateResult = '';
  revenueControlEnabled = false;

  vendCode = '';

  constructor(
    private notifications: AppNotificationsService,
    private revenueControl: RevenueControlService,
    private teams: TeamsIntegrationService,
    private incomingCallsService: IncomingCallsService,
    private issueSubmission: IssueSubmissionService,
    private nav: NavigationService,
    private ticketsService: TicketsService,
    @Inject(ANALYTICS) private analytics: IAnalyticsService,
  ) {}

  ngOnInit() {
    this.selectedProblem = null;
    this.selectedIssue = null;
    this._vendGate = false;

    this.subscriptions.push(
      this.nav.supportData$.pipe(filter((data) => !!data)).subscribe((data) => {
        this.values.set('cAudioVideoQuality', data.audioVideoQuality);

        if (data.support) {
          this.values.set('cSupport', data.support);
        }
      }),
    );
  }

  ngOnDestroy() {
    this.clearSubscriptions();
  }

  onLaneSelected(lane: ILane) {
    this.laneSelected.emit(lane);
    this.analytics.track(AgentAnalyticsEvent.LaneSelected, null);
  }

  selectProblem(problem: Problem) {
    this.selectedProblem = problem;
    this.setConversationParameters();
  }

  unSelectProblem() {
    this.vendGateResult = '';

    this.selectedProblem = null;
    this.selectedIssue = null;
    this.setConversationParameters();
    this.incomingCallsService.setValidConversation(true);
  }

  selectIssue(issue: Issue, $event) {
    this.vendGateResult = '';
    this.selectedIssue = issue;
    this.setConversationParameters();
    $event.stopPropagation();
  }

  unSelectIssue() {
    this.selectedIssue = null;
    this.setConversationParameters();
    this.incomingCallsService.setValidConversation(true);
  }

  private subscribeToIncomingCall(): void {
    if (this.incomingCall) {
      this.vendCode =
        (!!this.incomingCall.lane ? this.incomingCall.lane.Gate_Vend_Code : null) || this.incomingCall.location.AnalogGateVendCode;

      this.unSelectProblem();
      this.values = new Map<string, any>();

      this.subscriptions.push(
        this.incomingCall.conversation.state$
          .pipe(
            filter((state) => state === 'Finished'),
            first(),
          )
          .subscribe((state) => {
            this.saveForm();
          }),
      );
    }
  }

  getValue(field: any) {
    if (this.values.has(field.FieldName)) {
      return this.values.get(field.FieldName) || null;
    }

    return null;
  }

  setValue(field: any, value: any) {
    this.values.set(field.FieldName, value);
    this.patchFormValidStatus();
  }

  excludeHidden(fields: Array<IssueField>): Array<IssueField> {
    return fields.filter(
      (field) => field.FieldName !== 'cAlert' && field.FieldName !== 'cAudioVideoQuality' && field.FieldName !== 'cSupport',
    );
  }

  saveForm() {
    const fields: Array<FieldValue> = this.collectFields();

    if (!this._form || !this._form.valid) {
      return;
    }

    this.setConversationParameters();

    // we shouldn't save form fields when handling test call
    if (this.incomingCall.conversation.skipSavingIncident) {
      console.log('LOG submitted fields:', fields);
      this.teams.submitTask(fields);
      return;
    }

    this.issueSubmission
      .wrapupAndSave(this.incomingCall, fields, this.incomingCall.conversation.submissionParameters)
      .pipe(
        filter((res) => !!res && !!res.Incident?.Id),
        mergeMap((res) =>
          zip(
            this.incomingCall.conversation.submitWrapUp(this.incomingCall.conversation.submissionParameters),
            this.incomingCall.ticket ? this.updateTicketByIncidentId(res.Incident.Id) : of(res),
          ),
        ),
        finalize(() => this.clearSubscriptions()),
      )
      .subscribe(
        () => {},
        (err) => {
          console.log(err);
        },
        () => {
          this.clearSubscriptions();
        },
      );
  }

  private issueFields(issue: Issue): Array<IssueField> {
    return !!issue ? issue.Fields : [];
  }

  private patchFormValidStatus() {
    this.incomingCallsService.setValidConversation(this._form ? this._form.valid : true);

    if (this._form && Object.keys(this._form.form.controls).length === 0) {
      setTimeout(() => {
        this.incomingCallsService.setValidConversation(this._form.valid);
      });
    }
  }

  private collectFields(): Array<FieldValue> {
    let fields = new Array<FieldValue>();
    this.values.forEach((value, fieldName) => {
      let issueField =
        this.issueFields(this.selectedIssue).find((field) => field.FieldName === fieldName) ||
        COMMON_FIELDS.find((field) => field.FieldName === fieldName);

      if (issueField) {
        fields.push({
          FieldName: issueField.FieldName,
          FieldType: issueField.FieldType,
          Value: value,
          IsStatic: issueField.IsStatic,
        });
      }
    });
    return fields;
  }

  vendGate() {
    this.revenueControlEnabled = !!this.getRevenueControlName();

    this.analytics.track(AgentAnalyticsEvent.VendGateClicked, null);
    if (this.revenueControlEnabled) {
      this.vendGate_revenueControl();
    } else {
      this.vendGate_dtmf();
    }
  }

  vendGate_dtmf() {
    this.subscriptions.push(
      this.incomingCall.conversation.sendDtmf(this.vendCode).subscribe(() => {
        this._vendGate = true;
        this.vendGateResult = 'vended';
        this.notifications.success('Gate vended');
      }, this.handleVendGateError),
    );
  }

  vendGate_revenueControl() {
    this.revenueControl
      .openGate({
        LotId: this.incomingCall.location.Id,
        LaneId: this.incomingCall.lane.Id,
      })
      .subscribe((res) => {
        if (res.Success) {
          this._vendGate = true;
          this.vendGateResult = 'vended';
          this.notifications.success('Gate vended');
        } else {
          this.vendGateResult = 'failed';
          this.notifications.error(res.Message || 'Vend gate failed');
        }
      }, this.handleVendGateError);
  }

  private handleVendGateError(err: any) {
    this.vendGateResult = 'failed';
    this.notifications.error(err);
  }

  private setConversationParameters() {
    this.incomingCall.conversation.submissionParameters.fkProblem = null;
    this.incomingCall.conversation.submissionParameters.ProblemName = null;
    this.incomingCall.conversation.submissionParameters.fkIssue = null;
    this.incomingCall.conversation.submissionParameters.IssueName = null;
    this.incomingCall.conversation.submissionParameters.GateVend = false;
    this.incomingCall.conversation.submissionParameters.MgrNotified = false;

    if (!!this.selectedProblem) {
      this.incomingCall.conversation.submissionParameters.fkProblem = this.selectedProblem.Id;
      this.incomingCall.conversation.submissionParameters.ProblemName = this.selectedProblem.ProblemName;
    }

    if (!!this.selectedIssue) {
      this.incomingCall.conversation.submissionParameters.fkIssue = this.selectedIssue.Id;
      this.incomingCall.conversation.submissionParameters.IssueName = this.selectedIssue.IssueName;
      this.incomingCall.conversation.submissionParameters.GateVend = this._vendGate;
      this.incomingCall.conversation.submissionParameters.MgrNotified = this.selectedIssue.IsNotify;
    }
  }

  clearSubscriptions() {
    this.subscriptions.forEach((x) => x.unsubscribe());
    this.subscriptions = [];
  }

  fieldProperties(field: IssueField): any {
    if (field.FieldType === 'DropDown') {
      let result = (<any>field).fieldProperties;
      if (result) {
        return result;
      }

      let module = JSON.parse(field.Module || '{DropDownList: []}');
      result = module.DropDownList || [];

      (<any>field).fieldProperties = result;
      return result;
    }

    return {};
  }

  unknownFiled(fieldType: string): boolean {
    return (
      fieldType !== 'string' &&
      fieldType !== 'DateTime' &&
      fieldType !== 'Decimal' &&
      fieldType !== 'bool' &&
      fieldType !== 'int' &&
      fieldType !== 'DropDown'
    );
  }

  getRevenueControlName(): string {
    if (
      !!this.incomingCall &&
      !!this.incomingCall.lane &&
      !!this.incomingCall.lane.FacilityLaneCode &&
      !!this.incomingCall.locationInfo &&
      this.incomingCall.locationInfo.Lot.RevenueControlEnabled
    ) {
      return this.incomingCall.locationInfo.Lot.RevenueControlManufacturer.Name;
    }

    return null;
  }

  vendGateEnabled(): boolean {
    return !!this.vendCode || !!this.getRevenueControlName();
  }

  private updateTicketByIncidentId(incidentId: number): Observable<any> {
    this.incomingCall.ticket.incidentId = incidentId;
    this.incomingCall.ticket.locationId = this.incomingCall.location.Id;

    return this.ticketsService.createOrUpdateTicket(this.incomingCall.ticket);
  }
}
