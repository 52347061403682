import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { IConversation } from '../../abstractions';

import { LocationsService, LanesService, LocationLaneResolverService } from '@libs/portal-common/services';
import { AppNotificationsService } from '@libs/portal-common/system';

export interface IRevenueControlManufacturer {
  Id: number;
  Name: string;
  IsIntegrated: boolean;
  CanOpenGate: boolean;
  CanCloseGate: boolean;
  CanSetPrice: boolean;
  CanSetRate: boolean;
  CanEjectTicket: boolean;
  CanSwallowTicket: boolean;
  CanShowTicketInfo: boolean;
}

import { IncomingCall } from '../../abstractions';

@Injectable()
export class IncomingCallsService {
  public isValidIncomingCallRecords$ = new BehaviorSubject<boolean>(false);

  get isValidConversation(): Observable<boolean> {
    return this.isValidIncomingCallRecords$.asObservable();
  }

  setValidConversation(flag: boolean) {
    this.isValidIncomingCallRecords$.next(flag);
  }

  constructor(
    private locationsService: LocationsService,
    private lanesService: LanesService,
    private locationLaneResolver: LocationLaneResolverService,
    private notifications: AppNotificationsService,
  ) {}

  resolveIncomingCall(conversation: IConversation): Observable<IncomingCall> {
    return this.locationLaneResolver.resolveUri(conversation.callerUri).pipe(
      map((res) => {
        let result = new IncomingCall(conversation);
        result.locations = res.locations;
        result.location = res.location;

        result.lanes = res.lanes;
        result.lane = res.lane;

        return result;
      }),
    );
  }

  // getLocationManagementProperties(locationId: number): Observable<ILocationManagementProperties> {
  //   return this.locationsService.getBy({ Id: locationId })
  //     .pipe(
  //       map(res => {
  //         if (res.Success) {
  //           return {
  //             RevenueControlManufacturer: <IRevenueControlManufacturer>(res.Lot.RevenueControlManufacturer),
  //             RevenueControlEnabled: !!res.Lot.RevenueControlEnabled,
  //             AnalogGateVendCode: res.Lot.AnalogGateVendCode
  //           };
  //         } else {
  //           this.notifications.error(res.Message);
  //           return null;
  //         }
  //       })
  //     );
  // }
}
