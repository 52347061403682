import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

import { GadgetConfig } from '../model/gadget-config';
import { AppConfigurationService, APP_CONFIGURATION } from '../../../services/app-configuration.service';

@Injectable()
export class FinesseStateService {
  private _gadgetConfig: GadgetConfig;

  public get authHeader(): string {
    if (!!this._gadgetConfig) {
      return `Basic ${this._gadgetConfig.authorization}`;
    }

    return null;
  }

  public get finesseRestUrl(): string {
    if (!!this._gadgetConfig) {
      return `${this._gadgetConfig.scheme}://${this._gadgetConfig.host}:${this._gadgetConfig.hostPort}`;
    }

    return null;
  }

  public get gadgetConfig(): GadgetConfig {
    return this._gadgetConfig;
  }

  constructor(
    @Inject(PLATFORM_ID) platformId: Object,
    @Inject(APP_CONFIGURATION) private configuration: AppConfigurationService,
  ) {
    if (isPlatformBrowser(platformId) && this.configuration.data.integration.type === 'cisco') {
      let gadget = this.queryParameter('state');
      if (!!gadget) {
        gadget = window.atob(gadget);
        gadget = JSON.parse(gadget);
        this._gadgetConfig = gadget;
      }

      if (!gadget) {
        console.log('No Finess gadget found.');
        return;
      }
    }
  }

  private queryParameter(key) {
    let vars = [],
      hash;
    let hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
    for (let i = 0; i < hashes.length; i++) {
      hash = hashes[i].split('=');
      vars.push(hash[0]);
      vars[hash[0]] = hash[1];
    }
    return vars[key];
  }
}
