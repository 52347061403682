import { Injectable, ElementRef } from '@angular/core';

import * as $ from 'jquery';

export class ProgressBar {
  private $element: any;

  constructor(container: ElementRef) {
    this.$element = $(container?.nativeElement);

    this.$element?.append(`<div class="loading-indicatior k-loading-color">
                                <div class="k-loading-pdf-mask">
                                    <span class="k-i-loading k-icon"></span>
                                </div>
                            </div>`);
  }

  dispose() {
    this.$element?.find('.loading-indicatior').remove();
  }
}

@Injectable({ providedIn: 'root' })
export class ProgressBarService {
  constructor() {}

  show(el: ElementRef): ProgressBar {
    return new ProgressBar(el);
  }
}
