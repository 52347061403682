<div class="media">
  <div class="media-left">
    <object
      class="media-object contact-image"
      [data]="avatarUrl | safeUrl"
      *ngIf="!!avatarUrl"
      type="image/jpg"
      typemustmatch="true"
    ></object>
    <div class="media-object contact-image-placeholder" *ngIf="!avatarUrl"></div>
  </div>
  <div class="media-body">
    <h4 class="media-heading">{{ person.displayName$ | async }}</h4>
  </div>
</div>
