import { Injectable, InjectionToken, Injector } from '@angular/core';
import { HttpBackend, HttpClient, HttpInterceptor } from '@angular/common/http';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { CustomHttpHandler } from '../../custom-http-handler';

import { X2JS } from '../../xml2json';

import { IDialog, IUser, UserState } from '../model';

export const FINESSE_HTTP_INTERCEPTORS = new InjectionToken<HttpInterceptor[]>('FINESSE_HTTP_INTERCEPTORS');

@Injectable()
export class FinesseHttpClient extends HttpClient {
  private x2js = new X2JS({});

  constructor(
    backend: HttpBackend,
    private injector: Injector,
  ) {
    super(new CustomHttpHandler(backend, injector, FINESSE_HTTP_INTERCEPTORS));
  }

  public getUser(id: string): Observable<IUser> {
    return this.getJSON<{ User: IUser }>(`User/${id}`).pipe(map((x) => x.User));
  }

  public getUserDialogs(id: string): Observable<Array<IDialog>> {
    return this.getJSON<{ Dialogs: any }>(`User/${id}/Dialogs`).pipe(
      map((x) => {
        if (Array.isArray(x.Dialogs)) {
          return x.Dialogs.map((d) => d.Dialog);
        }

        if (!!x.Dialogs.Dialog) {
          return [x.Dialogs.Dialog];
        }

        return [];
      }),
    );
  }

  public setUserState(id: string, state: UserState): Observable<any> {
    return this.putJSON<any>(`User/${id}`, { User: { state: state } }).pipe(map((x) => x.User));
  }

  public finishCall(callId: string, userExt: string): Observable<any> {
    return this.putJSON<any>(`Dialog/${callId}`, {
      Dialog: {
        requestedAction: 'DROP',
        targetMediaAddress: userExt,
      },
    }).pipe(map((x) => x.User));
  }

  public sendDTMF(callId: string, dtmf: string, userExt: string): Observable<any> {
    return this.putJSON<any>(`Dialog/${callId}`, {
      Dialog: {
        requestedAction: 'SEND_DTMF',
        targetMediaAddress: userExt,
        actionParams: [
          {
            ActionParam: {
              name: 'dtmfString',
              value: dtmf,
            },
          },
        ],
      },
    }).pipe(map((x) => x.User));
  }

  private getJSON<T>(url: string): Observable<T> {
    return this.get(url, { responseType: 'text' }).pipe(
      map((x) => {
        const json = this.x2js.xml_str2json(x);
        return json;
      }),
    );
  }

  private putJSON<T>(url: string, body: any): Observable<T> {
    let xmlBody = this.x2js.json2xml_str(body).trim();

    return this.put(url, xmlBody, { responseType: 'text' }).pipe(
      map((x) => {
        const json = this.x2js.xml_str2json(x);
        return json;
      }),
    );
  }
}
