<div bsModal #modal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-sm">
    <div class="modal-content">
      <div class="modal-header clearfix">
        <h4 class="modal-title pull-left">{{ title }}</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="cancel()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body overflow-hidden">
        {{ message }}
      </div>
      <div class="modal-footer">
        <button class="button ND--buttons--second-button" (click)="cancel()">Cancel</button>
        <button class="button ND--buttons--primary" (click)="ok()">Confirm</button>
      </div>
    </div>
  </div>
</div>
