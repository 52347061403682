import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-no-content',
  templateUrl: 'no-content.component.html',
  styles: [
    '.no-content {\n' +
      '  text-align: center;\n' +
      '  padding: 100px;\n' +
      '  font-style: italic;\n' +
      '  font-size: 24px;\n' +
      '  font-weight: 200;\n' +
      '}',
  ],
})
export class NoContentComponent {
  @Input() text = 'There are no items to display';
  @Input() isWhiteBackground = false;
  constructor() {}
}
