import { Component, OnInit, HostBinding, Input, OnChanges, ViewChild, ElementRef, Output, EventEmitter, Inject } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

import { toDataURL, ExcelExportComponent } from '@progress/kendo-angular-excel-export';
import { saveAs } from '@progress/kendo-file-saver';

import { ActivityReportName, ANALYTICS, AnalyticsEvent, IAnalyticsService, IncidentService } from '@libs/portal-common/services';
import { daysOfWeek } from '@libs/portal-common/shared';

import { Color, PdfExporterService, ProgressBarService } from '@libs/portal-common/shared';

import * as moment from 'moment';
import Papa from 'papaparse';

export class HeatmapFilter {
  Start = new Date(new Date().setHours(0, 0, 0, 0));
  End = new Date(new Date().setHours(23, 59, 0, 0));
  LotId = null;
  DayOfWeek = null;
  MinTotalDuration = null;
  MinWrapUpTime = null;
  MinQueueTime = null;
  MinCallTime = null;
  Interval = 60;
}

@Component({
  selector: 'app-heatmap',
  templateUrl: './heatmap.component.html',
  styleUrls: ['./heatmap.component.scss'],
})
export class HeatmapComponent implements OnInit, OnChanges {
  @Input() filter: HeatmapFilter;
  @ViewChild('excel', { static: true }) private excel: ExcelExportComponent;
  @ViewChild('heatmap', { static: true }) private heatmap: ElementRef;
  @ViewChild('heatmapContainer', { static: true }) heatmapContainer: ElementRef;

  @Output() busy = new EventEmitter<boolean>();

  private _ready = new BehaviorSubject(false);

  startColor = new Color(255, 255, 255); // white
  endColor = new Color(243, 30, 116); // some pink

  loading = false;

  timeScale = [];

  days = [];

  locations = [];
  filteredLocations = [];

  rows = new Array<any>();

  maxCalls = 0;

  get ready$() {
    return this._ready;
  }

  interpolateColor = Color.interpolateColor;

  constructor(
    private incidentService: IncidentService,
    private _pdf: PdfExporterService,
    private _progressBarService: ProgressBarService,
    @Inject(ANALYTICS) private analytcis: IAnalyticsService,
  ) {
    this.days = daysOfWeek;
  }

  handleDaysFilter(value) {
    this.days = daysOfWeek.filter((s) => s.Name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
  }
  handleLocationsFilter(value) {
    this.filteredLocations = this.locations.filter((s) => s.LotName.toLowerCase().indexOf(value.toLowerCase()) !== -1);
  }

  getCellColor(calls): string {
    if (this.maxCalls / 2 <= calls) {
      return '#fff';
    }

    return '#111';
  }

  ngOnInit() {
    this.timeScale = this.getTimeScale(this.filter.Interval);
    this.analytcis.track(AnalyticsEvent.DailyActivityOpen, { ReportName: ActivityReportName.Heatmap });
  }

  ngOnChanges(changes) {
    if (changes.filter.currentValue) {
      setTimeout(() => this.loadHeatmap());
    }
  }

  search() {
    this.loadHeatmap();
  }

  loadHeatmap() {
    this._ready.next(false);
    this.maxCalls = 0;
    this.loading = true;

    let self = this;
    this.timeScale = this.getTimeScale(this.filter.Interval);

    this.busy.emit(true);
    return this.incidentService.getHeatmap(this.filter).subscribe(
      (response) => {
        let dates = new Map();

        (response.LocationHeatmaps || []).forEach(function (item) {
          if (!dates.has(item.Date)) {
            dates.set(item.Date, {});
          }

          let date = dates.get(item.Date);

          let startHour = moment(item.StartHour).format('h:mm A');
          if (!date[startHour]) {
            date[startHour] = 0;
          }

          let calls = (date[startHour] += item.Calls);

          if (calls > self.maxCalls) {
            self.maxCalls = calls;
          }
        });

        this.loading = false;

        this.rows = this.prepareRows(dates);
        setTimeout(() => {
          this._ready.next(true);
        }, 2000);

        this.busy.emit(false);
      },
      (err) => {
        this.busy.emit(false);
      },
    );
  }

  csvExport() {
    let self = this;
    if (!this.rows || this.rows.length === 0) {
      return;
    }

    this.analytcis.track(AnalyticsEvent.DailyActivityExport, { ReportName: ActivityReportName.Heatmap, ExportType: 'Csv' });

    let innerResArr = ['Dates'].concat(this.timeScale);
    let resArr = [];
    resArr.push(innerResArr);
    this.rows.forEach((row, index) => {
      innerResArr = [];
      innerResArr.push(moment(row[0]).format('MM/DD/YYYY') + '\t');
      for (let i = 0; i < self.timeScale.length; i++) {
        if (row[self.timeScale[i]]) {
          innerResArr.push(row[self.timeScale[i]]);
        } else {
          innerResArr.push('0');
        }
      }
      resArr.push(innerResArr);
    });

    let csvContent = Papa.unparse(resArr);
    let fileName = 'heatmap.csv';

    if (this.isMsi()) {
      let blob = new Blob(['sep=,\r\n' + csvContent], { type: 'text/csv;charset=utf-8;' });
      navigator.msSaveBlob(blob, fileName);
    } else {
      let encodedUri = encodeURI('data:text/csv;charset=utf-8,sep=,\n' + csvContent);
      let link = document.createElement('a');
      link.setAttribute('href', encodedUri);
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

  saveAsPdf(fileName = 'heatmap.pdf') {
    this._pdf.pdfExport(this.heatmap, fileName);
    this.analytcis.track(AnalyticsEvent.DailyActivityExport, { ReportName: ActivityReportName.Heatmap, ExportType: 'Pdf' });
  }

  excelExport() {
    this.analytcis.track(AnalyticsEvent.DailyActivityExport, { ReportName: ActivityReportName.Heatmap, ExportType: 'Excel' });
    let workbookOptions = this.excel.workbookOptions();
    let dataRows = workbookOptions.sheets[0].rows.filter((row) => (<any>row).type === 'data');
    let header = workbookOptions.sheets[0].rows.filter((row) => (<any>row).type !== 'data');

    let cellBorderConfig = { color: '#e0e0e0' };

    dataRows.forEach((row) => {
      let cells = row.cells;
      cells.forEach((cell, index) => {
        if (index === 0) {
          cell.format = 'ddd mmm yy';
        } else {
          cell.background = this.interpolateColor(<number>cell.value, this.maxCalls).toHex();
          cell.color = this.getCellColor(<number>cell.value);
          cell.textAlign = 'center';
        }

        cell.borderBottom = cellBorderConfig;
        cell.borderTop = cellBorderConfig;
        cell.borderLeft = cellBorderConfig;
        cell.borderRight = cellBorderConfig;
      });
    });

    header.forEach((row) => {
      row.cells.forEach((cell, index) => {
        cell.textAlign = 'center';
      });
    });

    let dataURL = toDataURL(workbookOptions);
    Promise.resolve(dataURL).then((data) => {
      saveAs(data, 'heatmap.xlsx');
    });
  }

  private prepareRows(heatmap: Map<any, any>): Array<any> {
    let self = this;
    if (!heatmap.size) {
      return [];
    }

    let result = [];
    heatmap.forEach(function (item, key) {
      let record = {
        date: new Date(key),
      };

      self.timeScale.forEach((timeScaleItem) => {
        if (item[timeScaleItem]) {
          record[timeScaleItem] = item[timeScaleItem];
        } else {
          record[timeScaleItem] = 0;
        }
      });

      result.push(record);
    });

    return result;
  }

  private getTimeScale(interval: number): Array<string> {
    let result = [];
    let end = moment({ hour: 0, minute: 0 }).add(1, 'days');

    for (let curr = moment({ hour: 0, minute: 0 }); curr.isBefore(end); curr.add(interval, 'minutes')) {
      result.push(curr.format('h:mm A'));
    }

    return result;
  }

  private isMsi(): boolean {
    let ua = window.navigator.userAgent;
    let msie = ua.indexOf('MSIE ');
    if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
      return true;
    }

    return false;
  }
}
