import { Component, OnInit, HostBinding, ViewChild, Inject, PLATFORM_ID, Injector } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

import { Observable, Observer } from 'rxjs';

import { ModalDirective } from 'ngx-bootstrap/modal';

import { IncomingCall } from '../../../abstractions';
import { ANALYTICS, IAnalyticsService, LocationLaneResolverService } from '@libs/portal-common/services';
import { AgentAnalyticsEvent } from '../../../services';

@Component({
  selector: 'app-lane-selector',
  templateUrl: './lane-selector.component.html',
  styleUrls: ['./lane-selector.component.scss'],
})
export class LaneSelectorComponent implements OnInit {
  @ViewChild('modal', { static: true }) public modal: ModalDirective;

  model = new IncomingCall(null);

  resultObserver: Observer<IncomingCall>;

  private get analytics(): IAnalyticsService {
    return this.injector.get(ANALYTICS);
  }

  constructor(
    private locationLaneResolver: LocationLaneResolverService,
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(Injector) private readonly injector: Injector,
  ) {}

  ngOnInit() {}

  open(incomingCall: IncomingCall): Observable<IncomingCall> {
    this.model = incomingCall;

    this.locationLaneResolver.getLotLanes(incomingCall.location.Id).subscribe((response) => {
      this.model.lanes = response || [];

      if (this.model.lanes.length === 1) {
        return this.selectLane(this.model.lanes[0]);
      }

      if (this.model.lanes.length === 0) {
        return this.selectLane(null);
      }

      if (isPlatformBrowser(this.platformId)) {
        this.modal.show();
      }
    });

    return new Observable((observer) => (this.resultObserver = observer));
  }

  selectLane(lane: any) {
    if (lane) {
      this.model.lane = lane;
      this.analytics.track(AgentAnalyticsEvent.LaneSelected, null);
    } else {
      this.model.lane = null;
    }

    this.modal.hide();

    this.resultObserver.next(this.model);
    this.resultObserver.complete();
  }

  cancel() {
    this.modal.hide();
    // this.callNotificationService.showCallToast(this.model)
    //   .subscribe(incomingCall => {
    //     this.resultObserver.next(incomingCall);
    //     this.resultObserver.complete();
    //   });
  }
}
