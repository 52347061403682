import { Observable, Observer, BehaviorSubject } from 'rxjs';

import { ISkypePerson, ISkypeGroup, ISkypeDisposable, IGroup } from '../../abstractions';
import { SkypePerson } from './skype-person';

export class SkypeGroup implements ISkypeGroup {
  private _id = '';
  private _name = new BehaviorSubject<string>(null);
  private _uri = new BehaviorSubject<string>(null);

  private _groups = new BehaviorSubject<Array<ISkypeGroup>>([]);
  private _persons = new BehaviorSubject<Array<ISkypePerson>>([]);

  private _listeners = new Array<{ listener: ISkypeDisposable; property: Observer<any> }>();

  public get id(): string {
    return this._id;
  }
  public get name$(): Observable<string> {
    return this._name.asObservable();
  }
  public get uri$(): Observable<string> {
    return this._uri.asObservable();
  }
  public get groups$(): Observable<Array<ISkypeGroup>> {
    return this._groups.asObservable();
  }
  public get persons$(): Observable<Array<ISkypePerson>> {
    return this._persons.asObservable();
  }

  constructor(private _group: IGroup) {
    let self = this;

    self._listeners.push({
      listener: self._group.id.changed((id) => {
        self._id = id;
      }),
      property: null,
    });

    self._listeners.push({
      listener: self._group.name.changed((name) => {
        self._name.next(name);
      }),
      property: self._name,
    });

    self._listeners.push({
      listener: self._group.uri.changed((uri) => {
        self._uri.next(status);
      }),
      property: self._uri,
    });

    self._listeners.push({
      listener: self._group.groups.changed(() => {
        self._group.groups
          .get()
          .then((groups) => {
            let skypeGroups = groups.map((group) => new SkypeGroup(group));
            self._groups.next(skypeGroups);
          })
          .catch((err) => self._groups.next([]));
      }),
      property: self._groups,
    });

    self._listeners.push({
      listener: self._group.persons.changed(() => {
        self._group.persons
          .get()
          .then((persons) => {
            let skypePersons = persons.map((person) => new SkypePerson(person));
            self._persons.next(skypePersons);
          })
          .catch((err) => self._persons.next([]));
      }),
      property: self._persons,
    });
  }

  dispose() {
    this._listeners.forEach((item) => {
      item.listener.dispose();
      if (item.property) {
        item.property.complete();
      }
    });
    this._listeners = [];
  }
}
