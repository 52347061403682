import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';

import { Subscription, zip } from 'rxjs';
import { flatMap, map, mergeMap, tap } from 'rxjs/operators';

import {
  IncidentsQualityAnalysisService,
  IIncidentQualityAnalysis,
  IncidentQualityAnalysisQuestion,
  IncidentsQualityAnalysisQuestionService,
  AnswerType,
} from '@libs/portal-common/services';
import { IEditableComponent, AppNotificationsService } from '@libs/portal-common/system';

@Component({
  selector: 'app-incident-quality-analysis',
  templateUrl: './incident-quality-analysis.component.html',
  styleUrls: ['./incident-quality-analysis.component.scss'],
})
export class IncidentQualityAnalysisComponent implements OnInit, OnDestroy, IEditableComponent {
  @ViewChild('form', { static: true }) public form: NgForm;

  editing = false;

  subscription: Subscription;

  validate = false;
  model: IIncidentQualityAnalysis;
  dynamicModel: any = {};
  modelCopy: string;
  submitPressed = false;

  questions: Array<IncidentQualityAnalysisQuestion>;

  constructor(
    private route: ActivatedRoute,
    private incidentsQualityAnalysis: IncidentsQualityAnalysisService,
    private incidentsQualityAnalysisQuestion: IncidentsQualityAnalysisQuestionService,
    private notifications: AppNotificationsService,
  ) {}

  ngOnInit() {
    this.questions = [];

    this.model = {
      Id: 0,
      fkIncident: null,
      IncidentQualityAnalysisAnswers: [],
    };

    this.subscription = this.route.parent.params
      .pipe(
        mergeMap((params) => {
          let incidentId = +params['id'];

          return zip(
            this.incidentsQualityAnalysisQuestion.getAll(true),
            this.incidentsQualityAnalysis.get({ fkIncident: incidentId }),
          ).pipe(
            map(([questionsResp, answersResp]) => ({
              incidentId,
              questions: questionsResp.Data,
              answers: answersResp,
            })),
          );
        }),
      )
      .subscribe(
        ({ incidentId, questions, answers }) => {
          this.questions = questions.filter((x) => x.Visible);

          this.editing = false;
          this.setModel({
            ...this.model,
            fkIncident: incidentId,
          });

          this.validate = false;

          if (!!answers.QualityAnalysis) {
            this.questions = questions.filter(
              (x) => answers.QualityAnalysis.IncidentQualityAnalysisAnswers?.find((a) => a.fkQualityAnalysisQuestion === x.Id),
            );

            this.setDynamicModel(answers.QualityAnalysis);
            this.setModel({
              ...this.model,
              Id: answers.QualityAnalysis.Id,
            });
          } else {
            this.questions = questions.filter((x) => x.Visible && !x.IsDeleted);
          }
        }
      );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  hasChanges(): boolean {
    return JSON.stringify(this.dynamicModel) !== this.modelCopy;
  }

  submit() {
    this.submitPressed = true;
    this.validate = true;

    if (!this.form.valid) {
      return;
    }

    this.model.IncidentQualityAnalysisAnswers = [];
    let questionsWithOutSoftDelete: Array<IncidentQualityAnalysisQuestion> = this.questions
      .filter((x) => !x.IsDeleted);
    questionsWithOutSoftDelete.forEach((question) => {
      if (question.AnswerType.toString() === 'MultiChoice') {
        this.model.IncidentQualityAnalysisAnswers.push({
          fkQualityAnalysisQuestion: question.Id,
          Answer: JSON.stringify(this.dynamicModel[question.Id]),
        });
      } else {
        this.model.IncidentQualityAnalysisAnswers.push({
          fkQualityAnalysisQuestion: question.Id,
          Answer: this.dynamicModel[question.Id],
        });
      }
    });

    this.incidentsQualityAnalysis.createOrUpdate(this.model).subscribe(
      (response) => {
        if (this.model.Id === 0) {
          this.model.Id = response.QualityAnalysis.Id;
        }
        this.setDynamicModel(response.QualityAnalysis);
        this.notifications.successUpdated();
        this.editing = false;
      }
    );
  }

  showEdit() {
    this.editing = true;
    this.submitPressed = false;
  }

  cancelEdit() {
    this.editing = false;
    this.submitPressed = false;
    if (this.hasChanges() && this.modelCopy) {
      this.cancel();
    }
  }

  private cancel() {
    this.dynamicModel = JSON.parse(this.modelCopy);
  }

  private setModel(model: IIncidentQualityAnalysis) {
    this.model = model;
  }

  private setDynamicModel(model: IIncidentQualityAnalysis) {
    model.IncidentQualityAnalysisAnswers.forEach(({ fkQualityAnalysisQuestion, Answer }) => {
      const question = this.questions.find((x) => x.Id === fkQualityAnalysisQuestion);
      if (question.AnswerType.toString() === 'MultiChoice') {
        this.dynamicModel[fkQualityAnalysisQuestion] = JSON.parse(Answer);
      } else {
        this.dynamicModel[fkQualityAnalysisQuestion] = Answer;
      }
    });
    this.modelCopy = JSON.stringify(this.dynamicModel);
  }

  private itemSelected(answer, questionId) {
    if (!this.dynamicModel[questionId]) {
      this.dynamicModel[questionId] = [];
    }

    if (!this.dynamicModel[questionId].includes(answer)) {
      this.dynamicModel[questionId] = [...this.dynamicModel[questionId], answer];
    } else {
      const index = this.dynamicModel[questionId].indexOf(answer);
      this.dynamicModel[questionId].splice(index, 1);
    }
  }
}
