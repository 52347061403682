import { Component, Input, OnInit } from '@angular/core';

import { Lot } from '../../../../abstractions';

interface IInstruction {
  title: string;
}

@Component({
  selector: 'app-gate-instructions',
  templateUrl: './gate-instructions.component.html',
  styleUrls: ['./gate-instructions.component.scss'],
})
export class GateInstructionsComponent implements OnInit {
  reasonsToOpenGate = new Array<IInstruction>();
  reasonsToCloseGate = new Array<IInstruction>();

  @Input('lot') set lot(val: Lot) {
    if (!val || !val.GateInstructions) {
      this.reasonsToCloseGate = [];
      this.reasonsToOpenGate = [];
    } else {
      let instructions = JSON.parse(val.GateInstructions);
      this.reasonsToOpenGate = instructions.reasonsToOpenGate || [];
      this.reasonsToCloseGate = instructions.reasonsToCloseGate || [];
    }
  }

  constructor() {}

  ngOnInit(): void {}
}
