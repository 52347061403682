import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';

import { of } from 'rxjs';

import { ConfirmationService } from '../confirmation/confirmation.service';

export interface IEditableComponent {
  hasChanges(): boolean;
}

@Injectable()
export class ConfirmDeactivateGuard implements CanDeactivate<IEditableComponent> {
  constructor(private confirmationService: ConfirmationService) {}

  canDeactivate(target: IEditableComponent) {
    if (target.hasChanges()) {
      return this.confirmationService.show('You have unsaved changes. Leave anyway?');
    }

    return of(true);
  }
}
