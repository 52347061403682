import { Injectable } from '@angular/core';

import { Observable, BehaviorSubject } from 'rxjs';

import { State } from '@progress/kendo-data-query';
import { tap, map } from 'rxjs/operators';

import { GridDataResult } from '@progress/kendo-angular-grid';

import { ReportsService, DateToString, DateToStringUtc } from '@libs/portal-common/services';
import { AppNotificationsService } from '@libs/portal-common/system';
import { IDynamicField } from './grid-state.service';

const EMPTY_RESULT: GridDataResult = {
  data: [],
  total: 0,
};

export class ActivityFilter {
  Start: Date = new Date(new Date().setHours(0, 0, 0, 0));
  End: Date = new Date(new Date().setHours(23, 59, 0, 0));
  DayOfWeek?: number;

  SipAddress?: string;
  LotId?: number;
  IncidentId?: number;
}

@Injectable()
export class CallHistoryService extends BehaviorSubject<GridDataResult> {
  public busy$ = new BehaviorSubject(false);

  constructor(
    private _reports: ReportsService,
    private notifications: AppNotificationsService,
  ) {
    super(null);
  }

  public query(filter: ActivityFilter, state: State, dynamicFields: Array<IDynamicField>) {
    state.sort = state.sort.filter((sortDescriptor) => sortDescriptor.dir === 'asc' || sortDescriptor.dir === 'desc');

    this.busy$.next(true);

    this.getCallHistory(filter, state, dynamicFields).subscribe(
      (x) => {
        this.busy$.next(false);
        super.next(x);
      },
      (err) => {
        this.busy$.next(false);
        super.next(EMPTY_RESULT);
        this.notifications.error(err);
      },
    );
  }

  public clear(): void {
    super.next(EMPTY_RESULT);
  }

  getCallHistory(filter: ActivityFilter, state: State, dynamicFields: Array<IDynamicField>): Observable<GridDataResult> {
    let requestFilter = this.constructFilter(filter, state);

    let params = {
      dynamicFields: dynamicFields
        .filter((x) => !!x.isSelected && !x.isStatic)
        .map((f) => ({
          fieldName: f.fieldName,
          fieldType: f.fieldType,
          displayName: f.displayName,
        })),
    };

    return this._reports.getCallHistory(params, requestFilter).pipe(
      tap((response) => {
        this.notifications.checkResponse(response);
      }),
      map(
        (response) =>
          <GridDataResult>{
            data: response.Groups || response.Data || [],
            total: response.Total,
          },
      ),
    );
  }

  constructFilter(filter: ActivityFilter, state: State) {
    let stateFilter = JSON.parse(
      JSON.stringify(
        state.filter || {
          logic: 'and',
          filters: [],
        },
      ),
    );

    let params = {
      group: state.group,
      filter: stateFilter,
      skip: state.skip,
      limit: state.take,
      sort: state.sort,
    };

    let filtersArr = params.filter.filters;

    if (filter.IncidentId) {
      filtersArr.push({ field: 'Id', operator: 'eq', value: filter.IncidentId });
      return params;
    }

    if (filter.Start) {
      filtersArr.push({ field: 'CallTime', operator: 'gt', value: DateToStringUtc(filter.Start) });
    }

    if (filter.End) {
      filtersArr.push({ field: 'CallTime', operator: 'lt', value: DateToStringUtc(filter.End) });
    }

    if (filter.DayOfWeek) {
      filtersArr.push({ field: 'DayOfWeek', operator: 'eq', value: filter.DayOfWeek });
    }

    if (filter.LotId) {
      filtersArr.push({ field: 'fkLot', operator: 'eq', value: filter.LotId });
    }

    if (filter.SipAddress) {
      filtersArr.push({ field: 'SipAddress', operator: 'eq', value: filter.SipAddress });
    }

    return params;
  }
}
