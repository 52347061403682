import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { TransferState, makeStateKey } from '@angular/platform-browser';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';

import { Observable, BehaviorSubject } from 'rxjs';

import { DomService } from '@libs/portal-common/system';

import { AppConfigurationService, APP_CONFIGURATION } from '../../services/app-configuration.service';

import { IncomingCall } from '../../abstractions';

import { SupportData, ISkypeAppServiceToken, ISkypeAppService } from '../../abstractions';

import { LocationSelectorComponent } from '../incoming-call/location-selector/location-selector.component';
import { LaneSelectorComponent } from '../incoming-call/lane-selector/lane-selector.component';
import { ContactsManagerComponent } from '../contacts-manager/contacts-manager.component';
import { NavbarItemsService } from '../../services/access-management/navbar-items.service';
import { Five9AppService, Five9Conversation } from '../../integrations/five9';
import { ConversationManagementComponent } from '../call-processing/call-processing-nav/conversation-management/conversation-management.component';
import { FinesseAppService, FinesseConversation } from '../../integrations/cisco-finesse';
import { GenesysAppService, GenesysConversation } from '../../integrations/genesys';

@Injectable()
export class NavigationService {
  private _contactsManager: ContactsManagerComponent;
  public locationSelector: LocationSelectorComponent;
  public laneSelector: LaneSelectorComponent;
  public conversationManagement: ConversationManagementComponent;

  private _incomingCall = new BehaviorSubject<IncomingCall>(null);
  private _supportData = new BehaviorSubject<SupportData>(null);

  get incomingCall$(): Observable<IncomingCall> {
    return this._incomingCall;
  }

  get supportData$(): Observable<SupportData> {
    return this._supportData;
  }

  constructor(
    private router: Router,
    private transferState: TransferState,
    @Inject(PLATFORM_ID) private platformId: any,
    @Inject(ISkypeAppServiceToken) private skypeApp: ISkypeAppService,
    private domService: DomService,
    private navbarItemsService: NavbarItemsService,
    private five9: Five9AppService,
    private finesse: FinesseAppService,
    private genesys: GenesysAppService,
    @Inject(APP_CONFIGURATION) private configuration: AppConfigurationService,
  ) {
    this.locationSelector = this.domService.appendComponentToBody(LocationSelectorComponent).instance;
    this.laneSelector = this.domService.appendComponentToBody(LaneSelectorComponent).instance;
    this._contactsManager = this.domService.appendComponentToBody(ContactsManagerComponent).instance;
    this.conversationManagement = this.domService.appendComponentToBody(ConversationManagementComponent).instance;

    let incomingCall: IncomingCall = null;
    if (isPlatformBrowser(this.platformId)) {
      const key = makeStateKey<string>('_incomingCall');
      let value = this.transferState.get<{ incomingCall: IncomingCall; conversation: string }>(key, null);
      if (!!value) {
        incomingCall = value.incomingCall;
        if (this.configuration.data.integration.type === 'five9') {
          incomingCall.conversation = Five9Conversation.Parse(value.conversation, this.five9);
        } else if (this.configuration.data.integration.type === 'cisco') {
          incomingCall.conversation = FinesseConversation.Parse(value.conversation, this.finesse);
        } else if (this.configuration.data.integration.type === 'genesys') {
          incomingCall.conversation = GenesysConversation.Parse(value.conversation, this.genesys);
        }

        this.transferState.remove(key);
      }
    }

    this._incomingCall = new BehaviorSubject<IncomingCall>(incomingCall);
    this.incomingCall$.subscribe((val) => {
      this.navbarItemsService.setIncomingCall(val);
    });

    this.navbarItemsService.openConversationManagement$.subscribe((res) => {
      let incCall = this._incomingCall.value;
      if (!!incCall) {
        this.conversationManagement.open(incCall.conversation);
      }
    });
  }

  init(incomingCall: IncomingCall) {
    this._supportData.next(null);
    this._incomingCall.next(incomingCall);

    if (isPlatformServer(this.platformId)) {
      this.transferState.onSerialize<{ incomingCall: IncomingCall; conversation: string }>(makeStateKey<string>('_incomingCall'), () => {
        let value = this._incomingCall.value;
        if (!!value) {
          let conversation = value.conversation.stringifyAndReset();
          value.conversation = null;
          return { incomingCall: value, conversation };
        }
        return { incomingCall: null, conversation: null };
      });
    }

    if (incomingCall) {
      this.router.navigate(['/call/call-processing/general-information']);
    }
  }

  incomingCallFinished() {
    this._incomingCall.next(null);
    this.router.navigate(['/activity/agent']);
  }

  setSupportData(data: SupportData) {
    this._supportData.next(data);
  }

  openContactsManager(): Observable<string[]> {
    return this._contactsManager.open();
  }
}
