import { NgModule, ModuleWithProviders } from '@angular/core';

import { GenesysAppService } from './services/genesys-app.service';
import { GenesysEmbeddedService } from './services/genesys-embedded.service';
import { GENESYS_HTTP_INTERCEPTORS, GenesysHttpClient } from './services/genesys-httpclient';
import { GenesysAuthInterceptor } from './services/genesys-auth.interceptor';

@NgModule({})
export class GenesysModule {
  static forRoot(): ModuleWithProviders<GenesysModule> {
    return {
      ngModule: GenesysModule,
      providers: [
        { provide: GENESYS_HTTP_INTERCEPTORS, useClass: GenesysAuthInterceptor, multi: true },
        GenesysHttpClient,
        GenesysAppService,
        GenesysEmbeddedService,
      ],
    };
  }

  constructor() {}
}
