import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class IssueFieldsService {

  filteredFields: any;

  fieldSet: any = [];

  getFieldsJson() {
    return JSON.stringify(
      this.fieldSet.map((field, index) => ({
        Id: field.Id,
        IsRequired: !!field.IsRequired,
        TabIndex: index
      })),
    );
  }

  setupFieldsSet(issue, filteredFields) {
    this.filteredFields = filteredFields;

    let fieldsJson = JSON.parse(issue.FieldsJson);

    let fields = issue.Fields;

    let sortPosition = fieldsJson.map((f) => f.Id);
    this.fieldSet = fields.slice().sort((a, b) => sortPosition.indexOf(a.Id) - sortPosition.indexOf(b.Id));
  }

  removeField(field) {
    this.fieldSet.findAndRemove(field);
    this.fieldSet = this.fieldSet.slice();

    this.filteredFields.push(field);
    this.filteredFields = this.filteredFields.slice();
  }

  fieldName(field: any): string {
    return `field-${field.Id}`;
  }
}
