import { Observable, of } from 'rxjs';

import { ISkypeAppService, ISkypeMePerson, SkypeOnlineStatus, IMePerson } from '../../abstractions';
import { SkypePerson } from './skype-person';

export class SkypeMePerson extends SkypePerson implements ISkypeMePerson {
  constructor(
    mePerson: IMePerson,
    private _skypeApp: ISkypeAppService,
  ) {
    super(mePerson);
  }

  public setStatus(status: SkypeOnlineStatus): Observable<SkypeOnlineStatus> {
    this._skypeApp.setStatus(status, '');
    return of(status);
  }
}
