<div class="grid-container grid-page">
  <app-activity-filter [mode]="'daily'" #activityFilter [filter]="filter" (search)="search($event)"></app-activity-filter>

  <app-activity-grid
    [mode]="'daily'"
    (busy)="activityFilter.disabled = $event"
    [filter]="filter"
    [pdfFileName]="'DailyActivity.pdf'"
    [excelFileName]="'AgentActivity.xlsx'"
    reportName="DailyActivity"
  ></app-activity-grid>
</div>
