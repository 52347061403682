import { Injectable, InjectionToken, Injector } from '@angular/core';
import { HttpBackend, HttpClient, HttpInterceptor } from '@angular/common/http';

import { CustomHttpHandler } from '../../custom-http-handler';

export const FIVE9_HTTP_INTERCEPTORS = new InjectionToken<HttpInterceptor[]>('FIVE9_HTTP_INTERCEPTORS');

@Injectable()
export class Five9HttpClient extends HttpClient {
  constructor(
    backend: HttpBackend,
    private injector: Injector,
  ) {
    super(new CustomHttpHandler(backend, injector, FIVE9_HTTP_INTERCEPTORS));
  }
}
