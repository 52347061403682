export interface IdentityProvider {
  Name: string;
  DisplayName: string;
  IsEnabled: boolean;
  WellKnownHostsUrl: string;
  AuthorizeUrl: string;
  AccessTokenUrl: string;
  UserProfileUrl: string;
  Scopes: string;
  ClientId: string;
  ClientSecret: string;
  ClaimsRegistration: boolean;
  ProviderType: ProviderType;
}

export enum ProviderType {
  None = 'None',
  AzureAD = 'AzureAD',
}

export function isAzureADProvider(type: ProviderType): boolean {
  return type === ProviderType.AzureAD;
}
