import { CallsMapType } from './../../abstractions/integration-app.serivce';
import { Component, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

import { Subscription, BehaviorSubject } from 'rxjs';
import { tap, map, filter, distinctUntilChanged } from 'rxjs/operators';

import { Router } from '@angular/router';

import { NavigationService } from '../services';

import { IIntegrationAppService, ICall, IncomingCall } from '../../abstractions';
import { IntegrationAppFactoryService } from '../services';

@Component({
  selector: 'app-incoming-call',
  templateUrl: './incoming-call.component.html',
  styleUrls: ['./incoming-call.component.scss'],
})
export class IncomingCallComponent implements OnInit, OnDestroy {
  private integration: IIntegrationAppService;
  private currentCall: IncomingCall;

  private subscription: Subscription;
  private incomingCallSubscription: Subscription;

  constructor(
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: Object,
    private integrationFactory: IntegrationAppFactoryService,
    private navService: NavigationService,
  ) {}

  ngOnInit() {
    this.integration = this.integrationFactory.getApp();
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }

    this.incomingCallSubscription = this.navService.incomingCall$.subscribe((x) => {
      this.currentCall = x;
    });

    this.subscription = this.integration.calls$
      .pipe(
        filter((x) => !this.currentCall),
        map((calls) => this.getCallsCollection(calls).filter((x) => x.state === 'Accepted')[0]),
        filter((x) => !!x),
        distinctUntilChanged((a, b) => a?.id === b?.id),
      )
      .subscribe(
        (call) => {
          this.router.navigate(['/call', 'start-incoming-call'], {
            queryParams: {
              caller: call.caller,
              callId: call.id,
              session_id: call.sessionId,
              agentSip: call.agentSip,
              test: false,
            },
          });
        },
        (err) => {
          console.log(err);
        },
      );
  }

  private getCallsCollection(callsMap: CallsMapType): ICall[] {
    return Object.getOwnPropertyNames(callsMap).map((x) => callsMap[x]);
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
    this.incomingCallSubscription?.unsubscribe();
  }
}
