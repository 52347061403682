import { Component, Inject, Input, NgZone, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { Subscription } from 'rxjs';
import { filter, tap } from 'rxjs/operators';

import { ANALYTICS, AppStateService, IAnalyticsService, IRouteDescription, LocationsService } from '@libs/portal-common/services';
import { AppNotificationsService } from '@libs/portal-common/system';
import { ActivityCssClass } from '@libs/portal-common/activity';

import { IncomingCall } from '../../../abstractions';
import { NavbarItemsService } from '../../../services/access-management/navbar-items.service';
import { APP_CONFIGURATION, AppConfigurationService, AgentAnalyticsEvent } from '../../../services';
import { IncomingCallsService, IntegrationAppFactoryService } from '../../../skype-client/services';

import { RevenueControlSelectorComponent } from '../../../skype-client/call-processing/revenue/revenue-control-selector/revenue-control-selector.component';
import { CallProcessingService } from '../../../skype-client/services/call-processing.service';
import { DomSanitizer } from '@angular/platform-browser';
import { animate, style, transition, trigger } from '@angular/animations';

interface ITimer {
  timer: NodeJS.Timer;
  startTime: number;
  minutes: number;
  seconds: number;
  cssClass: string;
  getCssClass(timer: ITimer): string;
}

interface IAdditionalLink {
  Id: number;
  Order: number;
  Enabled: boolean;
  Title: string;
  Url: string;
  BasicAuthEnabled: boolean;
  Username: string;
  Password: string;
  fkLot: number;
}

const LOGO = '/img/splash.png';

@Component({
  templateUrl: 'sidebar-nav.component.html',
  styleUrls: ['sidebar-nav.component.scss'],
  selector: 'app-sidebar-nav',
  animations: [
    trigger('insertRemoveNavList', [
      transition(':enter', [style({ opacity: 0 }), animate('0.1s 0.2s', style({ opacity: 1 }))]),
      transition(':leave', [animate('100ms', style({ opacity: 0 }))]),
    ]),
  ],
})
export class SidebarNavComponent implements OnInit {
  @ViewChild('revenueControlSelector', { static: true }) public revenueControlSelector: RevenueControlSelectorComponent;

  _isMenuCollapsed: boolean;
  @Input() set isMenuCollapsed(flag: boolean) {
    this._isMenuCollapsed = flag;
    if (!this._isMenuCollapsed) {
      this.navigationsCollapsed = this.setDefaultCollapseState();
    }
  }
  logo = LOGO;
  hasImage = true;
  companyNameShortcut = '';
  companyName = '';
  currentYear = new Date().getFullYear();
  isCollapsed = false;

  navigationsCollapsed: { [id: string]: boolean } = this.setDefaultCollapseState();

  routes: IRouteDescription[] = [];

  portalLoggedIn = false;

  incomingCall: IncomingCall;
  subscription: Subscription;
  state = '';
  hideCallControl = false;
  wrapUpButtonText = 'Wrap Up';

  hangupTimer: ITimer = {
    timer: null,
    startTime: null,
    minutes: 0,
    seconds: 0,
    cssClass: null,
    getCssClass: (x) => this.defaultCssSettings.durationFieldClass(x.minutes * 60 + x.seconds),
  };
  wrapupTimer: ITimer = {
    timer: null,
    startTime: null,
    minutes: 0,
    seconds: 0,
    cssClass: null,
    getCssClass: (x) => this.defaultCssSettings.wrapupFieldClass(x.minutes * 60 + x.seconds),
  };

  defaultCssSettings = ActivityCssClass.Default;

  busy = false;
  incomingCallProcessing = false;
  isOpenedCollapseMenu = false;
  additionalLinks: IAdditionalLink[] = [];

  public get isRevenuEcontrolEnabled(): boolean {
    if (!this.incomingCall || !this.incomingCall.locationInfo.Lot || !this.incomingCall.locationInfo.Lot) {
      return false;
    }

    const lot = this.incomingCall.locationInfo.Lot;
    return lot.RevenueControlEnabled && !!lot.RevenueControlManufacturer && lot.RevenueControlManufacturer.IsIntegrated;
  }

  constructor(
    private router: Router,
    private renderer: Renderer2,
    private ngZone: NgZone,
    private notifications: AppNotificationsService,
    private navbarItemsService: NavbarItemsService,
    private incomingCallsService: IncomingCallsService,
    private locationsService: LocationsService,
    private sanitizer: DomSanitizer,
    private callProcessingService: CallProcessingService,
    private appStateService: AppStateService,
    @Inject(APP_CONFIGURATION) private configuration: AppConfigurationService,
    private integrationFactory: IntegrationAppFactoryService,
    @Inject(ANALYTICS) private analytics: IAnalyticsService,
  ) {
    integrationFactory.getApp().isAuthenticated$.subscribe(() => {
      this.logo = null;
      setTimeout(() => (this.logo = LOGO));
    });
  }

  ngOnInit() {
    if (this.configuration.data.integration.type === 'cisco') {
      this.hideCallControl = true;
      this.wrapUpButtonText = 'Save and Close';
    }

    this.navbarItemsService.routes$.subscribe((routes) => (this.routes = routes));

    this.navbarItemsService.incomingCallProcessing$.subscribe((val) => {
      setTimeout(() => (this.incomingCallProcessing = val), 1);
    });

    this.navbarItemsService.incomingCall$
      .pipe(
        tap((incomingCall) => {
          this.clearTimer(this.hangupTimer);
          this.clearTimer(this.wrapupTimer);

          if (this.subscription) {
            this.subscription.unsubscribe();
            this.subscription = null;
          }

          this.incomingCall = incomingCall;

          if (incomingCall) {
            this.locationsService.getLocationIFrameTabs(incomingCall.locationInfo.Lot.Id).subscribe((tabs) => {
              this.additionalLinks = tabs.IFrameTabs.filter((x) => x.Enabled);
            });
          }
        }),
        filter((incomingCall) => !!incomingCall),
      )
      .subscribe((incomingCall) => {
        this.handleIncomingCall(incomingCall);
      });
  }

  /**
   * Temporary workaround since ngx-bootstrap has not collapse animation
   * @param el
   * @param height
   */
  setCollapsedHeight(el, height) {
    this.renderer.setStyle(el, 'height', height + 'px');
  }

  private handleIncomingCall(incomingCall: IncomingCall) {
    this.runTimer(this.hangupTimer);

    this.state = '';
    this.busy = false;

    if (this.subscription) {
      this.subscription.unsubscribe();
      this.subscription = null;
    }

    this.subscription = incomingCall.conversation.state$.subscribe((state) => {
      this.state = state;

      if (state === 'HangedUp') {
        this.stopTimer(this.hangupTimer);
        this.runTimer(this.wrapupTimer);
      } else if (state === 'Finished') {
        this.stopTimer(this.wrapupTimer);
      }
    });
  }

  openRevenueControl($event) {
    $event.preventDefault();
    this.revenueControlSelector.open(this.incomingCall.locationInfo.Lot.RevenueControlManufacturer, this.incomingCall).subscribe(() => {});
  }

  private runTimer(timer: ITimer) {
    this.clearTimer(timer);

    timer.startTime = new Date().getTime();

    this.ngZone.runOutsideAngular(() => {
      timer.timer = setInterval(() => {
        let now = new Date().getTime();

        let distance = now - timer.startTime;

        // Time calculations for days, hours, minutes and seconds
        this.ngZone.run(() => {
          timer.minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
          timer.seconds = Math.floor((distance % (1000 * 60)) / 1000);
          timer.cssClass = timer.getCssClass(timer);
        });
      }, 300);
    });
  }

  private clearTimer(timer: ITimer) {
    if (timer.timer) {
      clearInterval(timer.timer);
    }

    timer.cssClass = null;
    timer.timer = null;
    timer.minutes = 0;
    timer.seconds = 0;
  }

  private stopTimer(timer: ITimer) {
    if (timer.timer) {
      clearInterval(timer.timer);
    }

    timer.timer = null;
  }

  hangUp($event) {
    $event.preventDefault();
    $event.stopPropagation();

    this.analytics.track(AgentAnalyticsEvent.HangUp, null);
    this.busy = true;
    this.incomingCall.conversation.hangUp().subscribe(
      () => {
        this.busy = false;
      },
      (err) => {
        this.busy = false;
        this.notifications.error(err.message || 'Hanging up failed');
      },
    );
  }

  wrapUp($event) {
    $event.preventDefault();
    $event.stopPropagation();

    if (!this.incomingCallsService.isValidIncomingCallRecords$.value) {
      this.notifications.warning('Fill required fields');
      return;
    }

    this.analytics.track(AgentAnalyticsEvent.WrapUp, null);
    this.busy = true;
    this.incomingCall.conversation.wrapUp().subscribe(
      () => {
        this.busy = false;
      },
      (err) => {
        this.busy = false;
        this.notifications.error(err.message || 'Wrapping up failed');
      },
    );

    this.stopTimer(this.wrapupTimer);
  }

  dtmf($event) {
    this.navbarItemsService.openConversationManagement();
    $event.preventDefault();
    $event.stopPropagation();
    this.analytics.track(AgentAnalyticsEvent.DtmfClick, null);
  }

  pause($event) {
    $event.preventDefault();
    $event.stopPropagation();

    this.busy = true;
    this.incomingCall.conversation.pause().subscribe(
      () => {
        this.busy = false;
      },
      (err) => {
        this.busy = false;
        console.log('PAUSE CALL ERROR:', err);
      },
    );

    this.analytics.track(AgentAnalyticsEvent.PauseClick, null);
  }
  resume($event) {
    $event.preventDefault();
    $event.stopPropagation();

    this.busy = true;
    this.incomingCall.conversation.resume().subscribe(
      () => {
        this.busy = false;
      },
      (err) => {
        this.busy = false;
        console.log('RESUME CALL ERROR:', err);
      },
    );

    this.analytics.track(AgentAnalyticsEvent.ResumeClick, null);
  }

  testCall($event) {
    $event.preventDefault();
    $event.stopPropagation();

    this.router.navigate(['/call', 'start-incoming-call'], {
      queryParams: {
        caller: this.configuration.data.integration.testCallSip,
        callId: 'test-call-id',
        session_id: 'test-session-id',
        agentSip: 'test-sip',
        test: true,
      },
    });
  }

  openAdditionalLink(link: IAdditionalLink) {
    if (link.BasicAuthEnabled) {
      let xhr = new XMLHttpRequest();
      xhr.open('GET', link.Url);
      xhr.setRequestHeader('Access-Control-Allow-Origin', '*');
      xhr.setRequestHeader('Authorization', 'Basic ' + btoa(unescape(encodeURIComponent(link.Username + ':' + link.Password))));
      xhr.send();
    }

    if (!link.BasicAuthEnabled) {
      this.callProcessingService.updateViewTabsData(link.Title, this.sanitizer.bypassSecurityTrustResourceUrl(link.Url));
    }
  }

  openParkonect() {
    const user = this.incomingCall.locationInfo.Lot['ParkonectUsername'];
    const pass = this.incomingCall.locationInfo.Lot['ParkonectPassword'];
    const garageId = this.incomingCall.locationInfo.Lot['ParkonectId'];
    const parkonectUrl = `https://secure.parkonect.com/Admin/ViewLogs.aspx?GarageID=${garageId}`;
    let xhr = new XMLHttpRequest();
    xhr.open('GET', parkonectUrl);
    xhr.setRequestHeader('Access-Control-Allow-Origin', '*');
    xhr.setRequestHeader('Access-Control-Allow-Methods', 'POST, DELETE, OPTIONS');
    xhr.setRequestHeader('Authorization', 'Basic ' + btoa(unescape(encodeURIComponent(user + ':' + pass))));
    xhr.send();
    this.analytics.track(AgentAnalyticsEvent.ParkonectTabOpen, null);
  }

  private setDefaultCollapseState() {
    return { Activity: true, Reports: true };
  }
}
