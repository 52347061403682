import { Inject, Optional, PLATFORM_ID } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TransferState } from '@angular/platform-browser';

import { IAppConfigurationBase, APP_CONFIGURATION as token, AppConfigurationService as base, ApplicationMode } from '@libs/portal-common';

export type IntegrationType = 'five9' | 'cisco' | 'cc4all' | 'genesys';

export interface IAppConfiguration extends IAppConfigurationBase {
  paths: {
    baseUrl: string;
    dvrProxy: string;
  };
  production: boolean;
  skype: {
    authTimeout: number;
    origins: Array<string>;
    authAgentSip: string;
    appCryptoKey: string;
  };
  integration: {
    type: IntegrationType;
    testCallSip: string;
    five9_settings: {
      debug: boolean;
      metadataUrl: string;
    };
    cc4all_settings: {
      debug: boolean;
      socketsServerUrl: string;
      cc4AllTenant: string;
      aadClientId: string;
    };
    genesys_settings: {
      environment: string;
      clientId: string;
      embedded: boolean;
      embeddedUrlSuffix: string;
    };
  };
  amplitudeAnalytics: {
    apiKey: string;
  };
}

export const APP_CONFIGURATION = token;

export class AppConfigurationService extends base<IAppConfiguration> {
  public get mode(): ApplicationMode {
    return 'webagent';
  }

  constructor(
    _httpClient: HttpClient,
    @Inject(PLATFORM_ID) platformId: Object,
    @Optional() @Inject('ORIGIN_URL') ssrOriginUrl: string,
    transferState: TransferState,
  ) {
    super(_httpClient, platformId, ssrOriginUrl, transferState);
  }
}
