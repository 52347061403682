<div class="css-table">
  <div class="css-table-header">
    <div *ngFor="let item of header">{{ item }}</div>
  </div>

  <kendo-sortable
    [kendoSortableBinding]="data"
    (dragEnd)="onDragEnd($event)"
    (dataMove)="onDataMove($event)"
    class="css-table-body"
    itemClass="css-table-row"
  >
    <ng-template let-item="item">
      <ng-template [ngTemplateOutlet]="itemTemplateRef" [ngTemplateOutletContext]="{ item: item }"> </ng-template>
    </ng-template>
  </kendo-sortable>
</div>
