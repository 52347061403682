import { Injectable, Inject } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { Observable } from 'rxjs';
import { tap, map } from 'rxjs/operators';

import { INavbarItemsService, NAVBAR_ITEMS } from './navbar-items.service';

@Injectable()
export class RedirectGuard implements CanActivate {
  constructor(
    private router: Router,
    @Inject(NAVBAR_ITEMS) private navItemsService: INavbarItemsService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.navItemsService.redirectUrl$.pipe(
      tap((redirectUrl) => {
        const url = redirectUrl || '/auth/login';
        this.router.navigate([url]);
      }),
      map((redirectUrl) => true),
    );
  }
}
