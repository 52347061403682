import { Component, OnInit, ViewChild, ElementRef, Inject } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

import { ModalDirective } from 'ngx-bootstrap/modal';
import { AppConfigurationService, APP_CONFIGURATION, IAppConfigurationBase } from '@libs/portal-common/services';
import { AppNotificationsService } from '@libs/portal-common/system';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-video-record',
  templateUrl: './video-record.component.html',
  styleUrls: ['./video-record.component.scss'],
})
export class VideoRecordComponent implements OnInit {
  private subscription: Subscription;

  @ViewChild('modal', { static: true }) public modal: ModalDirective;
  @ViewChild('videoPlayer', { static: true }) videoPlayerRef: ElementRef;

  videoUrl: SafeResourceUrl;

  constructor(
    @Inject(APP_CONFIGURATION)
    private configuration: AppConfigurationService<IAppConfigurationBase>,
    private notifications: AppNotificationsService,
  ) {}

  ngOnInit() {
    this.subscription = this.modal.onHide.subscribe(() => {
      this.videoPlayerRef.nativeElement.pause();
      this.videoUrl = '';
      this.videoPlayerRef.nativeElement.load();
    });
  }

  open(incidentId: number) {
    this.videoUrl = `${this.configuration.apiBaseUrl}/VideoRecord/Incident/${incidentId}`;
    this.modal.show();
  }

  cancel() {
    this.modal.hide();
  }
}
