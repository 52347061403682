<div class="modal fade" bsModal #modal="bs-modal" [config]="{ backdrop: 'static' }" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header clearfix">
        <h4 class="modal-title pull-left">Select location</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="cancel()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body">
        <tabset #tabset>
          <tab heading="Select" [active]="!anonymousSelected" style='min-height: 385px;'>
            <div class="row search-form">
              <div class="col-sm-6 col-xs-6">
                <div class="input-group">
                  <input
                    type="text"
                    name="filter"
                    [ngModel]="filter"
                    (ngModelChange)="filterChanged($event)"
                    class="form-control"
                    placeholder="Enter text to search"
                  />
                  <span class="input-group-btn">
                    <button class="btn btn-default" (click)="clearFilter()" [disabled]="!filter">Clear</button>
                  </span>
                </div>
              </div>
            </div>

            <kendo-grid
              [data]="view"
              [sort]="state.sort"
              [group]="false"
              [sortable]="{ mode: 'single' }"
              [height]="300"
              [pageable]="false"
              [groupable]="false"
              (dataStateChange)="dataStateChange($event)"
            >
              <kendo-grid-column title="Select" field="Id" [sortable]="false" width="80">
                <ng-template kendoGridCellTemplate let-dataItem>
                  <button class="call-button icon-primary" (click)="selectLocation(dataItem)"><i class="fa fa-phone fa-2x"></i></button>
                </ng-template>
              </kendo-grid-column>

              <kendo-grid-column title="Lot Number" field="LotNumber" width="150">
                <ng-template kendoGridCellTemplate let-dataItem>
                  <div class="lot-number" [innerHTML]="dataItem.LotNumber | highlight: filter"></div>
                </ng-template>
              </kendo-grid-column>

              <kendo-grid-column title="Location Name" field="LotName">
                <ng-template kendoGridCellTemplate let-dataItem>
                  <div [innerHTML]="dataItem.LotName | highlight: filter"></div>
                </ng-template>
              </kendo-grid-column>

              <kendo-grid-column title="Address" field="Address">
                <ng-template kendoGridCellTemplate let-dataItem>
                  <div [innerHTML]="dataItem.Address | highlight: filter"></div>
                </ng-template>
              </kendo-grid-column>
            </kendo-grid>

            <div class="select-buttons">
              <button class="button ND--buttons--second-button" (click)="cancel()">Cancel</button>
              <button class="button ND--buttons--primary" (click)="anonymous()">Anonymous Call</button>
            </div>
          </tab>

          <tab heading="Anonymous Call" style='min-height: 150px;'
               *ngIf="!anonymousSelected" [active]="anonymousSelected" (deselect)="removeTab(tabset, $event)">
            <div class="row">
              <form class="col-sm-12 anonymous-form" #form="ngForm" novalidate>
                <div class="form-group">
                  <label>Notes</label>
                  <textarea class="form-control" rows="3" [(ngModel)]="anonymousNotes" name="notes"></textarea>
                </div>
                <div class="select-buttons">
                  <button type="button" class="button ND--buttons--second-button" (click)="cancel()">Cancel</button>
                  <button type="button" class="button ND--buttons--primary" (click)="saveAnonymous()">Save</button>
                </div>
              </form>
            </div>
          </tab>
        </tabset>
      </div>
    </div>
  </div>
</div>
