import { Observable } from 'rxjs';
import { IConversation, OutgoingConversationState } from './integration-app.serivce';
import { IPersonsAndGroupsManager } from './skype-types';

export type SkypeOnlineStatus = 'Online' | 'Offline' | 'Hidden' | 'Busy' | 'Idle' | 'DoNotDisturb' | 'BeRightBack' | 'Away' | 'Unknown';
export type SkypeSignInState = 'SignedOut' | 'SigningIn' | 'SignedIn' | 'SigningOut';

export type AuthStatus = 'WaitingSkype' | 'WaitingCode' | 'WaitingToken' | 'Finished';

export class SkypeMediaCapabilities {
  constructor(
    public isMicrophoneEnabled: boolean,
    public isWebCamEnabled: boolean,
    public isPluginRequired: boolean,
    public isPluginInstalled: boolean,
  ) {}

  public get isOK(): boolean {
    return (this.isPluginRequired && this.isPluginInstalled) || (this.isMicrophoneEnabled && this.isWebCamEnabled);
  }
}

export interface IConversationParticipant {
  sip$: Observable<string>;
  avatarUrl$: Observable<string>;
  status$: Observable<SkypeOnlineStatus>;
}
export interface IOutgoingConversation {
  selfParticipant$: Observable<ISkypeMePerson>;
  participants$: Observable<Array<ISkypePerson>>;

  state$: Observable<OutgoingConversationState>;

  leave(): Observable<boolean>;
}

export interface ISkypePerson {
  id: string;
  displayName$: Observable<string>;
  avatarUrl$: Observable<string>;
  status$: Observable<SkypeOnlineStatus>;
  activity$: Observable<string>;

  dispose(): void;
}
export interface ISkypeGroup {
  id: string;
  name$: Observable<string>;
  uri$: Observable<string>;

  groups$: Observable<Array<ISkypeGroup>>;
  persons$: Observable<Array<ISkypePerson>>;

  dispose(): void;
}

export interface ISkypeMePerson extends ISkypePerson {
  setStatus(status: SkypeOnlineStatus): Observable<SkypeOnlineStatus>;
}

export interface ISkypeCredentials {
  login: string;
  password: string;
}

export const ISkypeAppServiceToken = 'ISkypeAppService';

export interface ISkypeAppService {
  mePerson$: Observable<ISkypeMePerson>;

  isSignedIn$: Observable<boolean>;
  incomingCall$: Observable<IConversation>;
  outgoingCall$: Observable<IOutgoingConversation>;

  authStatus$: Observable<AuthStatus>;
  status$: Observable<SkypeOnlineStatus>;

  personsAndGroupsManager: IPersonsAndGroupsManager;

  mediaCapabilities$: Observable<SkypeMediaCapabilities>;

  getStatus(): Promise<SkypeOnlineStatus>;
  setStatus(status: SkypeOnlineStatus, message: string);

  getSip(): string;
  getName(): string;

  simulateSkypeCall(uri: string, skipSavingIncident: boolean): void;
  outboundCall(uris: Array<string>): Observable<IOutgoingConversation>;

  isSignedIn(): boolean;

  login(credentials: ISkypeCredentials): Promise<boolean>;
  checkLogin(): Promise<boolean>;
  clearCredentials();
}
