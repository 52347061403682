import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';

import * as $ from 'jquery';

import { Metadata } from '../model/metadata';
import { AppConfigurationService, APP_CONFIGURATION } from '../../../services/app-configuration.service';
import { isPlatformBrowser } from '@angular/common';

@Injectable()
export class Five9StateService {
  private metadata: Metadata;

  public get userId(): string {
    if (!!this.metadata) {
      return this.metadata.userId;
    }

    return null;
  }

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(APP_CONFIGURATION) private configuration: AppConfigurationService,
  ) {}

  public static GetApiUrlHost(metadata: Metadata): string {
    return metadata.metadata.dataCenters[0].apiUrls[0].host;
  }

  getMetadata(): Observable<Metadata> {
    if (!!this.metadata) {
      return of(this.metadata);
    }

    let self = this;
    let result = new Subject<Metadata>();
    let settings = this.configuration.data.integration.five9_settings;

    if (isPlatformBrowser(this.platformId)) {
      $.ajax({
        url: settings.metadataUrl,
        type: 'GET',
        crossDomain: true,
        xhrFields: {
          withCredentials: true,
        },
        success: function (data) {
          self.metadata = data;
          result.next(data);
        },
        error: function (jqXHR, textStatus, error) {
          console.log('error', jqXHR.responseText);
          self.metadata = null;
          result.error(error);
        },
      });
    }

    return result;
  }

  public getApiUrlHost(): string {
    return this.metadata.metadata.dataCenters[0].apiUrls[0].host;
  }
}
