import { Component, Inject, OnInit } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';

import { filter, map, mergeMap } from 'rxjs/operators';
import { init as fsInit, isInitialized as fsIsInitialized } from '@fullstory/browser';

import { DragTargetDragStartEvent } from '@progress/kendo-angular-utils';

import { ANALYTICS, AppStateService, AUTH_EXTENSIONS, IAnalyticsService } from '@libs/portal-common/services';
import { IntegrationAppFactoryService } from './skype-client/services';
import { IIntegrationAppService } from './abstractions';
import { AppConfigurationService, APP_CONFIGURATION, AuthExtensionsService } from './services';
import { animate, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    trigger('insertRemoveCollapseNavBtn', [
      transition(':enter', [style({ opacity: 0 }), animate('300ms', style({ opacity: 1 }))]),
      transition(':leave', [animate('100ms', style({ opacity: 0 }))]),
    ]),
    trigger('insertRemoveOpenNavBtn', [
      transition(':enter', [style({ opacity: 0 }), animate('300ms', style({ opacity: 1 }))]),
      transition(':leave', [animate('100ms', style({ opacity: 0 }))]),
    ]),
  ],
})
export class AppComponent implements OnInit {
  isAuthenticated = false;
  isIntegrationAuthenticated = false;

  isMenuCollapsed = false;
  isShowCollapseIcon = false;
  pageHeader = '';
  pageIcon = '';
  integration: IIntegrationAppService;

  genesysIframeUrl: string = null;
  iframePinterEvents = 'unset';

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private appState: AppStateService,
    private integrationFactory: IntegrationAppFactoryService,
    @Inject(AUTH_EXTENSIONS) private authExtensions: AuthExtensionsService,
    private appStateService: AppStateService,
    @Inject(ANALYTICS) private analytcis: IAnalyticsService,
    @Inject(APP_CONFIGURATION) private configuration: AppConfigurationService,
  ) {
    appState.isAuthenticated$.subscribe((res) => {
      this.isAuthenticated = res;

      if (
        this.isAuthenticated &&
        configuration.data.integration.type === 'genesys' &&
        configuration.data.integration.genesys_settings.embedded
      ) {
        this.genesysIframeUrl = `https://apps.${configuration.data.integration.genesys_settings.environment}/crm/${configuration.data.integration.genesys_settings.embeddedUrlSuffix}`;
      } else {
        this.genesysIframeUrl = null;
      }
    });

    this.integration = integrationFactory.getApp();
    this.integration.isAuthenticated$.subscribe((res) => (this.isIntegrationAuthenticated = res));
  }

  ngOnInit(): void {
    this.authExtensions.setIntegration(this.integration);

    this.analytcis.initialize();
    if (this.appStateService.isAuthenticated) {
      this.analytcis.setUserId(this.appStateService.username);
    }

    if (this.configuration.data.fullStory?.orgId && !fsIsInitialized()) {
      fsInit({ orgId: this.configuration.data.fullStory.orgId });
    }

    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map((route) => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter((route) => route.outlet === 'primary'),
        mergeMap((route) => route.data),
      )
      .subscribe((event) => {
        this.pageHeader = event['header'];
        this.pageIcon = event['pageIcon'];
      });
  }

  toggleMenu() {
    this.isMenuCollapsed = !this.isMenuCollapsed;
  }

  handleRelease(ev: any): void {
    this.iframePinterEvents = 'unset';
  }
  handleDragStart(ev: DragTargetDragStartEvent): void {
    this.iframePinterEvents = 'none';
  }

  mouseLeaveFromNavBar(event) {
    if (event && event.relatedTarget && event.relatedTarget.id === 'collapse-nav-icon') {
      return;
    }

    this.isShowCollapseIcon = false;
  }
}
