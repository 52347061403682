import { InjectionToken } from '@angular/core';

import { RequiredPermissions } from './access-management.service';
import { Observable } from 'rxjs';

export interface IRouteDescription {
  route?: string;
  displayName: string;

  icon?: string;
  iconClass?: string;

  id?: string;
  childs?: IRouteDescription[];
  portal?: boolean;

  requiredPermissions?: RequiredPermissions;
}

export interface INavbarItemsService {
  routes$: Observable<IRouteDescription[]>;
  redirectUrl$: Observable<string>;
}

export const NAVBAR_ITEMS = new InjectionToken<INavbarItemsService>('NAVBAR_ITEMS');
