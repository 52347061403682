import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, Router } from '@angular/router';

import { Observable, of, EMPTY } from 'rxjs';
import { take, catchError, map } from 'rxjs/operators';
import { Ticket, TicketsService } from '@libs/portal-common/services';

@Injectable()
export class TicketResolverService implements Resolve<Ticket> {
  constructor(
    private router: Router,
    private ticketsService: TicketsService,
  ) {}

  resolve(route: ActivatedRouteSnapshot): Ticket | Observable<Ticket> {
    let incidentId = +route.paramMap.get('id');
    if (!incidentId) {
      incidentId = +route.parent.paramMap.get('id');
    }

    if (!incidentId) {
      return of(null);
    }

    return this.ticketsService.getTicketByIncidentId(incidentId).pipe(
      take(1),
      map((response) => response.Ticket),
      catchError((err) => {
        return EMPTY;
      }),
    );
  }
}
