import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { HttpClient } from '@angular/common/http';

import { BaseResponse } from '../../models/rest/baseResponse';
import { Contact } from '../../models/contact';

export type PermissionEntities =
  | 'Rules'
  | 'Access'
  | 'System'
  | 'Locations'
  | 'Incidents'
  | 'Updates'
  | 'CallCenterStatistics'
  | 'CallCenterConfiguration'
  | 'All';

export type Access = 'CanCreate' | 'CanRead' | 'CanUpdate' | 'CanDelete' | 'CanExecute';

export interface GetPermissionsResponse extends BaseResponse {
  Permissions: Array<Permission>;
}

export class Permission {
  Id: number;
  fkRole: number;

  Entity: string;
  Reference = '';
  CanCreate = false;
  CanRead = false;
  CanUpdate = false;
  CanDelete = false;
  CanExecute = false;
}

export interface GetRolesResponse extends BaseResponse {
  Roles: Array<Role>;
}

export interface SaveRoleResponse extends BaseResponse {
  Role: Role;
}

export interface SavePermissionResponse extends BaseResponse {
  Permission: Permission;
}

export interface SavePermissionsResponse extends BaseResponse {
  Permissions: Array<Permission>;
}

export class Role {
  Id: number;
  Name: string;
  Description: string;
  fkRegion: number;
}

export interface GetRoleContactsResponse extends BaseResponse {
  RoleContacts: Array<Contact>;
}

class AssignRoleBase {
  UserAuthId?: number;
  ContactId: number;
  Login?: string;
  Operator?: number;
  UserId: number;
  UserRole?: string;
  AuthToken?: string;
}

export class AssignRolesModel extends AssignRoleBase {
  RoleIds = new Array<number>();
  RoleNames = new Array<string>();
}

export class AssignRoleModel extends AssignRoleBase {
  RoleId?: number;
  RoleName?: string;
}

@Injectable()
export class AuthorizationService {
  constructor(private _httpClient: HttpClient) {}

  getPermissions(roleId: number): Observable<GetPermissionsResponse> {
    let url = 'Authorization/Permissions';
    if (roleId !== undefined) {
      url += `/?roleId=${roleId}`;
    }

    return this._httpClient.get<GetPermissionsResponse>(url);
  }

  getUserRoles(userAuthId: number): Observable<GetRolesResponse> {
    return this._httpClient.get<GetRolesResponse>(`Authorization/users/${userAuthId}/roles`);
  }

  assignRole(model: AssignRoleModel): Observable<BaseResponse> {
    return this._httpClient.post<BaseResponse>('Authorization/AssignRole', model);
  }

  assignRoles(model: AssignRolesModel): Observable<BaseResponse> {
    return this._httpClient.post<BaseResponse>('Authorization/AssignRoles', model);
  }

  unassignRole(model: AssignRoleModel): Observable<BaseResponse> {
    return this._httpClient.post<BaseResponse>('Authorization/UnassignRole', model);
  }

  // Roles
  getRoles(): Observable<GetRolesResponse> {
    return this._httpClient.get<GetRolesResponse>('Authorization/Roles');
  }
  saveRole(model: Role): Observable<SaveRoleResponse> {
    return this._httpClient.put<SaveRoleResponse>('Authorization/Roles', { Role: model });
  }
  deleteRole(roleId: number): Observable<BaseResponse> {
    return this._httpClient.delete<BaseResponse>(`Authorization/Roles/?id=${roleId}`);
  }

  // Permissions
  savePermission(model: Permission): Observable<SavePermissionResponse> {
    return this._httpClient.put<SavePermissionResponse>('Authorization/Permissions', { Permission: model });
  }
  deletePermission(permissionId): Observable<BaseResponse> {
    return this._httpClient.delete<BaseResponse>(`Authorization/Permissions/?id=${permissionId}`);
  }

  savePermissions(model: Array<Permission>): Observable<SavePermissionsResponse> {
    return this._httpClient.put<SavePermissionsResponse>('Authorization/PermissionList', { Permissions: model });
  }

  // Contacts
  getRoleContacts(roleId: number): Observable<GetRoleContactsResponse> {
    return this._httpClient.get<GetRoleContactsResponse>(`Authorization/Roles/${roleId}/Users`);
  }
}
