<div class="activity-grid kendo-grid-bordered" #gridContainer>
  <kendo-grid
    #grid="kendoGrid"
    [data]="view | async"
    [pageSize]="state.take"
    [skip]="state.skip"
    [sort]="state.sort"
    [group]="state.group"
    [sortable]="{ mode: 'multiple' }"
    [height]="300"
    [pageable]="true"
    [groupable]="groupable"
    [selectable]="true"
    (dataStateChange)="dataStateChange($event)"
    (groupChange)="groupChange($event)"
    (excelExport)="excelExport($event)"
    [rowClass]="rowCallback.bind(this)"
    [filterable]="'menu'"
    [filter]="state.filter"
    [reorderable]="true"
    (columnReorder)="columnReorder($event, grid)"
    (selectionChange)="rowSelectionChange($event)"
    (cellClick)="cellClick($event)"
  >
    <ng-template kendoGridToolbarTemplate>
      <div class='d-flex justify-content-between' style='width: 100%'>
        <div class='d-flex'>
          <button k-button (click)="pdfExport($event)" class="k-button k-button-icontext hidden-on-narrow" tabindex="0">
            <span class="k-icon k-i-pdf"></span>Export to PDF
          </button>
          <button kendoGridExcelCommand><span class="k-icon k-i-file-excel"></span>Export to Excel</button>
          <div class="grid-legend">
            <img src="/img/general-icons/infoIcon.svg" />By clicking Incident row you will be taken to the incident
            details page.
          </div>
        </div>
        <div class='d-flex'>
          <button (click)="openFieldsPicker()" class="pull-right grid-settings"><span class="k-icon k-i-gear"></span></button>
        </div>
      </div>


    </ng-template>
    <kendo-grid-pdf [fileName]="pdfFileName" [allPages]="true" paperSize="auto" [repeatHeaders]="false" [landscape]="true" [scale]="0.5">
      <kendo-grid-pdf-margin top="2cm" left="1cm" right="1cm" bottom="2cm"></kendo-grid-pdf-margin>
    </kendo-grid-pdf>
    <kendo-grid-excel [fileName]="excelFileName" [fetchData]="fetchData.bind(this)">
      <kendo-excelexport-column *ngFor="let field of fields" [title]="field.displayName" [field]="field.fieldName" [width]="field.width">
      </kendo-excelexport-column>

      <kendo-excelexport-column title="Details" field="MergedFields" width="250"></kendo-excelexport-column>
      <kendo-excelexport-column title="fkCallCenter" field="fkCallCenter"></kendo-excelexport-column>
    </kendo-grid-excel>

    <kendo-grid-column *ngFor="let dynField of fields" [title]="dynField.displayName" [field]="dynField.fieldName" [width]="dynField.width">
      <ng-template *ngIf="!!dynField.groupHeaderTemplate" kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
        <ng-container
          [ngTemplateOutlet]="dynField.groupHeaderTemplate"
          [ngTemplateOutletContext]="{ $implicit: group, field: field, value: value }"
        >
        </ng-container>
      </ng-template>

      <ng-template *ngIf="!!dynField.cellTemplate" kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex" let-column="column">
        <ng-container
          [ngTemplateOutlet]="dynField.cellTemplate"
          [ngTemplateOutletContext]="{ $implicit: dataItem, rowIndex: rowIndex, column: column, field: dynField }"
        >
        </ng-container>
      </ng-template>

      <ng-template *ngIf="dynField.groupFooterTemplate" kendoGridGroupFooterTemplate let-group="group" let-aggregates let-field="field">
        <ng-container
          [ngTemplateOutlet]="dynField.groupFooterTemplate"
          [ngTemplateOutletContext]="{ $implicit: aggregates, group: group, field: field }"
        >
        </ng-container>
      </ng-template>
    </kendo-grid-column>

    <ng-template kendoGridDetailTemplate let-dataItem [kendoGridDetailTemplateShowIf]="showDetail">
      {{ dataItem.MergedFields }}
    </ng-template>
  </kendo-grid>
</div>

<app-audio-records #audioRecords></app-audio-records>
<app-video-record #videoRecord></app-video-record>
<app-voicebot-transcription #voiceBotTranscription></app-voicebot-transcription>
<app-activity-grid-fields-picker #activityGridFieldsPicker></app-activity-grid-fields-picker>
<app-activity-add-note #addNote></app-activity-add-note>

<ng-template #CallTime_header let-group let-field="field" let-value="value">
  Date: {{ value | kendoDate: "MMM d hh:mm:ss a" }}
</ng-template>
<ng-template #CallTime_cell let-dataItem>
  {{ dataItem.CallTime | kendoDate: "MMM d hh:mm:ss a" }}
</ng-template>

<ng-template #Vend_footer let-group="group" let-field="field" let-aggregates>
  {{ aggregates['Vend']?.sum }}
</ng-template>
<ng-template #Vend_cell let-dataItem>
  <span [class.vend-warning]="dataItem.Vend">
    {{ dataItem.Vend ? 'YES' : 'NO' }}
  </span>
</ng-template>

<ng-template #IsCallClosedByAgent_cell let-dataItem>
  <span>
    {{ dataItem.IsCallClosedByAgent ? 'YES' : 'NO' }}
  </span>
</ng-template>

<ng-template #QueueTime_footer let-group="group" let-aggregates>
  {{ aggregates['QueueTime']?.sum | duration: 'HH:mm' }}
</ng-template>
<ng-template #QueueTime_cell let-dataItem>
  <div #queueTime class="activity-cell" [ngClass]="queueFieldClass(queueTime, dataItem.QueueTime, dataItem.fkCallCenter)">
    {{ dataItem.QueueTime | duration: 'HH:mm' }}
  </div>
</ng-template>

<ng-template #CallDuration_footer let-group="group" let-aggregates>
  {{ aggregates['CallDuration']?.sum | duration: 'HH:mm' }}
</ng-template>
<ng-template #CallDuration_cell let-dataItem>
  <div #duration class="activity-cell" [ngClass]="durationFieldClass(duration, dataItem.CallDuration, dataItem.fkCallCenter)">
    {{ dataItem.CallDuration | duration: 'HH:mm' }}
  </div>
</ng-template>

<ng-template #Wrapup_footer let-group="group" let-aggregates>
  {{ aggregates['Wrapup']?.sum | duration: 'HH:mm' }}
</ng-template>
<ng-template #Wrapup_cell let-dataItem>
  <div #wrapup class="activity-cell" [ngClass]="wrapupFieldClass(wrapup, dataItem.Wrapup, dataItem.fkCallCenter)">
    {{ dataItem.Wrapup | duration: 'HH:mm' }}
  </div>
</ng-template>

<ng-template #TotalTime_footer let-group="group" let-aggregates>
  {{ aggregates['TotalTime']?.sum | duration: 'HH:mm' }}
</ng-template>
<ng-template #TotalTime_cell let-dataItem>
  <div #total class="activity-cell" [ngClass]="totalFieldClass(total, dataItem.TotalTime, dataItem.fkCallCenter)">
    {{ dataItem.TotalTime | duration: 'HH:mm' }}
  </div>
</ng-template>

<ng-template #IsRecordingFound_cell let-dataItem>
  <button *ngIf="dataItem.IsRecordingFound" class="button button--tiny button--secondary" (click)="showRecords(dataItem.Id, $event)">
    <i class="fa fa-music"></i>
  </button>
</ng-template>

<ng-template #IsVideoRecordingFound_cell let-dataItem>
  <button
    *ngIf="dataItem.IsVideoRecordingFound"
    class="button button--tiny button--secondary"
    (click)="showVideoRecord(dataItem.Id, $event)"
  >
    <i class="fa fa-play"></i>
  </button>
</ng-template>

<ng-template #bool_cell let-dataItem let-column="column" let-field="field">
  {{ dataItem[field.fieldName] ? 'YES' : 'NO' }}
</ng-template>
<ng-template #DateTime_cell let-dataItem let-field="field">
  {{ dataItem[field.fieldName] | kendoDate: "MMM d hh:mm:ss a" }}
</ng-template>

<ng-template #VBEventId_cell let-dataItem let-column="column" let-field="field">
  {{ dataItem[field.fieldName] ? 'YES' : 'NO' }}
</ng-template>

<ng-template #VBEventFileName_cell let-dataItem>
  <button
    *ngIf="dataItem.VBEventFileName"
    class="button button--tiny button--secondary"
    (click)="showVBEventRecords(dataItem.VBEventConferenceId, $event)"
  >
    <i class="fa fa-music"></i>
  </button>
</ng-template>

<ng-template #VBEventCallSegment_cell let-dataItem>
  <button
    *ngIf="dataItem.VBEventCallSegment"
    class="button button--tiny button--secondary"
    (click)="showTranscript(dataItem.Id, dataItem.VBEventConferenceId, $event)"
  >
    <i class="fa fa-file-text"></i>
  </button>
</ng-template>

<ng-template #VBEventDur_footer let-group="group" let-aggregates>
  {{ aggregates['VBEventDur']?.sum | duration: 'HH:mm' }}
</ng-template>
<ng-template #VBEventDur_cell let-dataItem>
  <div
    *ngIf="dataItem.VBEventId"
    #vbduration
    class="activity-cell"
    [ngClass]="durationFieldClass(vbduration, dataItem.VBEventDur, dataItem.fkCallCenter)"
  >
    {{ dataItem.VBEventDur | duration: 'HH:mm' }}
  </div>
  <div *ngIf="!dataItem.VBEventId" #vbduration class="activity-cell">__:__</div>
</ng-template>

<ng-template #NoodoeStationChargeStarted_cell let-dataItem let-column="column" let-field="field">
  {{ dataItem[field.fieldName] ? 'YES' : 'NO' }}
</ng-template>

<ng-template #NoodoeStationChargeStopped_cell let-dataItem let-column="column" let-field="field">
  {{ dataItem[field.fieldName] ? 'YES' : 'NO' }}
</ng-template>

<ng-template #NoodoeStationReseted_cell let-dataItem let-column="column" let-field="field">
  {{ dataItem[field.fieldName] ? 'YES' : 'NO' }}
</ng-template>
