import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { IRestBaseListResponse } from '../models';

export interface MitteLocation {
  Id: string;
  Name: string;
}

export interface OcraLocationsResponse extends IRestBaseListResponse<MitteLocation> {}

@Injectable({ providedIn: 'root' })
export class MitteService {
  constructor(private _httpClient: HttpClient) {}

  getLocations(): Observable<OcraLocationsResponse> {
    return this._httpClient.get<any>('mitte/location');
  }
}
