import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, of } from 'rxjs';

import { BaseResponse } from '../models/rest/baseResponse';
import { IMonitoringPageSettings, IMonitoringWidget } from '../models/monitoring-settings';
import { isPlatformBrowser } from '@angular/common';

export interface IMonitoringPageSettingsResponse extends BaseResponse {
  MonitoringSettings: IMonitoringPageSettings;
}

const PORTAL_MONITORING_SETTINGS = 'monitoring-settings';
const DEFAULT_PORTAL_WIDGETS: Array<IMonitoringWidget> = [
  { Type: 'CallsInQueue', Order: 0 },
  { Type: 'TotalCalls', Order: 1 },
  { Type: 'AverageQueueTimeLH', Order: 2 },
  { Type: 'AverageQueueTimeLD', Order: 3 },
  { Type: 'AverageCallLength', Order: 4 },
  { Type: 'AverageWrapupTime', Order: 5 },
  { Type: 'AbandonedCalls', Order: 6 },
  { Type: 'CancelledByAgentCalls', Order: 7 },
  { Type: 'DisconnectedCalls', Order: 8 },
  { Type: 'MaxCallsInQueue', Order: 9 },
  { Type: 'LongestQueueTime', Order: 10 },
  { Type: 'QueueTimeOver30s', Order: 11 },
];

@Injectable({ providedIn: 'root' })
export class MonitoringSettingsService {
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private _httpClient: HttpClient,
  ) {}

  getAgentSettingsById(id: number): Observable<IMonitoringPageSettingsResponse> {
    return this._httpClient.get<IMonitoringPageSettingsResponse>(`MonitoringSettings/${id}/agent`);
  }

  getPortalSettings(): Observable<Array<IMonitoringWidget>> {
    return of(this.getPortalMonitoringSettings().sort((x) => x.Order));
  }

  getAgentSettingsByRegionId(id: number): Observable<IMonitoringPageSettingsResponse> {
    return this._httpClient.get<IMonitoringPageSettingsResponse>(`region/${id}/monitoringsettings/agent`);
  }

  insertOrUpdateAgentSettings(settings: IMonitoringPageSettings): Observable<IMonitoringPageSettingsResponse> {
    return this._httpClient.post<IMonitoringPageSettingsResponse>('MonitoringSettings/agent', { MonitoringSettings: settings });
  }

  savePortalSettings(widgets: Array<IMonitoringWidget>): Observable<Array<IMonitoringWidget>> {
    this.setPortalMonitoringSettings(widgets);
    return of(widgets);
  }

  restoreDefaultAgentSettings(settings: IMonitoringPageSettings): Observable<IMonitoringPageSettingsResponse> {
    return this._httpClient.post<IMonitoringPageSettingsResponse>('MonitoringSettings/agent/default', { MonitoringSettings: settings });
  }

  restoreDefaultPortalSettings(): Observable<Array<IMonitoringWidget>> {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.removeItem(PORTAL_MONITORING_SETTINGS);
    }
    return of(JSON.parse(JSON.stringify(DEFAULT_PORTAL_WIDGETS)));
  }

  private getPortalMonitoringSettings(): Array<IMonitoringWidget> {
    let item: string = null;

    if (isPlatformBrowser(this.platformId)) {
      item = localStorage.getItem(PORTAL_MONITORING_SETTINGS);
    }

    return !!item ? JSON.parse(item) : JSON.parse(JSON.stringify(DEFAULT_PORTAL_WIDGETS));
  }

  private setPortalMonitoringSettings(value: Array<IMonitoringWidget>) {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.setItem(PORTAL_MONITORING_SETTINGS, JSON.stringify(value));
    }
  }
}
