import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';

import { tap, filter, mergeMap } from 'rxjs/operators';

import { ModalDirective } from 'ngx-bootstrap/modal';

import { IIntegrationAppService, IOutgoingConversation, OutgoingConversationState } from '../../abstractions';
import { IntegrationAppFactoryService } from '../services';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-skype-conversation',
  templateUrl: './skype-conversation.component.html',
  styleUrls: ['./skype-conversation.component.scss'],
})
export class SkypeConversationComponent implements OnInit, OnDestroy {
  @ViewChild('modal', { static: true }) public modal: ModalDirective;

  private subscriptions = new Subscription();
  integration: IIntegrationAppService;
  conversation: IOutgoingConversation;
  conversationState: OutgoingConversationState;
  leaving = false;

  constructor(private integrationFactory: IntegrationAppFactoryService) {
    this.integration = integrationFactory.getApp();
  }

  ngOnInit() {
    this.integration.outgoingCall$
      .pipe(
        filter((call) => !!call),
        tap((conversation) => {
          this.conversation = conversation;
          this.modal.show();
        }),
        mergeMap((conversation) => conversation.state$),
        tap((state) => (this.conversationState = state)),
        filter((state) => state === 'Disconnected'),
      )
      .subscribe((state) => {
        this.conversation = null;
        this.modal.hide();
      });
  }

  leaveDisabled(): boolean {
    return this.conversationState !== 'Connected' || this.leaving;
  }
  leaveConversation() {
    if (!this.conversation) {
      return;
    }

    this.leaving = true;
    this.subscriptions.add(
      this.conversation.leave().subscribe(
        (result) => {
          this.leaving = false;
        },
        (err) => {
          this.leaving = false;
        },
      ),
    );
  }

  cancelEnabled(): boolean {
    return this.conversationState === 'Created' || this.conversationState === 'Connecting';
  }
  cancel() {
    this.leaveConversation();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
